import React, { useState, useEffect } from 'react'
import s from './select.module.scss'
import arrowDownBlueIcon from '../assets/arrowDownBlue.svg'

export const Select = ({
	selected,
	setSelected,
	placeholder,
	dataList,
	headerSelect,
	setActiveModal
}) => {
	const [isActive, setIsActive, notificationText] = useState(false)

	return (
		<div className={s.container}>
			<div className={s.select}>
				<h3>{headerSelect}</h3>
				<div onClick={() => setIsActive(!isActive)} className={s.selectBtn}>
					{selected
						? selected?.title
							? selected?.title
							: selected?.representative
							? selected?.representative?.full_name
							: selected?.realty_address
							? selected?.realty_address
							: selected?.Name
							? selected?.Name
							: selected?.full_name
							? selected?.full_name
							: selected?.name
							? selected?.name
							: `Обращение ${
									1 + dataList?.findIndex((index) => index?.id === selected?.id)
							  }`
						: placeholder}

					<img src={arrowDownBlueIcon} alt='' />
				</div>

				{isActive && (
					<div className={s.selectContent}>
						{dataList?.map((item, i) => {
							const key = item?.id
								? 'id'
								: item?.deal_id
								? 'deal_id'
								: 'individual_id'

							return (
								<div
									key={i}
									className={
										(
											item?.representative
												? item?.representative?.[key] ===
												  selected?.representative?.[key]
												: selected?.[key] === item?.[key]
										)
											? s.selectItemActive
											: s.selectItem
									}
									onClick={() => {
										setSelected(item)
										setIsActive(false)
										setTimeout(() => {
											setActiveModal(false)
										}, 3000)
									}}
								>
									{item?.title}
									{item?.Name ? item?.Name : item?.name}
									{item.hasOwnProperty('active_appeal')
										? `Обращение ${i + 1}`
										: item?.representative
										? item?.representative?.full_name
										: item?.realty_address
										? item?.realty_address
										: item?.full_name}
								</div>
							)
						})}
					</div>
				)}
			</div>
		</div>
	)
}
