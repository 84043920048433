import React, { useEffect, useState } from 'react'
import s from './dealContent.module.scss'
import { Link } from 'react-router-dom'
import statusesTrue from '../../../common/images/deal/statusesTrue.svg'
import statusesFalse from '../../../common/images/deal/statusesFalse.svg'
import Button from '../../button/button'
import ReviewHistory from '../../../blocks/reviewHistory/reviewHistory'
import ObjectPayment from '../../../blocks/objectPayment/objectPayment'
import Duties from '../../../blocks/duties/duties'
import Documents from '../../../blocks/documents/documents'
import ParticipantsTransaction from '../../../blocks/participantsTransaction/participantsTransaction'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { deals } from '../../../api/deals/deals'
import { Modal } from '../../modal/modal'
import Input from '../../input/input'
import { appeals } from '../../../api/appeals'
import { setDeleteDeal, setDeleteDeals } from '../../../redux/deals/deals'
import { setAppealDeal } from '../../../redux/appeals/appeals'
import SendingRequestsRr from '../../../blocks/sendingRequestsRR/sendingRequestsRR'
import { setRealtyTextStatus } from '../../../redux/realty/realty'

const DealContent = ({ deal, appealDeal, appealId }) => {
	const accessToken = localStorage.getItem('accessToken')
	const appeal_id = localStorage.getItem('appeal_id')
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [windowAppealPP, setWindowAppealPP] = useState(false)
	const [openWindowDocumentCreation, setOpenWindowDocumentCreation] =
		useState(false)
	const [openDeleteDeal, setOpenDeleteDeal] = useState()
	const [documentType, setDocumentType] = useState(null)
	const { typeAppeals, appealDealStatusWS } = useSelector(
		(state) => state.appeals
	)

	const { deleteDeals, statementGenerate } = useSelector((state) => state.deals)
	const { realtyTextStatus } = useSelector((state) => state.realty)
	const { notificationText } = useSelector((state) => state.manager)
	const baseURL = process.env.REACT_APP_BASE_URL

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [openWindowDocumentCreation])

	useEffect(() => {
		setDocumentType(null)
	}, [appealId])

	useEffect(() => {
		if (!realtyTextStatus) return
		setTimeout(() => {
			dispatch(setRealtyTextStatus(null))
		}, 2000)
	}, [realtyTextStatus])

	useEffect(() => {
		dispatch(appeals.typeAppealsList(accessToken))
	}, [])

	useEffect(() => {
		if (!appealDealStatusWS) return
		appealDeal?.active_appeal &&
			dispatch(setAppealDeal({ ...appealDeal, ...appealDealStatusWS?.status }))
	}, [appealDealStatusWS])

	useEffect(() => {
		if (!documentType) return
		dispatch(appeals.editAppealType(documentType?.id, appeal_id, accessToken))
		dispatch(deals.deal(deal.id, accessToken))
	}, [documentType, appealId])

	const handleDeleteDealOrAppeal = async () => {
		await dispatch(deals.dealOrAppealDelete(accessToken, appealId?.id))

		if (appealId?.id === deal?.appeals[0]?.id && deal?.appeals?.length === 1) {
			dispatch(setDeleteDeal(deal?.id))
		} else {
			dispatch(setAppealDeal(appealId?.id))
		}
		setOpenDeleteDeal(false)
		window.location.reload()
	}

	useEffect(() => {
		if (deleteDeals) {
			dispatch(setDeleteDeals(false))
			setOpenDeleteDeal(false)
			navigate('/userRoles')
		}
	}, [deleteDeals])

	const statementGenerateFC = () => {
		setWindowAppealPP(true)
	}

	const sendPP = () => {
		dispatch(deals.requestRosreestr(appealDeal?.id, accessToken))
	}

	const dataRR = false

	return (
		<div className={s.wrapper}>
			<div>
				<div className={s.dealContent}>
					<h3>Обращение в Росреестр</h3>
					<div className={s.dealContent__wrapper}>
						<div>
							<h3>{appealId?.title}</h3>
							{appealId?.status && deal?.appeals?.length > 1 && (
								<Input
									disabled
									value={documentType?.title}
									select
									notificationText={notificationText}
									setSelectedValue={setDocumentType}
									selectedValue={documentType}
									dataList={typeAppeals}
									placeholderForSelect='Изменить тип документа'
									placeholder='Изменить тип документа'
								/>
							)}
						</div>
						{pp1 ? (
							<div className={s.dealContent__wrapper__termsStatusTrueWrapper}>
								{pp2 ? (
									<div
										className={
											s.dealContent__wrapper__termsStatusTrueWrapper__item
										}
									>
										<div>
											<span
												className={
													s.dealContent__wrapper__termsStatusTrueWrapper__item__trueSpan
												}
											>
												Зарегистрировано
											</span>
											<Link to=''>Регистрационные данные обращения</Link>
										</div>
										<ReviewHistory />
									</div>
								) : (
									<div
										className={
											s.dealContent__wrapper__termsStatusTrueWrapper__item
										}
									>
										<div>
											<span
												className={
													s.dealContent__wrapper__termsStatusTrueWrapper__item__falseSpan
												}
											>
												На рассмотрении
											</span>
											<Link to=''>Регистрационные данные обращения</Link>
										</div>
										<ReviewHistory dropDown={true} />
									</div>
								)}
							</div>
						) : (
							<div className={s.dealContent__wrapper__termsStatusFalseWrapper}>
								<div
									className={
										s.dealContent__wrapper__termsStatusFalseWrapper__wrapper
									}
								>
									<div
										className={
											s.dealContent__wrapper__termsStatusFalseWrapper__wrapper__item
										}
									>
										{appealDeal?.general_pd_status ? (
											<img src={statusesTrue} alt='img' />
										) : (
											<img src={statusesFalse} alt='img' />
										)}
										<p>Данные участников</p>
										<p>предоставлены и проверены</p>
									</div>

									<div
										className={
											s.dealContent__wrapper__termsStatusFalseWrapper__wrapper__item
										}
									>
										{appealDeal?.general_ecp_status ? (
											<img src={statusesTrue} alt='img' />
										) : (
											<img src={statusesFalse} alt='img' />
										)}
										<p>Электронные подписи</p>
										<p>для всех участников</p>
									</div>

									<div
										className={
											s.dealContent__wrapper__termsStatusFalseWrapper__wrapper__item
										}
									>
										{appealDeal?.general_docs_status ? (
											<img src={statusesTrue} alt='img' />
										) : (
											<img src={statusesFalse} alt='img' />
										)}
										<p>Документы по сделке</p>
										<p>загружены и подписаны</p>
									</div>

									<div
										className={
											s.dealContent__wrapper__termsStatusFalseWrapper__wrapper__item
										}
									>
										{appealDeal?.general_rosreestr_status ? (
											<img src={statusesTrue} alt='img' />
										) : (
											<img src={statusesFalse} alt='img' />
										)}
										<p>Обращение</p>
										<p>подписали все участники</p>
									</div>
								</div>
							</div>
						)}
						{(!appealDeal?.request_rosreestr ||
							appealDeal?.request_rosreestr?.status === 'create' ||
							(appealDeal?.request_rosreestr?.status_label === 'Ошибка при отправке'|| appealDeal?.request_rosreestr?.retry_send === 6)) && (
							<>
								{appealDeal?.general_docs_status &&
									!appealDeal?.general_rosreestr_status && (
										<Button
											FCOnclick={statementGenerateFC}
											disabled={statementGenerate}
										>
											Сформировать заявление
										</Button>
									)}
								{appealDeal?.general_rosreestr_status && (
									<Button FCOnclick={sendPP} filters={statementGenerate}>
										Оформить заявку в Росреестр
									</Button>
								)}
								{realtyTextStatus && (
									<div className={s.dealContent__wrapper__errorText}>
										{realtyTextStatus.description}
									</div>
								)}
							</>
						)}

						{appealDeal?.request_rosreestr?.status !== 'create' &&
							appealDeal?.request_rosreestr && (
								<div className={s.dealContent__wrapper__statusRrWrapper}>
									<h4>История рассмотрения</h4>
									<div>
										<span>
											{appealDeal?.request_rosreestr?.date_send
												? appealDeal?.request_rosreestr?.date_send
												: ' - '}
										</span>
										<div>
											<div
												className={
													s.dealContent__wrapper__statusRrWrapper__item
												}
											>
												<span>
													Обращение отправлено <br />
													Номер КУВД:
													<i>
														{appealDeal?.request_rosreestr?.kuvd
															? appealDeal?.request_rosreestr?.kuvd
															: ' - '}
													</i>
												</span>
												<a
													href={`${baseURL}/file-cloud/${appealDeal?.request_rosreestr?.package_path}`}
													target='_blank'
												>
													Скачать отправленное
												</a>
											</div>
											<div
												className={
													s.dealContent__wrapper__statusRrWrapper__participant
												}
											>
												{appealDeal?.documents.map((item) => {
													return (
														<>
															{item.participants_documents.length &&
																item.participants_documents.map((status) => {
																	return (
																		<div>
																			<span>
																				{status.representative
																					? `${status.representative?.full_name} / ${status?.full_name}`
																					: status?.full_name}
																			</span>
																			<span
																				style={
																					status
																						? { color: '#1FA34A' }
																						: { color: '#5D576B' }
																				}
																			>
																				{' '}
																				{status.task_status}
																			</span>
																		</div>
																	)
																})}
														</>
													)
												})}
											</div>
										</div>
									</div>
									<div style={{ marginBottom: '15px' }}>
										<div>
											<div
												className={
													s.dealContent__wrapper__statusRrWrapper__statusRr
												}
											>
												<div>
													Статус обращения РР:
													<i>{appealDeal?.request_rosreestr?.status_label}</i>
												</div>

												{appealDeal?.request_rosreestr?.request_file_local ? (
													<a
														href={`${baseURL}/file-cloud/${appealDeal?.request_rosreestr?.request_file_local}`}
														target='_blank'
														rel='noreferrer'
													>
														Пакет по обращению
													</a>
												) : null}
											</div>
										</div>
									</div>
									{dataRR && (
										<div style={{ display: 'flex' }}>
											<span style={{ fontWeight: '300' }}>
												{appealDeal?.request_rosreestr?.date_send
													? appealDeal?.request_rosreestr?.date_send
													: ' - '}
											</span>
											<div>
												<div
													className={
														s.dealContent__wrapper__statusRrWrapper__item
													}
												>
													<span>Ответ Росреестра</span>
													<a
														href={`${baseURL}/file-cloud/${appealDeal?.request_rosreestr?.package_path}`}
														target='_blank'
													>
														Скачать отправленное
													</a>
												</div>
											</div>
										</div>
									)}
								</div>
							)}
					</div>
				</div>
				<ParticipantsTransaction
					participants={appealDeal?.participant}
					seller={deal?.seller}
					deal={deal}
					appealId={appealId}
				/>
				<ObjectPayment object={appealDeal?.object} />
				<Duties appealDeal={appealDeal} />

				<Documents
					setOpenWindowDocumentCreation={setOpenWindowDocumentCreation}
					appealDeal={appealDeal}
					deal={deal}
					appealId={appealId}
				/>
				<div className={s.deleteMessage}>
					<button onClick={() => setOpenDeleteDeal(true)}>
						{appealId?.id === deal?.appeals[0]?.id
							? 'Удалить обращение и сделку'
							: 'Удалить обращение'}
					</button>
					{appealId?.id === deal?.appeals[0]?.id ? (
						<Modal
							onClose={true}
							active={openDeleteDeal}
							setActive={setOpenDeleteDeal}
						>
							<div className={s.popupDeleteDeal}>
								<p>
									{' '}
									Удалится обращение, сделка, и все данные по ним, восстановить
									нельзя
								</p>
								<p>Вы действительно хотите удалить обращение и сделку?</p>
								<div>
									<Button FCOnclick={() => setOpenDeleteDeal(false)}>
										Отмена
									</Button>
									<button onClick={() => handleDeleteDealOrAppeal()}>
										Да, удалить
									</button>
								</div>
							</div>
						</Modal>
					) : (
						<Modal
							onClose={true}
							active={openDeleteDeal}
							setActive={setOpenDeleteDeal}
						>
							<div className={s.popupDeleteDeal}>
								<p> Все данные из обращения удалятся, восстановить нельзя</p>
								<p>Вы действительно хотите удалить обращение?</p>
								<div>
									<Button FCOnclick={() => setOpenDeleteDeal(false)}>
										Отмена
									</Button>
									<button onClick={() => handleDeleteDealOrAppeal()}>
										Да, удалить
									</button>
								</div>
							</div>
						</Modal>
					)}
					<span>
						Вместе с последним оставшимся обращением удаляется вся сделка.
					</span>
				</div>
			</div>
			<Modal
				onClose
				fullScreen
				active={windowAppealPP}
				setActive={setWindowAppealPP}
			>
				<SendingRequestsRr
					windowAppealPP={windowAppealPP}
					setWindowAppealPP={setWindowAppealPP}
					additionalSendingRequestsRR={false}
					appealId={appealId}
					appealDeal={appealDeal}
				/>
			</Modal>
		</div>
	)
}

export default DealContent

const pp1 = false
const pp2 = false
