import React from 'react'
import ContainerContent from '../../components/container-content/container-content'
import { Modal } from '../../components/modal/modal'
import s from '../invitationToPersonalAccount/invitationToPersonalAccount.module.scss'
import Input from '../../components/input/input'
import {
	setCheckEmail,
	setCheckPhone,
	setInvitationAccountTextStatus
} from '../../redux/invitationToPersonalAccount/invitationToPersonalAccount'
import Button from '../../components/button/button'
import Container from '../../components/container/container'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { resetPassword } from '../../api/resetPassword/resetPassword'
import { setGlobalPersonalDataValue } from '../../redux/globalPersonalData/globalPersonalData'

const ResetPassword = ({ active, setActive, globalPersonalData }) => {
	const regEmail = /\S+@\S+\.\S+/
	const regPhone = /^\+?[8] [-\(]?\d{3}\) ?-?\d{3}-?\d{2}-?\d{2}$/
	const dispatch = useDispatch()
	const { checkEmail, checkPhone } = useSelector(
		(state) => state.invitationToPersonalAccount
	)
	const [disabledButton, setDisabledButton] = useState(true)

	const sendData = () => {
		dispatch(setInvitationAccountTextStatus(null))
		dispatch(resetPassword.client(checkPhone, checkEmail))
	}

	return (
		<Container>
			<ContainerContent>
				<Modal active={active} onClose={true} setActive={setActive}>
					<div className={s.invitationToPersonalAccount}>
						<h4>Сбросить пароль</h4>
						<div>
							<span>
								{globalPersonalData?.surname} {globalPersonalData?.name}{' '}
								{globalPersonalData?.patronymics}
							</span>
						</div>
						<div className={s.invitationToPersonalAccount__form}>
							<span>Почта</span>
							<div
								className={s.invitationToPersonalAccount__form__inputWrapper}
							>
								<Input
									value={
										globalPersonalData?.email ? globalPersonalData?.email : ''
									}
									name='email'
									validate={
										globalPersonalData?.email &&
										regEmail.test(globalPersonalData?.email)
									}
									onChange={(e) =>
										dispatch(
											setGlobalPersonalDataValue({
												name: e.target.name,
												value: e.target.value
											})
										)
									}
								/>
								<div
									className={
										s.invitationToPersonalAccount__form__inputWrapper__checkbox
									}
								>
									<Input
										type='checkbox'
										value={globalPersonalData?.email}
										onChangeChecked={setCheckEmail}
									/>
									<span>Отправить ссылку на почту</span>
								</div>
							</div>
						</div>
						<div className={s.invitationToPersonalAccount__form}>
							<span>Телефон</span>
							<div
								className={s.invitationToPersonalAccount__form__inputWrapper}
							>
								<Input
									name='phone'
									maxLength='17'
									validate={
										globalPersonalData?.phone &&
										regPhone.test(globalPersonalData?.phone)
									}
									value={
										globalPersonalData?.phone ? globalPersonalData?.phone : ''
									}
									onChange={(e) =>
										dispatch(
											setGlobalPersonalDataValue({
												name: e.target.name,
												value:
													+e.target.value.toString().length > 0 &&
													e.target.value.replace(
														/^(\d{1})(\d{3})(\d{3})(\d{2})(\d)+$/,
														'$1 ($2) $3-$4-$5'
													)
											})
										)
									}
								/>
								<div
									className={
										s.invitationToPersonalAccount__form__inputWrapper__checkbox
									}
								>
									<Input type='checkbox' onChangeChecked={setCheckPhone} />
									<span>Отправить ссылку на телефон</span>
								</div>
							</div>
						</div>
						{setInvitationAccountTextStatus && (
							<span>{setInvitationAccountTextStatus}</span>
						)}
						<div>
							<Button
								left={true}
								small={true}
								FCOnclick={sendData}
								disabled={disabledButton}
							>
								Сбросить пароль
							</Button>
						</div>
					</div>
				</Modal>
			</ContainerContent>
		</Container>
	)
}

export default ResetPassword
