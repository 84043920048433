import React from 'react'
import s from './message.module.scss'

const Message = ({
	text,
	date,
	colorBgDark,
	leftMessage,
	chatWindowRef,
	newMessages
}) => {
	return (
		<div
			className={s.message}
			style={
				colorBgDark
					? {
							background: 'rgba(31, 163, 74, 0.1)',
							border: 'rgba(31, 163, 74, 0.15)'
					  }
					: { background: '#FAFAFF' }
			}
			ref={chatWindowRef}
		>
			<div
				className={s.message__text}
				style={colorBgDark ? { color: '#35393C' } : { color: '#35393C' }}
			>
				<p>{text}</p>
			</div>
			<div className={s.message__date}>
				<span className={s.message__date__date}>{date}</span>
			</div>
		</div>
	)
}

export default Message
