import { Route, Routes } from 'react-router-dom'
import Login from './pages/login/login'
import PasswordRecovery from './pages/passwordRecovery/passwordRecovery'
import { useEffect, useState } from 'react'
import TaskList from './pages/entity/client/taskList'
import Deal from './pages/entity/client/deal/deal'
import DocumentCreation from './blocks/documentCreation/documentCreation'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	setLogin,
	setPersonalData,
	setUserName,
	setUserRole
} from './redux/login/login'
import {
	setWSListEgrn
} from './redux/admin/admin'
import { UserRoles } from './pages/entity/user-roles/user-roles'
import { config } from './app-config'
import PersonalData from './pages/entity/client/personalData/personalData'
import SignatureRelease from './components/signatureRelease/signatureRelease'
import SigningDocuments from './components/signingDocuments/signingDocuments'
import { ProfileClient } from './components/profile/profileClient'
import { TaskListContent } from './components/task-list/task-list'
import DealsManager from './pages/entity/manager/dealsManager'
import { DealPartyEdit } from './components/deal-party-edit/deal-party-edit'
import { ViewObjectPayment } from './components/view-object-payment/view-object-payment'
import {
	setLastMessages,
	setNewMessages,
	setPusNewMessages
} from './redux/chat/chat'
import { setEcpDataWS } from './redux/ecp'
import createAxiosResponseInterceptor, {
	refreshTokenFC
} from './api/refreshToken'
import {
	setDocumentIdWS,
	setNewAppealsDocumentWS,
	setNewAppealsPaymentsWS,
	setPaymentIdWS
} from './redux/deals/deals'
import { setDocumentWS } from './redux/documents/documents'
import {
	setAppealDealRequestRosreestrWS,
	setAppealDealStatusWS
} from './redux/appeals/appeals'
import { setWsConnect, setWsState } from './redux/webSocket/webSocket'
import axios from 'axios'
import { ViewStatement } from './components/egrn-new-statement/view-statement/view-statement'
import DutyPage from './components/dutyPage/dutyPage'
import DutyLoading from "./components/duty-loading/duty-loading";

function App() {
	const dispatch = useDispatch()
	const accessToken = localStorage.getItem('accessToken')
	const baseURL = process.env.REACT_APP_BASE_URL
	const { wsState } = useSelector((state) => state.webSocket)
	const { login: isLogin } = useSelector((state) => state.login)

	createAxiosResponseInterceptor()

	useEffect(() => {
		if (accessToken) {
			dispatch(setLogin(accessToken))
			dispatch(setWsConnect(false))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken])

	useEffect(() => {
		if (isLogin) {
			console.log('accessToken', localStorage.getItem('accessToken'))
			axios
				.get(`${baseURL}/me`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('accessToken')}`
					}
				})
				.then((response) => {
					dispatch(setUserRole(response.data.user_role))
					dispatch(setUserName(response.data.full_name))
					dispatch(
						setPersonalData({
							email: response.data.email,
							phone: response.data.phone
						})
					)

					if (!wsState) {
						connectWebsocket(localStorage.getItem('accessToken'))
					}
				})
				.catch((error) => {
					console.log('error', error)
				})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogin])

	const connectWebsocket = (accessToken) => {
		if (!accessToken) return
		console.log('connect...')
		let ws = new WebSocket(`${config.ws_url}?token=${accessToken}`)
		dispatch(setWsState(ws))

		ws.onerror = function (error) {
			console.log('onerror', error)
		}

		ws.onopen = function () {
			ws.send(JSON.stringify({ event: 'test', data: { test: 'ok' } }))
			console.log('WS: connection successfully')
			dispatch(setWsConnect(true))
		}

		ws.onmessage = function (event) {
			let data = JSON.parse(event.data)

			if (data.event === 'jwt.authorize') {
				if (data.event === 'jwt.authorize' && data.data.status === 'error') {
					dispatch(setWsConnect(false))
					refreshTokenFC()
						.then((response) => {
							localStorage.setItem('accessToken', response.data.access_token)
						})
						.catch(() => {
							localStorage.removeItem('accessToken')
						})
				}
			}

			if (data.event === 'check.new.messages') {
				dispatch(setNewMessages(data.data.new_messages))
			}

			if (data.event === 'last.messages') {
				dispatch(setLastMessages(data.data.messages))
			}

			if (data.event === 'messages.create' && data.event !== 'last.messages') {
				dispatch(setPusNewMessages(data.data))
			}

			if (data.event === 'ecp.update') {
				dispatch(setEcpDataWS(data.data))
			}

			if (data.event === 'sign.update' && data.client === localStorage.getItem('userId')) {
				dispatch(setNewAppealsDocumentWS(data.data))
				dispatch(setDocumentWS(data.data?.document))
			}

			if (data.event === 'sign.update' && ( localStorage.getItem('userRole') === 'AD' || "MA" )) {
				dispatch(setNewAppealsDocumentWS(data.data))
				dispatch(setDocumentWS(data.data?.document))
			}

			if (data.event === 'payment.update') {
				dispatch(setNewAppealsPaymentsWS(data.data))
			}

			if (data.event === 'deal.update') {
				dispatch(setAppealDealStatusWS(data.data))
			}

			if (data.event === 'document.client.status.update') {
				dispatch(setNewAppealsDocumentWS(data.data))
			}

			if (data.event === 'doc.delete') {
				dispatch(setDocumentIdWS(data.data.document_id))
			}

			if (data.event === 'payment.delete') {
				dispatch(setPaymentIdWS(data.data.payment_id))
			}

			if (data.event === 'rosreestr.requests.update') {
				dispatch(setAppealDealRequestRosreestrWS(data.data))
				dispatch(setWSListEgrn(data.data))
			}
		}

		const intervalId = setInterval(() => {
			console.log('check connection...')
			if ([2, 3].includes(ws.readyState)) {
				clearInterval(intervalId)
				connectWebsocket(accessToken)
				return
			}
			ws.send(JSON.stringify({ event: 'system.heartbeat' }))
		}, 1000 * 50)
	}
	
	return (
		<div className={`app`}>
			<Routes>
				<Route path='/' element={<DealsManager />} />
				<Route path='/login' element={<Login />} />

				<Route path='/login/:uid64/:token' element={<Login />} />

				<Route path='/profileClient' element={<ProfileClient />} />
				<Route path='/taskListClient' element={<TaskListContent />} />
				<Route path='/personalDataClient' element={<PersonalData />} />
				<Route path='/electronicSignature' element={<SignatureRelease />} />
				<Route
					path='/signingDocuments/:documentId'
					element={<SigningDocuments />}
				/>
				<Route path='/userRoles' element={<UserRoles />} />

				<Route path='/deal/:dealId' element={<Deal />} />
				<Route path='/dataClient/:partId' element={<DealPartyEdit />} />
				<Route path='/dataClient' element={<DealPartyEdit />} />
				<Route path='/taskList' element={<TaskList />} />
				<Route path='/personalData' element={<PersonalData />} />
				<Route path='/dutyLoading' element={<DutyLoading />} />

				<Route path='/documentCreation' element={<DocumentCreation />} />
				<Route
					path='/documentCreation/:documentId'
					element={<DocumentCreation />}
				/>
				<Route
					path='/viewObjectPayment/:realtyId'
					element={<ViewObjectPayment />}
				/>
				<Route
					path='/passwordRecovery/:uid64/:token'
					element={<PasswordRecovery />}
				/>
				<Route path='/dutyPage' element={<DutyPage />} />

				<Route path='/userRoles/extract/:id' element={<ViewStatement />} />
				<Route path='/' element={<UserRoles />} />
			</Routes>
		</div>
	)
}

export default App
