import axios from 'axios'

const baseURL = process.env.REACT_APP_BASE_URL

export const technicalSupport = {
	sendFile(formData, accessToken, setProgressUploadFile) {
		return async () => {
			try {
				const response = await axios.post(
					`${baseURL}/support_chat/upload_file`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				setProgressUploadFile(response)
			} catch (error) {
				console.log(error)
			}
		}
	}
}
