import axios from 'axios'
import {
	setAchiveDeal,
	setAddAppeal,
	setDeal,
	setDealId,
	setDeals,
	setDeleteDeals,
	setFoundRealty,
	setHideRequestRosreestr,
	setStatementGenerate
} from '../../redux/deals/deals'
import { setAppealDeal } from '../../redux/appeals/appeals'
import { setAddEmployee, setEmployees } from '../../redux/admin/admin'
import { setRealtyTextStatus } from '../../redux/realty/realty'
import { alertStatusText } from '../../components/alertStatusText/alertStatusText'
import { useNavigate } from 'react-router-dom'

const baseURL = process.env.REACT_APP_BASE_URL

export const deals = {
	deal(dealId, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/deals/${dealId}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				dispatch(setDeal(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
	dealsList(accessToken, itemOffset, itemsLength, archiveStatus, searchParams) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/deals?page=${itemOffset}&limit=${itemsLength}&archive=${archiveStatus}`,
					{
						params: searchParams,
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(setDeals(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
	dealsArchive(id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.patch(
					`${baseURL}/deals/${id}/archive`,
					{},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setAchiveDeal(id))
			} catch (error) {
				console.log(error)
			}
		}
	},
	dealCreate(data, accessToken, navigate) {
		
		return async (dispatch) => {
			try {
				const responseCreateDeal = await axios.post(`${baseURL}/deal`, data, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})

				const responseDataList = await axios.get(`${baseURL}/deals`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})

				dispatch(setDeals(responseDataList.data))
				dispatch(setDealId(responseCreateDeal.data.id))
				navigate(`/deal/${responseCreateDeal.data.id}`)
			} catch (error) {
				console.log(error)
			}
		}
	},
	dealOrAppealDelete(accessToken, appealId) {
		return async (dispatch) => {
			try {
				const response = await axios.post(`${baseURL}/appeals/${appealId}/delete`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				dispatch(setDeleteDeals(true))
			} catch (error) {
				console.log(error)
			}
		}
	},
	editNotes(accessToken, dealId, notes) {
		return async (dispatch) => {
			try {
				const response = await axios.patch(
					`${baseURL}/deals/${dealId}/note`,
					{ notes },
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
			} catch (error) {
				console.log(error)
			}
		}
	},
	realtySearch(numberAgreement, dateAgreement, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/deal/search_agreement?number_agreement=${numberAgreement}&date_agreement=${dateAgreement}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(setFoundRealty(response.data))
			} catch (error) {
				dispatch(setFoundRealty(undefined))
				console.log(error)
			}
		}
	},

	createSecondAppeal(deal_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/deals/${deal_id}/create_second_appeal`,
					{},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
			} catch (error) {
				console.log(error)
			}
		}
	},
	statementGenerate(
		appeal_id,
		accessToken,
		statementDetailsData,
		arrDocumentsIds,
		arrAppealsIds
	) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/egrn/statement_generate/${appeal_id}`,
					{
						statement_details_data: statementDetailsData,
						signers_ids: arrAppealsIds,
						documents_ids: arrDocumentsIds
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setRealtyTextStatus(alertStatusText('Сохранено', true)))
			} catch (error) {
				dispatch(
					setRealtyTextStatus(
						alertStatusText(
							error.response.data.detail
								? error.response.data.detail
								: 'Что-то пошло не так',
							false
						)
					)
				)
				console.log(error)
			}
		}
	},

	requestRosreestr(appeal_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/egrn/request-rosreestr/${appeal_id}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setHideRequestRosreestr(true))
			} catch (error) {
				dispatch(
					setRealtyTextStatus(
						alertStatusText('Ошибка. Запрос не отправлен.'),
						false
					)
				)
				console.log(error)
			}
		}
	}
}
