import { createSlice } from '@reduxjs/toolkit'

const loading = createSlice({
	name: 'loading',
	initialState: {
		isLoading: false,
	},
	reducers: {
		setIsLoading(state, action) {
			state.isLoading = action.payload
		}
	}
})

export default loading.reducer

export const { setIsLoading } = loading.actions