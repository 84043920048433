import React, { useEffect } from 'react'
import SignatureReleaseSidebar from '../../blocks/signatureRelease/signatureReleaseSidebar/signatureReleaseSidebar'
import SignatureReleaseContent from '../../blocks/signatureRelease/signatureReleaseContent/signatureReleaseContent'
import s from './signatureRelease.module.scss'
import { useState } from 'react'
import backImg from '../../common/images/electronicSignature/back.svg'
import { Link } from 'react-router-dom'

const SignatureRelease = () => {
	const [width, setWidth] = useState(document.documentElement.clientWidth)

	const [signatureData, setSignatureData] = useState(false)
	const [completed, setCompleted] = useState(false)
	const [processing, setProcessing] = useState(false)
	const [issuance, setIssuance] = useState(false)

	const onResize = (e) => {
		setWidth(e.target.innerWidth)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		window.addEventListener('resize', onResize)
		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [])

	return (
		<div className={s.signatureRelease}>
			{width > 750 ? (
				<SignatureReleaseSidebar
					signatureData={signatureData}
					completed={completed}
					processing={processing}
					issuance={issuance}
				/>
			) : (
				<div className={s.signatureRelease__mobile}>
					<Link to='/taskListClient'>
						<img src={backImg} alt='' />
					</Link>
					<span>Электронная подпись</span>
				</div>
			)}

			<SignatureReleaseContent
				signatureData={signatureData}
				setSignatureData={setSignatureData}
				completed={completed}
				setCompleted={setCompleted}
				processing={processing}
				setProcessing={setProcessing}
				issuance={issuance}
				setIssuance={setIssuance}
			/>
		</div>
	)
}

export default SignatureRelease
