import { createSlice } from '@reduxjs/toolkit'

const client = createSlice({
	name: 'client',
	initialState: {
		documentName: 'Удостоверение личности И СНИЛС личности',
		textStatus: null,
		passportPhoto1: null,
		passportPhoto2: null,
		snilsPhoto: null,
		taskList: null,
		taskListClientData: null,
		clientData: null,
		organizationOffices: null,
		paymentsData: null,
		documents: null,
		openedDocument: null,
		pay: null,
		statusLoadCheck: null,
		photoDataStatus: false,
		selectedDeal: null,
		pdfFormat: false,
		jpegFormat: false,
		tabSwitch: 'JPEG'
	},
	reducers: {
		setTaskList(state, action) {
			state.taskList = action.payload
		},
		setDocumentName(state, action) {
			state.documentName = action.payload
		},
		setClientData(state, action) {
			state.clientData = action.payload
		},
		setContractData(state, action) {
			state.contractData = action.payload
		},
		setClientDataRelate(state, action) {
			state.clientDataRelate = action.payload
		},
		setClientDataRelateInfo(state, action) {
			state.clientDataRelateInfo = action.payload
		},
		setTaskListClientData(state, action) {
			state.taskListClientData = action.payload
		},
		setTextStatus(state, action) {
			state.textStatus = action.payload
		},
		setPassportPhoto1(state, action) {
			state.passportPhoto1 = action.payload
		},
		setPassportPhoto2(state, action) {
			state.passportPhoto2 = action.payload
		},
		setSnilsPhoto(state, action) {
			state.snilsPhoto = action.payload
		},

		setOrganizationOffices(state, action) {
			state.organizationOffices = action.payload
		},

		setPaymentsData(state, action) {
			state.paymentsData = action.payload
		},
		setPay(state, action) {
			state.pay = action.payload
		},
		setDocuments(state, action) {
			state.documents = action.payload
		},
		setOpenedDocument(state, action) {
			state.openedDocument = action.payload
			state.documents = state.documents.map((document) => {
				if (action.payload.id === document.id) {
					return {
						...document,
						file_status: action.payload.file === null ? false : true,
						status_confirmed: action.payload.status_confirmed
					}
				}

				return document
			})
		},
		setStatusLoadCheck(state, action) {
			state.statusLoadCheck = action.payload
		},
		setPhotoDataStatus(state, action) {
			state.photoDataStatus = action.payload
		},
		setPdfFormat(state, action) {
			state.pdfFormat = action.payload
		},
		setJpegFormat(state, action) {
			state.jpegFormat = action.payload
		},
		setTabSwitch(state, action) {
			state.tabSwitch = action.payload
		}
	}
})

export default client.reducer

export const {
	setDocumentName,
	setTaskList,
	setTextStatus,
	setTaskListClientData,
	setClientData,
	setContractData,
	setClientDataRelate,
	setClientDataRelateInfo,
	setPassportPhoto1,
	setPassportPhoto2,
	setSnilsPhoto,
	setOrganizationOffices,
	setPaymentsData,
	setPay,
	setDocuments,
	setOpenedDocument,
	setStatusLoadCheck,
	setPhotoDataStatus,
	setPdfFormat,
	setJpegFormat,
	setTabSwitch
} = client.actions
