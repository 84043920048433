import React from 'react'
import s from './signatureReleaseSidebar.module.scss'
import img1 from '../../../common/images/electronicSignature/stateImg.svg'
import img2 from '../../../common/images/electronicSignature/disImg.svg'
import img3 from '../../../common/images/electronicSignature/processImg.svg'
import img4 from '../../../common/images/electronicSignature/errorImg.svg'
import img5 from '../../../common/images/electronicSignature/okImg.svg'
import { Link } from 'react-router-dom'

const SignatureReleaseSidebar = ({
	signatureData,
	completed,
	processing,
	issuance
}) => {
	return (
		<div className={s.signatureReleaseSidebar}>
			<Link to='/taskListClient'>&#8592; Кабинет</Link>
			<div className={s.signatureReleaseSidebar__wrapper}>
				<h4>Статус</h4>
				<div>
					{!signatureData && <img src={img1} alt='' />}
					{processing && <img src={img3} alt='' />}
					{signatureData && !completed && !processing && !issuance && (
						<img src={img4} alt='' />
					)}
					{completed && <img src={img5} alt='' />}
					{issuance && <img src={img5} alt='' />}
					<span>Заявление на выпуск</span>
				</div>

				<div className={issuance ? s.active : s.dis}>
					{!signatureData && <img src={img2} alt='' />}
					{processing && <img src={img2} alt='' />}
					{signatureData && !completed && !processing && !issuance && (
						<img src={img2} alt='' />
					)}
					{completed && <img src={img3} alt='' />}
					{issuance && <img src={img5} alt='' />}
					<span>Подпись выпущена</span>
				</div>
			</div>
		</div>
	)
}

export default SignatureReleaseSidebar
