import React from 'react'
import {Modal} from '../modal/modal'
import s from './real-estate.module.scss'

export const RealEstate = ({
                             openWindowObjectRealEstate,
                             setOpenWindowObjectRealEstate,
                             object
                           }) => {

  return (
    <Modal
      onClose={true}
      mobileHeaderText='Объект'
      active={openWindowObjectRealEstate}
      setActive={setOpenWindowObjectRealEstate}
    >
      <div className={s.container}>
        <div className={s.realEstate}>


          <p style={{marginTop: '20px'}}>Объект</p>
          <ul>
            <li>Вид право</li>
            <li>{object?.object_right_title ? object?.object_right_title : '-'}</li>
          </ul>
          <ul>
            <li>Основной тип</li>
            <li>{object?.type_origin_title ? object?.type_origin_title : '-'}</li>
          </ul>
          <ul>
            <li>Кадастровый номер земельного участка</li>
            <li>{object?.cadastral_number_area ? object?.cadastral_number_area : '-'}</li>
          </ul>
          <ul>
            <li>Площадь застройки</li>
            <li>{object?.building_area ? object?.building_area : '-'}</li>
          </ul>
          <ul>
            <li>Адрес</li>
            <li>{object?.address ? object?.address : '—'}</li>
          </ul>
          <ul>
            <li>Тип</li>
            <li>{object?.type_title ? object?.type_title : '—'}</li>
          </ul>
          <ul>
            <li>Кадастровый номер</li>
            <li>{object?.cadastral_number ? object?.cadastral_number : '-'}</li>
          </ul>
          <ul>
            <li>Площадь</li>
            <li>{object?.square ? object?.square : '—'}</li>
          </ul>
          <ul>
            <li>Номер дома</li>
            <li>{object?.suspended_number_building ? object?.suspended_number_building : '—'}</li>
          </ul>
          <ul>
            <li>Номер квартиры</li>
            <li>{object?.suspended_number_flat ? object?.suspended_number_flat : '—'}</li>
          </ul>
          <ul>
            <li>Описание</li>
            <li>{object?.description ? object?.description : '—'}</li>
          </ul>
          <ul>
            <li>Жилой комплекс</li>
            <li>{object?.apartment_complex ? object?.apartment_complex : '—'}</li>
          </ul>


          <div className={s.paymentMethod}>
            <p>Способ оплаты</p>
            <ul>
              <li>Ипотека</li>
              <li>{object?.mortgage ? 'Да' : 'Нет'}</li>
            </ul>
            <ul>
              <li>Форма взаиморасчётов</li>
              <li>
                {object?.mutual_settlements ? object?.mutual_settlements : '—'}
              </li>
            </ul>
            <ul>
              <li>Организация, проводящая взаиморасчёты</li>
              <li>
                {' '}
                {object?.organization_settlements
                  ? object?.organization_settlements
                  : '—'}
              </li>
            </ul>
          </div>


        </div>


      </div>
    </Modal>
  )
}
