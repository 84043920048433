import React, { useCallback, useEffect, useState } from 'react'
import s from './egrn-table.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { admin } from '../../../api/admin'
import { setExtract } from '../../../redux/admin/admin'
import debounce from 'lodash.debounce'
import { Link } from 'react-router-dom'
import ReactPaginate from "react-paginate";
import'../deals.css'

export const EgrnTable = () => {
	const accessToken = localStorage.getItem('accessToken')
	const [searchOtherParamsEgrn, setSearchOtherParamsEgrn] = useState(
		localStorage.getItem('paramsEgrn')
	)
	const dispatch = useDispatch()
	const { listEgrn, extract } = useSelector((state) => state.admin)

	const [page, setPage] = useState(0)
	const result = listEgrn?.result;
	const itemsLength = 20;
	let itemOffset = page + 1;
	const pageCount = Math.ceil(listEgrn?.total / itemsLength);

	const updateSearchValueEgrn = useCallback(
		debounce(() => {
			dispatch(
				admin.createEgrnExtractSearch(accessToken, {
					search: localStorage.getItem('paramsEgrn')
				})
			)
		}, 350),
		[]
	)

	useEffect(() => {
		//dispatch(admin.employees(accessToken))
		dispatch(setExtract(null))
		if (searchOtherParamsEgrn?.length) {
			updateSearchValueEgrn()
		} else {
			dispatch(admin.listEgrn(accessToken, itemOffset, itemsLength))
		}
	}, [page])

	useEffect(() => {
		if (extract && !listEgrn) {
			dispatch(setExtract(null))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listEgrn])

	return (
		<>
			{result?.map((item) => {
				return (
					<Link
						to={`/userRoles/extract/${item.id}`}
						key={item.id}
						className={s.container}
					>
						<div className={s.egrn}>
							<p>{item.date_created}</p>
							<p>{item.cadastral_number}</p>
							<p>{item.extract_title}</p>
							<p
								style={
									item.status_label === 'Запрос отправлен'
										? { opacity: '0.5' }
										: { opacity: '1' }
								}
							>
								{item.status_label}
							</p>
							<div>
								{item?.declarant?.full_name
									? item?.declarant?.full_name
									: ' - '}
							</div>
						</div>
					</Link>
				)
			})}
			{result && <ReactPaginate
				containerClassName={"pagination"}
				pageClassName={"page-item"}
				activeClassName={"active"}
				onPageChange={(event) => setPage(event.selected)}
				pageCount={pageCount}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				renderOnZeroPageCount={null}
				breakLabel="..."
				previousLabel={"< Назад"}
				nextLabel={"Вперед >"}
				forcePage={page}
			/>}
		</>
	)
}
