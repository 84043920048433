import React from 'react'
import supportIcon from '../../common/images/supportIcon.png'
import s from './support-icon.module.scss'

export const SupportIcon = ({ setOpenSupport, left, right }) => {
	return (
		<div
			style={{ left: left, right: right }}
			onClick={() => setOpenSupport(true)}
			className={s.support}
		>
			<img src={supportIcon} alt='' />
		</div>
	)
}
