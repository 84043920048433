import React, { useEffect, useState } from 'react'
import { Select } from '../../components/select/select'
import s from './documentCreation.module.scss'
import DocumentCreationData from './documentCreationData/documentCreationData'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { documents } from '../../api/document/document'
import { useParams, useLocation } from 'react-router'

const DocumentCreation = ({
	setOpenWindowDocumentCreation,
	openWindowDocumentCreation
}) => {
	const [selected, setSelected] = useState()
	// const dealIdLocal = localStorage.getItem('dealId')
	const accessToken = localStorage.getItem('accessToken')
	const dispatch = useDispatch()
	const { documentId } = useParams()
	const { documentsTypeList, documentData } = useSelector(
		(state) => state.documents
	)
	const appeal_id = localStorage.getItem('appeal_id')
	const navigate = useNavigate();
	const { state } = useLocation();

	useEffect(() => {
		dispatch(documents.getDocumentsTypeList(accessToken))
		if(state?.source === "egrn")  {
			dispatch(documents.getDocument(accessToken, documentId));
		} else {
			if (appeal_id) {
				dispatch(documents.getDealDocument(accessToken, appeal_id, documentId))
			}
		}

	}, [accessToken])


	useEffect(() => {
		if (documentData) {
			setSelected(documentData?.document_type_data)
		}
	}, [documentData])

	useEffect(() => {
		if (documentData?.document_type_data?.id === selected?.id) return
		dispatch(
			documents.editDocument(appeal_id, documentId, selected?.id, accessToken)
		)
	}, [selected])

	const closeWindowDocumentCreation = () => {
		setOpenWindowDocumentCreation && setOpenWindowDocumentCreation(!openWindowDocumentCreation)
		setSelected(null);
		navigate(-1);
	}

	if (!documentsTypeList) {
		return <div>Загрузка...</div>
	}

	return (
		<div className={s.documentCreation}>
			<div>
				<button onClick={() => closeWindowDocumentCreation()} />
			</div>
			<button onClick={() => closeWindowDocumentCreation()} />
			{documentData || selected ? (
				<DocumentCreationData
					selected={selected}
					setSelected={setSelected}
					documentsTypeList={documentsTypeList}
				/>
			) : (
				<div className={s.documentCreation__select}>
					<Select
						dataList={documentsTypeList}
						placeholder='Выберите тип документа'
						setSelected={setSelected}
						selected={selected}
						headerSelect='Тип документа'
					/>
				</div>
			)}
		</div>
	)
}

export default DocumentCreation
