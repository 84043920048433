import axios from 'axios'
import {
	setErrorLoginText,
	setLogin,
	setRefreshToken,
	setUserRole
} from '../../redux/login/login'

const baseURL = process.env.REACT_APP_BASE_URL

export const loginAPI = {
	authClient(phone, email, password) {
		return async (dispatch) => {
			try {
				const response = await axios.post(`${baseURL}/auth-client`, {
					email,
					phone,
					password
				})
				dispatch(setLogin(response.data.access_token))
				dispatch(setRefreshToken(response.data.refresh_token))
				dispatch(setUserRole(response.data.user_role))
			} catch (error) {
				console.log(error)

				dispatch(
					setErrorLoginText(
						error.response.data.detail[0].loc
							? error.response.data.detail[0].loc
							: error.response.data.detail
					)
				)
			}
		}
	}
}
