import axios from 'axios'
import {
	setErrorLoginText,
	setLogin,
	setRefreshToken,
	setUserName,
	setUserRole
} from '../redux/login/login'

const baseURL = process.env.REACT_APP_BASE_URL

export const registerClient = (phone, email, password, uid64, accessToken) => {
	return async (dispatch) => {
		try {
			const response = await axios.post(
				`${baseURL}/register-client/${uid64}/${accessToken}`,
				{
					phone,
					email,
					password
				}
			)

			dispatch(setLogin(response.data.access_token))
			dispatch(setRefreshToken(response.data.refresh_token))
			dispatch(setUserRole(response.data.user_role))
			dispatch(setUserName(response.data.full_name))
		} catch (error) {
			console.log(error)
			dispatch(setErrorLoginText(error.response.data.detail))
		}
	}
}
