import React, { useEffect, useRef, useState } from 'react'
import s from './chatWindow.module.scss'
import Button from '../../components/button/button'
import Input from '../../components/input/input'
import Message from '../message/message'
import mobileCloseIcon from '../../common/images/mobile-close.svg'
import sendMessageIcon from '../../common/images/sendMessageIconMobile.svg'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import {
	pushMessage,
	setChatData,
	setLastMessages,
	setMessage,
	setMessageStatusSeen,
	setNewMessages,
	setNewMessagesStatusSeen,
	setPushLastMessages,
	setPusNewMessages
} from '../../redux/chat/chat'

const ChatWindow = ({ activeChat, setActiveChat, setOpenChatWindow }) => {
	const { chatData } = useSelector((state) => state.chat)
	const { userRole } = useSelector((state) => state.login)
	const { wsState, wsConnect } = useSelector((state) => state.webSocket)
	const [width, setWidth] = useState(document.documentElement.clientWidth)
	const [height, setHeight] = useState(document.documentElement.clientHeight)
	const scrollBlockChat = useRef()
	const chatWindowRef = useRef()
	const dispatch = useDispatch()
	const [inputMessage, setInputMessage] = useState('')
	const messagesSentId = []
	const recipientInfo =
		userRole === 'CL' ? activeChat?.employee_id : activeChat?.client_id

	useEffect(() => {
		document.body.style.overflow = 'hidden'
		setWidth(document.documentElement.clientWidth)
		window.addEventListener('resize', () => {
			setWidth(document.documentElement.clientWidth)
			setHeight(document.documentElement.clientHeight)
		})

		return () => {
			window.removeEventListener('resize', {})
			document.body.style.overflow = ''
		}
	}, [])

	useEffect(() => {
		dispatch(setChatData([]))

		wsState &&
			wsState.send(
				JSON.stringify({
					event: 'messages.list',
					data: {
						chat_id: activeChat?.id ? activeChat?.id : 1,
						offset_message: 0,
						limit_message: 50
					}
				})
			)

		return () => dispatch(setChatData([]))
	}, [activeChat])

	useEffect(() => {
		if (chatData.length !== 0) {
			;[...chatData]?.forEach((message) => {
				if (
					message.status === 'MESSAGE_SENT' &&
					message.recipient_id !== recipientInfo
				) {
					messagesSentId.push(message.message_id)
				}
			})
			if (messagesSentId.length !== 0) {
				wsState &&
					wsState.send(
						JSON.stringify({
							event: 'messages.seen',
							data: {
								messages_ids: messagesSentId
							}
						})
					)
			}
		}

		if (chatData && scrollBlockChat.current) {
			scrollBlockChat.current.scrollTop = scrollBlockChat.current.scrollHeight
		}
	}, [chatData])

	useEffect(() => {
		dispatch(setMessage(inputMessage))
	}, [inputMessage])

	useEffect(() => {
		if (!wsState) return

		wsState.onmessage = function (event) {
			let data = JSON.parse(event.data)
			if (data.event === 'messages.list' && data.data.status === 'error') {
				dispatch(setChatData([]))
			}

			if (data.event === 'messages.seen') {
				dispatch(setNewMessagesStatusSeen(data.data.messages_ids))
			}

			if (data.event === 'check.new.messages') {
				dispatch(setNewMessages(data.data.new_messages))
			}

			if (data.data.messages && data.event === 'messages.list') {
				dispatch(setChatData(data.data.messages))
			}
			if (data?.data && data.event === 'messages.inbox') {
				dispatch(pushMessage(data.data))
				dispatch(setPushLastMessages(data.data))
			}

			if (
				data.event === 'messages.create' &&
				data.data.recipient_id !== recipientInfo &&
				(activeChat?.id || 1) === data.data.chat_id
			) {
				dispatch(pushMessage(data.data))
			}

			if (data.event === 'messages.create' && data.event !== 'last.messages') {
				dispatch(setPushLastMessages(data.data))
				dispatch(setPusNewMessages(data.data))
			}

			if (data.event === 'last.messages') {
				dispatch(setLastMessages(data.data.messages))
			}
		}
	}, [wsState])

	const sendMessage = () => {
		if (!inputMessage || inputMessage[0] === ' ' || inputMessage[0] === '\n') {
			setInputMessage('')
			return
		}

		wsState &&
			wsState.send(
				JSON.stringify({
					event: 'messages.create',
					data: {
						recipient_id: recipientInfo,
						chat_id: activeChat?.id ? activeChat?.id : 1,
						body: inputMessage
					}
				})
			)

		setInputMessage('')
	}

	const inputHandler = (e) => {
		setInputMessage(e.target.value)
	}

	return (
		<div className={s.chatWindow}>
			{activeChat && (
				<div className={s.chatWindow__wrapper}>
					<div className={s.chatWindow__wrapper__header}>
						<h3>
							{userRole === 'MA' || userRole === 'AD'
								? activeChat?.client_name
								: activeChat?.employee_name}
						</h3>
					</div>
					<div className={s.mobileHeader}>
						<div
							onClick={() => {
								setOpenChatWindow(false)
								setActiveChat(null)
							}}
						>
							<img src={mobileCloseIcon} alt='' />
						</div>
						<div>
							<p>
								{userRole === 'MA' || userRole === 'AD'
									? activeChat?.client_name
									: activeChat?.employee_name}
							</p>
						</div>
					</div>
					<div>
						<div
							ref={scrollBlockChat}
							style={width < 576 ? { height: `${height - 156}px` } : {}}
							className={s.chatWindow__wrapper__messageWrapper}
						>
							{chatData?.length !== 0 ? (
								chatData?.map((message) => {
									const date = moment(message.created_at).format(
										'DD MMMM HH:mm'
									)
									return (
										<div
											style={
												message.sender_id !== recipientInfo
													? { alignItems: 'flex-end' }
													: { alignItems: 'flex-start' }
											}
											key={message.message_id}
										>
											<Message
												text={message.body}
												date={date}
												colorBgDark={
													message.sender_id === recipientInfo && true
												}
												chatWindowRef={chatWindowRef}
											/>
										</div>
									)
								})
							) : (
								<h3>Нет сообщений</h3>
							)}
						</div>

						<div className={s.chatWindow__bottomWindow}>
							<Input
								type='input'
								placeholder='Сообщение'
								onChange={(e) => {
									if (
										!e.target.value ||
										e.target.value[0] === ' ' ||
										e.target.value[0] === '\n'
									) {
										setInputMessage('')
									} else {
										inputHandler(e)
									}
								}}
								value={inputMessage}
								textarea
								onKeyDown={(e) => {
									if (e.keyCode === 13) {
										if (e.ctrlKey) {
											e.target.value = e.target.value + '\n'
										} else {
											if (
												e.target.value &&
												e.target.value[0] !== ' ' &&
												e.target.value[0] !== '\n'
											) {
												setInputMessage('')
												sendMessage()
											}
										}
									}
								}}
							/>
							<Button FCOnclick={sendMessage} small={true}>
								{width < 576 ? <img src={sendMessageIcon} /> : 'Отправить'}
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default ChatWindow
