import React, { useState } from 'react'
import s from './modal.module.scss'
import closeIcon from '../assets/close.svg'
import mobileCloseIcon from '../../common/images/mobile-close.svg'
import { useEffect } from 'react'

export const Modal = ({
	active,
	setActive,
	children,
	fullScreen,
	onClose,
	onSave,
	mobileHeaderText
}) => {
	const [width, setWidth] = useState(document.documentElement.clientWidth)
	const onResize = (e) => {
		setWidth(e.target.innerWidth)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		window.addEventListener('resize', onResize)
		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [])

	useEffect(() => {
		if (active) {
			document.body.style.overflow = 'hidden'
		}
		return () => {
			document.body.style.overflow = ''
		}
	}, [active])

	return (
		<div
			className={active ? s.modalActive : s.modal}
			onClick={() => setActive(false)}
		>
			<div
				className={`${s.modalContent} ${
					fullScreen && s.modalContentFullScreen
				}`}
				onClick={(e) => e.stopPropagation()}
			>
				{mobileHeaderText && (
					<div className={s.mobileHeader}>
						<div onClick={() => setActive(false)}>
							<img src={mobileCloseIcon} alt='' />
						</div>
						<div>
							<p>{mobileHeaderText}</p>
						</div>
					</div>
				)}
				<div
					className={s.btn}
					style={mobileHeaderText && width < 576 ? { display: 'none' } : null}
				>
					{onSave && (
						<button className={s.btn__fullScreenSaveBtn}>Сохранить</button>
					)}
					{onClose && (
						<button
							className={fullScreen ? s.btn__fullScreenCloseBtn : ''}
							onClick={() => setActive(false)}
						>
							<img
								src={width < 576 ? mobileCloseIcon : closeIcon}
								alt='closeIcon'
							/>
						</button>
					)}
				</div>
				{children}
			</div>
			<div className={s.overlay}></div>
		</div>
	)
}
