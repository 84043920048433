import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import s from './view-statement.module.scss'
import { useNavigate } from 'react-router-dom'
import { admin } from '../../../api/admin'
import { payments } from '../../../api/payments'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { setPageName } from '../../../redux/deals/deals'
import {
	setExtract,
	setFormAPackage,
	setPackageDownloadLink,
	setShowDownloadData,
	setFile
} from '../../../redux/admin/admin'
import { Modal } from '../../modal/modal'
import { setDocument } from '../../../redux/documents/documents'
// import { DutyReceipt } from '../../duty-invoice/duty-receipt/duty-receipt'
import { setNotificationText } from '../../../redux/manager/manager'
import PopUpComplete from '../../popUpComplete/popUpComplete'
import FileViewer from 'react-file-viewer'
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json'
import Button from '../../button/button'
// import deleteIcon from '../../../common/images/delete.svg'

export const ViewStatement = () => {
	const defaultLayoutPluginInstance = defaultLayoutPlugin()
	const [pdfFile, setPdfFile] = useState(null)
	const [openDeleteModal, setOpenDeleteModal] = useState()
	const { extract, packageDownloadLink, showDownloadData, formAPackage } =
		useSelector((state) => state.admin)
	const { notificationText } = useSelector((state) => state.manager)
	const baseURL = process.env.REACT_APP_BASE_URL
	const accessToken = localStorage.getItem('accessToken')
	const [btnActive, setBtnActive] = useState('mainCharacteristics')
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { id } = useParams()
	// const inputFileRef = useRef()
	// const [openModal, setOpenModal] = useState(false)
	// const [openDownloadReceipt, setOpenDownloadReceipt] = useState(false)

	useEffect(() => {
		if (id) {
			dispatch(admin.getEgrnExtract(accessToken, id))
		}
	}, [])

	useEffect(() => {
		if (notificationText) {
			dispatch(admin.listEgrn(accessToken))
			setTimeout(() => {
				if (notificationText.redirect) {
					backButton()
				}
				dispatch(setFile(null))
				dispatch(setNotificationText(null))
				setPdfFile(null)
			}, 2500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notificationText])

	// useEffect(() => {
	// 	if (!openModal) {
	// 		setOpenDownloadReceipt(false)
	// 	}
	// }, [openModal])

	const backButton = () => {
		dispatch(setPageName('egrn'))
		dispatch(setExtract(null))
		dispatch(setPackageDownloadLink(null))
		dispatch(setFormAPackage(false))
		dispatch(setShowDownloadData(false))
		dispatch(setFile(null))
		navigate('/userRoles')
	}

	useEffect(() => {
		if (extract?.file_data?.file) {
			setPdfFile(
				`data:${extract?.file_data?.file};base64,${extract?.file_data?.file}`
			)
		} else {
			setPdfFile(null)
		}
	}, [extract])

	// const handleChange = (event) => {
	// 	dispatch(setFile(event.target.files[0]))
	// 	inputFileRef.current.value = ''
	// }

	const { request_rosreestr } = extract || {}

	const handleGetPayment = (paymentId) => {
		dispatch(payments.payment(paymentId, accessToken, navigate))
	}

	const formAPackageFC = () => {
		dispatch(admin.generateFormRequest(accessToken, extract?.id))
	}

	useEffect(() => {
		if (packageDownloadLink) {
			window.open(`${baseURL}/file-cloud/${packageDownloadLink}`, '_blank')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [packageDownloadLink])

	const sendRosreestr = () => {
		dispatch(admin.sendRosreestrFile(accessToken, extract?.id))
	}

	const checkStatus = (status) => {
		if (
			formAPackage ||
			status === 'Чек загружен' ||
			status === 'Оплата выписки'
		) {
			return 'Сформировать пакет'
		} else {
			return status
		}
	}

	const setDocumentState = (document) => {
		dispatch(setDocument(document))
		localStorage.removeItem('appeal_id')
	}

	const getDocumentsSignStatus = useMemo(() => {
		let status = false
		const documents = extract?.documents

		for (let i = 0; i <= documents?.length; i++) {
			if (documents[i]?.ecp_signed === false) {
				status = false
				break
			}

			status = true
		}

		return status
	}, [extract])

	return (
		<div className={s.viewStatement}>
			<button onClick={() => backButton()} />
			<PopUpComplete description={notificationText} />
			{pdfFile ? (
				<>
					<div className={s.downloadReceipt}>
						<div className={s.downloadReceipt__leftSidebar}>
							{extract?.file_data?.media_type !== 'application/pdf' &&
							extract?.file_data?.media_type !== 'image/jpeg' ? (
								<FileViewer
									fileType={'docx'}
									filePath={`data:${extract?.file_data?.media_type};base64,${extract?.file_data?.file}`}
								/>
							) : extract.file_data?.media_type === 'application/pdf' ? (
								<Worker workerUrl='/pdf.worker.bundle.worker.js'>
									<Viewer
										localization={ru_RU}
										fileUrl={pdfFile}
										plugins={[defaultLayoutPluginInstance]}
									/>
								</Worker>
							) : (
								<img
									title='file'
									src={pdfFile}
									style={{ opacity: '1' }}
									alt=''
								/>
							)}
						</div>
						<div className={s.downloadReceipt__rightSidebar}>
							<p>Выписки</p>
							<div className={s.statements}>
								<button
									onClick={() => setBtnActive('mainCharacteristics')}
									className={
										btnActive === 'mainCharacteristics'
											? s.statements__btnACtive
											: undefined
									}
								>
									{extract?.extract_title}
								</button>
							</div>
						</div>
					</div>
				</>
			) : (
				<div className={s.viewStatement__downloadCheckBG}>
					<div className={s.viewStatement__downloadCheckBG__content}>
						<div>
							<h3>Данные создания выписки</h3>
							<div className={s.viewStatement__downloadCheckBG__content__item}>
								<span>Статус:</span>
								<span>{checkStatus(extract?.status_label)}</span>
							</div>
							<div className={s.viewStatement__downloadCheckBG__content__item}>
								<span>Кадастровый номер:</span>
								<span>{extract?.cadastral_number}</span>
							</div>
							<div className={s.viewStatement__downloadCheckBG__content__item}>
								<span>Дата создания:</span>
								<span>
									{request_rosreestr?.date_send
										? request_rosreestr?.date_send
										: extract?.date_created}
								</span>
							</div>
							{extract?.payment?.amount && (
								<div
									className={s.viewStatement__downloadCheckBG__content__item}
								>
									<span>Пошлина:</span>
									<span
										className={s.duty_btn}
										onClick={() => {
											// setOpenDownloadReceipt(true)
											// setOpenModal(true)
											handleGetPayment(extract?.payment.id)
											navigate('/dutyLoading')
										}}
									>
										{extract?.payment.amount}₽
									</span>
								</div>
							)}
							{/* {extract?.status === 'pay_extract' && !formAPackage && (
							<DutyReceipt notDowl extractId={extract?.id} />
						)} */}
							{(formAPackage ||
								extract?.status === 'receipt_upload' ||
								extract?.status === 'pay_extract') &&
								!showDownloadData && (
									<div style={{ marginTop: '20px' }}>
										<Button FCOnclick={formAPackageFC}>
											Сформировать пакет
										</Button>
									</div>
								)}
							{/* {(extract?.status === 'generate_package' || showDownloadData) && (
							<div
								className={
									s.viewStatement__downloadCheckBG__content__fileDownloadAndUploadWrapper
								}
							>
								<div
									className={
										s.viewStatement__downloadCheckBG__content__item__input
									}
								>
									<span>
										<button
											onClick={() =>
												dispatch(admin.egrnExtract(accessToken, extract?.id))
											}
										>
											Скачать
										</button>{' '}
										пакет для подписания
									</span>
								</div>

								<div
									className={
										s.viewStatement__downloadCheckBG__content__item__input
									}
								>
									<input
										type='file'
										onChange={handleChange}
										ref={inputFileRef}
									/>
									<span>
										<button>Загрузить</button> пакет с подписанными документами
									</span>
								</div>
								<p>
									{file?.name ? (
										<>
											<div>
												Наименование фаила: ${file?.name}
												<button onClick={() => dispatch(setFile(null))}>
													<img src={deleteIcon} alt='' />
												</button>
											</div>
										</>
									) : (
										'Файл не выбран'
									)}
								</p>
								{file && (
									<Button FCOnclick={sendRosreestr}>Заказать выписку</Button>
								)}
							</div>
						)} */}

							{(extract?.status === 'generate_package' || showDownloadData) &&
								extract?.documents && (
									<>
										{extract?.documents.map((item, i) => {
											return (
												<Link
													onClick={setDocumentState(item)}
													to={`/documentCreation/${item.id}`}
													state={{ source: 'egrn' }}
													className={s.package}
													key={i + 'pack'}
												>
													<p className={s.package__type}>
														{item.document_type}
													</p>
													<p
														className={`${s.package__status} ${
															item.ecp_signed ? s.package__success : ''
														}`}
													>
														{item.ecp_signed ? 'подписано' : 'подписать'}
													</p>
												</Link>
											)
										})}
										<Button
											buttonStyles={{ marginTop: '20px' }}
											disabled={!getDocumentsSignStatus}
											FCOnclick={sendRosreestr}
										>
											Отправить
										</Button>
									</>
								)}
							{extract?.request_rosreestr?.date_send && (
								<a
									href='/'
									target='_blank'
									className={
										s.viewStatement__downloadCheckBG__content__documentLink
									}
								>
									Тут будeт ссылка на документы из РР
								</a>
							)}
						</div>

						<Button
							buttonClassname={s.delete_btn}
							FCOnclick={() => setOpenDeleteModal(true)}
						>
							Удалить выписку
						</Button>
					</div>
				</div>
			)}

			<Modal
				onClose={true}
				active={openDeleteModal}
				setActive={setOpenDeleteModal}
			>
				<div className={s.popupDelete}>
					<p>Вы действительно хотите удалить выписку?</p>
					<div className={s.popupDelete_btns}>
						<Button FCOnclick={() => setOpenDeleteModal(false)}>Отмена</Button>
						<button
							className={s.popupDelete_delete_btn}
							onClick={() => {
								if (extract?.id) {
									dispatch(admin.deleteEgrn(accessToken, extract?.id))
									backButton()
								}
							}}
						>
							Да, удалить
						</button>
					</div>
				</div>
			</Modal>
		</div>
	)
}
