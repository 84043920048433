import { createSlice } from '@reduxjs/toolkit'

const ecp = createSlice({
	name: 'ecp',
	initialState: {
		alias: '',
		ecpStatusText: null,
		ecpStatus: false,
		loadEcpStatus: false,
		documentEcp: null,
		ecpTariffs: null,
		ecpData: null,
		personalDataEcp: null,
	},
	reducers: {
		setEcpData(state, action) {
			state.ecpData = action.payload
		},
		setEcpDataWS(state, action) {
			/*if(state.ecpData.application_status !== action.payload.application_status){
        state.ecpData = action.payload
      }*/
			if (state?.ecpData) {
				if (state.ecpData.id === action?.payload?.id) {
					state.ecpData = action.payload
				}
			} else {
				state.ecpData = action.payload
			}
		},
		setPersonalDataEcp(state, action) {
			state.personalDataEcp = action.payload
		},
		setAlias(state, action) {
			state.alias = action.payload
		},
		setEcpStatusText(state, action) {
			state.ecpStatusText = action.payload
		},
		setDocumentEcp(state, action) {
			state.documentEcp = action.payload
		},

		setEcpTariffs(state, action) {
			state.ecpTariffs = action.payload
		},

		setEcpStatus(state, action) {
			state.ecpStatus = action.payload
		},
		setOrganizations(state, action) {
			state.organizations = action.payload
		},
		setLoadStatus(state, action) {
			state.loadEcpStatus = action.payload
		},
		clearEcpState(state) {
			state.alias = ''
			state.ecpStatusText = null
			state.ecpStatus = false
			state.loadEcpStatus = false
			state.documentEcp = null
			state.ecpTariffs = null
			state.ecpData = null
			state.personalDataEcp = null
		}
	}
})

export default ecp.reducer

export const {
	setAlias,
	setEcpStatusText,
	setDocumentEcp,
	setEcpTariffs,
	setEcpData,
	setEcpStatus,
	setOrganizations,
	setEcpDataWS,
	setLoadStatus,
	clearEcpState,
	setPersonalDataEcp
} = ecp.actions
