import axios from 'axios'
import {setEcpStatusText} from '../../redux/ecp'
import {
  setDicts,
  setObject,
  setRealtyTextStatus,
  setRealtyTypes
} from '../../redux/realty/realty'
import {alertStatusText} from '../../components/alertStatusText/alertStatusText'
import de from 'react-datepicker'

const baseURL = process.env.REACT_APP_BASE_URL

export const realty = {
  getRealty(appeal_id, realtyId, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.get(
          `${baseURL}/appeals/${appeal_id}/realty/${realtyId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setObject(response.data))
      } catch (error) {
        console.log(error)
      }
    }
  },
  getRealtyTypes(accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.get(`${baseURL}/realty_type`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        dispatch(setRealtyTypes(response.data.types))
      } catch (error) {
        dispatch(setEcpStatusText('Ошибка'))
        console.log(error)
      }
    }
  },
  editRealtyThirdParty(
    appeal_id,
    realtyId,
    object,
    rightType,
    typeOriginObj,
    typeObj,
    descriptionPrice,
    accessToken
  ) {
    return async (dispatch) => {
      try {
        const response = await axios.put(
          `${baseURL}/appeals/${appeal_id}/edit-realty-third-party/${realtyId}`,
          {
            cadastral_number_area: object.cadastral_number_area,
            building_area: object.building_area,
            suspended_number_building: object.suspended_number_building,
            suspended_number_flat: object.suspended_number_flat,
            apartment_complex: object.apartment_complex,
            right_type_id:rightType,
            type_obj_id: typeObj,
            type_origin_obj_id: typeOriginObj,
            square: object.square,
            description: object.description,
            cadastral_number: object.cadastral_number,
            address: object.address,
            mortgage: object.mortgage,
            mutual_settlements: object.mutual_settlements,
            organization_settlements: object.organization_settlements
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setRealtyTextStatus(alertStatusText('Сохранено', true)))
      } catch (error) {
        dispatch(
          setRealtyTextStatus(alertStatusText('Что-то пошло не так', false))
        )
      }
    }
  },

  getDicts(accessToken) {
    
    return async (dispatch) => {
      try {
        const response = await axios.get(`${baseURL}/right_type`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        
        dispatch(setDicts(response.data.types))
      } catch (error) {
        
        dispatch(setEcpStatusText('Ошибка'))
        console.log(error)
      }
    }
  },
  egrnRealty(extractData, realtyId, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/egrn/realty/${realtyId}/extract`,
          {
            extract_id: extractData.id,
            amount: extractData.price_individual
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setRealtyTextStatus(alertStatusText('Сохранено', true)))
      } catch (error) {
        dispatch(
          setRealtyTextStatus(alertStatusText('Что-то пошло не так', false))
        )
      }
    }
  }
}
