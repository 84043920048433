import { createSlice } from '@reduxjs/toolkit'

const payments = createSlice({
	name: 'payments',
	initialState: {
		payments: null,
		openedPayment: null,
		error: null,
		priceName: 'legalEntity',
		descriptionPrice: null,
		egrnType: null,
		rightType: null,
		typeOriginObj: null,
		typeObj: null
	},

	reducers: {
		setOpenedPayment(state, action) {
			state.openedPayment = action.payload
		},
		setPriceName(state, action) {
			state.priceName = action.payload
		},
		setDescriptionPrice(state, action) {
			state.descriptionPrice = action.payload
		},
		setEgrnType(state, action) {
			state.egrnType = action.payload
		},
		setRightTypeState(state, action) {
			state.rightType = action.payload
		}	,
		setTypeOriginObjState(state, action) {
			state.typeOriginObj = action.payload
		}	,
		setTypeObjState(state, action) {
			state.typeObj = action.payload
		}
	}
})

export default payments.reducer

export const {
	setOpenedPayment,
	setPriceName,
	setDescriptionPrice,
	setEgrnType,
	setRightTypeState,
	setTypeOriginObjState,
	setTypeObjState
} = payments.actions
