import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru'

import App from './App'
import { store } from './redux/reducers'

import './index.scss'
import 'react-datepicker/dist/react-datepicker.css'

moment.locale('ru')

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
	<BrowserRouter>
		<Provider store={store}>
			<App />
		</Provider>
	</BrowserRouter>
)
