import React from 'react'
import s from './checkbox.module.scss'

const Checkbox = ({ label, defaultChecked, item, disabled, ...props }) => {
	return (
		<div className={s.checkbox}>
			<input
				{...props}
				id={item?.id}
				className={s.customCheckbox}
				type='checkbox'
				disabled={disabled}
				defaultChecked={defaultChecked}
			/>
			<label>{label}</label>
		</div>
	)
}
export default Checkbox
