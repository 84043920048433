import React from 'react'
import s from '../downloadPowerOfAttorney/downloadPowerOfAttorney.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
	setFileAttorney,
	setFileSig,
	setFileDate
} from '../../redux/manager/manager'
import Input from '../input/input'

const DownloadPowerOfAttorneyLegal = ({
	filePowerOfAttorney,
	deleteFilePowerOfAttorney,
	handleChange
}) => {
	const dispatch = useDispatch()
	const { globalPersonalData } = useSelector(
		(state) => state.globalPersonalData
	)
	const {		
		fileAttorney,
		fileSig
	} = useSelector((state) => state.manager)
	const baseURL = process.env.REACT_APP_BASE_URL

	const deleteAttorneyDocs = (name) => {
		if (name === "fileSig") {
			dispatch(setFileSig(null));
			document.getElementById("input__file_sig").value = '';
		}
		if (name === "fileAttorney") {
			dispatch(setFileAttorney(null));
			document.getElementById("input__fileAttorney").value = '';
		}
	}
	console.log(globalPersonalData)
	return (
		<div className={s.wrapper}>
			<h4>Доверенность</h4>
			<div className={s.wrapper__expireDateBox}>
				<span className={s.wrapper__expireDate}>Действительна до</span>
				<Input
					type="date"
					onChange={(event) => dispatch(setFileDate(event.target.value))}
					value={globalPersonalData?.power_attorney_expiry_date}
				/>
			</div>
			{filePowerOfAttorney?.path ? (
				<div className={s.wrapper__infoFile}>
					Загружен фаил:
					<span>{globalPersonalData?.power_attorney_title}</span>
					<div>
						<a
							href={`${baseURL}/file-cloud/${globalPersonalData?.power_attorney_path}`}
							target='_blank'
							download rel="noreferrer"
						>
							Скачать
						</a>
					</div>
				</div>
			) : (
				<div className={s.wrapper__downloadWarpper}>
					<input
						type='file'
						name='fileAttorney'
						id='input__fileAttorney'
						onChange={handleChange}
						disabled={!localStorage.getItem('participant_id')}
					/>
					<label
						style={
							!localStorage.getItem('participant_id') ? { opacity: '0.4' } : null
						}
						htmlFor='input__fileAttorney'
					>
						{fileAttorney === null ? "Загрузить доверенность" : fileAttorney.name}
					</label>
					{fileAttorney !== null && <button onClick={(event) => {deleteAttorneyDocs('fileAttorney')}}>Удалить</button>}
				</div>
			)}
			{filePowerOfAttorney?.path ? (
				<div className={s.wrapper__infoFile}>
					Загружен фаил:
					<span>{globalPersonalData?.power_attorney_path_sig && "SIG"}</span>
					<div>
						<a
							href={`${baseURL}/file-cloud/${globalPersonalData?.power_attorney_path_sig}`}
							target='_blank'
							download rel="noreferrer"
						>
							Скачать
						</a>
						<button onClick={(event) => deleteFilePowerOfAttorney()}>Удалить</button>
					</div>
				</div>
			) : (
				<div className={s.wrapper__downloadWarpper}>
					<input
						type='file'
						name='file_sig'
						id='input__file_sig'
						onChange={handleChange}
						disabled={!localStorage.getItem('participant_id')}
					/>
					<label
						style={
							!localStorage.getItem('participant_id') ? { opacity: '0.4' } : null
						}
						htmlFor='input__file_sig'
					>
						{fileSig === null ? "Загрузить файл sig" : fileSig.name}
					</label>
					{fileSig !== null && <button onClick={(event) => {deleteAttorneyDocs('fileSig')}}>Удалить</button>}
					<span className={s.wrapper__downloadWarpper__infoLabel}>
						Сохраните пользовотеля,чтобы загрузить доверенность
					</span>
				</div>
			)}
		</div>
	)
}

export default DownloadPowerOfAttorneyLegal