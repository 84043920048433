import { createSlice } from '@reduxjs/toolkit'

const duty = createSlice({
	name: 'duty',
	initialState: {
		dutyData: null
	},
	reducers: {
		setDutyData(state, action) {
			state.dutyData = action.payload
		}
	}
})

export default duty.reducer

export const { setDutyData } = duty.actions
