import { createSlice } from '@reduxjs/toolkit'

const invitationToPersonalAccount = createSlice({
	name: 'invitationToPersonalAccount',
	initialState: {
		checkEmail: false,
		checkPhone: false,
		invitationAccountTextStatus: null
	},
	reducers: {
		setCheckEmail(state, action) {
			state.checkEmail = action.payload
		},
		setCheckPhone(state, action) {
			state.checkPhone = action.payload
		},
		setInvitationAccountTextStatus(state, action) {
			state.invitationAccountTextStatus = action.payload
		}
	}
})

export default invitationToPersonalAccount.reducer

export const { setCheckEmail, setCheckPhone, setInvitationAccountTextStatus } =
	invitationToPersonalAccount.actions
