import React from 'react'
import Input from '../../input/input'
import s from './contacts.module.scss'
import InvitationToPersonalAccount from '../../../blocks/invitationToPersonalAccount/invitationToPersonalAccount'
import { useState } from 'react'
import ResetPassword from '../../../blocks/resetPassword/resetPassword'
import { useDispatch } from 'react-redux'
import { setGlobalPersonalDataValue } from '../../../redux/globalPersonalData/globalPersonalData'

export const Contacts = ({ dataCorrect, globalPersonalData, accStatus }) => {
	const [active, setActive] = useState(false)
	const [resetPasswordWindow, setResetPasswordWindow] = useState(false)
	const dispatch = useDispatch()
	// const regEmail = /\S+@\S+\.\S+/
	// const regPhone = /^\+?[8] [-\(]?\d{3}\) ?-?\d{3}-?\d{2}-?\d{2}$/

	return (
		<div className={s.contacts}>
			<div className={s.contacts__header}>
				<h4>Контакты</h4>
			</div>
			<div className={s.contacts__data}>
				<div>
					<span>Телефон</span>
					<Input
						dataCorrect={dataCorrect}
						disabled={dataCorrect}
						value={globalPersonalData?.phone ? globalPersonalData?.phone : ''}
						name='phone'
						maxLength='17'
						onChange={(e) =>
							dispatch(
								setGlobalPersonalDataValue({
									name: e.target.name,
									value:
										+e.target.value.toString().length > 0 &&
										e.target.value.replace(
											/^(\d{1})(\d{3})(\d{3})(\d{2})(\d)+$/,
											'$1 ($2) $3-$4-$5'
										)
								})
							)
						}
					/>
				</div>

				<div>
					<span>Почта</span>
					<Input
						dataCorrect={dataCorrect}
						disabled={dataCorrect}
						value={globalPersonalData?.email ? globalPersonalData?.email : ''}
						name='email'
						onChange={(e) =>
							dispatch(
								setGlobalPersonalDataValue({
									name: e.target.name,
									value: e.target.value
								})
							)
						}
					/>
				</div>

				<div className={s.account}>
					{(accStatus === 'not_created' || accStatus === 'not_activated') && (
						<>
							<span>Аккаунт</span>
							<div>
								Не создан —{' '}
								<div onClick={() => setActive(true)}>выслать приглашение</div>
							</div>
						</>
					)}

					{accStatus === 'activated' && (
						<>
							<span>Аккаунт</span>
							<div>Создан — {globalPersonalData?.email}</div>
						</>
					)}
				</div>
				{globalPersonalData?.id && (
					<p onClick={() => setResetPasswordWindow(true)}>cбросить пароль...</p>
				)}
			</div>
			<InvitationToPersonalAccount
				active={active}
				setActive={setActive}
				globalPersonalData={globalPersonalData}
			/>
			<ResetPassword
				active={resetPasswordWindow}
				setActive={setResetPasswordWindow}
				globalPersonalData={globalPersonalData}
			/>
		</div>
	)
}
