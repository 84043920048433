import React, { useEffect, useState } from 'react'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FileViewer from 'react-file-viewer'
import downImg from '../../common/images/save.svg'
import { Modal } from '../modal/modal'
import closeIcon from '../assets/close.svg'
import { payments } from '../../api/payments'
import s from './index.module.scss';

const pdfjs = import('pdfjs-dist/build/pdf').then(() => {
	const pdfjsWorker = import('pdfjs-dist/build/pdf.worker.entry').then(() => {
		if (pdfjs.GlobalWorkerOptions) {
			pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
		}
	})
})

const DutyLoading = () => {
	const defaultLayoutPluginInstance = defaultLayoutPlugin()
	const dispatch = useDispatch()
	const accessToken = localStorage.getItem('accessToken')

	const [test, setTest] = useState(null)
	const [test2, setTest2] = useState(null)

	const dealIdLocal = localStorage.getItem('dealId')
	const { appealDeal } = useSelector((state) => state.appeals)
	const { openedPayment } = useSelector((state) => state.payments)
	const navigate = useNavigate()
	const baseURL = process.env.REACT_APP_BASE_URL

	useEffect(() => {
		if (openedPayment?.receipt && openedPayment?.media_type) {
			setTest(
				`data:${openedPayment?.media_type};base64,${openedPayment?.receipt}`
			)
		}
	}, [openedPayment])

	useEffect(() => {
		if (window.location.pathname === '/dutyLoading') {
			if (!openedPayment) {
				navigate(`/deal/${dealIdLocal}`)
			}
		}
	}, [])

	const addedPayer = (participant_id) => {
		dispatch(payments.addedPayer(accessToken, openedPayment.id, participant_id))
		setTest2(false)
	}

	return (
		<div className={s.documentCreationData}>
			<div className={s.documentCreationData__leftSidebar}>
				{test ? (
					<>
						{openedPayment.media_type !== 'application/pdf' &&
							openedPayment.media_type !== 'application/xml' &&
							openedPayment.media_type !== 'image/jpeg' && (
								<FileViewer
									fileType={'docx'}
									filePath={`data:${openedPayment?.media_type};base64,${openedPayment?.receipt}`}
								/>
							)}
						{openedPayment.media_type === 'application/pdf' && (
							<Worker workerUrl='/pdf.worker.bundle.worker.js'>
								<Viewer
									localization={ru_RU}
									fileUrl={test}
									plugins={[defaultLayoutPluginInstance]}
								/>
							</Worker>
						)}

						{openedPayment.media_type === 'image/jpeg' && (
							<img title='file' src={test} style={{ opacity: '1' }} alt='' />
						)}

						{openedPayment.media_type === 'application/xml' && (
							<div className={s.documentCreationData__leftSidebar__bg}>
								<a
									className={
										s.documentCreationData__leftSidebar__bg__downloadImg
									}
									href={`${baseURL}/file-cloud${openedPayment?.path}`}
									target='_blank'
									download
								>
									<img src={downImg} alt='' />
									<span>Скачать</span>
								</a>
							</div>
						)}
					</>
				) : (
					<span className={s.documentCreationData__leftSidebar__bg}>
						Документ пока не загружен
					</span>
				)}
			</div>
			<div className={s.documentCreationData__rightSidebar}>
				<>
					<div className={s.documentCreationData__rightSidebar__fileWrapper}>
						<div
							className={
								s.documentCreationData__rightSidebar__fileWrapper__divDutyLoading
							}
						>
							<span>Пошлина</span>
							<button
								style={{ background: 'unset', border: 'unset' }}
								onClick={() => navigate(`/deal/${dealIdLocal}`)}
							>
								<img src={closeIcon} alt='closeIcon' />
							</button>
						</div>

						<div
							className={
								s.documentCreationData__rightSidebar__fileWrapper__downloadFalse
							}
						>
							<>
								<div
									className={
										s.documentCreationData__rightSidebar__fileWrapper__downloadFalse__filePresent
									}
								>
									<span>
										{openedPayment?.payment_type} <br />
										<a
											href={`${baseURL}/file-cloud/${openedPayment?.path}`}
											target='_blank'
											download
										>
											Скачать
										</a>
									</span>
								</div>
							</>
						</div>
					</div>

					<div className={s.documentCreationData__rightSidebar__signature}>
						<div
							style={
								openedPayment?.payer
									&& { borderBottom: '1px solid #F1F1F1' }
									
							}
						>
							<h4>Плательщики</h4>
						</div>

						<div
							className={
								s.documentCreationData__rightSidebar__signature__wrapper
							}
						>
							<div
								className={
									s.documentCreationData__rightSidebar__signature__wrapper__name
								}
							>
								<span>
									{openedPayment?.payer?.representative
										? openedPayment?.payer?.representative?.full_name
										: openedPayment?.payer?.full_name}
								</span>
							</div>
							{/* <button
                className={s.delete_payer_button}
								onClick={() => {}}
							/> */}
						</div>
					</div>

					<div className={s.documentCreationData__rightSidebar__signature}>
						<h4>Назначить пошлину</h4>

						<button className={s.add_payer_button} onClick={() => setTest2(true)}>Добавить плательщика</button>
					</div>
				</>
			</div>
			<Modal onClose={true} active={test2} setActive={setTest2}>
				<div className={s.documentCreationData__addedPayer}>
					<h4>Добавить плательщика</h4>

					{appealDeal?.participant.map((item) => {
						return (
							<div onClick={() => addedPayer(item?.participant_id)}>
								{item?.representative
									? item?.representative?.full_name
									: item?.full_name}
							</div>
						)
					})}
				</div>
			</Modal>
		</div>
	)
}

export default DutyLoading
