import React, { useEffect, useState } from 'react'
import s from './readContract.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { client } from '../../api/client/client'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { useNavigate } from 'react-router-dom'

const ReadContract = ({ width }) => {

	const dispatch = useDispatch()
	const baseURL = process.env.REACT_APP_BASE_URL;
	const { contractData } = useSelector((state) => state.client);
	const [fileDataURL, setFileDataURL] = useState(false);
	const [buttonVisible, setButtonVisible] = useState(true);
	const [message, setMessage] = useState('');
	const defaultLayoutPluginInstance = defaultLayoutPlugin();
	const document_id = contractData?.id;
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(client.getContractData()); 
		setFileDataURL(contractData?.file);
	}, [])

	const handleButtonReject = () => {
		setButtonVisible(!buttonVisible);
	}

	const handleButtonAccept = () => {
		dispatch(client.confirmContract(document_id));
		navigate(`/taskListClient`);
	}

	const handleButtonSend = () => {
		dispatch(client.unconfirmContract(document_id, message));
		navigate(`/taskListClient`);
	}

	return (
		<>	
			{width > 600 && <h3>Договор для ознакомления</h3>}
			{fileDataURL && (
				<div
					className={s.signingDocumentsContentMobile__wrapper__file}
				>
					<Worker workerUrl='/pdf.worker.bundle.worker.js'>
						<Viewer
							localization={ ru_RU }
							fileUrl={`${baseURL}/file-cloud/${contractData?.file}`}
							plugins={ [defaultLayoutPluginInstance] }
						/>
					</Worker>
				</div>
			)}
			<div className={s.buttonBox}>
				<button 
					className={ buttonVisible === true ? s.contractButton : s.contractButtonInvisible }
					onClick={ handleButtonAccept }
				>
					Ознакомлен
				</button>
				<span 
					className={ buttonVisible === true ? s.buttonSpan : s.contractButtonInvisible }
				>
					Нажимая на кнопку "Ознакомлен" вы подтверждаете, что в договоре все ваши данные верны.
				</span>
				<button 
					className={s.contractButton} 
					onClick={ handleButtonReject }
				>
					{ buttonVisible === true ? 'Отправить на доработку' : 'Отмена' }
				</button>
				<textarea 
					className={ buttonVisible === false ? s.textArea : s.contractButtonInvisible }
					placeholder='Оставьте комментарий'
					onChange={ (event) => setMessage(event.target.value) }
				>
				</textarea>
				<button 
					className={ buttonVisible === false ? s.contractButton : s.contractButtonInvisible }
					onClick={ handleButtonSend }
				>
					Отправить
				</button>
				<span 
					className={ buttonVisible === true ? s.buttonSpan : s.contractButtonInvisible }
				>
					Если нашли ошибку в договоре отправьте на доработку и оставьте комментарий.
				</span>
			</div>
        </>
	)
}
export default ReadContract
