/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react'
import Input from '../input/input'
import { Contacts } from './contacts/contacts'
import s from './deal-party-edit.module.scss'
import { HeaderParty } from './header-party/header-party'
import { Identification } from './identification/identification'
import { PassportImage } from './passport-image/passport-image'
import { PersonalData } from './personal-data/personal-data'
import Ecp from '../../blocks/ecp/ecp'
import { useDispatch, useSelector } from 'react-redux'
import { manager } from '../../api/manager/manager'
import { useParams } from 'react-router'
import LegalEntityData from '../../blocks/legalEntityData/legalEntityData'
import {
	setAccStatus,
	setDataCorrect,
	setDeleteImgStatus,
	setNewUser,
	setNotificationText,
	setRole,
	setType,
	setDataLoad,
	setRepresentativeData,
	setRolesPowerId,
	setSearchList,
	setSearchResult,
	setFileAttorney,
	setFileSig
} from '../../redux/manager/manager'
import { appeals } from '../../api/appeals'
import {
	setGlobalPersonalData,
	setGlobalPersonalDataValue
} from '../../redux/globalPersonalData/globalPersonalData'
import preloader from '../../common/preloader/preloader.gif'
import { Modal } from '../modal/modal'
import { useNavigate } from 'react-router-dom'
import PopUpComplete from '../popUpComplete/popUpComplete'
import DownloadPowerOfAttorney from '../downloadPowerOfAttorney/downloadPowerOfAttorney'
import { documents } from '../../api/document/document'
import { setRealtyTextStatus } from '../../redux/realty/realty'
import { setFilePowerOfAttorney } from '../../redux/documents/documents'

export const DealPartyEdit = ({ setActiveDealPartyEdit }) => {
	const dispatch = useDispatch()
	const {
		loadingApp,
		roles,
		role,
		notificationText,
		dataCorrect,
		type,
		photoPagePassport,
		signaturePagePassport,
		snilsPagePassport,
		accStatus,
		deleteImgStatus,
		newUser,
		representativeData,
		headerTypeData,
		pdfSwitch,
		photoPassportPdf1,
		photoPassportPdf2,
		photoSnilsPdf,
		photoPassportPdf1Representative,
		photoPassportPdf2Representative,
		photoSnilsPdfRepresentative,
		rolesPowerId,
		rolesPowersList,
		searchList,
		searchResult,
		fileAttorney,
		fileSig,
		fileDate
	} = useSelector((state) => state.manager)
	const { globalPersonalData } = useSelector(
		(state) => state.globalPersonalData
	)
	const { appealDeal } = useSelector((state) => state.appeals)
	const { realtyTextStatus } = useSelector((state) => state.realty)
	const { filePowerOfAttorney } = useSelector((state) => state.documents)
	const { representativeStatus } = useSelector((state) => state.manager)
	const [rolesPowersItem, setRolesPowersItem] = useState()
	const [selectValue, setSelectedValue] = useState()
	const [search, setSearch] = useState("")
	const { partId } = useParams()
	const dealId = localStorage.getItem('dealId')
	const accessToken = localStorage.getItem('accessToken')
	const userRole = localStorage.getItem('userRole')
	const imgPassport = true
	const [disabledDataUser, setDisabledDataUser] = useState(false)
	const appeal_id = localStorage.getItem('appeal_id')
	const [openDeleteWindow, setOpenDeleteWindow] = useState(false)
	const [whomToRemove, setWhomToRemove] = useState('')
	const [id, setId] = useState('');

	useEffect(() => {
		if (globalPersonalData) {
			for (const [key, value] of Object.entries(globalPersonalData)) {
				if (value?.length) {
					setDisabledDataUser(true)
					break
				} else {
					setDisabledDataUser(false)
				}
			}
		}
		
		localStorage.setItem('partId', partId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalPersonalData])

	useEffect(() => {
		setRolesPowersItem(null)
		dispatch(setRolesPowerId(null))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [headerTypeData])

	useEffect(() => {
		dispatch(manager.getRoles(appeal_id, accessToken))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalPersonalData?.user_auth_id, globalPersonalData?.id])

	useEffect(() => {
		if (deleteImgStatus) {
			if (headerTypeData === 'participant') {
				dispatch(manager.getDataClient(partId, accessToken))
			}
			if (headerTypeData === 'representative') {
				dispatch(manager.getRepresentative(Number(partId), accessToken))
			}
		}
		dispatch(setDeleteImgStatus(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteImgStatus])

	useEffect(() => {
		dispatch(setDataCorrect(globalPersonalData?.save_status))
		dispatch(
			setFilePowerOfAttorney({
				title: globalPersonalData?.power_attorney_title,
				path: globalPersonalData?.power_attorney_path
			})
		)
		if (
			(globalPersonalData?.user_auth_id && type === 'REPRESENTATIVE') ||
			globalPersonalData?.representative_exists
		) {
			dispatch(setRepresentativeData(true))
		}

		if (type !== 'REPRESENTATIVE') {
			if (globalPersonalData) {
				localStorage.setItem(
					'participant_id',
					globalPersonalData.participant_id
				)
			}
			if (type === 'INDIVIDUAL') {
				if (
					globalPersonalData.identification_card?.photo_page_key &&
					globalPersonalData.identification_card?.signature_page_key &&
					globalPersonalData.snils?.scan_key
				) {
					dispatch(setDataLoad(true))
				}
			}

			if (
				type === 'LEGAL_ENTITY' ||
				globalPersonalData?.type === 'LEGAL_ENTITY'
			) {
				if (globalPersonalData?.id) {
					dispatch(setDataLoad(true))
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalPersonalData])

	useEffect(() => {
		if (!globalPersonalData?.type) return
		dispatch(setType(globalPersonalData?.type))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalPersonalData?.type, appealDeal])

	useEffect(() => {
		if (roles) return
		dispatch(manager.getRoles(appeal_id, accessToken))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roles])

	useEffect(() => {
		if (!selectValue) return
		dispatch(setRole(selectValue))
		dispatch(manager.getRoles(appeal_id, accessToken))
		if (!newUser) {role?.id !== null && dispatch(manager.changeParticipantRole(accessToken, partId, selectValue?.id))}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectValue])

	useEffect(() => {
		window.scrollTo(0, 0)
		dispatch(setAccStatus(null))
		dispatch(setNewUser(true))
		dispatch(setGlobalPersonalData({}))
		dispatch(manager.getRolesPowers(appeal_id, accessToken))
		if (partId) {
			if (type === 'INDIVIDUAL') {
				dispatch(manager.getDataClient(partId, accessToken))
			}
			dispatch(setNewUser(false))
			if (dealId) {
				dispatch(appeals.appealDeal(appeal_id))
			}
		}
	}, [])

	useEffect(() => {
		roles &&
			roles.forEach(
				(item) => item.id == globalPersonalData?.role && setSelectedValue(item)
			)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalPersonalData?.role])

	useEffect(() => {
		if (!globalPersonalData?.id || !globalPersonalData?.user_auth_id) {
			if (globalPersonalData.phone?.length === 17) {
				dispatch(setAccStatus(null))
				dispatch(manager.getAccStatus(globalPersonalData?.phone, accessToken))
			} else {
				dispatch(setAccStatus(null))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalPersonalData?.phone])

	useEffect(() => {
		if (!rolesPowersItem) return
		dispatch(setRolesPowerId(rolesPowersItem.id))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rolesPowersItem])

	useEffect(() => {
		if (!rolesPowersList || !globalPersonalData.powers_id) return

		rolesPowersList &&
			rolesPowersList.forEach(
				(item) =>
					item.id == globalPersonalData?.powers_id && setRolesPowersItem(item)
			)
	}, [globalPersonalData.powers_id, rolesPowersList])

	const saveDataClient = () => {
		const formData = new FormData()

		const pdf1 =
			headerTypeData === 'representative'
				? photoPassportPdf1Representative
				: photoPassportPdf1
		const pdf2 =
			headerTypeData === 'representative'
				? photoPassportPdf2Representative
				: photoPassportPdf2
		const snilsPdf =
			headerTypeData === 'representative'
				? photoSnilsPdfRepresentative
				: photoSnilsPdf

		if (pdfSwitch) {
			if (!globalPersonalData?.identification_card?.photo_page_key && pdf1) {
				formData.append('photo_page', pdf1)
			}

			if (
				!globalPersonalData?.identification_card?.signature_page_key &&
				pdf2
			) {
				formData.append('signature_page', pdf2)
			}

			if (!globalPersonalData?.snils?.scan_key && snilsPdf) {
				formData.append('snils_scan', snilsPdf)
			}
		} else {
			if (photoPagePassport) {
				formData.append('photo_page', photoPagePassport)
			}

			if (signaturePagePassport) {
				formData.append('signature_page', signaturePagePassport)
			}

			if (snilsPagePassport) {
				formData.append('snils_scan', snilsPagePassport)
			}
		}

		if (headerTypeData === 'representative') {
			if (newUser) {
				dispatch(
					manager.createRepresentative(
						(photoPagePassport || photoPassportPdf1Representative) &&
							(signaturePagePassport || photoPassportPdf2Representative) &&
							(snilsPagePassport || photoSnilsPdfRepresentative)
							? true
							: false,
						globalPersonalData,
						partId,
						rolesPowerId,
						accessToken,
						dataCorrect === undefined && false,
						appeal_id,
						photoPagePassport ||
							photoPassportPdf1Representative ||
							signaturePagePassport ||
							photoPassportPdf2Representative ||
							snilsPagePassport ||
							photoSnilsPdfRepresentative
							? formData
							: {}
					)
				)
			} else {
				dispatch(
					manager.editRepresentative(
						(photoPagePassport || photoPassportPdf1Representative) &&
							(signaturePagePassport || photoPassportPdf2Representative) &&
							(snilsPagePassport || photoSnilsPdfRepresentative)
							? true
							: false,
						partId,
						rolesPowerId,
						globalPersonalData,
						accessToken,
						photoPagePassport ||
							photoPassportPdf1Representative ||
							signaturePagePassport ||
							photoPassportPdf2Representative ||
							snilsPagePassport ||
							photoSnilsPdfRepresentative
							? formData
							: {},
						appeal_id,
						dataCorrect
					)
				)
			}
		}

		if (type === 'INDIVIDUAL' && headerTypeData !== 'representative') {
			if (newUser) {
				dispatch(
					manager.createClientIndividual(
						(photoPagePassport || photoPassportPdf1) &&
							(signaturePagePassport || photoPassportPdf2) &&
							(snilsPagePassport || photoSnilsPdf)
							? true
							: false,
						globalPersonalData,
						role.id,
						accessToken,
						formData,
						dataCorrect === undefined && false,
						partId,
						appeal_id
					)
				)
			} else {
				dispatch(
					manager.editClientIndividual(
						// (photoPagePassport || photoPassportPdf1) &&
						// 	(signaturePagePassport || photoPassportPdf2) &&
						// 	(snilsPagePassport || photoSnilsPdf)
						// 	? true
						// 	: false,
						partId,
						globalPersonalData,
						role.id,
						accessToken,
						photoPagePassport ||
							photoPassportPdf1 ||
							signaturePagePassport ||
							photoPassportPdf2 ||
							snilsPagePassport ||
							photoSnilsPdf
							? formData
							: {},
						appeal_id,
						dataCorrect
					)
				)
			}
		}
		if (type === 'LEGAL_ENTITY' && headerTypeData !== 'representative') {
			if (newUser) {
				dispatch(
					manager.createLegalEntity(
						appeal_id,
						globalPersonalData,
						role.id,
						accessToken,
						dataCorrect,
						rolesPowerId
					)
				)
			} else {
				dispatch(
					manager.editLegalEntity(
						partId,
						globalPersonalData,
						role.id,
						accessToken,
						dataCorrect,
						rolesPowerId
					)
				)
			}
		}
	}

	const addedRepresentative = () => {
		dispatch(setGlobalPersonalData({}))
		dispatch(setRepresentativeData(true))
		dispatch(setNewUser(true))
		dispatch(setDataCorrect(false))
	}

	const deleteUser = () => {
		setOpenDeleteWindow(false)
		if (whomToRemove === 'LEGAL_ENTITY') {
			dispatch(
				manager.deleteLegalEntity(
					appeal_id,
					globalPersonalData.participant_id,
					accessToken
				)
			)
		}

		if (whomToRemove === 'INDIVIDUAL') {
			dispatch(
				manager.deleteClientIndividual(
					appeal_id,
					globalPersonalData?.participant_id,
					accessToken
				)
			)
		}

		if (whomToRemove === 'REPRESENTATIVE') {
			dispatch(
				manager.deleteRepresentative(
					appeal_id,
					globalPersonalData?.participant_id,
					accessToken
				)
			)
		}
	}

	const handleChangePowerAttorney = (event) => {
		const formData = new FormData()
		event && formData.append('file', event.target.files[0])
		dispatch(
			documents.documentPowerAttorney(
				globalPersonalData?.individual_id,
				formData,
				accessToken
			)
		)
	}

	const handleChangePowerAttorneyLegal = (event) => {
		if (event.target.name === "fileAttorney") {
			dispatch(setFileAttorney(event.target.files[0]))
		}
		if (event.target.name === "file_sig") {
			dispatch(setFileSig(event.target.files[0]))
		}
		console.log(fileAttorney,fileSig, fileDate)
	}

	const savePowerAttorneyLegal = (event) => {
		const formData = new FormData()
		formData.append('file', fileAttorney)
		formData.append('file_sig', fileSig)
		formData.append('expiry_date', fileDate)
		dispatch(
			documents.documentPowerAttorneyLegal(
				globalPersonalData?.id,
				formData,
				accessToken
			)
		)
	}
	

	const deleteFilePowerOfAttorney = () => {
		if (globalPersonalData?.power_attorney_title) {
			dispatch(
				documents.documentDeletePowerAttorney(
					globalPersonalData?.individual_id,
					accessToken
				)
			)
		} else {
			dispatch(setFilePowerOfAttorney(null))
		}
	}

	const deleteSearch = () => {
		dispatch(setSearchList([]));
		dispatch(setSearchResult(false));
		setSearch('');
	}

	useEffect(() => {
		if (!realtyTextStatus) return
		setTimeout(() => {
			dispatch(setRealtyTextStatus(null))
		}, 3000)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [realtyTextStatus])

	return (
		<div className={s.test}>
			<PopUpComplete description={notificationText} />
			<HeaderParty
				setActive={setActiveDealPartyEdit}
				saveDataClient={saveDataClient}
				savePowerAttorneyLegal={savePowerAttorneyLegal}
				type={type}
				partId={partId}
				globalPersonalData={globalPersonalData}
				appealDeal={appealDeal}
			/>

			{!loadingApp && partId ? (
				<div className={s.preloderWrapper}>
					<img src={preloader} alt='preloader' />
					<span>Подождите</span>
				</div>
			) : (
				<>
					{headerTypeData === 'ecp' ? (
						<Ecp
							type={type}					
							setNotificationText={setNotificationText}
							withParticipant={true}
						/>
					) : headerTypeData !== 'representative' ||
					(
						headerTypeData === 'representative' && (representativeStatus === true || representativeData)
					) ? (
						<div className={s.containerDealParty}>
							<div className={s.dealParty}>
								<div
									className={s.dataCorrect}
									style={
										dataCorrect
											? { background: 'rgba(31, 163, 74, 0.15)' }
											: { background: '#FFFFFF' }
									}
								>
									<span>Данные проверены и верны</span>
									<div
										className={dataCorrect ? s.activeData : s.disActiveData}
										onClick={() =>
											(globalPersonalData?.save_status && userRole === 'AD') ||
											!globalPersonalData?.save_status
												? dispatch(
														manager.confirmDataParticipant(
															globalPersonalData?.is_representative,
															partId,
															accessToken
														)
												  )
												: null
										}
									/>
								</div>
								{ type === 'REPRESENTATIVE' && newUser === true &&
								<>
									<div className={s.search}> 
										<span>Поиск представителя</span>
										<Input
											placeholder={ 'Введите почту' }
											onChange={ (event) => setSearch(event.target.value) }
											value={search}
											maxLength={ type === 'LEGAL_ENTITY' ? '10' : '100' }
											type={ type === 'LEGAL_ENTITY' ? "text" : "email" }
										/>
										<button
											onClick={ ((event) =>  dispatch(manager.getClientList(search, 'REPRESENTATIVE', accessToken))) }
										>
											Найти
										</button>
									</div>
									{ searchResult === true &&
										<div  className={s.searchResult}>
											<ul>
												{searchList.map(item =>
													<li
														key={item.id}
														onClick={ 
															(event) =>  {dispatch(manager.getClient(item.id, 'REPRESENTATIVE', accessToken)); setId(item.id);}
														}
													>
														<span>Представитель: {item.full_name}</span>
													</li>
												)}
											</ul>
											<div>
												<button 
													onClick={
														(event) => dispatch(manager.setRepresentative(localStorage.getItem("participant_id"), id, selectValue?.id, accessToken))
													}
												>
													Добавить
												</button>
											</div>
										</div>
									}
								</>									
								}
								{type !== 'REPRESENTATIVE' && (
									<>
										<div className={s.type}>
											<span>Тип</span>
											{ dataCorrect === false || dataCorrect === undefined ? 
												(<div>
													<span
														onClick={
															disabledDataUser && userRole !== 'AD'
																? null
																: () => {dispatch(setType('LEGAL_ENTITY')); deleteSearch();}
														}
														className={
															type === 'LEGAL_ENTITY' ? s.active : s.disActive
														}
													>
														Юрлицо
													</span>
													<span
														onClick={
															disabledDataUser && userRole !== 'AD'
																? null
																: () => {dispatch(setType('INDIVIDUAL')); deleteSearch();}
														}
														className={
															type === 'INDIVIDUAL' ? s.active : s.disActive
														}
													>
														Физлицо
													</span>
												</div>)
												:
												(<Input
													value={globalPersonalData.type === 'INDIVIDUAL' ? "Физлицо" : "Юрлицо"}
												/>)
											}
										</div>
										{ newUser === true &&
											<div className={s.search}> 
												<span>Поиск участника</span>
												<Input
													placeholder={ type === 'LEGAL_ENTITY' ? 'Введите ИНН' : 'Введите почту' }
													onChange={ (event) => setSearch(event.target.value) }
													value={search}
													maxLength={ type === 'LEGAL_ENTITY' ? '10' : '100' }
													type={ type === 'LEGAL_ENTITY' ? "text" : "email" }
												/>
												<button
													onClick={type === 'LEGAL_ENTITY' ? 
														((event) =>  dispatch(manager.getClientList(search, "LEGAL_ENTITY", accessToken))) : 
														((event) =>  dispatch(manager.getClientList(search, 'INDIVIDUAL', accessToken)))
													}
												>
													Найти
												</button>
											</div>
										}
										{ searchResult === true &&
											<div  className={s.searchResult}>
												<ul>
													{searchList.map(item =>
														<li
															key={item.id}
															onClick={ type === 'LEGAL_ENTITY' ? 
															(event) =>  {dispatch(manager.getClient(item.id, "LEGAL_ENTITY", accessToken)); setId(item.id);} :
															(event) =>  {dispatch(manager.getClient(item.id, 'INDIVIDUAL', accessToken)); setId(item.id);}
															}
														>
															<span>Участник: {item.full_name}</span>
															<span>Представитель: {item.representative_full_name === null ? "Нет представителя" : item.representative_full_name}</span>
														</li>
													)}
												</ul>
												<div>
													<button 
														onClick={type === 'LEGAL_ENTITY' ? 
															(event) => {
																dispatch(manager.setClient(appeal_id, "LEGAL_ENTITY", id, selectValue?.id, false,  accessToken))
															} :
															(event) => dispatch(manager.setClient(appeal_id, 'INDIVIDUAL', id, selectValue?.id, false, accessToken))
														}
													>
														Добавить
													</button>
													{ type === 'LEGAL_ENTITY' && 
														<button 
															onClick={ (event) => { dispatch(manager.setClient(appeal_id, "LEGAL_ENTITY", id, selectValue?.id, true, accessToken)) } }
														>
															Копировать
														</button>
													}
												</div>
											</div>
										}
										<div className={s.role}>
											<ul>
												<li>Роль</li>
												<li>
													<Input
														dataCorrect={dataCorrect}
														value={role?.title}
														setSelectedValue={setSelectedValue}
														selectedValue={role}
														select
														dataList={roles}
														placeholder='Выберите роль'
														placeholderForSelect='Выберите роль'
													/>
												</li>
											</ul>
										</div>
										{appealDeal?.realty_ownership && (
											<div className={s.share}>
												<ul>
													<li>Доля</li>
													<div className={s.share__containerInput}>
														<Input
															dataCorrect={dataCorrect}
															disabled={dataCorrect}
															name='numerator'
															value={
																globalPersonalData?.numerator
																	? globalPersonalData?.numerator
																	: ''
															}
															maxLength='2'
															onChange={(e) =>
																dispatch(
																	setGlobalPersonalDataValue({
																		name: e.target.name,
																		value: +e.target.value
																	})
																)
															}
														/>
														/
														<Input
															dataCorrect={dataCorrect}
															disabled={dataCorrect}
															name='denominator'
															value={
																globalPersonalData?.denominator
																	? globalPersonalData?.denominator
																	: ''
															}
															maxLength='2'
															onChange={(e) =>
																dispatch(
																	setGlobalPersonalDataValue({
																		name: e.target.name,
																		value: +e.target.value
																	})
																)
															}
														/>
													</div>
												</ul>
											</div>
										)}
									</>
								)}
								{type === 'LEGAL_ENTITY' ? (
									<>
										<LegalEntityData
											globalPersonalData={globalPersonalData}
											dataCorrect={dataCorrect}
											filePowerOfAttorney={filePowerOfAttorney}
											handleChange={handleChangePowerAttorneyLegal}
										/>
									</>
								) : (
									<>
										{(!globalPersonalData?.representative_exists ||
											type === 'REPRESENTATIVE') && (
											<Contacts
												dataCorrect={dataCorrect}
												globalPersonalData={globalPersonalData}
												accStatus={accStatus}
											/>
										)}
										<PersonalData dataCorrect={dataCorrect} />
										<Identification dataCorrect={dataCorrect} />

										{headerTypeData !== 'representative' && (
											<DownloadPowerOfAttorney
												filePowerOfAttorney={filePowerOfAttorney}
												deleteFilePowerOfAttorney={deleteFilePowerOfAttorney}
												handleChange={handleChangePowerAttorney}
											/>
										)}
										<PopUpComplete description={realtyTextStatus} />
									</>
								)}
								<>
									{type === 'LEGAL_ENTITY' &&
										globalPersonalData.participant_id &&
										!globalPersonalData?.save_status && (
											<span
												className={s.deleteAgentorClient}
												onClick={() => {
													setOpenDeleteWindow(true)
													setWhomToRemove('LEGAL_ENTITY')
												}}
											>
												Удалить юрлицо
											</span>
										)}
									{type === 'INDIVIDUAL' &&
										headerTypeData !== 'representative' &&
										globalPersonalData.participant_id &&
										// globalPersonalData?.user_auth_id &&
										!globalPersonalData?.save_status && (
											<span
												className={s.deleteAgentorClient}
												onClick={() => {
													setOpenDeleteWindow(true)
													setWhomToRemove('INDIVIDUAL')
												}}
											>
												Удалить физлицо
											</span>
										)}
								</>
							</div>

							{type !== 'LEGAL_ENTITY' && imgPassport && (
								<div className={s.passportDealParty}>
									<PassportImage />
								</div>
							)}
						</div>
					) : (
						<span
							className={s.noneRepresentative}
							onClick={() => addedRepresentative()}
						>
							Добавить представителя
						</span>
					)}
				</>
			)}

			<Modal active={openDeleteWindow} onClose setActive={setOpenDeleteWindow}>
				<div className={s.deleteWindow}>
					<p>Вы точно хотите удалить пользователя?</p>
					<div className={s.deleteWindow__wrapperButton}>
						<button onClick={() => deleteUser()}>Да</button>
						<button onClick={() => setOpenDeleteWindow(false)}>Нет</button>
					</div>
				</div>
			</Modal>
		</div>
	)
}
