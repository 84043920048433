import React from 'react'
import s from './issueEcp.module.scss'
// import { useEffect, useState } from 'react'
import Input from '../../components/input/input'
import { useDispatch } from 'react-redux'
import { setOrganizations } from '../../redux/ecp'

const IssueEcp = ({
	tariffEcpData,
	setTariffEcpData,
	idOrganizations,
	setIdOrganizations,
	organizations,
	personalDataEcp,
	ecpTariffs,
	setAlias
}) => {
	// const [selectedFile, setSelectedFile] = useState()
	// const [preview, setPreview] = useState()
	const dispatch = useDispatch()

	// useEffect(() => {
	// 	if (!selectedFile) {
	// 		setPreview(undefined)
	// 		return
	// 	}

	// 	const objectUrl = URL.createObjectURL(selectedFile)
	// 	setPreview(objectUrl)

	// 	return () => URL.revokeObjectURL(objectUrl)
	// }, [selectedFile])

	// const onSelectFile = (e) => {
	// 	if (!e.target.files || e.target.files.length === 0) {
	// 		setSelectedFile(undefined)
	// 		return
	// 	}
	// 	setSelectedFile(e.target.files[0])
	// }

	// const deletePhoto = () => {
	// 	setSelectedFile(undefined)
	// }

	

	return (
		<div className={s.issueEcp}>
			<h4>Заявление на выпуск ЭЦП</h4>
			<div className={s.issueEcp__wrapperData}>
				<div className={s.issueEcp__wrapperData__item}>
					<span>Фамилия</span>
					<span>{personalDataEcp?.surname}</span>
				</div>
				<div className={s.issueEcp__wrapperData__item}>
					<span>Имя</span>
					<span>{personalDataEcp?.name}</span>
				</div>
				<div className={s.issueEcp__wrapperData__item}>
					<span>Отчество</span>
					<span>{personalDataEcp?.patronymics}</span>
				</div>
				<div className={s.issueEcp__wrapperData__item}>
					<span>Пол</span>
					<span>
						{personalDataEcp?.gender === 'MALE' ? 'Мужской' : 'Женский'}
					</span>
				</div>
				<div className={s.issueEcp__wrapperData__item}>
					<span>Дата рождения</span>
					<span>{personalDataEcp?.birthday}</span>
				</div>
				<div className={s.issueEcp__wrapperData__item}>
					<span>Место рождения</span>
					<span>{personalDataEcp?.place_birth}</span>
				</div>
				<div className={s.issueEcp__wrapperData__item}>
					<span>Адрес регистрации</span>
					<span>{personalDataEcp?.reg_address}</span>
				</div>
				<div className={s.issueEcp__wrapperData__item}>
					<span>Гражданство</span>
					<span>{personalDataEcp?.citizenship || 'РФ'}</span>
				</div>
				<div className={s.issueEcp__wrapperData__input}>
					<Input
						value={tariffEcpData?.Name}
						setSelectedValue={setTariffEcpData}
						selectedValue={tariffEcpData}
						select={true}
						dataList={ecpTariffs}
						placeholder='Выберите тариф'
						placeholderForSelect='Выберите тариф'
					/>
				</div>

				{organizations && (
					<div className={s.issueEcp__wrapperData__input}>
						<Input
							value={idOrganizations?.name}
							setSelectedValue={setIdOrganizations}
							selectedValue={idOrganizations}
							select={true}
							dataList={organizations}
							placeholder='Выберите организацию'
							placeholderForSelect='Выберите организацию'
						/>
					</div>
				)}

				{/* <div className={s.issueEcp__wrapperData__item}>
					<span>Код</span>
					<span>
						<Input
							onChange={(e) =>
								dispatch(
									setAlias({
										value: e.target.value
									})
								)
							}
						/>
					</span>
				</div> */}
			</div>
		</div>
	)
}

export default IssueEcp
