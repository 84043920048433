import axios from 'axios'
import {
	setAdminTextStatus,
	setDeletedEmployee,
	setEmployees,
	setExtract,
	setFormAPackage,
	setListEgrn,
	setOpenedOrganization,
	setOrganizations,
	setPackageDownloadLink,
	setShowDownloadData
} from '../redux/admin/admin'
import { setGlobalPersonalData } from '../redux/globalPersonalData/globalPersonalData'
import { alertStatusText } from '../components/alertStatusText/alertStatusText'
import { setNotificationText } from '../redux/manager/manager'
import { setStatusLoadCheck } from '../redux/client/client'
import { setEcpData, setEcpStatus, setPersonalDataEcp } from '../redux/ecp'

const baseURL = process.env.REACT_APP_BASE_URL

export const admin = {
	employees(accessToken, itemOffset, itemsLength) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/employees?page=${itemOffset}&limit=${itemsLength}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})

				dispatch(setEmployees(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
	usersSearch(accessToken, itemOffset, itemsLength, searchParams) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/employees?page=${itemOffset}&limit=${itemsLength}&search=${
						searchParams.search === null ? '' : searchParams.search
					}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setEmployees(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
	employeeById(employeeId, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/employees/${employeeId}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				const espStatus = response.data?.ecp?.ecp_status;
				dispatch(setPersonalDataEcp(response.data))
				dispatch(setGlobalPersonalData(response.data))
				dispatch(setEcpStatus(espStatus
					? espStatus : false));
				if(response.data?.ecp) {
					dispatch(setEcpData(response.data?.ecp))
				}
				
			} catch (error) {}
		}
	},
	async sendDocuments(accessToken, documents, employee_id) {
		try {
			const formDataPhoto = await axios.post(
				`${baseURL}/employees/${employee_id}/upload_scans`,
				documents,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			)
		} catch (error) {
			console.log(error)
		}
	},
	employeeEdit(data, employeeId, documents, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/employees/${employeeId}/edit`,
					data,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				await this.sendDocuments(accessToken, documents, employeeId)

				dispatch(setAdminTextStatus(alertStatusText('Сохранено', true)))
			} catch (error) {
				console.log(error)
				if (
					error?.response?.data?.detail &&
					typeof error.response.data.detail === 'string'
				) {
					dispatch(
						setAdminTextStatus(
							alertStatusText(error?.response?.data?.detail, false)
						)
					)
				} else {
					dispatch(
						setAdminTextStatus(alertStatusText('Что-то пошло не так', false))
					)
				}
			}
		}
	},
	employeeCreate(data, accessToken, documents) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/employees/create`,
					{
						...data,
						citizenchip: 'РФ',
						identification_card: {
							...data.identification_card,
							title: 'Паспорт'
						}
					},

					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				if (response?.data?.id) {
					await this.sendDocuments(accessToken, documents, response?.data?.id)
					dispatch(setAdminTextStatus(alertStatusText('Сохранено', true)))
				}
			} catch (error) {
				console.log(error)
				if (
					error?.response?.data?.detail &&
					typeof error.response.data.detail === 'string'
				) {
					dispatch(
						setAdminTextStatus(
							alertStatusText(error.response.data.detail, false)
						)
					)
				} else {
					dispatch(
						setAdminTextStatus(alertStatusText('Что-то пошло не так', false))
					)
				}
			}
		}
	},
	deleteImage(
		employee_id,
		type_field,
		accessToken
	  ) {
		return async (dispatch) => {
		  try {
			const response = await axios.post(
			  `${baseURL}/employees/${employee_id}/delete_scans/${type_field}`,
			  {
				headers: {
				  Authorization: `Bearer ${accessToken}`
				}
			  }
			)
			dispatch(this.employeeById(employee_id, accessToken))
			/*  dispatch(setDeleteImgStatus(true))*/
		  } catch (error) {
			console.log(error)
		  }
		}
	  },
	employeeDelete(employeeId, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/employees/${employeeId}/delete`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setDeletedEmployee(employeeId))
			} catch (error) {
				if (
					error?.response?.data?.detail &&
					typeof error.response.data.detail === 'string'
				) {
					dispatch(
						setAdminTextStatus(
							alertStatusText(error.response.data.detail, false)
						)
					)
				} else {
					dispatch(
						setAdminTextStatus(alertStatusText('Что-то пошло не так', false))
					)
				}
			}
		}
	},
	organizations(accessToken, itemOffset, itemsLength) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/organizations?page=${itemOffset}&limit=${itemsLength}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})

				dispatch(setOrganizations(response.data))
			} catch (error) {}
		}
	},
	organizationById(organizationId, accessToken, itemOffset, itemsLength) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/organizations/${organizationId}?page=${itemOffset}&limit=${itemsLength}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setOpenedOrganization(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
	listEgrn(accessToken, itemOffset, itemsLength) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/egrn/extract?page=${itemOffset}&limit=${itemsLength}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})

				dispatch(setListEgrn(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
	getEgrnExtract(accessToken, extractId) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/egrn/extract/${extractId}/delete`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setExtract(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
	createEgrnExtract(
		accessToken,
		cadastral_number,
		extract_id,
		amount,
		object_type,
		address_note,
		areaValue
	) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/egrn/extract`,
					{
						cadastral_number,
						extract_id,
						amount,
						object_type,
						full_address: address_note,
						area_value: areaValue
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setExtract(response.data))

				return response
			} catch (error) {
				if (
					error?.response?.data?.detail &&
					typeof error.response.data.detail === 'string'
				) {
					dispatch(
						setAdminTextStatus(
							alertStatusText(error.response.data.detail, false)
						)
					)
				} else {
					dispatch(
						setAdminTextStatus(alertStatusText('Что-то пошло не так', false))
					)
				}
			}
		}
	},
	createEgrnExtractSearch(accessToken,  itemOffset, itemsLength, searchParams) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/egrn/extract?page=${itemOffset}&limit=${itemsLength}&search=${
						searchParams.search === null ? '' : searchParams.search
					}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(setListEgrn(response.data))
			} catch (error) {
				console.log(error)
				if (
					error?.response?.data?.detail &&
					typeof error.response.data.detail === 'string'
				) {
					dispatch(
						setAdminTextStatus(
							alertStatusText(error.response.data.detail, false)
						)
					)
				} else {
					dispatch(
						setAdminTextStatus(alertStatusText('Что-то пошло не так', false))
					)
				}
			}
		}
	},
	extractReceiptUpload(accessToken, extract_id, file) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/egrn/extract/${extract_id}/receipt`,
					{ receipt: file },
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
							'Content-Type': 'multipart/form-data'
						}
					}
				)
				dispatch(
					setNotificationText(alertStatusText('Выписка создана!', false))
				)
				dispatch(setFormAPackage(true))
			} catch (error) {
				dispatch(setStatusLoadCheck('Что-то пошло не так'))
				console.log(error)
				if (
					error?.response?.data?.detail &&
					typeof error.response.data.detail === 'string'
				) {
					dispatch(
						setAdminTextStatus(
							alertStatusText(error.response.data.detail, false)
						)
					)
				} else {
					dispatch(
						setAdminTextStatus(alertStatusText('Что-то пошло не так', false))
					)
				}
			}
		}
	},
	generateFormRequest(accessToken, extract_id) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/egrn/generate/form_request/${extract_id}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setShowDownloadData(true))
			} catch (error) {
				console.log(error)
				if (
					error?.response?.data?.detail &&
					typeof error.response.data.detail === 'string'
				) {
					dispatch(
						setAdminTextStatus(
							alertStatusText(error.response.data.detail, false)
						)
					)
				} else {
					dispatch(
						setAdminTextStatus(alertStatusText('Что-то пошло не так', false))
					)
				}
			}
		}
	},

	egrnExtract(accessToken, extract_id) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/egrn/extract/${extract_id}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				console.log(response)
				dispatch(setPackageDownloadLink(response.data.package_path))
			} catch (error) {
				console.log(error)

				if (
					error?.response?.data?.detail &&
					typeof error.response.data.detail === 'string'
				) {
					dispatch(
						setAdminTextStatus(
							alertStatusText(error.response.data.detail, false)
						)
					)
				} else {
					dispatch(
						setAdminTextStatus(alertStatusText('Что-то пошло не так', false))
					)
				}
			}
		}
	},
	deleteEgrn(accessToken, extract_id) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/egrn/extract/${extract_id}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				console.log(response)
			} catch (error) {
				console.log(error)
			}
		}
	},
	sendRosreestrFile(accessToken, extract_id) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/egrn/extract/send-rosreestr/${extract_id}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
							'Content-Type': 'multipart/form-data'
						}
					}
				)

				console.log(response)
				dispatch(
					setNotificationText(
						alertStatusText('Выписка успешно заказана!', true)
					)
				)
			} catch (error) {
				console.log(error)
				console.log(error)

				if (
					error?.response?.data?.detail &&
					typeof error.response.data.detail === 'string'
				) {
					dispatch(
						setAdminTextStatus(
							alertStatusText(error.response.data.detail, false)
						)
					)
				} else {
					dispatch(
						setAdminTextStatus(alertStatusText('Что-то пошло не так', false))
					)
				}
			}
		}
	}
}
