import { createSlice } from '@reduxjs/toolkit'

const globalPersonalData = createSlice({
	name: 'globalPersonalData',
	initialState: {
		globalPersonalData: {}
	},
	reducers: {
		setGlobalPersonalData(state, action) {
			state.globalPersonalData = action.payload
		},
		setGlobalPersonalDataValue(state, action) {
			state.globalPersonalData[action.payload.name] = action.payload.value
		},
		setGlobalPersonalDataSnilsValue(state, action) {
			state.globalPersonalData.snils.number[action.payload.name] = action.payload.value
		},
		setGlobalPersonalDataIdValue(state, action) {
			state.globalPersonalData.snils[action.payload.name] = action.payload.value
		},
		setLegalEntityData(state, action) {
			state.globalPersonalData[action.payload.name] = action.payload.value
		},
		setIdentificationCardData(state, action) {
			state.globalPersonalData.identification_card
				? (state.globalPersonalData.identification_card[action.payload.name] =
						action.payload.value)
				: (state.globalPersonalData.identification_card = {
						[action.payload.name]: action.payload.value
				  })
		},
		setSnilsData(state, action) {
			state.globalPersonalData.snils
				? (state.globalPersonalData.snils[action.payload.name] =
						action.payload.value)
				: (state.globalPersonalData.snils = {
						[action.payload.name]: action.payload.value
				  })
		}
	}
})

export default globalPersonalData.reducer

export const {
	setGlobalPersonalData,
	setGlobalPersonalDataValue,
	setGlobalPersonalDataSnilsValue,
	setIdentificationCardData,
	setGlobalPersonalDataIdValue,
	setLegalEntityData,
	setSnilsData,
} = globalPersonalData.actions
