import React, { useEffect, useRef } from 'react'
import s from './support.module.scss'
import happySmile from '../../common/images/emojiIcon.png'
import attachment from '../../common/images/attachment.png'
import closeIcon from '../assets/closeSupport.svg'
import supportOpenIcon from '../../common/images/supportOpenIcon.png'
import sendMessageIcon from '../../common/images/supportSendMessageIcon.png'
import Input from '../input/input'
import { useState } from 'react'
import { MessageSupport } from './message/message-support'
import { useDispatch, useSelector } from 'react-redux'
import { pushMessage, setChatData } from '../../redux/support/support'
import moment from 'moment'
import Picker from 'emoji-picker-react'
import { technicalSupport } from '../../api/technicalSupport/technicalSupport'

export const Support = ({ onClose }) => {
	const accessToken = localStorage.getItem('accessToken')
	const [inputMessage, setInputMessage] = useState('')
	const dispatch = useDispatch()
	const { chatData, file } = useSelector((state) => state.support)
	const { userId } = useSelector((state) => state.login)
	const { wsState } = useSelector((state) => state.webSocket)
	const [progressUploadFile, setProgressUploadFile] = useState()
	const [openEmoji, setOpenEmoji] = useState(false)
	const inputRef = useRef(null)

	const onEmojiClick = (event, emojiObject) => {
		setInputMessage(inputMessage + emojiObject.emoji)
	}

	const handleOpenEmoji = () => {
		setOpenEmoji(!openEmoji)
	}

	const scrollBlockChat = useRef()

	useEffect(() => {
		dispatch(setChatData([]))

		wsState.send(
			JSON.stringify({
				event: 'support.chat.messages.list',
				data: {
					offset_message: 0,
					limit_message: 50
				}
			})
		)

		return () => dispatch(setChatData([]))
	}, [])

	useEffect(() => {
		if (chatData && scrollBlockChat.current) {
			scrollBlockChat.current.scrollTop = scrollBlockChat.current.scrollHeight
		}
	}, [chatData, progressUploadFile])

	useEffect(() => {
		if (file) {
			const downloadFile = document.createElement('a')
			downloadFile.href = `data:application/pdf;base64,${file?.file}`
			downloadFile.download = file.file_name

			document.body.appendChild(downloadFile)
			downloadFile.click()
		}
	}, [file])

	const sendMessage = () => {
		if (!inputMessage || inputMessage[0] === ' ' || inputMessage[0] === '\n') {
			setInputMessage('')
			return
		}

		wsState.send(
			JSON.stringify({
				event: 'messages.bot',
				data: {
					body: inputMessage
				}
			})
		)
		setInputMessage('')
	}

	const sendFile = (formData) => {
		dispatch(
			technicalSupport.sendFile(formData, accessToken, setProgressUploadFile)
		)
	}

	const onSelectFile = async (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			return
		}
		const formData = new FormData()
		formData.append('file', e.target.files[0])
		await sendFile(formData)
	}

	wsState.onmessage = function (event) {
		let data = JSON.parse(event.data)

		if (data.data.messages && data.event === 'support.chat.messages.inbox') {
			dispatch(setChatData(data.data.messages.reverse()))
		}

		if (!data.data.messages && data.event === 'support.chat.messages.inbox') {
			data.data.date_create = new Date()
			data.data.content_type = 'message'
			data.data.id = chatData[chatData?.length - 1]?.id + 1
			dispatch(pushMessage(data.data))
		}
		if (data.event === 'messages.bot.to.client') {
			const message = data.data
			message.date_create = new Date()
			message.content_type = 'message'
			data.data.id = chatData[chatData?.length - 1]?.id + 1
			dispatch(pushMessage(message))
			setProgressUploadFile(null)
		}
		if (data.event === 'bot.send.file.to.client') {
			dispatch(pushMessage(data.data))
			setProgressUploadFile(null)
		}
	}

	const onPaste = (e) => {
		const items = (e.clipboardData || e.originalEvent.clipboardData).items
		const formData = new FormData()

		for (const item of items) {
			if (item.kind !== 'file') {
				continue
			}

			formData.append('file', item.getAsFile())
		}

		if (Array.from(formData.keys()).length === 0) {
			return
		}
		setProgressUploadFile(false)

		let obj = {
			body: null,
			recipient: null,
			sender: userId,
			id: chatData[chatData?.length - 1]?.id + 1,
			content_type: 'document',
			date_create: new Date(),
			file_name: Array.from(formData.values())?.at()?.name ?? 'image.png'
		}

		dispatch(pushMessage(obj))
		obj = null

		sendFile(formData)
	}

	useEffect(() => {}, [chatData])

	return (
		<div className={s.support}>
			<div className={s.support__head}>
				<div className={s.support__head__textLeft}>
					<div>
						<img src={supportOpenIcon} alt='' />
					</div>
					<p>Напишите нам</p>
				</div>
				<div onClick={onClose} className={s.support__head__close}>
					<img src={closeIcon} alt='' />
				</div>
			</div>
			{chatData.length !== 0 ? (
				<div ref={scrollBlockChat} className={s.support__content}>
					{chatData.map((message) => {
						const date = moment(message.date_create).format('DD MMMM HH:mm')
						return (
							<div key={message.id}>
								<div
									className={s.avatarHelper}
									style={
										!isNaN(message.sender)
											? { display: 'none' }
											: { display: 'block' }
									}
								>
									{message?.sender}
								</div>
								<div
									className={
										!isNaN(message.sender)
											? s.support__content__messageContent__right
											: s.support__content__messageContent__left
									}
								>
									<MessageSupport
										text={message.body}
										date={date}
										contentType={message.content_type}
										colorBgDark={!isNaN(message.sender) && true}
										fileName={message?.file_name}
										messageId={
											message.id === undefined
												? message?.message_id
												: message.id
										}
										progressUploadFile={progressUploadFile}
									/>
								</div>
							</div>
						)
					})}
				</div>
			) : (
				<div className={s.support__content}>
					<div className={s.support__content__infoHelper}>
						<div className={s.support__content__infoHelper__avatar}>
							<img src={supportOpenIcon} alt='' />
						</div>
					</div>
					<div className={s.support__content__infoQuestions}>
						<p>Задавайте вопрос &mdash; мы ответим на почту или здесь.</p>
					</div>
				</div>
			)}
			<div className={s.support__footer}>
				<Input
					placeholder='Сообщение...'
					nameRef={inputRef}
					onPaste={onPaste}
					onChange={(e) => {
						if (
							!e.target.value ||
							e.target.value[0] === ' ' ||
							e.target.value[0] === '\n'
						) {
							setInputMessage('')
						} else {
							setInputMessage(e.target.value)
						}
					}}
					value={inputMessage}
					textarea
					onKeyDown={(e) => {
						if (e.keyCode === 13) {
							if (e.ctrlKey) {
								e.target.value = e.target.value + '\n'
							} else {
								if (
									e.target.value &&
									e.target.value[0] !== ' ' &&
									e.target.value[0] !== '\n'
								) {
									setInputMessage('')
									sendMessage()
								}
							}
						}
					}}
				/>
				<div>
					<div>
						{openEmoji && (
							<Picker onEmojiClick={onEmojiClick} disableAutoFocus />
						)}

						<img onClick={handleOpenEmoji} src={happySmile} alt='' />
					</div>
					{inputMessage ? (
						<div
							onClick={() => {
								sendMessage()
								setOpenEmoji(null)
							}}
						>
							<img src={sendMessageIcon} alt='' />
						</div>
					) : (
						<div>
							<input
								type='file'
								onChange={(e) => {
									setProgressUploadFile(false)
									let obj = {
										body: null,
										recipient: null,
										sender: userId,
										id: chatData[chatData?.length - 1]?.id + 1,
										content_type: 'document',
										date_create: new Date(),
										file_name: e.target.files[0].name
									}

									dispatch(pushMessage(obj))
									obj = null
									onSelectFile(e)
								}}
							/>
							<img src={attachment} alt='' />
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
