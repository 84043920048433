import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { admin } from '../../../api/admin'
import { Modal } from '../../modal/modal'
import { UserView } from './user-view/user-view'
import s from './users.module.scss'
import debounce from 'lodash.debounce'
import { deals } from '../../../api/deals/deals'
import ReactPaginate from "react-paginate";
import'../deals.css'

export const Users = () => {
	const accessToken = localStorage.getItem('accessToken')
	const [searchOtherParamsUsers, setSearchOtherParamsUsers] = useState(
		localStorage.getItem('paramsUsers')
	)
	const dispatch = useDispatch()
	const { employees, adminTextStatus } = useSelector((state) => state.admin)
	const [employeeId, setEmployeeId] = useState(null)
	const [openModal, setOpenModal] = useState()
	const [page, setPage] = useState(0)
	const result = employees?.result;
	const itemsLength = 20;
	let itemOffset = page + 1;
	const pageCount = Math.ceil(employees?.total / itemsLength);
	const role = localStorage.getItem("userRole")

	useEffect(() => {
		dispatch(admin.employees(accessToken, itemOffset, itemsLength))
		if (searchOtherParamsUsers?.length) {
			updateSearchValueUsers()
		}
	}, [page])

	const updateSearchValueUsers = useCallback(
		debounce(() => {
			if (role === "AD") {
				dispatch(
					admin.usersSearch(accessToken, itemOffset, itemsLength, {
						search: localStorage.getItem('paramsUsers')
					})
				)
			}
		}, 350),
		[]
	)

	useEffect(() => {
		if (!openModal) {
			setEmployeeId(null)
		}
	}, [openModal])

	const handleGetEmployeeById = async (employeeId) => {
		await dispatch(admin.employeeById(employeeId, accessToken))
		setOpenModal(true)
	}

	return (
		<div className={s.users}>
			{result?.map((employee) => (
				<div
					key={employee?.id}
					className={s.container}
					onClick={() => {
						handleGetEmployeeById(employee?.id)
						setEmployeeId(employee?.id)
					}}
				>
					<div>
						<div>{employee?.full_name}</div>
						<div>{employee?.email}</div>
						<div>{employee?.role === 'MA' ? 'Менеджер' : 'Администратор'}</div>
					</div>
				</div>
			))}

			{result && <ReactPaginate
				containerClassName={"pagination"}
				pageClassName={"page-item"}
				activeClassName={"active"}
				onPageChange={(event) => setPage(event.selected)}
				pageCount={pageCount}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				renderOnZeroPageCount={null}
				breakLabel="..."
				previousLabel={"< Назад"}
				nextLabel={"Вперед >"}
				forcePage={page}
			/>}

			<Modal fullScreen={true} active={openModal} setActive={setOpenModal}>
				<UserView
					employeeId={employeeId}
					setOpenModal={setOpenModal}
					adminTextStatus={adminTextStatus}
					itemOffset={itemOffset} 
					itemsLength={itemsLength} 
				/>
			</Modal>
		</div>
	)
}
