import React, { useEffect } from 'react'
import s from './profileClient.module.scss'
import { useState } from 'react'
import { Modal } from '../modal/modal'
import ChangePassword from '../../blocks/changePassword/changePassword'
import PasswordRecovery from '../../pages/passwordRecovery/passwordRecovery'
import { Link, Navigate } from 'react-router-dom'
import closeIcon from '../assets/close.svg'
import Button from '../button/button'
import { ProfileContent } from './profile-content/profile-content'
import { useDispatch, useSelector } from 'react-redux'
import { setExitLogin } from '../../redux/login/login'
import iconLeft from '../../common/images/iconLeftProfile.svg'
import { MenuClient } from '../menuClient/menu-client'

export const ProfileClient = () => {
	const [changePasswordOpenModal, setChangePasswordOpenModal] = useState(false)
	const [changePassword, setChangePassword] = useState(false)
	const [sendPasswordRecoveryLink, setSendPasswordRecoveryLink] =
		useState(false)
	const [linkExpired, setlinkExpired] = useState(false)
	const [openMenu, setOpenMenu] = useState(false)
	const [width, setWidth] = useState(document.documentElement.clientWidth)
	const dispatch = useDispatch()
	const login = useSelector((state) => state.login.login)

	const exitLogin = () => {
		dispatch(setExitLogin(null))
		localStorage.clear()
		/* localStorage.removeItem('userEmail')
    localStorage.removeItem('userPhone')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('selectedDeal')*/
	}

	const onResize = (e) => {
		setWidth(e.target.innerWidth)
	}

	useEffect(() => {
		window.addEventListener('resize', onResize)
		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [])

	const handleChangePassword = () => {
		setChangePasswordOpenModal(true)
		setChangePassword(true)
	}

	const handleCloseModal = () => {
		setChangePasswordOpenModal(false)
		setChangePassword(false)
	}

	const handleSendPasswordRecoveryLink = () => {
		setChangePassword(false)
		setSendPasswordRecoveryLink(true)
	}

	if (!login) {
		return <Navigate to='/login' replace />
	}

	return (
		<>
			<div className={s.profile}>
				<div className={s.profile__leftSidebar}>
					<Link to='/taskListClient'>
						<div className={s.profile__leftSidebar__deals}>
							<img src={iconLeft} alt='icon-left' />
							Сделки
						</div>
					</Link>
				</div>
				<ProfileContent
					onChangePassword={handleChangePassword}
					onOpenMenu={setOpenMenu}
					exitLogin={exitLogin}
				/>
				<div className={width < 576 && s.mobileModal}>
					<Modal
						fullScreen={true}
						onClose={true}
						active={changePasswordOpenModal}
						setActive={setChangePasswordOpenModal}
					>
						{width < 576 && (
							<div className={s.changePasswordHead}>
								<button
									onClick={handleCloseModal}
									className={s.changePasswordCloseBtn}
								>
									<img src={closeIcon} alt='closeIcon' />
								</button>
								<p>
									{changePassword && 'Изменить пароль'}
									{sendPasswordRecoveryLink && 'Восстановление пароля'}
								</p>
								<p></p>
							</div>
						)}
						{changePassword && (
							<div className={s.passwordChange}>
								<ChangePassword
									onCloseModal={handleCloseModal}
									onSendPasswordRecoveryLink={handleSendPasswordRecoveryLink}
									changePasswordWindow={changePassword}
									setChangePasswordWindow={handleCloseModal}
								/>
							</div>
						)}

						{sendPasswordRecoveryLink && (
							<div className={s.passwordChange}>
								<div>
									<PasswordRecovery />
								</div>
							</div>
						)}
					</Modal>
				</div>

				<MenuClient openMenu={openMenu} setOpenMenu={setOpenMenu} />
			</div>
			<div>
				{linkExpired && (
					<div className={s.passwordRecovery}>
						<div>
							<p>Ссылка устарела</p>
							<Button small={true}>Отпарвить новую...</Button>
						</div>
					</div>
				)}
			</div>
		</>
	)
}
