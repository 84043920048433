import axios from 'axios'
import { setFile } from '../redux/support/support'

const baseURL = process.env.REACT_APP_BASE_URL

export const support = {
	getFile(accessToken, message_id) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/support_chat/${message_id}/get_file`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setFile(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
	uploadFile(accessToken, file) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/support_chat/upload_file`,
					file,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
			} catch (error) {
				console.log(error)
			}
		}
	}
}
