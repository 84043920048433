import React, { useCallback, useEffect, useState } from 'react'
import s from './dealSidebar.module.scss'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deals } from '../../../api/deals/deals'
import { setEditNotes } from '../../../redux/deals/deals'
import moment from 'moment'
import { appeals } from '../../../api/appeals'
import { debounce } from 'lodash'

const DealSidebar = ({
	setActiveChat,
	activeChat,
	deal,
	appealId,
	handleAddArchive,
	setOpenChatWindow,
	onChangeAppealId
}) => {
	const accessToken = localStorage.getItem('accessToken')
	const dispatch = useDispatch()

	const { newMessages, lastMessages } = useSelector((state) => state.chat)
	const { userRole } = useSelector((state) => state.login)
	const { wsState, wsConnect } = useSelector((state) => state.webSocket)

	const [openAddArchive, setOpenAddArchive] = useState(null)
	const [noteInput, setNoteInput] = useState(null)

	const handleGetAppealById = (appeal) => {
		if (appeal?.id) {
			dispatch(appeals.appealDeal(appeal?.id, accessToken))
		}
		onChangeAppealId(appeal)
	}

	const updateSearchValue = useCallback(
		debounce((accessToken, dealId, dealNotes) => {
			dispatch(deals.editNotes(accessToken, dealId, dealNotes))
		}, 350),
		[]
	)

	useEffect(() => {
		var textarea = document.getElementsByTagName('textarea')[0]

		textarea.style.height = 'auto'
		textarea.style.height = textarea.scrollHeight + 'px'
	}, [noteInput])

	useEffect(() => {
		const chatsIds = []
		deal?.chats?.forEach((chat) => {
			chatsIds.push(chat.id)
		})
		try {
			wsState?.send(
				JSON.stringify({
					event: 'last.messages',
					data: {
						chats_ids: chatsIds
					}
				})
			)
		} catch (error) {
			console.log(error)
		}
	}, [newMessages, deal?.chats])

	const changeDate = (date) => {
		const todayDate = new Date()
		const messageCreateAt = new Date(date)
		const yesterDay = new Date(todayDate)

		const correctWeekDay = [
			'в понедельник',
			'во вторник',
			'в среду',
			'в четверг',
			'в пятницу',
			'в субботу',
			'в воскресенье'
		]

		if (
			moment(todayDate).format('DD.MM.YYYY') ===
			moment(messageCreateAt).format('DD.MM.YYYY')
		) {
			return `в ${moment(messageCreateAt).format('HH:mm')}`
		}

		// если использовать перменную todayDate, метод setDate изменяет эту переменную
		if (
			messageCreateAt.toDateString() ===
			(yesterDay.setDate(yesterDay.getDate() - 1) && yesterDay.toDateString())
		) {
			return 'вчера'
		}

		if (
			messageCreateAt.getMonth() === todayDate.getMonth() &&
			todayDate.getDate() - messageCreateAt.getDate() < 7
		) {
			return correctWeekDay[messageCreateAt.getDay() - 1]
		}

		return messageCreateAt.toLocaleString('ru', {
			day: 'numeric',
			month: 'long'
		})
	}
	return (
		<div className={s.dealSidebar}>
			<div className={s.dealSidebar__headline}>
				<Link to='/userRoles'>﹤ Сделки</Link>
				<button onClick={() => setOpenAddArchive(!openAddArchive)}>
					Действия ⁝
				</button>
			</div>
			{openAddArchive && (
				<div className={s.dealSidebar__archive}>
					<p
						onClick={() => {
							handleAddArchive()
							setOpenAddArchive(false)
						}}
					>
						{deal?.archive
							? 'Перенести сделку в активные'
							: 'Перенести сделку в архив'}
					</p>
				</div>
			)}
			{deal?.archive && (
				<div className={s.dealSidebar__dealArchive}>
					<p>Сделка в архиве</p>
					<p>
						Нельзя редактировать архивную сделку. Если нужно вернуться к работе
						с ней, достаньте её из архива.
					</p>
				</div>
			)}
			<div className={s.dealSidebar__correspondence}>
				<h3>Переписка</h3>
				{deal?.chats?.length !== 0 ? (
					deal?.chats?.map((chat) => {
						const foundLastMessage = lastMessages?.find(
							(message) => chat.id === message.chat_id
						)
						return (
							<div
								key={chat.id}
								className={s.dealSidebar__correspondence__wrapper}
								style={
									activeChat?.id === chat.id
										? {
												background: '#1FA34A',
												color: '#FFFFFF'
										  }
										: { background: 'rgba(31, 163, 74, 0.1)' }
								}
							>
								<div
									className={s.dealSidebar__correspondence__wrapper__item}
									onClick={() => setActiveChat(chat)}
								>
									<span
										style={
											activeChat?.id === chat.id
												? { color: '#FFFFFF' }
												: { color: '#1FA34A' }
										}
									>
										{chat.client_name}
									</span>
									{foundLastMessage && (
										<div
											className={
												s.dealSidebar__correspondence__wrapper__item__message
											}
											style={
												activeChat?.id === chat.id
													? { color: '#FFFFFF' }
													: { color: '#1FA34A' }
											}
										>
											{foundLastMessage.sender_id === chat.employee_id &&
											(userRole === 'MA' || userRole === 'CL') ? (
												<span>
													{' '}
													Вы ответили {changeDate(foundLastMessage.created_at)}
												</span>
											) : (
												<span>
													{' '}
													Собеседник написал{' '}
													{changeDate(foundLastMessage.created_at)}
												</span>
											)}
											{userRole === 'MA' &&
												foundLastMessage.status === 'MESSAGE_SENT' && (
													<span
														className={s.newMessages}
														style={
															activeChat?.id === chat.id
																? { backgroundColor: '#FAFAFF' }
																: chat?.employee_id ===
																  foundLastMessage?.sender_id
																? {
																		backgroundColor: ' rgba(31, 163, 74, 0.15)'
																  }
																: { backgroundColor: '#1FA34A' }
														}
													/>
												)}
										</div>
									)}
									{!foundLastMessage && <span>Нет сообщений</span>}
								</div>
							</div>
						)
					})
				) : (
					<div className={s.dealSidebar__notParticipant}>
						Добавьте участников сделки
					</div>
				)}
			</div>
			<>
				<div className={s.dealSidebar__appeals}>
					<div>
						<h3>Обращения</h3>
						<div className={s.dealSidebar__appeals__button}>
							<button>+</button>
							<span
								onClick={async () => {
									await dispatch(deals.createSecondAppeal(deal.id, accessToken))
									dispatch(deals.deal(deal.id, accessToken))
								}}
							>
								Обращение
							</span>
						</div>
					</div>
					{deal?.appeals.map((appeal, index) => {
						return (
							<div
								key={appeal?.id}
								onClick={() => {
									handleGetAppealById(appeal)
									setOpenChatWindow(false)
									setActiveChat(null)
								}}
								className={`${s.dealSidebar__appeals__item} ${
									appeal?.id === appealId?.id &&
									!activeChat &&
									s.dealSidebar__appeals__itemActive
								}`}
							>
								<h3>Обращение {index + 1}</h3>
							</div>
						)
					})}
				</div>
			</>
			<div className={s.dealSidebar__notes}>
				<h3>Заметки</h3>
				<textarea
					value={deal?.notes}
					onChange={(ev) => {
						dispatch(setEditNotes(ev.target.value))
						setNoteInput(ev.target.value)
						updateSearchValue(accessToken, deal?.id, ev.target.value)
					}}
					placeholder='Их видно только менеджерам'
				/>
			</div>

			<div className={s.dealSidebar__otherDeals} style={{ display: 'none' }}>
				<h3>Другие сделки</h3>
				{otherDeals.map((item) => {
					return (
						<div className={s.dealSidebar__otherDeals__item} key={item.id}>
							<h3>{item.title}</h3>
							<span>{item.description}</span>
							<button />
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default DealSidebar

const otherDeals = [
	{
		id: 0,
		title: 'Гараж №153 - заврешена',
		description: 'Петр Гаврилович Самоходов',
		completed: true
	},
	{
		id: 1,
		title: 'Кладовка №10 - в процессе',
		description: 'Алина Станиславовна Самоходова',
		completed: false
	}
]
