import React, { useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Modal } from '../modal/modal'
import { SupportIcon } from '../support-icon/support-icon'
import { Support } from '../support/support'
import s from './menu-client.module.scss'

export const MenuClient = ({ openMenu, setOpenMenu }) => {
	const { pathname } = useLocation()
	const [openSupport, setOpenSupport] = useState(false)

	const handleCloseSupport = () => {
		setOpenSupport(false)
	}

	return (
		<div className={s.menu}>
			<Modal
				onClose={true}
				fullScreen={true}
				active={openMenu}
				setActive={setOpenMenu}
			>
				<div className={s.menu__header}>
					<div>
						<div>Уни</div>
						<div>строй</div>
					</div>
				</div>
				<div className={s.menu__content}>
					<div>
						<Link
							onClick={() => setOpenMenu(false)}
							to='/taskListClient'
							className={pathname === 'taskListClient'}
						>
							<p
								style={
									pathname === '/taskListClient' ? { color: '#1FA34A' } : {}
								}
							>
								Мои сделки
							</p>
						</Link>
						<Link onClick={() => setOpenMenu(false)} to='/profileClient'>
							<p
								style={
									pathname === '/profileClient' ? { color: '#1FA34A' } : {}
								}
							>
								Профиль
							</p>
						</Link>
						{!openSupport && (
							<SupportIcon left='20px' setOpenSupport={setOpenSupport} />
						)}
						{openSupport && <Support onClose={handleCloseSupport} />}
					</div>
				</div>
			</Modal>
		</div>
	)
}
