import React, { useEffect, useState } from 'react'
import s from './downloadPowerOfAttorney.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from '../modal/modal'
import { documents } from '../../api/document/document'

const DownloadPowerOfAttorney = ({
	filePowerOfAttorney,
	deleteFilePowerOfAttorney,
	handleChange
}) => {
	const dispatch = useDispatch()
	const accessToken = localStorage.getItem('accessToken')
	const { globalPersonalData } = useSelector(
		(state) => state.globalPersonalData
	)
	const { appealAtorneyList } = useSelector((state) => state.appeals)
	const baseURL = process.env.REACT_APP_BASE_URL

	const [windowPowerOfAttorneyDate, setWindowPowerOfAttorneyDate] =
		useState(false)

	useEffect(() => {
		if (filePowerOfAttorney?.title) {
			setWindowPowerOfAttorneyDate(false)
		}
	}, [filePowerOfAttorney?.title])

	useEffect(() => {
		dispatch(documents.powerOfAttorneyAppeal(localStorage.getItem("appeal_id")))
	}, [])

	const sendPowerOfAttorneyDate = (doc) => {
		dispatch(
			documents.powerOfAttorneyDate(
				doc.id,
				globalPersonalData.individual_id,
				accessToken
			)
		)
	}
	
	return (
		<div className={s.wrapper}>
			<h4>Доверенность</h4>
			{filePowerOfAttorney?.title ? (
				<div className={s.wrapper__infoFile}>
					Загружен фаил:
					<span>{filePowerOfAttorney?.title}</span>
					<div>
						<a
							href={`${baseURL}/file-cloud/${filePowerOfAttorney?.path}`}
							target='_blank'
							download rel="noreferrer"
						>
							Скачать
						</a>
						{!globalPersonalData?.save_status && (
							<button onClick={deleteFilePowerOfAttorney}>Удалить</button>
						)}
					</div>
				</div>
			) : (
				<div className={s.wrapper__downloadWarpper}>
					<input
						type='file'
						name='fileAttorney'
						id='input__fileAttorney'
						onChange={handleChange}
						// disabled={!globalPersonalData?.individual_id}
					/>
					<label
						style={
							!globalPersonalData?.individual_id ? { opacity: '0.4' } : null
						}
						htmlFor='input__fileAttorney'
					>
						Загрузить доверенность
					</label>
					{localStorage.getItem("sellerStatus") !== "THIRD_PARTY" ? 
						<span
							className={s.wrapper__downloadWarpper__powerOfAttorneyDate}
							onClick={
								globalPersonalData?.individual_id
									? () => setWindowPowerOfAttorneyDate(true)
									: null
							}
							style={
								!globalPersonalData?.individual_id ? { opacity: '0.4' } : null
							}
						>
							Выбрать доверенность из списка
						</span> : <></>
					}
					{!globalPersonalData?.individual_id && (
						<span className={s.wrapper__downloadWarpper__infoLabel}>
							Сохраните пользовотеля,чтобы загрузить доверенность
						</span>
					)}
				</div>
			)}
			<div></div>
			<Modal
				onClose={true}
				active={windowPowerOfAttorneyDate}
				setActive={setWindowPowerOfAttorneyDate}
			>
				<h3>Выберите документ</h3>
				{appealAtorneyList ? (
					<div className={s.wrapper__modal}>
						{appealAtorneyList?.document_types?.map((doc) => {
							return (
								<div
									key={doc.id}
									onClick={() => sendPowerOfAttorneyDate(doc)}
									className={s.wrapper__modal__item}
								>
									<span>Тип документа: Доверенность</span>
									<span>Название документа: {doc.title}</span>
								</div>
							)
						})}
					</div>
				) : null}
			</Modal>
		</div>
	)
}

export default DownloadPowerOfAttorney
