import axios from 'axios'
import {
  setDeletedPayment,
  setEditedPaymentStatus
} from '../redux/appeals/appeals'
import {setEgrnType, setOpenedPayment} from '../redux/payments/payments'
import {setStatusLoadCheck} from '../redux/client/client'

const baseURL = process.env.REACT_APP_BASE_URL

export const payments = {
  paymentsCreate(data, accessToken) {
    return async (dispatch) => {
      try {
        const responseCratedPayment = await axios.post(
          `${baseURL}/payments-request`,
          data,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        const responseSearchPaymentId = await axios.get(
          `${baseURL}/payments/${responseCratedPayment.data.id}/delete`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setOpenedPayment(responseSearchPaymentId.data))
      } catch (error) {
        alert(error?.response?.data?.detail)
        console.log(error)
      }
    }
  },

  payment(paymentId, accessToken,navigate) {
    return async (dispatch) => {
      try {
        const response = await axios.get(`${baseURL}/payments/${paymentId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        
        dispatch(setOpenedPayment(response.data))
        navigate('/dutyLoading')
      } catch (error) {
        console.log(error)
      }
    }
  },

  paymentDelete(accessToken, paymentId) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/payments/${paymentId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )

        dispatch(setOpenedPayment(null))
        dispatch(setDeletedPayment(paymentId))
      } catch (error) {
        console.log(error)
      }
    }
  },

  paymentsReceiptUpload(accessToken, paymentId, file) {
    return async (dispatch) => {
      try {
        const response = await axios.put(
          `${baseURL}/payments-request/${paymentId}`,
          {receipt: file},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        const responseMarkPay = await axios.put(
          `${baseURL}/payments/${paymentId}/mark-pay`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )

        const responseSearchPaymentId = await axios.get(
          `${baseURL}/payments/${paymentId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )

        dispatch(setOpenedPayment(responseSearchPaymentId.data))
        dispatch(setEditedPaymentStatus(paymentId))
        dispatch(setStatusLoadCheck('Чек загружен'))
        setTimeout(() => {
          dispatch(setStatusLoadCheck(null))
        }, 3000)
      } catch (error) {
        dispatch(setStatusLoadCheck('Что-то пошло не так'))
        console.log(error)
      }
    }
  },

  paymentsReceiptDelete(accessToken, paymentId) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/payments/${paymentId}/receipt/delete`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        const responseSearchPaymentId = await axios.get(
          `${baseURL}/payments/${paymentId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )

        dispatch(setOpenedPayment(responseSearchPaymentId.data))
      } catch (error) {
        console.log(error)
      }
    }
  },

  paymentsMarkPay(accessToken, paymentId) {
    return async (dispatch) => {
      try {
        const response = await axios.put(
          `${baseURL}/payments/${paymentId}/mark-pay`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        const responseSearchPaymentId = await axios.get(
          `${baseURL}/payments/${paymentId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setOpenedPayment(responseSearchPaymentId.data))
      } catch (error) {
        console.log(error)
      }
    }
  },
  egrType(accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.get(`${baseURL}/egrn/type`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        dispatch(setEgrnType(response.data.types))
      } catch (error) {
        console.log(error)
      }
    }
  },

  addedPayer(accessToken, payments_id, participant_id) {
    return async (dispatch) => {
      try {
        const response = await axios.put(
          `${baseURL}/payments/${payments_id}/request/participant/${participant_id}/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        
        dispatch(payments.payment(payments_id, accessToken))
      } catch (error) {
        dispatch(setStatusLoadCheck('Что-то пошло не так'))
        
        console.log(error)
      }
    }
  },
}
