import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import s from './index.module.scss'
import {
	setPhotoPagePassport,
	setSignaturePagePassport,
	setSnilsPagePassport,
	setPhotoPassport1,
	setPhotoPassport2,
	setPhotoSnils
} from '../../../../../redux/user/user'
import { admin } from '../../../../../api/admin'

export const PassportImage = ({ employeeId }) => {
	const baseURL = process.env.REACT_APP_BASE_URL;
	const dispatch = useDispatch();

	const accessToken = localStorage.getItem('accessToken');
	const userRole = localStorage.getItem('userRole');

	const { 
		globalPersonalData, 
		dataCorrect 
	} = useSelector((state) => state.globalPersonalData);

	const { 
		photoPassport1, 
		photoPassport2, 
		photoSnils 
	} = useSelector((state) => state.user);

	const [photoNumber, setPhotoNumber] = useState(null);

	const deleteImg = async (photoNumber) => {

		if (photoNumber === 1) {
			dispatch(setPhotoPassport1(null));
			dispatch(setPhotoPagePassport(null));
			document.getElementById("input__file1").value = "";
		}
		if (photoNumber === 2) {
			dispatch(setPhotoPassport2(null));
			dispatch(setSignaturePagePassport(null));
			document.getElementById("input__file2").value = "";
		}
		if (photoNumber === 3) {
			dispatch(setPhotoSnils(null));
			dispatch(setSnilsPagePassport(null));
			document.getElementById("input__file3").value = "";
		}

		if (
			globalPersonalData?.identification_card?.photo_page_key ||
			globalPersonalData?.identification_card?.signature_page_key ||
			globalPersonalData?.snils?.scan_key
		) {
			dispatch(admin.deleteImage(
				employeeId, 
				photoNumber === 1
					? 'photo_page_key'
					: photoNumber === 2
					? 'signature_page_key'
					:'scan_key',
				accessToken
				)
			)
		}
	}
	
	return (
		<div className={s.passport__pdfBlock}>

			<div className={s.passport__pdfBlock__item}>
				<span>Паспорт - страница с фото:</span>
				<input
					disabled={dataCorrect && userRole !== 'AD'}
					type='file'
					name='file'
					id='input__file1'
					accept='image/png, image/jpeg, application/pdf'
					onChange={
						(event) =>{
							dispatch(setPhotoPagePassport(event.target.files[0]));
							dispatch(setPhotoPassport1(event.target.files[0].name));
						}
					}
				/>
				{globalPersonalData?.identification_card?.photo_page_key || photoPassport1 ? (
					<div className={s.passport__pdfBlock__item__wrapper}>
						<div>
							<span>Документ загружен {globalPersonalData?.identification_card?.photo_page_key || photoPassport1}</span>
							{globalPersonalData?.identification_card?.photo_page_key ? (
								<a
									href={`${baseURL}/file-cloud/${globalPersonalData?.identification_card?.photo_page_key}`}
									target='_blank'
									rel="noreferrer"
									download
								>
									Скачать
								</a>
							) : null}
						</div>
						<button
							onClick={
								() => {
									setPhotoNumber(1);
									deleteImg(1);
								}
							} 
						/>
					</div>
				) : (
					<></>
				)}
				{globalPersonalData?.identification_card?.photo_page_key || photoPassport1 ? null:
					<label 
						htmlFor='input__file1'
					>
						Загрузить документ...
					</label> 
				}
			</div>

			<div className={s.passport__pdfBlock__item}>
				<span>Паспорт - страница с пропиской:</span>
				<input
					disabled={dataCorrect && userRole !== 'AD'}
					type='file'
					name='file'
					id='input__file2'
					accept='image/png, image/jpeg, application/pdf'
					onChange={
						(event) =>{
							dispatch(setSignaturePagePassport(event.target.files[0]));
							dispatch(setPhotoPassport2(event.target.files[0].name));
						}
					}
				/>
				{globalPersonalData?.identification_card?.signature_page_key || photoPassport2 ? (
					<div className={s.passport__pdfBlock__item__wrapper}>
						<div>
							<span>Документ загружен {globalPersonalData?.identification_card?.signature_page_key || photoPassport2}</span>
							{globalPersonalData?.identification_card?.signature_page_key ? (
								<a
									href={`${baseURL}/file-cloud/${globalPersonalData?.identification_card?.signature_page_key}`}
									target='_blank'
									rel="noreferrer"
									download
								>
									Скачать
								</a>
							) : null}
						</div>
						<button 
							onClick={
								() => {
									setPhotoNumber(2);
									deleteImg(2);
								}
							}
						/>
					</div>
				) : (
					<></>
				)}
				{globalPersonalData?.identification_card?.signature_page_key || photoPassport2 ? null:
					<label 
						htmlFor='input__file2'
					>
						Загрузить документ...
					</label>
				}
			</div>

			<div className={s.passport__pdfBlock__item}>
				<span>Снилс:</span>
	 			<input
					disabled={dataCorrect && userRole !== 'AD'}
					type='file'
					name='file'
					id='input__file3'
					accept='image/png, image/jpeg, application/pdf'
					onChange={
						(event) =>{
							dispatch(setSnilsPagePassport(event.target.files[0]));
							dispatch(setPhotoSnils(event.target.files[0].name))
						}
					}
				/>
				{globalPersonalData?.snils?.scan_key || photoSnils ? (
					<div className={s.passport__pdfBlock__item__wrapper}>
						<div>
							<span>Документ загружен {globalPersonalData?.snils?.scan_key || photoSnils}</span>
							{globalPersonalData?.snils?.scan_key ? (
								<a
									href={`${baseURL}/file-cloud/${globalPersonalData?.snils?.scan_key}`}
									target='_blank'
									rel="noreferrer"
									download
								>
									Скачать
								</a>
							) : null}
						</div>
						<button
							onClick={
								() => {
									setPhotoNumber(3);
									deleteImg(3);
								}
							}
						/>
					</div>
				) : (
					<></>
				)}
				{globalPersonalData?.snils?.scan_key || photoSnils ? null:
					<label 
						htmlFor='input__file3'
					>
						Загрузить документ...
					</label>
				}
			</div>
		</div>
	)
}
