import React from 'react'
import s from './manager.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { Deals } from '../../../../components/deals/deals'

const Manager = ({ userRole }) => {
	const secretUrl = useSelector(
		(state) => state.invitationToPersonalAccount.secretUrl
	)
	const { login } = useSelector((state) => state.login)

	if (!login) {
		return <Navigate to='/login' replace />
	}

	return (
		<div className={s.manager}>
			<Deals userRole={userRole} />
			<div className={s.deals}>
				<div>
					<a href={secretUrl} target='_blank'>
						{secretUrl}
					</a>
				</div>
			</div>
		</div>
	)
}

export default Manager
