import axios from 'axios'
import { setInvitationAccountTextStatus } from '../redux/invitationToPersonalAccount/invitationToPersonalAccount'

const baseURL = process.env.REACT_APP_BASE_URL

export const invitationAccount = (
	globalPersonalData,
	checkPhone,
	checkEmail,
	accessToken
) => {
	return async (dispatch) => {
		try {
			const response = await axios.post(
				`${baseURL}/invitation`,
				{
					phone: globalPersonalData.phone,
					email: globalPersonalData.email,
					send_phone: checkPhone,
					send_email: checkEmail
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			)
			dispatch(setInvitationAccountTextStatus('Приглашение отправлено'))
		} catch (error) {
			dispatch(
				setInvitationAccountTextStatus(
					error.response.data?.detail
						? error.response.data?.detail
						: 'Что-то пошло не так'
				)
			)
		}
	}
}
