import React, { useEffect, useState } from 'react'
import s from './signingDocumentsContent.module.scss'
import cloudUploadImg from '../../common/images/cloud-upload.svg'
import cloudUploadWhiteImg from '../../common/images/cloud-upload-white.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { client } from '../../api/client/client'
// import { setOpenedDocument } from '../../redux/client/client'
import { Viewer, Worker, LocalizationMap } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json'

import FileViewer from 'react-file-viewer'
import PowerAttorneyComponents from '../../components/powerAttorneyComponents/powerAttorneyComponents'

const pdfjs = import('pdfjs-dist/build/pdf').then(() => {
	const pdfjsWorker = import('pdfjs-dist/build/pdf.worker.entry').then(() => {
		if (pdfjs.GlobalWorkerOptions) {
			pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
		}
	})
})

const SigningDocumentsContent = ({ width, documentName }) => {
	const { documentId } = useParams()
	const defaultLayoutPluginInstance = defaultLayoutPlugin()
	const accessToken = localStorage.getItem('accessToken')
	const { openedDocument, taskListClientData } = useSelector(
		(state) => state.client
	)
	const dispatch = useDispatch()
	const [height, setHeight] = useState(document.documentElement.clientHeight)
	const appealId = localStorage.getItem('appealId')
	const [file, setFile] = useState(false)
	const [fileDataURL, setFileDataURL] = useState(false)
	const [fileDataType, setFileDataType] = useState(false)
	const [pdfFile, setPdfFile] = useState(null)

	useEffect(() => {
		window.addEventListener('resize', () => {
			setHeight(document.documentElement.clientHeight)
		})

		return () => {
			window.removeEventListener('resize', {})
		}
	}, [])

	useEffect(() => {
		setPdfFile(null)
		setFileDataType(null)
		setFileDataURL(null)
		if (documentName === 'Доверенность') {
		} else {
			dispatch(client.getDocumentById(appealId, documentId, accessToken))
		}
	}, [documentId])

	useEffect(() => {
		if (openedDocument?.file) {
			setPdfFile(
				`data:${openedDocument?.media_type};base64,${openedDocument?.file}`
			)
		}
	}, [openedDocument])

	useEffect(() => {
		setPdfFile(null)
		setFileDataType(null)
		setFileDataURL(null)
	}, [])

	const uploadDocument = (selectedFile) => {
		const formData = new FormData()
		selectedFile && formData.append('file', selectedFile)

		dispatch(client.uploadDocument(appealId, documentId, formData, accessToken))
	}

	const handleChange = (event) => {
		let fileType = ext(event.target.files[0].name)

		setFileDataType(false)
		setFile(false)

		function ext(name) {
			var m = name.match(/\.([^.]+)$/)
			return m && m[1]
		}

		if (fileType === 'doc') {
			setFileDataType('docx')
		} else {
			setFileDataType(ext(event.target.files[0].name))
		}

		setFile(event.target.files[0])
		uploadDocument(event.target.files[0])
		setTimeout(function () {window.location.reload()}, 3000)
	}

	useEffect(() => {
		let fileReader,
			isCancel = false
		if (openedDocument?.file) {
			const fileType =
				openedDocument?.media_type.split('.')[
					openedDocument?.media_type.split('.').length - 1
				]
			setFileDataURL(
				`data:${
					openedDocument?.media_type.split('/')[1] === 'msword'
						? 'application/docx'
						: openedDocument?.media_type
				};base64,${openedDocument?.file}`
			)
			setFileDataType(
				fileType === 'document'
					? 'docx'
					: fileType === 'sheet'
					? 'xlsx'
					: openedDocument?.media_type.split('/')[1] === 'msword'
					? 'docx'
					: openedDocument?.media_type.split('/')[1]
			)
		} else if (file) {
			fileReader = new FileReader()
			fileReader.onload = (e) => {
				const { result } = e.target
				if (result && !isCancel) {
					setFileDataURL(result)
				}
			}
			fileReader.readAsDataURL(file)
		}
		return () => {
			isCancel = true
			if (fileReader && fileReader.readyState === 1) {
				fileReader.abort()
			}
		}
	}, [file, openedDocument])

	if (documentName === 'Доверенность') {
		return <PowerAttorneyComponents taskListClientData={taskListClientData} />
	}

	return (
		<>
			{width < 800 ? (
				<div className={s.signingDocumentsContentMobile}>
					{pdfFile ? (
						<div
							style={width < 576 ? { height: `${height - 200}px` } : {}}
							className={s.signingDocumentsContentMobile__wrapper}
						>
							{fileDataURL && (
								<div
									style={
										openedDocument?.media_type !== 'application/pdf'
											? { padding: '10px 20px 10px 20px' }
											: {}
									}
									className={s.signingDocumentsContentMobile__wrapper__file}
								>
									{openedDocument?.media_type === 'application/pdf' ? (
										<Worker workerUrl='/pdf.worker.bundle.worker.js'>
											<Viewer
												localization={ru_RU}
												fileUrl={fileDataURL}
												plugins={[defaultLayoutPluginInstance]}
											/>
										</Worker>
									) : openedDocument.media_type === 'image/jpeg' ? (
										<img
											title='file'
											src={fileDataURL}
											style={{ opacity: '1' }}
											alt=''
										/>
									) : (
										<FileViewer
											fileType={fileDataType}
											filePath={fileDataURL}
										/>
									)}
								</div>
							)}
							{!openedDocument?.status_confirmed && (
								<div
									className={
										s.signingDocumentsContentMobile__wrapper__newDocumentUpload
									}
								>
									<div>
										<img src={cloudUploadImg} alt='' />
										<div>
											<input type='file' onChange={handleChange} accept='application/pdf'/>
											<label htmlFor='input__file'>
												Загрузить новую версию документа только в PDF формате
											</label>
										</div>
									</div>
								</div>
							)}
							{!openedDocument?.sign_status && openedDocument?.status_confirmed && (
								<button>Подпишите в приложении</button>
							)}
							{!openedDocument?.status_confirmed && (
								<button>Документ отправлен на проверку</button>
							)}
						</div>
					) : (
						!openedDocument?.status_confirmed && (
							<div
								className={s.signingDocumentsContentMobile__documentUpload}
								style={{ marginTop: '200px' }}
							>
								<div>
									<img src={cloudUploadWhiteImg} alt='' />
									<div>
										<input
											type='file'
											name='file'
											accept='application/pdf'
											id='input__file'
											onChange={handleChange}
										/>
										<label htmlFor='input__file'>Загрузить документ только в PDF формате</label>
									</div>
								</div>
							</div>
						)
					)}
				</div>
			) : (
				<div
					className={s.signingDocumentsContent}
					style={!fileDataURL ? { display: 'flex' } : {}}
				>
					{fileDataURL ? (
						<div className={s.signingDocumentsContent__file}>
							{openedDocument?.media_type === 'application/pdf' ? (
								<Worker workerUrl='/pdf.worker.bundle.worker.js'>
									<Viewer
										localization={ru_RU}
										fileUrl={fileDataURL}
										plugins={[defaultLayoutPluginInstance]}
									/>
								</Worker>
							) : openedDocument.media_type === 'image/jpeg' ? (
								<img
									title='file'
									src={fileDataURL}
									style={{ opacity: '1', maxHeight: '700px' }}
									alt=''
								/>
							) : (
								<FileViewer fileType={fileDataType} filePath={fileDataURL} />
							)}
							{!openedDocument?.sign_status && (
								<div className={s.signingDocumentsContent__newDocumentUpload}>
									<img src={cloudUploadImg} alt='' />
									<div>
										<input
											type='file'
											name='file'
											id='input__file'
											accept='application/pdf'
											onChange={handleChange}
										/>
										<label htmlFor='input__file'>
											Загрузить новую{' '}
											{documentName === 'Доверенность'
												? 'доверенность только в PDF формате'
												: 'версию документа только в PDF формате'}
										</label>
									</div>
								</div>
							)}
						</div>
					) : (
						!openedDocument?.status_confirmed && (
							<div className={s.signingDocumentsContent__documentUpload}>
								<img src={cloudUploadWhiteImg} alt='' />
								<div>
									<input
										type='file'
										name='file'
										id='input__file'
										accept='application/pdf'
										onChange={handleChange}
									/>
									<label htmlFor='input__file'>Загрузить документ только в PDF формате</label>
								</div>
							</div>
						)
					)}
				</div>
			)}
		</>
	)
}
export default SigningDocumentsContent
