import React, { useState } from 'react'
import Button from '../../button/button'
import s from './deals-header.module.scss'
import { setExitLogin, setUserName } from '../../../redux/login/login'
import { useDispatch, useSelector } from 'react-redux'
import logoCompany from '../../../common/images/logoCompany.svg'
import { user } from '../../../api/user'
import { useEffect } from 'react'
import { setPageName } from '../../../redux/deals/deals'

export const HeaderDeal = ({
	FConCreation,
	userRole,
	setChangePasswordWindow,
	setNewEgrn
}) => {
	const accessToken = localStorage.getItem('accessToken')
	const { pageName } = useSelector((state) => state.deals)
	const dispatch = useDispatch()
	const { userName } = useSelector((state) => state.login)
	const [profileUser, setProfileUser] = useState(false)

	const exitLogin = () => {
		dispatch(setExitLogin())
		//localStorage.removeItem('accessToken')
		localStorage.clear()
	}

	const createDeal = () => {
		FConCreation('createDeal')
	}

	const createUser = () => {
		FConCreation('createUser')
	}

	const createStatement = () => {
		FConCreation('createStatement')
		setNewEgrn(true)
	}

	const handleGetUserName = () => {
		if (!userName) {
			dispatch(user.getUserName(accessToken))
		}
		setProfileUser(!profileUser)
	}

	useEffect(() => {
		return () => dispatch(setUserName(null))
	}, [])

	return (
		<div className={s.header}>
			<div className={s.headerItemLeft}>
				<div className={s.headerLogo}>
					<img src={logoCompany} alt='logoCompany' />
				</div>
				<>
					<h4
						className={
							pageName === 'deals' || pageName === 'archive'
								? s.Headeractive
								: undefined
						}
						onClick={() => dispatch(setPageName('deals'))}
					>
						Сделки
					</h4>
					<h4
						className={pageName === 'egrn' ? s.Headeractive : undefined}
						onClick={() => dispatch(setPageName('egrn'))}
					>
						ЕГРН
					</h4>
					{userRole === 'AD' && (
						<>
							<h4
								className={pageName === 'users' ? s.Headeractive : undefined}
								onClick={() => dispatch(setPageName('users'))}
							>
								Пользователи
							</h4>
							<h4
								className={
									pageName === 'organization' ? s.Headeractive : undefined
								}
								onClick={() => dispatch(setPageName('organization'))}
							>
								Организации
							</h4>
						</>
					)}
				</>
			</div>
			<div>
				{pageName === 'egrn' && (
					<Button FCOnclick={createStatement} small={true}>
						Новая выписка
					</Button>
				)}
				{(pageName === 'deals' || pageName === 'archive') && (
					<Button FCOnclick={createDeal} small={true}>
						Новая сделка
					</Button>
				)}
				{pageName === 'users' && (
					<Button FCOnclick={createUser} small={true}>
						Новый пользователь
					</Button>
				)}
				<div className={s.headerButtonProfile} onClick={handleGetUserName}>
					<img src='' alt='' />
					<div
						style={profileUser ? { display: 'block' } : { display: 'none' }}
						className={s.profileIcon}
					>
						<h4>{userName}</h4>
						<span onClick={() => setChangePasswordWindow(true)}>
							Сменить пароль
						</span>
						<span onClick={() => exitLogin()}>Выйти из профиля</span>
					</div>
				</div>
			</div>
		</div>
	)
}
