import React, { useEffect } from 'react'
import Container from '../container/container'
import ContainerContent from '../container-content/container-content'
import s from './deal-creation.module.scss'
import Button from '../button/button'
import Input from '../input/input'
import { Modal } from '../modal/modal'
import { useState } from 'react'
import { SaleMan } from './sales-man/sales-man'
import { RealEstate } from './real-estate/real-estate'
import { useDispatch, useSelector } from 'react-redux'
import { appeals } from '../../api/appeals'
import { deals } from '../../api/deals/deals'
import { useNavigate } from 'react-router'
import { setFoundAddress } from '../../redux/address/address'
import { setFoundRealty } from '../../redux/deals/deals'

export const DealCreation = ({ active, setActive }) => {
	const accessToken = localStorage.getItem('accessToken')
	const { objectTypeState } = useSelector((state) => state.realty)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [selectSeller, setSelectSeller] = useState('UNISTROY')
	const [realtyData, setRealtyData] = useState({
		seller: selectSeller,
		type_appeal_id: 0,
		agreement_number: '',
		agreement_date: '',
		object: {
			cadastral_number: '',
			description: '',
			address: '',
			square: '',
			type_id: 0,
			right_type_id: null
		}
	})

	const [selectedValuetypeAppeals, setSelectedtypeAppeals] = useState()
	const [selectedValuetypeAppealsId, setSelectedValuetypeAppealsId] = useState()
	const [selectTypeOriginObjId, setSelectTypeOriginObjId] = useState()
	const [typeRealtyValueSelected, setTypeRealtyValueSelected] = useState()
	const [objectType, setObjectType] = useState(null)

	const { typeAppeals } = useSelector((state) => state.appeals)
	const { foundRealty, dealId } = useSelector((state) => state.deals)

	useEffect(() => {
		dispatch(appeals.typeAppealsList(accessToken))

		return () => {
			dispatch(setFoundAddress(null))
			dispatch(setFoundRealty(null))
		}
	}, [])

	const handleSelectSeller = (seller) => {
		setSelectSeller(seller)
		setRealtyData({ ...realtyData, seller: seller })
	}

	const handleRealtyData = (e) => {
		if (selectSeller === 'UNISTROY') {
			setRealtyData({ ...realtyData, [e.target.name]: e.target.value })
		}
		if (selectSeller === 'THIRD_PARTY') {
			setRealtyData({
				...realtyData,
				object: { ...realtyData.object, [e.target.name]: e.target.value }
			})
		}
	}

	const handleTypeAppeal = (typeAppeal) => {
		setRealtyData({ ...realtyData, type_appeal_id: typeAppeal?.id })
		setSelectedtypeAppeals(typeAppeal)
	}

	const handleTypeOriginObjId = (typeAppeal) => {
		setRealtyData({ ...realtyData, type_origin_obj_id: typeAppeal?.id })
		setSelectTypeOriginObjId(typeAppeal)
	}

	const handleTypeId = (typeAppeal) => {
		setRealtyData({ ...realtyData, type_id: typeAppeal?.id })
		setSelectedValuetypeAppealsId(typeAppeal)
	}

	const handleTypeRealty = (typeRealty) => {
		setRealtyData({
			...realtyData,
			object: { ...realtyData.object, type_id: typeRealty?.id }
		})
		setTypeRealtyValueSelected(typeRealty)
	}

	const onCreateDeal = async () => {
		
		if (selectSeller === 'THIRD_PARTY') {
			await dispatch(
				deals.dealCreate(
					{
						seller: realtyData?.seller,
						type_appeal_id: realtyData.type_appeal_id,
						object: {
							cadastral_number: realtyData?.object?.cadastral_number,
							cadastral_number_area: realtyData?.object?.cadastral_number_area,
							description: realtyData?.object?.description,
							address: realtyData?.object.address,
							square: realtyData?.square,
							right_type_id: objectTypeState?.id,
							type_origin_obj_id: realtyData.type_origin_obj_id,
							type_id: realtyData.type_id,
							suspended_number_building: realtyData?.suspended_number_building,
							suspended_number_flat: realtyData?.suspended_number_flat,
							building_area: realtyData?.object.building_area
						}
					},
					accessToken,
					navigate
				)
			)

			navigate(`/deal/${dealId}`)
		}
		if (selectSeller === 'UNISTROY') {
			await dispatch(
				deals.dealCreate(
					{
						seller: realtyData?.seller,
						type_appeal_id: realtyData?.type_appeal_id,
						agreement_number: realtyData?.agreement_number,
						agreement_date: realtyData?.agreement_date,
						object: {
							type_origin_obj_id: realtyData.type_origin_obj_id
						}
					},
					accessToken,
					navigate
				)
			)
		}
	}

	const diabledButtonCreationDeal =
		selectedValuetypeAppeals &&
		selectTypeOriginObjId &&
		objectTypeState &&
		realtyData.object.address &&
		realtyData.object.description

	return (
		<Container>
			<ContainerContent>
				<Modal fullScreen onClose active={active} setActive={setActive}>
					<div className={s.container}>
						<div className={s.dealCreation}>
							<p>Новая сделка</p>
							<SaleMan
								seller={selectSeller}
								FCSelectSeller={handleSelectSeller}
							/>
							<div className={s.dealType}>
								<p className={s.dealTypeField}>Тип сделки</p>
								<Input
									value={selectedValuetypeAppeals?.title}
									setSelectedValue={handleTypeAppeal}
									selectedValue={selectedValuetypeAppeals}
									select
									dataList={typeAppeals}
									placeholderForSelect='Выберите тип соглашения'
									placeholder='Выберите тип соглашения'
									readOnly
								/>
							</div>
							<RealEstate
								foundRealty={foundRealty}
								seller={selectSeller}
								realtyData={realtyData}
								handleRealtyData={handleRealtyData}
								handleTypeAppeal={handleTypeAppeal}
								handleTypeRealty={handleTypeRealty}
								handleTypeOriginObjId={handleTypeOriginObjId}
								typeRealtyValueSelected={typeRealtyValueSelected}
								setRealtyData={setRealtyData}
								selectedValuetypeAppeals={selectedValuetypeAppeals}
								typeAppeals={typeAppeals}
								setSelectTypeOriginObjId={setSelectTypeOriginObjId}
								selectTypeOriginObjId={selectTypeOriginObjId}
								handleTypeId={handleTypeId}
								selectedValuetypeAppealsId={selectedValuetypeAppealsId}
								objectType={objectType}
								setObjectType={setObjectType}
							/>
							<div className={s.createDealButton}>
								<Button
									FCOnclick={onCreateDeal}
									disabled={
										selectSeller === 'UNISTROY'
											? !(foundRealty && selectedValuetypeAppeals?.id)
											: !diabledButtonCreationDeal
									}
									small={true}
								>
									Создать сделку
								</Button>
								{!foundRealty && selectSeller === 'UNISTROY' && (
									<p>Укажите объект сделки</p>
								)}
							</div>
						</div>
					</div>
				</Modal>
			</ContainerContent>
		</Container>
	)
}
