import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { admin } from '../../../api/admin'
import { Modal } from '../../modal/modal'
import s from './organization.module.scss'
import { ViewOrganization } from './view-organization/view-organization'
import ReactPaginate from "react-paginate";
import'../deals.css'

export const Organization = () => {
	const accessToken = localStorage.getItem('accessToken')
	const dispatch = useDispatch()
	const { organizations, openedOrganization } = useSelector(
		(state) => state.admin
	)
	const [openModal, setOpenModal] = useState(false)

	const [page, setPage] = useState(0)
	const result = organizations?.result;
	const itemsLength = 20;
	let itemOffset = page + 1;
	const pageCount = Math.ceil(organizations?.total / itemsLength);

	useEffect(() => {
		dispatch(admin.organizations( accessToken, itemOffset, itemsLength ))
	}, [page])

	const handleGetOrganizationById = async (organizationId) => {
		await dispatch(admin.organizationById(organizationId, itemOffset, itemsLength, accessToken))
		setOpenModal(true)
	}

	return (
		<>
			{result?.map((organization) => (
				<div className={s.container}>
					<div>
						<div onClick={() => handleGetOrganizationById(organization?.id)}>
							{organization?.full_name}
						</div>
						<div>{organization?.numberOfAgents}</div>
					</div>
				</div>
			))}
			{result && <ReactPaginate
				containerClassName={"pagination"}
				pageClassName={"page-item"}
				activeClassName={"active"}
				onPageChange={(event) => setPage(event.selected)}
				pageCount={pageCount}
				marginPagesDisplayed={2}
				pageRangeDisplayed={2}
				renderOnZeroPageCount={null}
				breakLabel="..."
				previousLabel={"< Назад"}
				nextLabel={"Вперед >"}
				forcePage={page}
			/>}
			<Modal
				fullScreen={true}
				onClose={true}
				active={openModal}
				setActive={setOpenModal}
			>
				<ViewOrganization openedOrganization={openedOrganization} />
			</Modal>
		</>
	)
}
