import React, { useEffect, useState } from 'react'
import preloader from '../../../common/preloader/preloader.gif'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json'
import DocumentSignatureWindow from '../../documentSignatureWindow/documentSignatureWindow'
import RequestDocument from '../../requestDocument/requestDocument'
import s from './documentCreationData.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { documents } from '../../../api/document/document'
import { useParams, useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import {
	setDateDocument,
	setDocumentStatusText,
	setNumberDocument,
	setSignatureManual
} from '../../../redux/documents/documents'
import { Modal } from '../../../components/modal/modal'
import FileViewer from 'react-file-viewer'
import Input from '../../../components/input/input'
import Button from '../../../components/button/button'
import XML from '../../../images/2001708.png';

const Signer = ({ item, setLegalEntityDocument, deleteSigner, status }) => {
	const {documentStatusText} = useSelector((state) => state.documents)
	const [loading, setLoading] = useState(false)
	const [disabled, setDisabled] = useState(false)
	const dispatch = useDispatch()
	const {
		representative,
		full_name,
		doc_signed_status,
		signature_manual,
		signer_id,
		error_state,
		error_description,
		task_status
	} = item

	useEffect(() => {
		if(status === `Документ подписан. Удаление невозможно`) {
			setLoading(false);
		}
	}, [status])

	const deleteHandle = (id) => {
		setLoading(true)
		deleteSigner(id)
	}
	
	useEffect(() => {
		task_status === "В процессе" ? setDisabled(true) : setDisabled(false);
	}, [task_status])
	

	return (
		<div className={s.documentCreationData__rightSidebar__signature__wrapper}>
			{loading ? (
				<div className={s.preloderWrapper}>
					<img src={preloader} alt='preloader' />
					<span>Подождите</span>
				</div>
			) : (
				<div
					className={
						s.documentCreationData__rightSidebar__signature__wrapper__name
					}
				>
					<span>{representative ? representative?.full_name : full_name}</span>
					{task_status === "Подписано" && error_state === null && <span style={{ color: '#1FA34A' }}>{task_status}</span>}
					{task_status !== "Подписано" && error_state === null && <span style={{ color: '#ED6A5A' }}>{task_status}</span>}
					{error_state && 
						<span>{error_description}</span>
					}
					{(error_state !== null || task_status === 'Подтвердите подписание') &&
						<span onClick={() => {dispatch(documents.reloadDocumentSigner(signer_id, console.log("Повторно отправленно!")))}}>{error_state !== null ? "Повторить отправку" : "Подтвердить подписание"}</span>
					}

					{signature_manual && (
						<div
							className={
								s.documentCreationData__rightSidebar__signature__wrapper__name__documentStatus
							}
						>
							{doc_signed_status ? (
								<span>Подписанный документ загружен</span>
							) : (
								<>
									<input
										type='file'
										name='fileDown'
										id='input__fileDown'
										onChange={(e) =>
											setLegalEntityDocument({
												file: e.target.files[0],
												signer_id: signer_id
											})
										}
									/>
									<label htmlFor='input__fileDown'>
										Загрузить подписанный документ...
									</label>
								</>
							)}
						</div>
					)}
				</div>
			)}
			<button className={disabled === true && s.hide} disabled={loading} onClick={() => deleteHandle(signer_id)} />
		</div>
	)
}

const DocumentCreationData = ({ selected, setSelected, documentsTypeList }) => {
	const defaultLayoutPluginInstance = defaultLayoutPlugin()
	const [requestDocument, setRequestDocument] = useState(null)
	const [requestDocumentWindow, setRequestDocumentWindow] = useState(false)
	const [requestSignatureWindow, setRequestSignatureWindow] = useState(false)
	const [addSigned, setAddSigned] = useState(false)
	const [activeUser, setActiveUser] = useState(null)
	const [documentTypeChange, setDocumentTypeChange] = useState(false)
	const dispatch = useDispatch()
	let { state } = useLocation()
	const accessToken = localStorage.getItem('accessToken')
	const [urlImg, setUrlImg] = useState(null)
	const [test, setTest] = useState(null)
	const [
		windowConfirmationDeleteDocument,
		setWindowConfirmationDeleteDocument
	] = useState(false)
	const [legalEntityDocument, setLegalEntityDocument] = useState(null)
	const [signatureDown, setSignatureDown] = useState(null)
	const [editNumberAndDate, setEditNumberAndDate] = useState(false)
	const [windowPowerOfAttorneyDate, setWindowPowerOfAttorneyDate] = useState(false)

	const {
		documentData,
		documentRequestUserStatus,
		documentStatusText,
	} = useSelector((state) => state.documents)
	const { appealDeal } = useSelector((state) => state.appeals)
	const { document, powerOfAttorney } = useSelector((state) => state.documents)
	const { dealId } = useSelector((state) => state.deals)
	const appeal_id = localStorage.getItem('appeal_id')
	const { documentId } = useParams()
	const navigate = useNavigate()

	useEffect(() => {
		dispatch(documents.powerOfAttorneyAppeal(appeal_id))
		dispatch(documents.powerOfAttorney(appeal_id))
	}, [])

	useEffect(() => {
		if (signatureDown) {
			const formData = new FormData()
			formData.append('file', signatureDown)
		}
	}, [signatureDown])

	useEffect(() => {
		if (documentData) {
			if (!documentId) {
				navigate(`/documentCreation/${documentData.id}`)
			}

			if (documentData?.file) {
				setTest(`data:${documentData?.media_type};base64,${documentData?.file}`)
			}
		} else {
			dispatch(documents.documentCreate(selected?.id, appeal_id, accessToken))
		}
	}, [selected, documentData])

	useEffect(() => {
		if (documentData && urlImg) {
			const formData = new FormData()
			formData.append('file', urlImg)
			dispatch(
				documents.editDocumentFile(appeal_id, documentId, formData, accessToken)
			)
		}
	}, [urlImg])

	useEffect(() => {
		if (requestDocument) {
			setRequestDocumentWindow(false)
			setRequestSignatureWindow(false)
		}
	}, [requestDocument])

	const addSignedFC = () => {
		setAddSigned(true)
		setRequestDocumentWindow(true)
		setRequestDocumentWindow(true)
	}

	const documentTypeChangeFc = (item) => {
		setSelected(item)
		setDocumentTypeChange(false)
	}

	useEffect(() => {
		if (!documentStatusText) {
			return
		}

		if (state?.source === 'egrn') {
			dispatch(documents.getDocument(accessToken, documentId))
		} else {
			dispatch(
				documents.getDealDocument(accessToken, appealDeal?.id, documentId)
			)
		}

		setTimeout(() => {
			dispatch(setDocumentStatusText(null))
		}, 30000)
	}, [documentStatusText])

	useEffect(() => {
		if (legalEntityDocument) {
			const formData = new FormData()
			formData.append('file', legalEntityDocument.file)

			if (state?.source === 'egrn') {
				dispatch(
					documents.uploadEgrnSignDocumentFile(
						documentId,
						documentData?.signers_data[0].signer_id,
						accessToken,
						formData
					)
				)
			} else {
				dispatch(
					documents.uploadSignDocumentFile(
						appeal_id,
						documentId,
						legalEntityDocument.signer_id,
						accessToken,
						formData
					)
				)
			}
		}
	}, [legalEntityDocument])

	const closeRequestDocumentWindow = () => {
		setRequestDocumentWindow(!requestDocumentWindow)
	}
	const closeRequestSignatureWindow = () => {
		setRequestSignatureWindow(!requestSignatureWindow)
	}

	useEffect(() => {
		if (documentStatusText && windowConfirmationDeleteDocument) {
			setTimeout(() => {
				navigate(`/deal/${dealId}`)
			}, 1000)
		}
	}, [documentStatusText])

	const editNumberAndDateFC = () => {
		dispatch(
			documents.documentEditDetail(
				documentId,
				accessToken,
				documentData.number,
				documentData.issue_date
			)
		)
		setEditNumberAndDate(false)
	}

	const deleteSigner = (signerId) => {
		if (state?.source === 'egrn') {
			dispatch(
				documents.documentEgrnDeleteSigner(documentId, signerId, accessToken)
			)
		} else {
			dispatch(
				documents.documentDeleteSigner(
					appeal_id,
					documentId,
					signerId,
					accessToken
				)
			)
		}
	}

	const sendPowerOfAttorneyDate = (doc) => {
		dispatch(
			documents.editPowerOfAttorneyAppeal(doc.id || doc.power_attorney_doc_id, documentId, accessToken)
		)
		setWindowPowerOfAttorneyDate(false)
	}

	return (
		<div className={s.documentCreationData}>
			<div className={s.documentCreationData__leftSidebar}>
				{test ? (
					<>
						{(documentData.media_type === 'application/msword') && (
								<FileViewer
									fileType={'docx'}
									filePath={`data:${documentData?.media_type};base64,${documentData?.file}`}
								/>
							)}
						{documentData.media_type === 'application/pdf' && (
							<Worker workerUrl='/pdf.worker.bundle.worker.js'>
								<Viewer
									localization={ru_RU}
									fileUrl={test}
									plugins={[defaultLayoutPluginInstance]}
								/>
							</Worker>
						)}

						{documentData.media_type === 'image/jpeg' && (
							<img title='file' src={test} style={{ opacity: '1' }} alt='' />
						)}

						{documentData.media_type === 'application/xml' && (
							<img title='file' src={XML} style={{ opacity: '1' }} alt='XML' />
						)}		
					</>
				) : (
					<span className={s.documentCreationData__leftSidebar__bg}>
						Документ пока не загружен
					</span>
				)}
			</div>

			<div className={s.documentCreationData__rightSidebar}>
				{documentTypeChange ? (
					<div className={s.documentTypeChange}>
						<h4>Тип документа</h4>
						<div className={s.documentTypeChange__wrapper}>
							{documentsTypeList.map((item) => (
								<span
									key={item.id}
									className={
										selected?.id === item.id
											? s.documentTypeChange__wrapper__itemActive
											: s.documentTypeChange__wrapper__item
									}
									onClick={() => documentTypeChangeFc(item)}
								>
									{item.title}
								</span>
							))}
						</div>
					</div>
				) : (
					<>
						<h4>{selected?.title}</h4>
						{state?.source !== 'egrn' && (
							<button onClick={() => setDocumentTypeChange(true)}>
								Изменить тип документа...
							</button>
						)}
						<div className={s.documentCreationData__rightSidebar__inputWrapper}>
							{selected?.title === 'Чек' && editNumberAndDate && (
								<>
									<div>
										<span>Номер:</span>
										<Input
											value={documentData.number ? documentData.number : ''}
											maxLength='15'
											onChange={(e) =>
												dispatch(setNumberDocument(e.target.value))
											}
										/>
									</div>
									<div>
										<span>Дата:</span>
										<Input
											dataCorrect
											type='date'
											value={
												documentData.issue_date ? documentData.issue_date : ''
											}
											onChange={(e) =>
												dispatch(setDateDocument(e.target.value))
											}
										/>
									</div>
									<Button FCOnclick={editNumberAndDateFC}>Сохранить</Button>
								</>
							)}
							{selected?.title === 'Чек' &&
								documentData?.file &&
								!editNumberAndDate && (
									<>
										<div>
											<span>Номер:</span>
											<span
												style={{ width: 'unset' }}
												className={
													s.documentCreationData__rightSidebar__inputWrapper__input
												}
											>
												{documentData.numberDocument}
											</span>
										</div>
										<div>
											<span>Дата:</span>
											<span
												style={{ width: 'unset' }}
												className={
													s.documentCreationData__rightSidebar__inputWrapper__input
												}
											>
												{documentData.issue_date}
											</span>
										</div>
										<button
											className={
												s.documentCreationData__rightSidebar__inputWrapper__buttonEdit
											}
											onClick={() => setEditNumberAndDate(true)}
										/>
									</>
								)}
						</div>

						<div className={s.documentCreationData__rightSidebar__fileWrapper}>
							<span>Файл</span>
							<div
								className={
									s.documentCreationData__rightSidebar__fileWrapper__downloadFalse
								}
							>
								{documentData?.title ? (
									<>
										<div
											className={
												s.documentCreationData__rightSidebar__fileWrapper__downloadFalse__filePresent
											}
										>
											<span>
												{documentData?.title} <br />
												<a
													href={`data:${documentData?.media_type};base64,${documentData?.file}`}
													download={documentData?.document_type_data?.title}
												>
													Скачать
												</a>
											</span>
											{documentData?.signers_data?.length ? null : (
												<button
													onClick={() =>
														dispatch(
															documents.documentDeleteFile(
																appeal_id,
																documentId,
																accessToken
															)
														)
													}
												/>
											)}
										</div>

										{documentData?.file && !documentData?.status_confirmed && (
											<button
												onClick={() =>
													dispatch(
														documents.documentConfirmed(
															appeal_id,
															documentId,
															accessToken
														)
													)
												}
											>
												Подтвердить, что документ верный
											</button>
										)}
									</>
								) : (
									<div
										className={
											s.documentCreationData__rightSidebar__fileWrapper__downloadFalse__file
										}
									>
										<input
											type='file'
											name='file'
											id='input__file'
											onChange={(e) => setUrlImg(e.target.files[0])}
										/>
										<label htmlFor='input__file'>Загрузить документ...</label>
									</div>
								)}
								{documentData?.can_upload_by_participant ||
								documentRequestUserStatus
									? !documentData?.status_confirmed && (
											<div
												className={
													s.documentCreationData__rightSidebar__fileWrapper__downloadFalse__requestDocument
												}
											>
												<span>
													Документ будет запрошен у &#8195;
													{documentData.can_upload_by_participant?.full_name
														? documentData.can_upload_by_participant.full_name
														: requestDocument?.full_name}
												</span>
												<div>
													<button
														onClick={() =>
															dispatch(
																documents.documentRequestCancel(
																	appeal_id,
																	documentId,
																	accessToken
																)
															)
														}
													>
														Отменить запрос
													</button>
												</div>
											</div>
									  )
									: !documentData?.file && (
										<>
											<button onClick={() => setRequestDocumentWindow(true)}>
												Запросить документ у клиента...{' '}
											</button>
											<button onClick={() => setWindowPowerOfAttorneyDate(true)}>
												Загрузить документ из списка...{' '}
											</button>
										</>
									  )}
							</div>
						</div>
						<div className={s.documentCreationData__rightSidebar__signature}>
							<div
								style={
									documentData?.signers_data?.length
										? { borderBottom: '1px solid #F1F1F1' }
										: null
								}
							>
								<h4>
									{' '}
									{documentData?.signers_data?.length
										? 'Подписанты'
										: 'Добавить подписантов'}
								</h4>
							</div>

							{documentData?.signers_data?.map((item, i) => {
								return (
									<Signer
										key={i + 'signer'}
										item={item}
										setLegalEntityDocument={setLegalEntityDocument}
										deleteSigner={deleteSigner}
										status={documentStatusText}
									/>
								)
							})}
							{documentStatusText && (
								<div className={s.documentStatusText}>{documentStatusText}</div>
							)}

							<div className={s.buttonWrapper}>
								<h3>
									{' '}
									{documentData?.signers_data?.length
										? 'Добавить подписантов'
										: null}
								</h3>

								<button
									onClick={() => {
										addSignedFC()
										dispatch(setSignatureManual(true))
									}}
								>
									USB токен
								</button>
								<button onClick={() => addSignedFC()}>Мобильная ЭП</button>
							</div>
						</div>
						<div className={s.documentDelete}>
							<button onClick={() => {
											dispatch(setDocumentStatusText(null));
											setWindowConfirmationDeleteDocument(true);
										}
									}>
								Удаление документа
							</button>
						</div>
					</>
				)}
			</div>

			{requestSignatureWindow && (
				<DocumentSignatureWindow
					requestSignatureWindow={requestSignatureWindow}
					closeRequestSignatureWindow={closeRequestSignatureWindow}
					test={activeUser}
				/>
			)}

			{requestDocumentWindow && (
				<RequestDocument
					requestDocumentWindow={requestDocumentWindow}
					closeRequestDocumentWindow={closeRequestDocumentWindow}
					requestDocument={requestDocument}
					setRequestDocument={setRequestDocument}
					addSigned={addSigned}
					setAddSigned={setAddSigned}
				/>
			)}

			{windowConfirmationDeleteDocument && (
				<Modal active={windowConfirmationDeleteDocument}>
					<div className={s.windowConfirmationDeleteDocument}>
						<h4>Вы точно хотите удалить документ?</h4>
						{documentStatusText ? (
							<span>{documentStatusText}</span>
						) : (
							<div
								className={
									s.windowConfirmationDeleteDocument__wrapperButtonDeleteDocument
								}
							>
								<button
									onClick={() =>
										dispatch(
											documents.documentDelete(
												appeal_id,
												documentId,
												accessToken
											)
										)
									}
								>
									Да
								</button>
								<button
									onClick={() => setWindowConfirmationDeleteDocument(false)}
								>
									Нет
								</button>
							</div>
						)}
					</div>
				</Modal>
			)}

			<Modal
				onClose={true}
				active={windowPowerOfAttorneyDate}
				setActive={setWindowPowerOfAttorneyDate}
			>
				<h3>Выберите документ</h3>
				{document || powerOfAttorney ? (
					<div className={s.wrapper__modal}>
						{/* {document?.document_types?.map((doc) => {
							return (
								<div
									key={doc.power_attorney_doc_id}
									onClick={() => sendPowerOfAttorneyDate2(doc)}
									className={s.wrapper__modal__item}
								>
									<span>{doc.title}</span>
								</div>
							)
						})} */}
						{powerOfAttorney?.map((doc) => {
							return (
								<div
									key={doc.power_attorney_doc_id}
									onClick={() => sendPowerOfAttorneyDate(doc)}
									className={s.wrapper__modal__item}
								>
									<span>{doc.name}</span>
								</div>
							)
						})}
					</div>
				) : null}
			</Modal>
		</div>
	)
}

export default DocumentCreationData
