import { createSlice } from '@reduxjs/toolkit'

const user = createSlice({
	name: 'user',
	initialState: {
		photoPagePassport: null,
		signaturePagePassport: null,
		snilsPagePassport: null,
		deleteImgStatus: false,
		dataCorrect: false,
		headerTypeData: 'user',
		pageNumber: 1,
		organizations: null,
	},
	reducers: {
		setSnilsPagePassport(state, action) {
			state.snilsPagePassport = action.payload
		},
		setPhotoPagePassport(state, action) {
			state.photoPagePassport = action.payload
		},
		setSignaturePagePassport(state, action) {
			state.signaturePagePassport = action.payload
		},
		setDeleteImgStatus(state, action) {
			state.deleteImgStatus = action.payload
		},
		setPhotoPassport1(state, action) {
			state.photoPassport1 = action.payload
		},
		setPhotoPassport2(state, action) {
			state.photoPassport2 = action.payload
		},
		setPhotoSnils(state, action) {
			state.photoSnils = action.payload
		},
		setDataCorrect(state, action) {
			state.dataCorrect = action.payload
		},
		setHeaderTypeData(state, action) {
			state.headerTypeData = action.payload
		},
		setPageNumber(state, action) {
			state.pageNumber = action.payload
		},
		clearState(state) {
			state.photoPagePassport = null
			state.signaturePagePassport = null
			state.snilsPagePassport = null
			state.deleteImgStatus = false
			state.photoPassport1 = null
			state.photoPassport2 = null
			state.photoSnils = null
			state.dataCorrect = false
			state.headerTypeData = 'user'
		}
	}
})

export default user.reducer

export const {
	setSnilsPagePassport,
	setPhotoPagePassport,
	setSignaturePagePassport,
	setDeleteImgStatus,
	setPhotoPassport1,
	setPhotoPassport2,
	setPhotoSnils,
	setDataCorrect,
	setHeaderTypeData,
	clearState,
	setPageNumber,
	setUserOrganizations
} = user.actions
