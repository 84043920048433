import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { admin } from '../../../../api/admin'
import {
	setGlobalPersonalData,
	setGlobalPersonalDataValue,
} from '../../../../redux/globalPersonalData/globalPersonalData'
import {
	setHeaderTypeData,
	clearState,
	setPhotoPassport1,
	setPhotoPassport2,
	setPhotoSnils
} from '../../../../redux/user/user'
import { clearEcpState } from '../../../../redux/ecp'
import { Identification } from '../../../deal-party-edit/identification/identification'
import { PersonalData } from '../../../deal-party-edit/personal-data/personal-data'
import Input from '../../../input/input'
import s from './user-view.module.scss'
import { setNotificationText } from '../../../../redux/manager/manager'
import { setAdminTextStatus } from '../../../../redux/admin/admin'
import { Modal } from '../../../modal/modal'
import PopUpComplete from '../../../popUpComplete/popUpComplete'
import debounce from 'lodash.debounce'
import { deals } from '../../../../api/deals/deals'
import { alertStatusText } from '../../../alertStatusText/alertStatusText'
import { PassportImage } from './passport-image'
import Ecp from '../../../../blocks/ecp/ecp'

export const UserView = ({ setOpenModal, onCreation, itemOffset, itemsLength, employeeId }) => {
	const accessToken = localStorage.getItem('accessToken')
	const dispatch = useDispatch()
	const { globalPersonalData } = useSelector(
		(state) => state.globalPersonalData
	)
	const { adminTextStatus } = useSelector((state) => state.admin)
	const [windowConfirmDeletion, setWindowConfirmDeletion] = useState(false)
	const role = localStorage.getItem("userRole")

	const {
		photoPagePassport,
		signaturePagePassport,
		snilsPagePassport,
		headerTypeData,
	} = useSelector((state) => state.user)

	const {
		ecpStatus
	} = useSelector((state) => state.ecp)

	const updateSearchValueUsers = useCallback(
		debounce(() => {
			if (role === "AD") {
				dispatch(
					admin.usersSearch(accessToken, itemOffset, itemsLength, {
						search: localStorage.getItem('paramsUsers')
					})
				)
			}
		}, 350),
		[]
	)

	useEffect(() => {
		dispatch(clearState())
		dispatch(clearEcpState())
	}, [])

	useEffect(() => {
		if (adminTextStatus) {
			dispatch(admin.employees(accessToken))
			updateSearchValueUsers()
			if (adminTextStatus.redirect) {
				setTimeout(() => {
					dispatch(setAdminTextStatus(null))
					closeForm()
				}, 3000)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adminTextStatus])

	const saveDataEmployee = () => {
		const condition =
			!globalPersonalData?.permission_status &&
			!globalPersonalData?.role &&
			!globalPersonalData?.email &&
			!globalPersonalData?.phone &&
			!globalPersonalData.snils?.number &&
			!globalPersonalData?.birthday &&
			!globalPersonalData?.gender &&
			!globalPersonalData?.identification_card?.date_issue &&
			!globalPersonalData?.identification_card?.issued_by_whom &&
			!globalPersonalData?.identification_card?.series_number &&
			!globalPersonalData?.identification_card?.subsection_code &&
			!globalPersonalData?.name &&
			!globalPersonalData?.patronymics &&
			!globalPersonalData?.place_birth &&
			!globalPersonalData?.reg_address &&
			!globalPersonalData?.snils &&
			!globalPersonalData?.inn &&
			!globalPersonalData?.number &&
			!globalPersonalData?.surname

		if (condition) {
			dispatch(
				setAdminTextStatus(
					alertStatusText('Все поля обязательны к заполнению.', false)
				)
			)

			return
		}
		const formData = new FormData()

		if (photoPagePassport && typeof photoPagePassport !== 'string') {
			formData.append('photo_page', photoPagePassport)
		}
		if (signaturePagePassport && typeof signaturePagePassport !== 'string') {
			formData.append('signature_page', signaturePagePassport)
		}
		if (snilsPagePassport && typeof snilsPagePassport !== 'string') {
			formData.append('snils_scan', snilsPagePassport)
		}

		if (!employeeId) {
			dispatch(admin.employeeCreate(globalPersonalData, accessToken, formData))
		} else {
			dispatch(
				admin.employeeEdit(
					globalPersonalData,
					employeeId,
					formData,
					accessToken
				)
			)
		}
	}

	const closeForm = () => {
		setOpenModal(false)
		dispatch(clearState())
		dispatch(clearEcpState())
		dispatch(setPhotoPassport1(null))
		dispatch(setPhotoPassport2(null))
		dispatch(setPhotoSnils(null))
	}

	const deleteEmployee = () => {
		dispatch(admin.employeeDelete(employeeId, accessToken))
		closeForm()
	}

	useEffect(() => {
		onCreation && dispatch(setGlobalPersonalData({}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onCreation])

	return (
		<div
			className={s.container}
			style={onCreation && { margin: '0px -40px 0px -40px' }}
		>
			<div className={s.user__header}>
				<PopUpComplete description={adminTextStatus} />
				<div className={s.header__links}>
					<div
						className={`${s.header__link} ${
							headerTypeData === 'user' ? s.header__link_active : ``
						} `}
						onClick={() => dispatch(setHeaderTypeData('user'))}
					>
						<h4>Пользователь</h4>
					</div>
					{employeeId && (
						<div
							className={`${s.header__link} ${
								headerTypeData === 'ecp' ? s.header__link_active : ``
							} `}
							onClick={() => dispatch(setHeaderTypeData('ecp'))}
						>
							<h4>ЭЦП</h4>
							{ecpStatus ? <p>выпущена</p> : <p>не выпущена</p>}
						</div>
					)}
				</div>
				<div className={s.user__header__wrapperButton}>
					<span onClick={closeForm}>Отмена</span>
					<span onClick={() => saveDataEmployee()}>Сохранить</span>
				</div>
			</div>
			{headerTypeData === 'user' && (
				<div className={s.user__main_container}>
					<div>
						<div className={s.user}>
							<div className={s.roleHeader}>
								<h4>Аккаунт</h4>
							</div>
							<ul>
								<li>Работа в системе</li>
								<li className={s.user__selectWorkSystem}>
									<button
										onClick={() =>
											dispatch(
												setGlobalPersonalDataValue({
													name: 'permission_status',
													value: true
												})
											)
										}
										className={`${s.buttonRoleM} ${
											globalPersonalData?.permission_status
												? s.buttonRoleActive
												: ``
										}`}
									>
										Разрешена
									</button>
									<button
										onClick={() =>
											dispatch(
												setGlobalPersonalDataValue({
													name: 'permission_status',
													value: false
												})
											)
										}
										className={`${s.buttonRoleA} ${
											globalPersonalData?.permission_status === false
												? s.buttonRoleActive
												: ``
										}`}
									>
										Запрещена
									</button>
									<div>
										Если работа запрещена, пользователь не может входить в
										систему и работать с ней.
									</div>
								</li>
							</ul>

							<div className={s.user__role}>
								<span>Роль</span>
								<div>
									<span
										className={
											globalPersonalData?.role === 'MA' ? s.active : ``
										}
										onClick={() =>
											dispatch(
												setGlobalPersonalDataValue({
													name: 'role',
													value: 'MA'
												})
											)
										}
									>
										Менеджер
									</span>
									<span
										className={
											globalPersonalData?.role === 'AD' ? s.active : ``
										}
										onClick={() =>
											dispatch(
												setGlobalPersonalDataValue({
													name: 'role',
													value: 'AD'
												})
											)
										}
									>
										Администратор
									</span>
								</div>
							</div>
							<ul>
								<li>Почта</li>
								<li>
									<Input
										required
										value={
											globalPersonalData?.email ? globalPersonalData?.email : ''
										}
										onChange={(ev) =>
											dispatch(
												setGlobalPersonalDataValue({
													name: ev.target.name,
													value: ev.target.value
												})
											)
										}
										name='email'
									/>
								</li>
							</ul>

							<ul>
								<li>Телефон</li>
								<li>
									<Input
										required
										maxLength='17'
										value={
											globalPersonalData?.phone ? globalPersonalData?.phone : ''
										}
										onChange={(ev) =>
											dispatch(
												setGlobalPersonalDataValue({
													name: ev.target.name,
													value:
														+ev.target.value.toString().length > 0 &&
														ev.target.value.replace(
															/^(\d{1})(\d{3})(\d{3})(\d{2})(\d)+$/,
															'$1 ($2) $3-$4-$5'
														)
												})
											)
										}
										name='phone'
									/>
								</li>
							</ul>
						</div>
						<div className={s.customizedPersonalData}>
							<PersonalData />
						</div>
						<div className={s.customizedIndentification}>
							<Identification />
						</div>
						{!onCreation && (
							<div
								onClick={() => setWindowConfirmDeletion(true)}
								className={s.deleteUser}
							>
								<button>Удалить пользователя</button>
							</div>
						)}
					</div>

					<div>
						<PassportImage employeeId={employeeId} />
					</div>
				</div>
			)}

			{headerTypeData === 'ecp' && employeeId && (
				<Ecp
					setNotificationText={setNotificationText}
					isUser={true}
					employeeId={employeeId}
				/>
			)}
			{windowConfirmDeletion && (
				<Modal
					active={windowConfirmDeletion}
					onClose={true}
					setActive={setWindowConfirmDeletion}
				>
					<p>Вы точно хотите удалить пользователя?</p>
					<div className={s.wrapperButton}>
						<button onClick={() => deleteEmployee()}>Да</button>
						<button onClick={() => setWindowConfirmDeletion(false)}>Нет</button>
					</div>
				</Modal>
			)}
		</div>
	)
}
