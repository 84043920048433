import { createSlice } from '@reduxjs/toolkit'

const loginSlice = createSlice({
	name: 'loginSlice',
	initialState: {
		phone: null,
		email: null,
		password: null,
		validateEmail: false,
		validatePhone: false,
		errorTextLogin: null,
		errorPassword: false,
		disabledButton: true,
		loginUser: false,
		loginManager: true,
		login: false,
		userRole: localStorage.getItem('userRole'),
		userId: localStorage.getItem('userId'),
		userName: null,
		accessToken: null,
		uid64: null,
		testLoginClient: null,
		ws: null,
		errorLoginText: null
	},
	reducers: {
		setPhone(state, action) {
			state.phone = action.payload
		},
		setEmail(state, action) {
			state.email = action.payload
		},
		setPassword(state, action) {
			state.password = action.payload
		},
		setErrorLoginText(state, action) {
			state.errorTextLogin = action.payload
		},
		setLogin(state, action) {
			localStorage.setItem('accessToken', action.payload)
			state.login = true
		},
		setRefreshToken(state, action) {
			localStorage.setItem('refreshToken', action.payload)
		},
		setExitLogin(state, action) {
			localStorage.clear()
			state.login = false
		},
		setAssetToken(state, action) {
			state.accessToken = action.payload
		},
		setUid64(state, action) {
			state.uid64 = action.payload
		},
		setUserRole(state, action) {
			localStorage.setItem('userRole', action.payload)
			state.userRole = action.payload
		},
		setUserName(state, action) {
			state.userName = action.payload
		},
		setUserId(state, action) {
			localStorage.setItem('userId', action.payload)
			state.userId = action.payload
		},
		setPersonalData(state, action) {
			localStorage.setItem('userEmail', action.payload.email)
			localStorage.setItem('userPhone', action.payload.phone)
		}
	}
})

export default loginSlice.reducer

export const {
	setPhone,
	setEmail,
	setPassword,
	setErrorLoginText,
	setLogin,
	setExitLogin,
	setAssetToken,
	setUid64,
	setUserRole,
	setUserName,
	setUserId,
	setPersonalData,
	setRefreshToken
} = loginSlice.actions
