import React, { useEffect, useState } from 'react'
import s from './powerAttorneyComponents.module.scss'
import addedImg from '../../common/images/electronicSignature/added.svg'
import { documents } from '../../api/document/document'
import { useDispatch, useSelector } from 'react-redux'
import { setRealtyTextStatus } from '../../redux/realty/realty'
import PopUpComplete from '../popUpComplete/popUpComplete'
import { setFilePowerOfAttorney } from '../../redux/documents/documents'

const PowerAttorneyComponents = ({ taskListClientData }) => {
	const dispatch = useDispatch()
	const reprsentative_id = localStorage.getItem('representative_id')
	const accessToken = localStorage.getItem('accessToken')
	const appealId = localStorage.getItem('appealId')
	const { realtyTextStatus } = useSelector((state) => state.realty)
	const { filePowerOfAttorney } = useSelector((state) => state.documents)
	const { globalPersonalData } = useSelector(
		(state) => state.globalPersonalData
	)
	const baseURL = process.env.REACT_APP_BASE_URL

	useEffect(() => {
		if (!realtyTextStatus) return
		setTimeout(() => {
			dispatch(setRealtyTextStatus(null))
		}, 3000)
	}, [realtyTextStatus])

	useEffect(() => {
		dispatch(
			setFilePowerOfAttorney({
				title: taskListClientData?.power_attorney_title,
				path: taskListClientData?.power_attorney_path
			})
		)
	}, [])

	const handleChangePowerAttorney = (event) => {
		const formData = new FormData()
		event && formData.append('file', event.target.files[0])

		dispatch(
			documents.documentPowerAttorney(reprsentative_id, formData, accessToken)
		)
	}

	return (
		<>
			<PopUpComplete description={realtyTextStatus} />
			{filePowerOfAttorney?.path ? (
				<div className={s.powerAttorneyComponentsWrapper}>
					Наименование файла:<span> {filePowerOfAttorney?.title}</span>
					<div>
						<a
							href={`${baseURL}/file-cloud/${filePowerOfAttorney?.path}`}
							target='_blank'
						>
							Скачать
						</a>
						{!globalPersonalData?.save_status && (
							<button
								onClick={() =>
									dispatch(
										documents.documentDeletePowerAttorney(
											reprsentative_id,
											accessToken,
											appealId
										)
									)
								}
							>
								Удалить
							</button>
						)}
					</div>
				</div>
			) : (
				<div className={s.powerAttorneyComponentsWrapper__download}>
					<input
						type='file'
						name='fileAttorney'
						id='input__fileAttorney'
						onChange={handleChangePowerAttorney}
					/>
					<label htmlFor='input__fileAttorney'>
						<img src={addedImg} alt='addedImg' />
						Загрузить доверенность
					</label>
				</div>
			)}
		</>
	)
}

export default PowerAttorneyComponents
