import React, { useEffect } from 'react'
import s from './documents.module.scss'
import { Link } from 'react-router-dom'
import { manager } from '../../api/manager/manager'
import { useDispatch, useSelector } from 'react-redux'
import { deals } from '../../api/deals/deals'
import { setListCopiedDocuments } from '../../redux/manager/manager'
import { setAppealDeal } from '../../redux/deals/deals'
import { documents } from '../../api/document/document'
import { useNavigate } from 'react-router-dom'

const Documents = ({ appealDeal, deal, appealId }) => {
	const dispatch = useDispatch()
	const accessToken = localStorage.getItem('accessToken')
	const { listCopiedDocuments } = useSelector((state) => state.manager)
	const { newAppealsDocumentWS } = useSelector((state) => state.deals)
	const navigate = useNavigate();

	useEffect(() => {
		if (!newAppealsDocumentWS) return
		if (newAppealsDocumentWS?.appeal_id === appealDeal?.id) {
			dispatch(
				setAppealDeal(
					appealDeal.documents.map((item) => {
						return item.id === newAppealsDocumentWS.document.id
							? newAppealsDocumentWS.document
							: item
					})
				)
			)
		}
	}, [newAppealsDocumentWS])

	return (
		<div className={s.documents}>
			<div className={s.documents__headers}>
				<h3>Документы</h3>
				<div>
					{deal?.appeals?.length > 1 && appealId?.status && (
						<div
							onClick={() =>
								listCopiedDocuments
									? dispatch(setListCopiedDocuments(null))
									: dispatch(
											manager.getListCopiedDocuments(deal?.id, accessToken)
									  )
							}
						>
							<span>+</span>
							<span>Скопировать документ</span>
							{listCopiedDocuments?.length > 0 && (
								<div className={s.documents__headers__wrapperListParticipant}>
									{listCopiedDocuments?.map((item) => {
										return (
											<div
												key={item.id}
												style={
													listCopiedDocuments.length > 1
														? { borderBottom: '1px solid #DBDCDC' }
														: null
												}
											>
												<h4>{item.document_type}</h4>
												<span
													onClick={async () => {
														await dispatch(
															manager.copyDocument(
																deal?.id,
																item.id,
																accessToken
															)
														)
														dispatch(deals.deal(deal.id, accessToken))
													}}
												>
													Скопировать документ
												</span>
											</div>
										)
									})}
								</div>
							)}
						</div>
					)}
					<Link
						className={s.documents__headers__wrapper}
						to={
							appealDeal?.general_pd_status && appealDeal?.general_ecp_status
								? '/documentCreation'
								: ''
						}
						disabled={
							!appealDeal?.general_pd_status && !appealDeal?.general_ecp_status
						}
						style={
							appealDeal?.general_pd_status && appealDeal?.general_ecp_status
								? { color: '#1FA34A' }
								: { color: '#ADAAB4' }
						}
					>
						<span>+</span>
						<span>Документ</span>
					</Link>
				</div>
			</div>
			{appealDeal?.documents.length ? (
				appealDeal?.documents?.map((item) => {
					return (
						<div
							key={item.id}
							className={s.documents__item}
							onClick={() => navigate(`/documentCreation/${item.id}`)}
						>
							<div className={s.documents__item__leftInfo}>
								<h4>{item.document_type}</h4>
								{item.description && (
									<h4 style={{ paddingTop: `10px` }}>Описание:</h4>
								)}
							</div>
							{item.participants_documents.length ? (
								<div className={s.test1}>
									{item.participants_documents?.map((sig) => {
										return (
											<div className={s.test}>
												<span
													className={
														s.documents__item__rightInfo__item__status__name
													}
												>
													{sig.representative
														? sig.representative.full_name
														: sig.full_name}
													{item.description && (
														<span
															style={{
																display: 'block',
																paddingTop: `10px`,
																fontSize: '13px'
															}}
														>
															{item.description}
														</span>
													)}
												</span>
												<div className={s.column}>
													{sig.doc_signed_status || (sig.task_status === 'Подписано') ? (
														<span
															className={
																s.documents__item__rightInfo__item__status__spanTrue
															}
														>
															{sig.task_status}
														</span>
													) : (
														<span
															className={
																s.documents__item__rightInfo__item__status__spanFalse
															}
														>
															{sig.task_status}
														</span>
													)}
													{(sig.task_status === 'Подтвердите подписание') &&
														<span
															className={
																s.documents__item__rightInfo__item__status__spanFalse__button
															}
															onClick={(event) => {
																event.stopPropagation();
																dispatch(documents.reloadDocumentSigner(sig.signer_id, console.log("Повторно отправленно!")));
															}
														}>Подтвердить подписание</span>
													}
												</div>
											</div>
										)
									})}
								</div>
							) : (
								<div className={s.documents__item__rightInfoFalse}>
									{item?.file ? (
										<span>Добавьте подписантов</span>
									) : (
										<>
											<span>Загрузить документ...</span>
											<span>Запросить документ у клиента...</span>
										</>
									)}
								</div>
							)}
						</div>
					)
				})
			) : (
				<span className={s.documentsNone}>Документы не добавлены</span>
			)}
		</div>
	)
}

export default Documents
