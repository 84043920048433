import React, { useEffect, useState } from 'react'
import s from './deals.module.scss'
import { DealsTable } from './deals-content/deals-table'
import { DealsSearch } from './deals-search/deals-search'
import { HeaderDeal } from './deals-header/deals-header'
import { Modal } from '../modal/modal'
import { EgrnNewStatement } from '../egrn-new-statement/egrn-new-statement'
import { UserView } from './users/user-view/user-view'
import { DealCreation } from '../deal-creation/deal-creation'
import { EgrnTable } from './egrn/egrn-table'
import { Users } from './users/users'
import { Organization } from './organization/organization'
import { Support } from '../support/support'
import { deals } from '../../api/deals/deals'
import { useDispatch, useSelector } from 'react-redux'
import ChangePassword from '../../blocks/changePassword/changePassword'
import { setDeals } from '../../redux/deals/deals'
import ReactPaginate from "react-paginate";
import'./deals.css'

export const Deals = ({ userRole }) => {
	const accessToken = localStorage.getItem('accessToken')
	const dispatch = useDispatch()
	const [changePasswordWindow, setChangePasswordWindow] = useState(false)
	const [onCreation, setopenOnCreation] = useState('')
	const [newEgrn, setNewEgrn] = useState(false)
	const [openSupport, setOpenSupport] = useState(false)
	const { dealsData, searchDeals, pageName } = useSelector(
		(state) => state.deals
	)
	const [searchOtherParams, setSearchOtherParams] = useState(
		localStorage.getItem('params')
	)
	const [searchOtherParamsArchive, setSearchOtherParamsArchive] = useState(
		localStorage.getItem('params')
	)
	const [searchOtherParamsUsers, setSearchOtherParamsUsers] = useState(
		localStorage.getItem('paramsUsers')
	)
	const [searchOtherParamsEgrn, setSearchOtherParamsEgrn] = useState(
		localStorage.getItem('paramsEgrn')
	)
	const [filteres, setFilteres] = useState(localStorage.getItem('filterOpen'))

	const [page, setPage] = useState(0)
	const result = dealsData?.result;
	const itemsLength = 10;
	let itemOffset = page + 1;
	const pageCount = Math.ceil(dealsData?.total / itemsLength);

	useEffect(() => {
		if (
			searchOtherParams == null
			&& searchOtherParamsUsers == null
			&& searchOtherParamsEgrn == null
			&& searchOtherParamsArchive == null
		) {
			if (pageName === 'archive') {
				dispatch(setDeals(null))
				dispatch(deals.dealsList(accessToken, itemOffset, itemsLength, true, null))
			}
			if (pageName === 'deals') {
				dispatch(setDeals(null))
				dispatch(deals.dealsList(accessToken, itemOffset, itemsLength, false))
			}			
		}
	}, [pageName, page])

	const handleCloseSupport = () => {
		setOpenSupport(false)
	}

	return (
		<div className={s.deals}>
			<div className={s.deals__header}>
				<HeaderDeal
					userRole={userRole}
					FConCreation={setopenOnCreation}
					setChangePasswordWindow={setChangePasswordWindow}
					setNewEgrn={setNewEgrn}
				/>
			</div>

			<ChangePassword
				changePasswordWindow={changePasswordWindow}
				setChangePasswordWindow={setChangePasswordWindow}
			/>

			<div className={s.deals__content}>
				<div className={s.deals__content__search}>
					<DealsSearch 
						itemOffset={itemOffset} 
						itemsLength={itemsLength} 
						filteres={filteres} 
						setFilteres={setFilteres} 
						searchOtherParams={searchOtherParams}
						setSearchOtherParams={setSearchOtherParams}
						searchOtherParamsUsers={searchOtherParamsUsers}
						setSearchOtherParamsUsers={setSearchOtherParamsUsers}
						searchOtherParamsEgrn={searchOtherParamsEgrn}
						setSearchOtherParamsEgrn={setSearchOtherParamsEgrn}
						searchOtherParamsArchive={searchOtherParamsArchive}
						setSearchOtherParamsArchive={setSearchOtherParamsArchive}
						page={page}
					/>
				</div>
				<div
					className={
						!result?.length
							? s.deals__content__tableNotFound
							: s.deals__content__table
					}
				>
					<>
						{(pageName === 'deals' || pageName === 'archive') && (
							<>
								{result?.length ? (
									result?.map((item) => {
										return (
											<DealsTable
												onCreation={onCreation}
												userRole={userRole}
												pageName={pageName}
												item={item}
												key={item.id}
											/>
										)
									})
								) : (
									<div className={s.nothingFound}>Ничего не нашлось</div>
								)}
							</>
						)}
						{pageName === 'egrn' && <EgrnTable />}
						{pageName === 'users' && <Users />}
						{pageName === 'organization' && <Organization />}
						{result && (pageName === 'deals' || pageName === 'archive') && (
							<ReactPaginate
								containerClassName={"pagination"}
								pageClassName={"page-item"}
								activeClassName={"active"}
								onPageChange={(event) => setPage(event.selected)}
								pageCount={pageCount}
								marginPagesDisplayed={2}
								pageRangeDisplayed={2}
								renderOnZeroPageCount={null}
								breakLabel="..."
								previousLabel={"< Назад"}
								nextLabel={"Вперед >"}
								forcePage={page}
							/>
						)}
					</>
				</div>
				{onCreation === 'createDeal' && (
					<DealCreation active={onCreation} setActive={setopenOnCreation} />
				)}
				{onCreation === 'createUser' && (
					<Modal fullScreen={true} active={onCreation}>
						<UserView
							onCreation={onCreation}
							setOpenModal={setopenOnCreation}
						/>
					</Modal>
				)}
				{onCreation === 'createStatement' && (
					<Modal
						notHeight
						fullScreen
						onClose
						active={newEgrn}
						setActive={setNewEgrn}
					>
						<EgrnNewStatement newEgrn={newEgrn} aktiveModal={newEgrn} />
					</Modal>
				)}
				<div className={s.deals__content__support}>
					<p onClick={() => { setOpenSupport(true);}}>Техподдержка</p>
				</div>
			</div>
			{openSupport && <Support onClose={handleCloseSupport} />}
		</div>
	)
}
