/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import Input from '../../input/input'
import s from './deals-search.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../button/button'
import closeIcon from '../../assets/close.svg'
import arrowDownIcon from '../../../common/images/arrow-down-filter.svg'
import arrowLeftIcon from '../../../common/images/arrow-left-filter.svg'
import DataPicker from 'react-datepicker'
import { deals } from '../../../api/deals/deals'
import debounce from 'lodash.debounce'
import moment from 'moment'
import { admin } from '../../../api/admin'
import { setPageName } from '../../../redux/deals/deals'
import { appeals } from '../../../api/appeals'

export const DealsSearch = ({ 
	filteres, 
	setFilteres, 
	itemOffset, 
	itemsLength, 
	searchOtherParams, 
	setSearchOtherParams, 
	searchOtherParamsUsers, 
	setSearchOtherParamsUsers, 
	searchOtherParamsArchive,
	setSearchOtherParamsArchive,
	searchOtherParamsEgrn,
	setSearchOtherParamsEgrn,
	page}) => {
	const accessToken = localStorage.getItem('accessToken')
	const dispatch = useDispatch()
	const dateRef = React.createRef()
	const { typeAppeals, filteresDate } = useSelector((state) => state.appeals)
	const { pageName, dealsData } = useSelector((state) => state.deals)
	const [dateRange, setDateRange] = useState([null, null])
	const [startDate, endDate] = dateRange
	const [dateValue, setDateValue] = useState({
		dateSubmissionGte: '',
		dateSubmissionLte: ''
	})
	const role = localStorage.getItem("userRole")

	useEffect(() => {
		if (!filteres) return
		dispatch(appeals.typeAppealsList(accessToken))
		dispatch(appeals.getDicts())
	}, [filteres])

	const [selectedValuetypeAppeal, setSelectedValueTypeAppeal] = useState(
		JSON.parse(localStorage.getItem('typeAppeal'))
	)

	const [selectedValueSeller, setSelectedValueSeller] = useState(
		JSON.parse(localStorage.getItem('seller'))
	)

	const [selectedValueStatuses, setSelectesValueStatuses] = useState(
		JSON.parse(localStorage.getItem('statuses'))
	)

	const handleSelectedValyTypeAppeal = (typeAppeal) => {
		localStorage.setItem('typeAppeal', JSON.stringify(typeAppeal))
		setSelectedValueTypeAppeal(typeAppeal)
	}

	const handleSelectedValueSeller = (seller) => {
		localStorage.setItem('seller', JSON.stringify(seller))
		setSelectedValueSeller(seller)
	}

	const handleSelectedValueStatuses = (statuses) => {
		localStorage.setItem('statuses', JSON.stringify(statuses))
		setSelectesValueStatuses(statuses)
	}

	const handleOtherParams = (ev) => {
		if (pageName === 'deals') {
			localStorage.setItem('params', ev.target.value)
			setSearchOtherParams(ev.target.value)
		}

		if (pageName === 'archive') {
			localStorage.setItem('paramsArchive', ev.target.value)
			setSearchOtherParamsArchive(ev.target.value)
		}

		if (pageName === 'users') {
			localStorage.setItem('paramsUsers', ev.target.value)
			setSearchOtherParamsUsers(ev.target.value)
		}

		if (pageName === 'egrn') {
			localStorage.setItem('paramsEgrn', ev.target.value)
			setSearchOtherParamsEgrn(ev.target.value)
		}
	}

	useEffect((
		selectedValuetypeAppeal,
		dateValueGte,
		dateValueLte,
		selectedValueSeller,
		selectedValueStatuses
	) => {
		pageName === 'archive'
		? dispatch(
				deals.dealsList(
					accessToken,
					itemOffset, 
					itemsLength,
					true,
					{
						search: localStorage.getItem('paramsArchive'),
						type_appeal_id: selectedValuetypeAppeal?.id,
						date_submission_gte: dateValueGte,
						date_submission_lte: dateValueLte,
						seller:
							selectedValueSeller?.title === 'Унистрой'
								? 'UNISTROY'
								: selectedValueSeller?.title === 'Третье лицо'
								? 'THIRD_PARTY'
								: undefined,
						statuses: selectedValueStatuses?.title
					}
				)
		  )
		: dispatch(
				deals.dealsList(
					accessToken,
					itemOffset, 
					itemsLength,
					false,
					{
						search: localStorage.getItem('params'),
						type_appeal_id: selectedValuetypeAppeal?.id,
						date_submission_gte: dateValueGte,
						date_submission_lte: dateValueLte,
						seller:
							selectedValueSeller?.title === 'Унистрой'
								? 'UNISTROY'
								: selectedValueSeller?.title === 'Третье лицо'
								? 'THIRD_PARTY'
								: undefined,
						statuses: selectedValueStatuses?.title
					}
				)
		  )
	}, [page, pageName])

	const updateSearchValue = useCallback(
		debounce(
			(
				selectedValuetypeAppeal,
				dateValueGte,
				dateValueLte,
				selectedValueSeller,
				selectedValueStatuses
			) => {
				{
					pageName === 'archive'
						? dispatch(
								deals.dealsList(
									accessToken,
									itemOffset, 
									itemsLength,
									true,
									{
										search: localStorage.getItem('paramsArchive'),
										type_appeal_id: selectedValuetypeAppeal?.id,
										date_submission_gte: dateValueGte,
										date_submission_lte: dateValueLte,
										seller:
											selectedValueSeller?.title === 'Унистрой'
												? 'UNISTROY'
												: selectedValueSeller?.title === 'Третье лицо'
												? 'THIRD_PARTY'
												: undefined,
										statuses: selectedValueStatuses?.title
									}
								)
						  )
						: dispatch(
								deals.dealsList(
									accessToken,
									itemOffset, 
									itemsLength,
									false,
									{
										search: localStorage.getItem('params'),
										type_appeal_id: selectedValuetypeAppeal?.id,
										date_submission_gte: dateValueGte,
										date_submission_lte: dateValueLte,
										seller:
											selectedValueSeller?.title === 'Унистрой'
												? 'UNISTROY'
												: selectedValueSeller?.title === 'Третье лицо'
												? 'THIRD_PARTY'
												: undefined,
										statuses: selectedValueStatuses?.title
									}
								)
						  )
				}
			},
			350
		),
		[pageName]
	)

	const updateSearchValueUsers = useCallback(
		debounce(() => {
			if (role === "AD") {
				dispatch(
					admin.usersSearch(accessToken, itemOffset, itemsLength, {
						search: localStorage.getItem('paramsUsers')
					})
				)
			}
		}, 350),
		[]
	)

	useEffect(() => {
		if (role === "AD") {
			dispatch(
				admin.usersSearch(accessToken, itemOffset, itemsLength, {
					search: localStorage.getItem('paramsUsers')
				})
			)
		}
	}, [page])

	//TODO: ниже поиск по ЕГРН
	const updateSearchValueEgrn = useCallback(
		debounce(() => {
			dispatch(
				admin.createEgrnExtractSearch(accessToken, itemOffset, itemsLength, {
					search: localStorage.getItem('paramsEgrn')
				})
			)
		}, 350),
		[]
	)

	useEffect(() => {
		dispatch(
			admin.createEgrnExtractSearch(accessToken, itemOffset, itemsLength, {
				search: localStorage.getItem('paramsEgrn')
			})
		)
	}, [page])

	useEffect(() => {
		if (
			searchOtherParams?.length ||
			searchOtherParamsArchive?.length ||
			selectedValueStatuses ||
			selectedValueSeller ||
			selectedValuetypeAppeal ||
			dateValue?.dateSubmissionGte ||
			dateValue?.dateSubmissionLte ||
			selectedValueStatuses
		) {
			updateSearchValue(
				selectedValuetypeAppeal,
				dateValue?.dateSubmissionGte,
				dateValue?.dateSubmissionLte,
				selectedValueSeller,
				selectedValueStatuses
			)
		}
	}, [
		selectedValueStatuses,
		selectedValueSeller,
		selectedValuetypeAppeal,
		dateValue,
		accessToken,
		searchOtherParams,
		searchOtherParamsArchive
	])

	useEffect(() => {
		if (searchOtherParamsUsers?.length) {
			updateSearchValueUsers()
		}
	}, [searchOtherParamsUsers])

	useEffect(() => {
		if (searchOtherParamsEgrn?.length || searchOtherParamsEgrn?.length === 0) {
			updateSearchValueEgrn()
		}
	}, [searchOtherParamsEgrn])

	useEffect(() => {
		if (pageName === 'users') {
			if (!searchOtherParamsUsers?.length) {
				dispatch(admin.employees(accessToken))
			}
		}

		if (pageName === 'egrn') {
			if (!searchOtherParamsEgrn?.length) {
				dispatch(admin.listEgrn(accessToken, itemOffset, itemsLength))
			}
		}

		if (pageName === 'archive') {
			if (!filteres && !searchOtherParamsArchive?.length && dealsData) {
				dispatch(deals.dealsList(accessToken, itemOffset, itemsLength, true, null))
			}
		} else {
			if (!filteres && !searchOtherParams?.length && dealsData) {
				dispatch(deals.dealsList(accessToken, itemOffset, itemsLength, false))
			}
		}

		if (filteres) {
			localStorage.setItem('filterOpen', filteres)
		}

		if (!filteres) {
			localStorage.removeItem('filterOpen')
			setSelectedValueSeller()
			setSelectedValueTypeAppeal()
			setSelectesValueStatuses()
			setDateValue()
			localStorage.removeItem('typeAppeal')
			localStorage.removeItem('seller')
			localStorage.removeItem('statuses')
		}
	}, [
		filteres,
		searchOtherParams,
		searchOtherParamsUsers,
		searchOtherParamsArchive
	])

	const handleChangeFilteres = () => {
		setFilteres(!filteres)
	}

	useEffect(() => {
		if (startDate) {
			setDateValue({
				...dateValue,
				dateSubmissionGte: moment(startDate).format('DD.MM.yyyy')
			})
		}
		if (endDate) {
			setDateValue({
				...dateValue,
				dateSubmissionLte: moment(endDate).format('DD.MM.yyyy')
			})
		}
	}, [startDate, endDate])

	const CustomDateInput = forwardRef(({ onClick, value, onChange }, ref) => (
		<div onClick={onClick}>
			<Input
				placeholder='Любой период'
				onClick={onClick}
				value={value}
				onChange={onChange}
				nameRef={ref}
				date={true}
				readOnly
			/>
		</div>

	))

	return (
		<div className={s.container}>
			<div>
				<div className={s.infoPageName}>
					{pageName === 'egrn' && <p>Выписки</p>}
					{pageName === 'deals' && (
						<>
							<p>Актуальные сделки</p>
							<p
								onClick={
									dealsData ? () => dispatch(setPageName('archive')) : null
								}
							>
								архив
							</p>
						</>
					)}
					{pageName === 'users' && <p>Пользователи</p>}
					{pageName === 'archive' && (
						<>
							<p>Архив сделок</p>
							<p
								onClick={
									dealsData ? () => dispatch(setPageName('deals')) : null
								}
							>
								актуальные сделки
							</p>
						</>
					)}
				</div>
				<div className={s.dealsSearch}>
					{pageName !== 'organization' && (
						<Input
							value={
								pageName === 'deals' && searchOtherParams?.length
									? searchOtherParams
									: pageName === 'archive' && searchOtherParamsArchive?.length
									? searchOtherParamsArchive
									: pageName === 'users' && searchOtherParamsUsers?.length
									? searchOtherParamsUsers
									: pageName === 'egrn' && searchOtherParamsEgrn?.length
									? searchOtherParamsEgrn
									: ''
							}
							onChange={handleOtherParams}
							placeholder='Поиск'
						/>
					)}
					{pageName === 'deals' && (
						<Button FCOnclick={handleChangeFilteres}>
							Фильтры
							<div>
								<img src={filteres ? arrowDownIcon : arrowLeftIcon} alt='' />
							</div>
						</Button>
					)}
				</div>
			</div>

			{filteres && (
				<div
					style={
						pageName === 'deals' ? { display: 'block' } : { display: 'none' }
					}
					className={s.dealsFilter}
				>
					<div>
						<p>Фильтры</p>
						<div onClick={handleChangeFilteres}>
							<img src={closeIcon} alt='' />
						</div>
					</div>
					<div className={s.dealsFilter__input}>
						<Input
							value={selectedValueSeller?.title}
							setSelectedValue={handleSelectedValueSeller}
							selectedValue={selectedValueSeller}
							select
							dataList={filteresDate?.seller_data}
							placeholderForSelect={
								pageName === 'egrn' ? 'Выберите статус' : 'Выберите продавца'
							}
							placeholder={
								pageName === 'egrn' ? 'Любой статус' : 'Любой продавец'
							}
							name='seller'
							readOnly
						/>
						<Input
							value={selectedValueStatuses?.title}
							setSelectedValue={handleSelectedValueStatuses}
							selectedValue={selectedValueStatuses}
							select
							dataList={filteresDate?.statuses_deal_data}
							placeholderForSelect={
								pageName === 'egrn' ? 'Выберите кад.номер' : 'Выберите этап'
							}
							placeholder={
								pageName === 'egrn' ? 'Любой кад.номер' : 'Любой этап'
							}
							name='stage'
							readOnly
						/>
						<Input
							value={selectedValuetypeAppeal?.title}
							setSelectedValue={handleSelectedValyTypeAppeal}
							selectedValue={selectedValuetypeAppeal}
							select
							dataList={typeAppeals}
							placeholderForSelect='Выберите тип обращения'
							placeholder='Любой тип обращения'
							name='typeAppeal'
							readOnly
						/>
						<DataPicker
							customInput={<CustomDateInput ref={dateRef} />}
							selectsRange={true}
							startDate={startDate}
							endDate={endDate}
							onChange={(update) => setDateRange(update)}
							isClearable
							dateFormat={'dd.MM.yyyy'}
							maxDate={new Date()}
							showYearDropdown
						/>
					</div>
					<div className={s.dealsFilter__infoSearch}>
						<p>
							Для остальных параметров используйте поиск. Поиск работает по
							полям:
						</p>
						<p>
							Номер КУВД; Адрес объекта; Кадастровый номер; ФИО участника
							сделки.
						</p>
					</div>
				</div>
			)}
		</div>
	)
}
