import React from 'react'
import s from './personalDataSidebar.module.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setDocumentName } from '../../../redux/client/client'
import { setGlobalPersonalData } from '../../../redux/globalPersonalData/globalPersonalData'
import {
	setSnilsPhoto,
	setPassportPhoto1,
	setPassportPhoto2,
} from '../../../redux/client/client'

const PersonalDataSidebar = ({ width }) => {
	const { documentName } = useSelector((state) => state.client)

	const { globalPersonalData } = useSelector(
		(state) => state.globalPersonalData
	)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const backFC = () => {
		dispatch(setGlobalPersonalData(null))
		dispatch(setSnilsPhoto(null))
		dispatch(setPassportPhoto1(null))
		dispatch(setPassportPhoto2(null))
		navigate('/taskListClient')
	}

	return (
		<div className={s.personalDataSidebar}>
			<div onClick={() => backFC()}>
				<span>&#60; Кабинет</span>
			</div>
			<div className={s.personalDataSidebar__wrapper}>
				{width > 900 && <h3>Требуемые данные</h3>}
				<div className={s.personalDataSidebar__wrapper__data}>
					{personalDataArr.map((item) => {
						return (
							<div
								key={item.id}
								className={
									documentName === item.name
										? s.personalDataSidebar__wrapper__data__itemActive
										: s.personalDataSidebar__wrapper__data__item
								}
								onClick={() => dispatch(setDocumentName(item.name))}
							>
								<span>{item.name}</span>
								{item.name === 'Удостоверение личности и СНИЛС' && (
									<span>
										{globalPersonalData?.client_data_status === true
											? 'Готово'
											: 'Ждём данные и фото'}
									</span>
								)}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default PersonalDataSidebar

const personalDataArr = [
	{
		id: 0,
		name: 'Удостоверение личности и СНИЛС',
		status: false
	},
	// {
	// 	id: 1,
	// 	name: 'Договор для ознакомления',
	// 	status: false
	// },
	// {
	// 	id: 2,
	// 	name: 'Доверенность',
	// 	status: false
	// }
]
