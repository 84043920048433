import React, { useEffect } from 'react'
import s from './input.module.scss'
import { useDispatch } from 'react-redux'
import { Select } from '../select/select'
import arrowDownIcon from '../assets/arrowDown.svg'
import { useState } from 'react'
import { Modal } from '../modal/modal'
import searchRealEstateSuccessIcon from '../assets/searchRealEstateSuccess.svg'
import calendarIcon from '../../common/images/calendar.svg'
import PopUpComplete from '../popUpComplete/popUpComplete'
import { setNotificationText } from '../../redux/manager/manager'

const Input = ({
	disabled,
	onChange,
	validate = 'true',
	nameRef,
	buttonIcon,
	buttonClick,
	select,
	placeholderForSelect,
	dataList,
	selectedValue,
	setSelectedValue,
	value,
	findedRealEstate,
	dataCorrect,
	onChangeChecked,
	placeholder,
	date,
	textarea,
	mobileHeaderText,
	onPaste,
	onlyNumber,
	onClick,
	notificationText,
	...props
}) => {
	const dispatch = useDispatch()
	const [activeModal, setActiveModal] = useState(false)
	const userRole = localStorage.getItem('userRole')

	useEffect(() => {
		if (notificationText) {
			setTimeout(() => {
				dispatch(setNotificationText(null))
			}, 3000)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notificationText])

	const onClickSelectInput = () => {
		if(select) {
			setActiveModal(true)
		}
	}

	return (
		<div className={s.inputWrapper}>
			<label className={s.inputWrapper__label}>
				{textarea ? (
					<textarea
						name='text'
						{...props}
						disabled={disabled && userRole !== 'AD'}
						ref={nameRef}
						onPaste={onPaste}
						className={validate ? s.inputWrapper__input : s.error}
						onChange={
							onChangeChecked
								? (e) => dispatch(onChangeChecked(e.target.checked))
								: onChange
						}
						value={value}
						style={
							!dataCorrect
								? { background: 'rgba(31, 163, 74, 0.1)' }
								: { background: '#FFFFFF' }
						}
						placeholder={placeholder}
					/>
				) : (
					<input
						{...props}
						disabled={disabled && userRole !== 'AD'}
						ref={nameRef}
						className={validate ? s.inputWrapper__input : s.error}
						onClick={onClickSelectInput}
						onChange={
							onChangeChecked
								? (e) => dispatch(onChangeChecked(e.target.checked))
								: onChange
						}
						value={value}
						style={{
							background: !dataCorrect ? 'rgba(31, 163, 74, 0.1)' : '#FFFFFF',
							cursor: select || date ? 'pointer' : 'text',
						}}
						placeholder={placeholder}
					/>
				)}
				{findedRealEstate && (
					<img
						className={s.inputWrapper_searchSucces}
						src={searchRealEstateSuccessIcon}
						alt=''
					/>
				)}
				{select && (
					<img
						className={s.inputWrapper_arrowDown}
						src={arrowDownIcon}
						alt=''
					/>
				)}
				{date && (
					<img className={s.inputWrapper_calendar} src={calendarIcon} alt='' />
				)}
			</label>

			{buttonIcon && (
				<button className={s.button} onClick={() => buttonClick()} />
			)}

			<Modal
				fullScreen={true}
				onClose={true}
				active={activeModal}
				setActive={setActiveModal}
				mobileHeaderText={mobileHeaderText}
			>
				<PopUpComplete description={notificationText} />
				<Select
					placeholder={placeholderForSelect}
					selected={selectedValue}
					dataList={dataList}
					setSelected={setSelectedValue}
					setActiveModal={setActiveModal}
					notificationText={notificationText}
				/>
			</Modal>
		</div>
	)
}

export default Input
