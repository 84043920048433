import React from 'react'
import Input from '../../input/input'
import s from './identification.module.scss'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIdentificationCardData } from '../../../redux/globalPersonalData/globalPersonalData'
import {
	setPassportPhoto1,
	setPassportPhoto2,
	setSnilsPhoto
} from '../../../redux/client/client'
import { client } from '../../../api/client/client'
import { setSnilsData } from '../../../redux/globalPersonalData/globalPersonalData'
import { documents } from '../../../api/document/document'
import { setFilePowerOfAttorney } from '../../../redux/documents/documents'

export const Identification = ({ passportPhoto, dataCorrect, sendPersonalDataPassport }) => {
	const { globalPersonalData } = useSelector(
		(state) => state.globalPersonalData
	)
	const { passportPhoto1, passportPhoto2, snilsPhoto } =
		useSelector((state) => state.client)
	const baseURL = process.env.REACT_APP_BASE_URL
	const [attorney, setAttorney] = useState(true)
	const dispatch = useDispatch()
	const regIdentification_card = /\d{4}\s\d{6}/
	const accessToken = localStorage.getItem('accessToken')
	const individual_id = localStorage.getItem('individual_id')
	const representative_id = localStorage.getItem('representative_id')
	const reprsentative_id = localStorage.getItem('representative_id')
	const snilsRegular = /\d{3}\-\d{3}\-\d{3}\-\d{2}/

	const handleChange = (event) => {
		const formData = new FormData()
		event && formData.append('file', event.target.files[0])
		dispatch(
			documents.documentPowerAttorney(
				localStorage.getItem('individual_id'),
				formData,
				accessToken
			)
		)
		dispatch(
			client.editClientData(
				individual_id ? individual_id : reprsentative_id,
				{
					name: globalPersonalData.name,
					surname: globalPersonalData.surname,
					patronymics: globalPersonalData.patronymics,
					gender: globalPersonalData.gender,
					birthday: globalPersonalData.birthday,
					place_birth: globalPersonalData.place_birth,
					reg_address: globalPersonalData.reg_address,
					citizenship: 'РФ',
					inn: globalPersonalData.inn,
					identification_card: {
						title: 'Паспорт',
						series_number: globalPersonalData.identification_card.series_number,
						date_issue: globalPersonalData.identification_card.date_issue,
						issued_by_whom:
							globalPersonalData.identification_card.issued_by_whom,
						subsection_code:
							globalPersonalData.identification_card.subsection_code
					},
					snils: {
						number: globalPersonalData.snils.number
					}
				},
				passportPhoto1 || passportPhoto2 || snilsPhoto ? formData : {},
				passportPhoto1,
				passportPhoto2,
				snilsPhoto,
				accessToken,
				false
			)
		)
		setAttorney(true)
	}


	const deleteFilePowerOfAttorney = () => {
		if (globalPersonalData?.power_attorney_title) {
			dispatch(
				documents.documentDeletePowerAttorneyUser(
					localStorage.getItem('individual_id'),
					accessToken
				)
			)
			setAttorney(false)
			
		} else {
			dispatch(setFilePowerOfAttorney(null))
		}
	}

	useEffect(() => {
		if (
			globalPersonalData?.identification_card?.photo_page_key 
			|| globalPersonalData?.identification_card?.signature_page_key 
			|| globalPersonalData?.snils?.scan_key 
		) {
			dispatch(
				setPassportPhoto1(
					globalPersonalData?.identification_card?.photo_page_key
				)
			)
			dispatch(
				setPassportPhoto2(
					globalPersonalData?.identification_card?.signature_page_key
				)
			)
			dispatch(
				setSnilsPhoto(
					globalPersonalData?.snils?.scan_key
				)
			)
		}
		if (
			globalPersonalData?.identification_card?.photo_page_key === null
		) {
			dispatch(setPassportPhoto1(null))
		}

		if (
			globalPersonalData?.identification_card?.signature_page_key === null
			) {
				dispatch(setPassportPhoto2(null))
		}
		if (
			globalPersonalData?.snils?.scan_key === null
		) {
			dispatch(setSnilsPhoto(null))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalPersonalData])

	const deletePhoto = (preview) => {
		if (
			globalPersonalData?.identification_card?.photo_page_key ||
			globalPersonalData?.identification_card?.signature_page_key
		) {
			dispatch(
				client.deleteImage(
					individual_id ? individual_id : representative_id,
					preview === 'photo_page_key'
						? 'photo_page_key'
						: 'signature_page_key',
					true,
					accessToken,
					false
				)
			)
		}
		if (preview === 'photo_page_key') {
			dispatch(setPassportPhoto1(null))
			document.getElementById("input__file1").value = "";
		}
		if (preview === 'signature_page_key') {
			dispatch(setPassportPhoto2(null))
			document.getElementById("input__file2").value = "";
		}
	}

	const deletePhotoSnils = () => {
		if (globalPersonalData?.snils?.scan_key) {
			dispatch(
				client.deleteImage(
					individual_id ? individual_id : reprsentative_id,
					'scan_key',
					false,
					accessToken,
					true
				)
			)
		}
		dispatch(setSnilsPhoto(null))
		document.getElementById("input__file3").value = "";
	}

	return (
		<>
			<div className={s.identification}>
				<div className={s.identification__header}>
					<h4>Удостоверение личности и СНИЛС</h4>
				</div>
				<div className={s.identification__data}>
					<div>
						<span>Документ</span>
						<Input
							dataCorrect={dataCorrect}
							disabled={true}
							value={
								globalPersonalData?.identification_card?.title ? globalPersonalData?.identification_card?.title : 'Паспорт'
							}
							name='title'
							readOnly
						/>
					</div>
					<div>
						<span>Серия и номер</span>
						<Input
							dataCorrect={dataCorrect}
							disabled={dataCorrect}
							value={
								globalPersonalData?.identification_card?.series_number
									? globalPersonalData?.identification_card?.series_number &&
									!globalPersonalData?.id
										? globalPersonalData?.identification_card?.series_number
												.match(/\d{4}(?=\d{6})|\d+/g)
												?.join(' ')
										: globalPersonalData?.identification_card?.series_number
									: ''
							}
							name='series_number'
							validate={
								globalPersonalData?.identification_card?.series_number &&
								regIdentification_card.test(
									globalPersonalData?.identification_card?.series_number
								)
							}
							maxLength='11'
							onChange={(e) =>
								dispatch(
									setIdentificationCardData({
										name: e.target.name,
										value:
											e.target.value.length > 0 &&
											e.target.value.match(/\d{4}(?=\d{6})|\d+/g)?.join(' ')
									})
								)
							}
						/>
					</div>
					<div>
						<span>Дата выдачи</span>
						<Input
							dataCorrect={dataCorrect}
							disabled={dataCorrect}
							value={
								globalPersonalData?.identification_card?.date_issue
									? globalPersonalData?.identification_card?.date_issue
									: ''
							}
							name='date_issue'
							type='date'
							onChange={(e) => {
								dispatch(
									setIdentificationCardData({
										name: e.target.name,
										value: e.target.value
									})
								)
							}
							}
						/>
					</div>
					<div>
						<span>Кем выдан</span>
						<Input
							dataCorrect={dataCorrect}
							disabled={dataCorrect}
							value={
								globalPersonalData?.identification_card?.issued_by_whom
									? globalPersonalData?.identification_card?.issued_by_whom
									: ''
							}
							name='issued_by_whom'
							textarea
							onChange={(e) =>
								dispatch(
									setIdentificationCardData({
										name: e.target.name,
										value: e.target.value
									})
								)
							}
						/>
					</div>
					<div>
						<span>Код подразд.</span>
						<Input
							dataCorrect={dataCorrect}
							disabled={dataCorrect}
							value={
								globalPersonalData?.identification_card?.subsection_code
									? globalPersonalData?.identification_card?.subsection_code
									: ''
							}
							name='subsection_code'
							maxLength='7'
							validate={
								globalPersonalData?.identification_card?.subsection_code &&
								globalPersonalData?.identification_card?.subsection_code
									.length === 7
							}
							onChange={(e) =>
								dispatch(
									setIdentificationCardData({
										name: e.target.name,
										value:
											e.target.value.length > 0 &&
											e.target.value.match(/\d{3}|\d+/g)?.join('-')
									})
								)
							}
						/>
					</div>
					<div className={s.personalDataSnils__item}>
						<span style={{ width: '179px' }}>СНИЛС</span>
						<Input
							maxLength='14'
							validate={
								globalPersonalData?.snils?.number &&
								snilsRegular.test(globalPersonalData?.snils?.number)
							}
							value={globalPersonalData?.snils?.number ? globalPersonalData?.snils?.number : ''}
							name='number'
							onChange={(e) =>
								dispatch(
									setSnilsData({
										name: e.target.name,
										value:
											e.target.value.length > 0 &&
											e.target.value
												.match(/\d{3}(?=\d{2})|\d+/g)
												.join('-')
									})
								)
							}
					
						/>
					</div>
				</div>
				{passportPhoto && (
					<div className={s.identification__data__wrapperPhoto}>
						<span>Фотографии</span>
						<div>
							<div className={s.identification__data__wrapperPhoto__pdfFormatWrapper}>
								<div>
									<h4>Паспорт - страница с фото:</h4>
									{passportPhoto1 ? (
										<div className={s.identification__data__wrapperPhoto__pdfFormatWrapper__block}>
											<div>
												<span>Загружено</span>
												<span>
													{passportPhoto1?.name
														? ` ${passportPhoto1?.name?.substring(0, 20)}${
																passportPhoto1?.name?.length > 20 ? '...' : ''
														}`
														: null}
												</span>
												{globalPersonalData?.identification_card?.photo_page_key ? (
													<a
														href={`${baseURL}/file-cloud/${globalPersonalData?.identification_card?.photo_page_key}`}
														target='_blank'
														rel="noreferrer"
														download
													>
														Скачать
													</a>
												) : null}
											</div>
											<button onClick={() => deletePhoto('photo_page_key')} />
											</div>
									) : (
										<>
											<input
												type='file'
												accept='image/png, image/jpeg, application/pdf'
												id='input__file1'
												onChange={(e) =>
													dispatch(setPassportPhoto1(e.target.files[0]))
												}
											/>
											<label htmlFor='input__file1'>
												Загрузить документ...
											</label>
										</>
									)}
								</div>

								<div>
									<h4>Паспорт - страница с пропиской:</h4>
									{passportPhoto2 ? (
										<div
											className={
												s.identification__data__wrapperPhoto__pdfFormatWrapper__block
											}
										>
											<div>
												<span>Загружено</span>
												<span>
													{passportPhoto2?.name
														? ` ${passportPhoto2?.name?.substring(0, 20)}${
																passportPhoto2?.name?.length > 20 ? '...' : ''
														}`
														: null}
												</span>
												{globalPersonalData?.identification_card?.signature_page_key ? (
													<a
														href={`${baseURL}/file-cloud/${globalPersonalData?.identification_card?.signature_page_key}`}
														target='_blank'
														rel="noreferrer"
														download
													>
														Скачать
													</a>
												) : null}
											</div>
											<button
												onClick={() => deletePhoto('signature_page_key')}
											/>
										</div>
									) : (
										<>
											<input
												type='file'
												accept='image/png, image/jpeg, application/pdf'
												id='input__file2'
												onChange={(e) =>
													dispatch(setPassportPhoto2(e.target.files[0]))
												}
											/>
											<label htmlFor='input__file2'>
												Загрузить документ...
											</label>
										</>
									)}
								</div>

								<div>
									<h4>СНИЛС:</h4>
									{ globalPersonalData?.snils?.scan_key == null ?
										<div className={s.identification__data__wrapperPhoto}>
										</div> : <></>
									}
										<div
											className={
												s.identification__data__wrapperPhoto__pdfFormatWrapper
											}
										>
											{snilsPhoto ? (
												<div
													className={
														s.identification__data__wrapperPhoto__pdfFormatWrapper__block
													}
												>
													<div>
														<span>Загружено</span>
														<span>
															{snilsPhoto?.name
																? ` ${snilsPhoto?.name?.substring(0, 20)}${
																		snilsPhoto?.name?.length > 20 ? '...' : ''
																}`
																: null}
														</span>
														{globalPersonalData?.snils?.scan_key ? (
															<a
																href={`${baseURL}/file-cloud/${globalPersonalData?.snils?.scan_key}`}
																target='_blank'
																download rel="noreferrer"
															>
																Скачать
															</a>
														) : null}
													</div>
													<button onClick={() => deletePhotoSnils()} />
												</div>
											) : (
												<>
													<input
														type='file'
														accept='image/png, image/jpeg, application/pdf'
														id='input__file3'
														onChange={(e) => {
															dispatch(setSnilsPhoto(e.target.files[0]));
														}}
													/>
													<label htmlFor='input__file3'>Загрузить документ...</label>
												</>
											)}
										</div>
								</div>
								{ globalPersonalData?.representative_exists &&
									<div>
									<h4>Доверенность:</h4>
									{ globalPersonalData?.power_attorney_path !== null  ?
										<div className={s.identification__data__wrapperPhoto}>
										</div> : <></>
									}
										<div
											className={
												s.identification__data__wrapperPhoto__pdfFormatWrapper
											}
										>
											{globalPersonalData?.power_attorney_path !== null && attorney !== false ? (
												<div
													className={
														s.identification__data__wrapperPhoto__pdfFormatWrapper__block
													}
												>
													<div>
														<span>Загружено</span>
														{globalPersonalData?.power_attorney_path !== null ? (
															<a
																href={`${baseURL}/file-cloud/${globalPersonalData?.power_attorney_path}`}
																target='_blank'
																download rel="noreferrer"
															>
																Скачать
															</a>
														) : null}
													</div>
													<button onClick={deleteFilePowerOfAttorney} />
												</div>
											) : (
												<>
													<input
														type='file'
														name='fileAttorney'
														id='input__fileAttorney'
														onChange={handleChange}
														disabled={!localStorage.getItem('individual_id')}
													/>
													<label htmlFor='input__fileAttorney'>Загрузить документ...</label>
												</>
											)}
										</div>
								</div>
								}
							</div>
						</div>
					</div>
			)}
		</div>
	</>
	)
}

