import React from 'react'
import s from './index.module.scss'

const EcpComment = ({
	photoPassport1,
	setPhotoPassport1,
	photoPassport2,
	setPhotoPassport2,
	ecpData,
											powerOfAttorney,
											setPowerOfAttorney
}) => {
	return (
		<div className={s.ecpCommentWrapper}>
			<div className={s.ecpCommentWrapper__wrapperFile}>
				<div className={s.ecpCommentWrapper__wrapperFile__item}>
					<span>Паспорт - страница с фото</span>
					<div>
						<input
							type='file'
							onChange={(e) => setPhotoPassport1(e.target.files[0])}
						/>
						<button>Выберите файл</button>
						<p>
							{photoPassport1?.name
								? `${photoPassport1?.name?.substring(0, 30)}${
										photoPassport1?.name?.length > 30 ? '...' : ''
								  }`
								: 'Файл не выбран'}
						</p>
					</div>
				</div>

				<div className={s.ecpCommentWrapper__wrapperFile__item}>
					<span>Паспорт - страница с пропиской</span>
					<div>
						<input
							type='file'
							onChange={(e) => setPhotoPassport2(e.target.files[0])}
						/>
						<button>Выберите файл</button>
						<p>
							{photoPassport2?.name
								? `${photoPassport2?.name?.substring(0, 30)}${
										photoPassport2?.name?.length > 30 ? '...' : ''
								  }`
								: 'Файл не выбран'}
						</p>
					</div>
				</div>

			</div>
			{ecpData.comment.map((item) => {
				return (
					<div className={s.ecpCommentWrapper__text}>
						<div className={s.ecpCommentWrapper__text_span}>
							Текст ошибки: 
						</div>
						<span>{item.Comment}</span>
					</div>
				)
			})}
		</div>
	)
}

export default EcpComment
