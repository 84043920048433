import React, { useEffect, useState } from 'react'
import Checkbox from '../checkbox/checkbox'
import { TaskListSideBar } from './task-list-sidebar/task-list-sidebar'
import s from './task-list.module.scss'
import ChatWindow from '../../blocks/chatWindow/chatWindow'
import { useDispatch, useSelector } from 'react-redux'
import PaymentDuty from '../paymentDuty/paymentDuty'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import arrowLeftIcon from '../../common/images/chevron-left.svg'
import { RealEstate } from '../real-estate/real-estate'
import {
	setDocumentName,
	setTaskListClientData,
} from '../../redux/client/client'
import { client } from '../../api/client/client'
// import EcpModalWindow from '../ecpModalWindow/ecpModalWindow'
import { Support } from '../support/support'
import { SupportIcon } from '../support-icon/support-icon'
import { user } from '../../api/user'
import RegistrationRRModal from "../registration-RR-modal/registration-RR-modal";

export const TaskListContent = () => {
	const accessToken = localStorage.getItem('accessToken')
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { taskList, taskListClientData, clientData, clientDataRelate, contractData } = useSelector((state) => state.client)
	const {
		newAppealsPaymentsWS,
		newAppealsDocumentWS,
		documentIdWS,
		paymentIdWs
	} = useSelector((state) => state.deals)
	const [activeChat, setActiveChat] = useState(null)
	const [openChatWindow, setOpenChatWindow] = useState(false)
	const [openSupport, setOpenSupport] = useState(false)
	const [openBlock, setOpenBlock] = useState()
	const [selectedDeal, setSelectedDeal] = useState()
	const [openWindowPaymentDuty, setOpenWindowPaymentDuty] = useState(false)
	const [openWindowObjectRealEstate, setOpenWindowObjectRealEstate] =
		useState(false)
	const [mapWindow, setMapWindow] = useState(false)
	const [registrationRR, setRegistrationRR] = useState(false)
	const [width, setWidth] = useState(document?.documentElement?.clientWidth)
	const { globalPersonalData } = useSelector(
		(state) => state.globalPersonalData
	)
	const [selectedAppeal, setSelectedAppeal] = useState()

	const onResize = (e) => {
		setWidth(e.target.innerWidth)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		window.addEventListener('resize', onResize)

		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [])

	useEffect(() => {
		dispatch(client.getTaskList(accessToken))
	}, [])

	useEffect(() => {
		dispatch(client.getClientData())
	}, [])

	useEffect(() => {
		if (clientData?.id) {
			dispatch(client.getClientIdentityCredentials(clientData?.id, accessToken))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientData?.id])

	useEffect(() => {
		dispatch(client.getClientDataRelate())
	}, [])

	useEffect(() => {
		if (!newAppealsDocumentWS) return
		if (newAppealsDocumentWS?.appeal_id === taskListClientData.id) {
			const document = taskListClientData.documents.filter((item) => {
				return item.id !== newAppealsDocumentWS?.document.id
			})
			dispatch(
				setTaskListClientData({
					...taskListClientData,
					documents: [...document, newAppealsDocumentWS?.document]
				})
			)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newAppealsDocumentWS])

	useEffect(() => {
		if (!documentIdWS) return

		const documentFilter = taskListClientData.documents.filter((item) => {
			return item.id !== documentIdWS
		})

		dispatch(
			setTaskListClientData({
				...taskListClientData,
				documents: documentFilter
			})
		)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documentIdWS, paymentIdWs])

	useEffect(() => {
		if (!paymentIdWs) return

		const paymentFilter = taskListClientData.payments.filter((item) => {
			return item.id !== paymentIdWs
		})

		dispatch(
			setTaskListClientData({
				...taskListClientData,
				payments: paymentFilter
			})
		)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentIdWs])

	useEffect(() => {
		if (!newAppealsPaymentsWS) return

		if (newAppealsPaymentsWS?.appeal_id === taskListClientData?.id) {
			const payments = taskListClientData.payments.filter((item) => {
				return item.id !== newAppealsPaymentsWS?.payment.id
			})
			dispatch(
				setTaskListClientData({
					...taskListClientData,
					payments: [...payments, newAppealsPaymentsWS?.payment]
				})
			)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newAppealsPaymentsWS])

	useEffect(() => {
		dispatch(client.getContractData())
	}, [])

	const handleCloseSupport = () => {
		setOpenSupport(false)
	}

	const openPaymentDuty = (id) => {
		setOpenWindowPaymentDuty(true)
		localStorage.setItem('idPayment', id)
	}

	useEffect(() => {
		dispatch(client.getTaskList(accessToken))

		dispatch(user.getUserName(accessToken))
		if (taskListClientData) {
			localStorage.setItem(
				'identificationCardDataStatus',
				taskListClientData?.personal_docs?.representative?.identification_card
					?.data_status
			)
			localStorage.setItem(
				'snilsDataStatus',
				taskListClientData?.personal_docs?.representative?.snils?.data_status
			)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (!taskList) return

		setOpenChatWindow(false)
		setActiveChat(null)
		selectedDeal == null && localStorage.setItem('selectedDeal', JSON.stringify(taskList[0]))
		selectedDeal == null && setSelectedDeal(taskList[0])

		if (selectedDeal) {
			const foundAppealActiveStatusTrue = selectedDeal?.appeals?.find(
				(appeal) => appeal?.active_appeal === true
			)

			localStorage.setItem(
				'appealId',
				foundAppealActiveStatusTrue?.id
					? foundAppealActiveStatusTrue?.id
					: selectedDeal?.appeals[selectedDeal?.appeals?.length - 1]?.id
			)

			setSelectedAppeal(
				foundAppealActiveStatusTrue
					? foundAppealActiveStatusTrue
					: selectedDeal?.appeals[selectedDeal?.appeals?.length - 1]
			)

			dispatch(
				client.getTaskListClientData(
					foundAppealActiveStatusTrue?.id
						? foundAppealActiveStatusTrue?.id
						: selectedDeal?.appeals[selectedDeal?.appeals?.length - 1]?.id,
					accessToken
				)
			)
		}
	}, [taskList, selectedDeal])


	const handleAddClassActive = (nameBlock) => {
		if (openBlock === nameBlock) {
			setOpenBlock('')
		} else {
			setOpenBlock(nameBlock)
		}
	}

	const goToPage = (e, representative) => {
		localStorage.removeItem('representative_id')
		localStorage.removeItem('individual_id')

		if (taskListClientData?.personal_docs?.is_representative) {
			localStorage.setItem(
				'representative_id',
				taskListClientData?.personal_docs?.representative?.representative_id
			)
		}

		if (
			clientData?.id &&
			!representative
		) {
			localStorage.setItem(
				'individual_id',
				clientData?.id
			)
		}

		navigate(`/personalDataClient`)
		dispatch(setDocumentName(e))
	}

	const openWindowElectronicSignature = () => {
		setMapWindow(true)
	}

	const whosePersonalDocs =
		taskListClientData?.personal_docs?.hasOwnProperty('individual') &&
		taskListClientData?.personal_docs?.is_representative
			? true
			: !taskListClientData?.personal_docs?.hasOwnProperty('individual') &&
			  taskListClientData?.personal_docs?.is_representative
			? false
			: taskListClientData?.personal_docs?.hasOwnProperty('individual') &&
			  !taskListClientData?.personal_docs?.is_representative &&
			  null

	const personalDocsStatus = (personalDocs) => {
		let amountWaitingAction = 0

		if (whosePersonalDocs === null || whosePersonalDocs === false) {
			if (
				!personalDocs?.[
					whosePersonalDocs === null ? 'individual' : 'representative'
				]?.snils?.data_status
			) {
				++amountWaitingAction
			}
			if (
				!personalDocs?.[
					whosePersonalDocs === null ? 'individual' : 'representative'
				]?.identification_card?.data_status
			) {
				++amountWaitingAction
			}
		}

		if (whosePersonalDocs === true) {
			if (!personalDocs?.individual?.snils?.data_status) {
				++amountWaitingAction
			}
			if (!personalDocs?.individual?.snils?.data_status) {
				++amountWaitingAction
			}

			if (!personalDocs?.representative?.snils?.data_status) {
				++amountWaitingAction
			}
			if (!personalDocs?.representative?.snils?.data_status) {
				++amountWaitingAction
			}
		}
		// return amountWaitingAction
		// 	? {
		// 			text: `${amountWaitingAction}/${
		// 				whosePersonalDocs === null || whosePersonalDocs === false
		// 					? '2'
		// 					: '4'
		// 			} ждут вашего действия`,
		// 			status: false
		// 	  }
		// 	: {
		// 			text: `${
		// 				whosePersonalDocs === null || whosePersonalDocs === false
		// 					? '2'
		// 					: '4'
		// 			}/${
		// 				whosePersonalDocs === null || whosePersonalDocs === false
		// 					? '2'
		// 					: '4'
		// 			} подтверждены`,
		// 			status: true
		// 	  }
	}

	const documentStatus = (document) => {
		let amountWaitingAction = 0
		let documentsReview = 0

		if (Array.isArray(document)) {
			document?.forEach((document) => {
				if (
					(!document?.file_status && document?.can_upload) ||
					(document?.status_confirmed && !document?.sign_status)
				) {
					++amountWaitingAction
				} else if (
					document?.file_status &&
					document?.can_upload &&
					!document?.status_confirmed
				) {
					++documentsReview
				}
			})

			return amountWaitingAction
			? {
						text: `${amountWaitingAction}/${taskListClientData?.documents?.length} ожидают вашего действия`,
						status: 1
				  }
				: documentsReview
				? {
						text: `${documentsReview}/${taskListClientData?.documents?.length} на проверке`,
						status: 2
				  }
				: {
						text: `${taskListClientData?.documents?.length}/${taskListClientData?.documents?.length} подписаны`,
						status: 4
				  }
		} else if (typeof document === 'object' && document !== null) {
			return !document?.file_status && document?.can_upload
				? { text: 'Загрузите сюда фото документа', status: 1 }
				: document?.file_status &&
				  document?.can_upload &&
				  !document?.status_confirmed
				? { text: 'Документ отправлен на проверку', status: 2 }
				: document?.status_confirmed && !document?.sign_status
				? { text: 'Ждём вашу подпись', status: 1 }
				: { text: document?.sign_status && 'Подписан', status: 4 }
		}
	}

	if (!accessToken) {
		return <Navigate to='/login' replace />
	}

	return (
		<>
			<div className={s.container}>
				<TaskListSideBar
					setOpenBlock={handleAddClassActive}
					openBlock={openBlock}
					activeChat={activeChat}
					onChat={setActiveChat}
					taskListClientData={taskListClientData}
					taskList={taskList}
					selectedDeal={selectedDeal}
					setSelectedDeal={setSelectedDeal}
					setOpenChatWindow={setOpenChatWindow}
					selectedAppeal={selectedAppeal}
					setSelectedAppeal={setSelectedAppeal}
				/>
				{openChatWindow ? (
					<ChatWindow
						activeChat={activeChat}
						setActiveChat={setActiveChat}
						setOpenChatWindow={setOpenChatWindow}
					/>
				) : (
					<div className={s.taskListContent}>
						<div
							className={s.taskListContent__personalDocuments}
							style={
								whosePersonalDocs === true
									? { marginBottom: '30px' }
									: { marginBottom: '42px' }
							}
						>
							<p>Личные Документы</p>
							<div
								onClick={() => handleAddClassActive('personalDoc')}
								className={s.taskListContent__personalDocuments__infoField}
							>
								<div>
									<p>Личные документы</p>
									<p
										style={
											personalDocsStatus(taskListClientData?.personal_docs)
												?.status
												? { color: '#1FA34A' }
												: { color: '#ED6A5A' }
										}
									>
										{
											personalDocsStatus(taskListClientData?.personal_docs)
												?.text
										}
									</p>
								</div>
								<img
									src={arrowLeftIcon}
									style={
										openBlock !== 'personalDoc'
											? null
											: { transform: 'rotate(-90deg)' }
									}
									alt=''
								/>
							</div>
							<div
								className={`${
									s.taskListContent__personalDocuments__infoForDocuments
								} ${openBlock === 'personalDoc' && s.active}`}
							>
								<div onClick={() => goToPage('Удостоверение личности и СНИЛС')}>
									<p>
									Удостоверение личности и СНИЛС{' '}
										{whosePersonalDocs === true
											? 'покупателя'
											: whosePersonalDocs === false
											? 'представителя'
											: ''}
									</p>
									<p
										style={
											taskListClientData?.personal_docs?.[
												whosePersonalDocs === null || whosePersonalDocs === true
													? 'individual'
													: 'representative'
											]?.identification_card?.data_status
												? { color: '#1FA34A' }
												: {}
										}
									>
										{' '}
										{
											globalPersonalData?.client_data_status === true ? 'Данные подтверждены'
											: 'Ждём данные и фото'
										}
									</p>
								</div>
							</div>

							<div
								className={`${s.taskListContent__personalDocuments__checkbox} ${
									openBlock === 'personalDoc' && s.active
								}`}
							>
								<div>
									<Checkbox
										checked={
											whosePersonalDocs === true || whosePersonalDocs === false
										}
										label={
											'За покупателя сделку оформит представитель по доверенности'
										}
									/>
								</div>
							</div>
						</div>
						{clientDataRelate?.length !== 0 &&
						<div
							className={s.taskListContent__personalDocuments}
							style={
								whosePersonalDocs === true
									? { marginBottom: '30px' }
									: { marginBottom: '42px' }
							}
						>
							<p>Данные {clientDataRelate?.length === 1 ? 'участника' : 'участников'} представителем {clientDataRelate?.length === 1 ? 'которого' : 'которых'} Вы являетесь</p>
							<div
								onClick={() => handleAddClassActive('clientRelate')}
								className={s.taskListContent__personalDocuments__infoField}
							>
								<div>
									<p>Данные {clientDataRelate?.length === 1 ? 'участника' : 'участников'} представителем {clientDataRelate?.length === 1 ? 'которого' : 'которых'} Вы являетесь</p>
									<p
										style={
											personalDocsStatus(taskListClientData?.personal_docs)
												?.status
												? { color: '#1FA34A' }
												: { color: '#ED6A5A' }
										}
									>
										{
											personalDocsStatus(taskListClientData?.personal_docs)
												?.text
										}
									</p>
								</div>
								<img
									src={arrowLeftIcon}
									style={
										openBlock !== 'clientRelate'
											? null
											: { transform: 'rotate(-90deg)' }
									}
									alt=''
								/>
							</div>

							<div
								className={`${
									s.taskListContent__personalDocuments__infoForDocuments
								} 
								${openBlock === 'clientRelate' && s.active}`}
							>
								{clientDataRelate?.map(item => 
									<div 
										onClick={() => {
												localStorage.removeItem('representative_id')
												localStorage.removeItem('individual_id')
												localStorage.setItem('individual_id', item.id)
												navigate(`/personalDataClient`)
												dispatch(setDocumentName('Удостоверение личности и СНИЛС'))
											}
										}
									>
										<p>
											{item.full_name}
										</p>
										<p
											style={
												taskListClientData?.personal_docs?.[
													whosePersonalDocs === null || whosePersonalDocs === true
														? 'individual'
														: 'representative'
												]?.identification_card?.data_status
													? { color: '#1FA34A' }
													: {}
											}
										>
											{
												item?.client_data_status === true ? 'Данные подтверждены'
												: 'Ждём данные и фото'
											}
										</p>
									</div>
								)}
							</div>
						</div>
					}

					{contractData ? 
						<div
							className={s.taskListContent__personalDocuments}
							style={
								whosePersonalDocs === true
									? { marginBottom: '30px' }
									: { marginBottom: '42px' }
							}
						>
							<p>Ознакомление с договором</p>
							<div
								onClick={() => handleAddClassActive('readContract')}
								className={s.taskListContent__personalDocuments__infoField}
							>
								<div>
									<p>Ознакомление с договором</p>
									<p
										style={
											personalDocsStatus(taskListClientData?.personal_docs)
												?.status
												? { color: '#1FA34A' }
												: { color: '#ED6A5A' }
										}
									>
										{
											personalDocsStatus(taskListClientData?.personal_docs)
												?.text
										}
									</p>
								</div>
								<img
									src={arrowLeftIcon}
									style={
										openBlock !== 'readContract'
											? null
											: { transform: 'rotate(-90deg)' }
									}
									alt=''
								/>
							</div>

							<div
								className={`${
									s.taskListContent__personalDocuments__infoForDocuments
								} 
								${openBlock === 'readContract' && s.active}`}
							>
								<div 
									onClick={() => {
										setDocumentName('Договор для ознакомления');
										goToPage('Договор для ознакомления'); 
									}}
								>
									<p>
										Договор для ознакомления
									</p>
									<p
										style={
											taskListClientData?.personal_docs?.[
												whosePersonalDocs === null || whosePersonalDocs === true
													? 'individual'
													: 'representative'
											]?.identification_card?.data_status
												? { color: '#1FA34A' }
												: {}
										}
									>
									</p>
								</div>
							</div>
						</div>
					: <></>} 

						{taskListClientData?.request_rosreestr && (
							<div className={s.taskListContent__personalDocuments}>
								<p>Регистрация в Росреестре</p>
								<div
									onClick={() => handleAddClassActive('registrationRR')}
									className={s.taskListContent__personalDocuments__infoField}
								>
									<div>
										<p>Регистрация в Росреестре</p>
										{/*<p>
											Данные обращения
										</p>*/}
									</div>
									<img
										src={arrowLeftIcon}
										style={
											openBlock !== 'registrationRR'
												? null
												: { transform: 'rotate(-90deg)' }
										}
										alt=''
									/>
								</div>
								<div
									className={`${
										s.taskListContent__personalDocuments__infoForDocuments
									} ${openBlock === 'registrationRR' && s.active}`}
									onClick={() => setRegistrationRR(true)}
								>
									<div>
										<p
										>
											Данные обращения
										</p>
										<p></p>
									</div>
								</div>
							</div>
						)}




						{taskListClientData?.appeal_statuses?.personal_doc_status && taskListClientData?.active_appeal === true && (
							<div className={s.taskListContent__personalDocuments}>
								<p>Электронная подпись</p>
								<div
									onClick={() => handleAddClassActive('electronicSignature')}
									className={s.taskListContent__personalDocuments__infoField}
								>
									<div>
										<p>Электронная подпись</p>
										<p
											style={
												taskListClientData?.ecp_data
													? { color: '#1FA34A' }
													: { color: '#ED6A5A' }
											}
										>
											{!taskListClientData?.ecp_data
												? 'Ждём Вас в офисе'
												: 'Оформлена и выдана вам'}
										</p>
									</div>
									<img
										src={arrowLeftIcon}
										style={
											openBlock !== 'electronicSignature'
												? null
												: { transform: 'rotate(-90deg)' }
										}
										alt=''
									/>
								</div>
								<div
									className={`${
										s.taskListContent__personalDocuments__infoForDocuments
									} ${openBlock === 'electronicSignature' && s.active}`}
									onClick={() => openWindowElectronicSignature()}
								>
									<div>
										<p
											style={
												!taskListClientData?.ecp_data
													? { color: '#ED6A5A' }
													: {}
											}
										>
											{!taskListClientData?.ecp_data
												? 'Ждём Вас в офисе, чтобы выпустить подпись'
												: 'Оформлена и выдана вам'}
										</p>
										<p></p>
									</div>
								</div>
							</div>
						)}

						{taskListClientData?.payments.length > 0 && (
							<div className={s.taskListContent__personalDocuments}>
								<p>Пошлины</p>
								<div
									onClick={() => handleAddClassActive('duties')}
									className={s.taskListContent__personalDocuments__infoField}
								>
									<p>Пошлины </p>
									{taskListClientData?.payments.map((item) => {
										return (
											<div>
												<p
													style={
														item.payments_status === '5' ||
														item.payments_status === '6' ||
														item.payments_status === '7'
															? { color: '#1FA34A' }
															: { color: '#ED6A5A' }
													}
												></p>
											</div>
										)
									})}

									<img
										src={arrowLeftIcon}
										style={
											openBlock !== 'duties'
												? null
												: { transform: 'rotate(-90deg)' }
										}
										alt=''
									/>
								</div>
								{taskListClientData?.payments.map((item) => {
									return (
										<div
											key={taskListClientData?.payments?.id}
											onClick={() => openPaymentDuty(item.id)}
											className={`${
												s.taskListContent__personalDocuments__infoForDocuments
											} ${openBlock === 'duties' && s.active}`}
										>
											<div>
												<p>{item.amount} ₽</p>
												<p>{item.payment_type}</p>
												<p
													style={
														item.payments_status === '5' ||
														item.payments_status === '6' ||
														item.payments_status === '7'
															? { color: '#1FA34A' }
															: { color: '#ED6A5A' }
													}
												>
													{item.payments_status_label}
												</p>
											</div>
										</div>
									)
								})}
							</div>
						)}
						{taskListClientData?.documents?.length > 0 && (
							<div className={s.taskListContent__personalDocuments}>
								<p>Документы по сделке</p>
								<div
									onClick={() => handleAddClassActive('dealDoc')}
									className={s.taskListContent__personalDocuments__infoField}
								>
									<div>
										<p>Документы по сделке</p>
										<p
											style={
												documentStatus(taskListClientData?.documents)
													?.status === 1
													? { color: '#ED6A5A' }
													: documentStatus(taskListClientData?.documents)
															?.status === 4
													? { color: '#1FA34A' }
													: {}
											}
										>
											{documentStatus(taskListClientData?.documents)?.text}
										</p>
									</div>
									<img
										src={arrowLeftIcon}
										style={
											openBlock !== 'dealDoc'
												? null
												: { transform: 'rotate(-90deg)' }
										}
										alt=''
									/>
								</div>
								<div
									className={`${
										s.taskListContent__personalDocuments__infoForDocuments
									} ${openBlock === 'dealDoc' && s.active}`}
								>
									{taskListClientData?.documents?.map((document) => {
										return (
											<Link
												key={document?.id}
												to={`/signingDocuments/${document?.id}`}
											>
												<div>
													<p>{document?.document_type}</p>
													<p
														style={
															documentStatus(document)?.status === 1
																? { color: '#ED6A5A' }
																: documentStatus(document)?.status === 4
																? { color: '#1FA34A' }
																: {}
														}
													>
														{documentStatus(document)?.text}
													</p>
												</div>
											</Link>
										)
									})}
								</div>
							</div>
						)}
						{/* {taskListClientData?.documents?.length > 0 && ( */}
						{taskList?.length > 0 && (
						<div className={s.taskListContent__property}>
							<p>Объект</p>
							<div
								onClick={() => handleAddClassActive('property')}
								className={s.taskListContent__personalDocuments__infoField}
							>
								<div>
									<p>Объект</p>
									<p>Адрес и номер</p>
								</div>
								<img
									src={arrowLeftIcon}
									style={
										openBlock !== 'property'
											? null
											: { transform: 'rotate(-90deg)' }
									}
									alt=''
								/>
							</div>
							<div
								onClick={() => setOpenWindowObjectRealEstate(true)}
								className={`${s.taskListContent__property__infoForDocuments} ${
									openBlock === 'property' && s.active
								}`}
							>
								<div>
									<p>{taskListClientData?.object?.cadastral_number}</p>
								</div>
								<div>
									<p>{taskListClientData?.object?.address}</p>
								</div>
							</div>
						</div>
						)}
					</div>
				)}

				{!openSupport && (
					<SupportIcon right='10px' setOpenSupport={setOpenSupport} />
				)}
			</div>
			{openSupport && <Support onClose={handleCloseSupport} />}

			{openWindowPaymentDuty && (
				<PaymentDuty
					width={width}
					openWindowPaymentDuty={true}
					setOpenWindowPaymentDuty={setOpenWindowPaymentDuty}
				/>
			)}

			{openWindowObjectRealEstate && (
				<RealEstate
					object={taskListClientData?.object}
					openWindowObjectRealEstate={openWindowObjectRealEstate}
					setOpenWindowObjectRealEstate={setOpenWindowObjectRealEstate}
				/>
			)}
			{/* {mapWindow && (
				<EcpModalWindow mapWindow={mapWindow} setMapWindow={setMapWindow} />
			)} */}
			{registrationRR && (
				<RegistrationRRModal registrationRR={registrationRR} setRegistrationRR={setRegistrationRR} />
			)}
		</>
	)
}
