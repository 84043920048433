import axios from 'axios'
import {
	setEcpData,
	setEcpStatusText,
	setEcpTariffs,
	setLoadStatus,
	setOrganizations,
	setPersonalDataEcp
} from '../../redux/ecp'
import { setLoadingApp, setNotificationText } from '../../redux/manager/manager'
import { alertStatusText } from '../../components/alertStatusText/alertStatusText'

const baseURL = process.env.REACT_APP_BASE_URL

export const ecp = {
	sendEcp(
		accessToken,
		participant_id,
		alias,
		tariff_id,
		ExpireDay,
		reissue,
		organization_id,
		tariff_cost,
		tariff_name
	) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/ecp/release/${participant_id}`,
					{
						alias,
						tariff_id,
						expire_day: ExpireDay,
						reissue,
						organization_id,
						tariff_cost,
						tariff_name
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(setEcpStatusText('Запрос на выпуск отправлен'))
				setTimeout(() => {
					dispatch(setEcpStatusText(null))
				}, 3000)
				dispatch(setEcpData(response.data))
				dispatch(setLoadStatus(false))
			} catch (error) {
				dispatch(setLoadStatus(false))
				error.response?.data?.detail ? 
					dispatch(setEcpStatusText(`Ошибка! ${error.response?.data?.detail?.message || error.response?.data?.detail}`)) 
					: dispatch(setEcpStatusText(`Ошибка!`))
			}
		}
	},
	
	sendUserEcp(
		accessToken,
		individual_id,
		alias,
		tariff_id,
		ExpireDay,
		reissue,
		organization_id,
		tariff_cost,
		tariff_name
	) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/employees/ecp/release/${individual_id}`,
					{
						alias,
						tariff_id,
						expire_day: ExpireDay,
						reissue,
						organization_id,
						tariff_cost,
						tariff_name
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(setEcpStatusText('Запрос на выпуск отправлен'))
				setTimeout(() => {
					dispatch(setEcpStatusText(null))
				}, 3000)
				dispatch(setEcpData(response.data))
				dispatch(setLoadStatus(false))
			} catch (error) {
				dispatch(setLoadStatus(false))
				error.response?.data?.detail ? dispatch(setEcpStatusText(`Ошибка! ${error.response?.data?.detail}`)) : dispatch(setEcpStatusText(`Ошибка!`))
			}
		}
	},

	getTariffs(accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/ecp/tariffs/dss`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})

				dispatch(setEcpTariffs(response.data.tariffs))
			} catch (error) {
				console.log(error)
			}
		}
	},

	sendPhoto(accessToken, uuid, formData) {
		return async (dispatch) => {
			dispatch(setEcpStatusText('Отправлено'))
			try {
				const response = await axios.post(
					`${baseURL}/ecp/${uuid}/statement/upload`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(setEcpData(response.data))
				setTimeout(() => {
					dispatch(setEcpStatusText(null))
				}, 10000)
			} catch (error) {
				console.log(error)
			} finally {
				dispatch(setEcpStatusText(null))
			}
		}
	},

	async getDocumentEcp(accessToken, uuid) {
		try {
			const response = await axios.get(
				`${baseURL}/ecp/${uuid}/statement/template`,
				{
					responseType: 'blob',
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			)
			return response
		} catch (error) {
			console.log(error)
		}
	},

	getEcp(participant_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/ecp/${participant_id}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				dispatch(setEcpData(response.data.ecp))
				dispatch(setPersonalDataEcp(response.data))
				dispatch(setLoadingApp(true))
			} catch (error) {
				console.log(error)
			}
		}
	},

	sendNewAlias(uuid, alias, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/ecp/alias/${uuid}`,
					{
						alias
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setEcpStatusText('Новый код авторизации успешно установлен'))
				setTimeout(() => {
					dispatch(setEcpStatusText(null))
				}, 5000)
			} catch (error) {
				error.response.data?.innerContent?.message && dispatch(setEcpStatusText(error.response.data?.innerContent?.message));

				error.response.data?.errors?.Codeword[0] && ( error.response.data.errors.Codeword[0] === "'Codeword' is not in the correct format." &&
					dispatch(setEcpStatusText('Проверьте правильность ввода кода из MyDss'))
				);
				error.response.data?.innerContent?.Alias[0] && dispatch(setEcpStatusText(error.response.data.innerContent.Alias[0]));

				(!error.response.data?.errors?.Codeword[0] || !error.response.data?.innerContent?.Alias[0] || !error.response.data?.innerContent?.message) 
				&& dispatch(setEcpStatusText("Что-то пошло не так!"));

				setTimeout(() => {
					dispatch(setEcpStatusText(null))
				}, 10000)
			}
		}
	},

	getOrganizations(accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/ecp/organizations/`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})

				dispatch(setOrganizations(response.data.organizations))
			} catch (error) {
				console.log(error)
			}
		}
	},

	sendForVerification(accessToken, request_id) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/ecp/requests/${request_id}/send`,
					{},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				return response
			} catch (error) {
				console.log(error)
			}
		}
	},

	sendRequests(accessToken, request_id, participant_id) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/ecp/requests/${request_id}/upload-auto/${participant_id}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(setEcpData(response.data))
				dispatch(setLoadStatus(false))
			} catch (error) {
				dispatch(setLoadStatus(false))
				dispatch(
					setNotificationText(
						alertStatusText(
							error?.response?.text ? error?.response?.text : 'Ошибка',
							false
						)
					)
				)
				console.log(error)
			}
		}
	},

	uploadUserEcpDocuments(accessToken, request_id, employee_id) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/employees/ecp/requests/${request_id}/upload-auto/${employee_id}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(setEcpData(response.data))
				dispatch(setLoadStatus(false))
			} catch (error) {
				dispatch(setLoadStatus(false))
				dispatch(
					setNotificationText(
						alertStatusText(
							error?.response?.text ? error?.response?.text : 'Ошибка',
							false
						)
					)
				)
				console.log(error)
			}
		}
	},

	async getPowerAttorney(accessToken, request_id) {
		try {
			const response = await axios.get(
				`${baseURL}/ecp/${request_id}/power-attorney/template`,
				{
					responseType: 'blob',
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			)
			return response
		} catch (error) {
			console.log(error)
		}
	},
}
