import React from 'react'
import s from './header-party.module.scss'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	setHeaderTypeData,
	setLoadingApp,
	setRepresentativeData,
	setType,
	setButtonState,
	setSearchResult,
	setSearchList,
	setNewUser,
	setPhotoPassport1,
	setPhotoPassport2,
	setPhotoSnils,
	setFileAttorney,
	setFileSig,
} from '../../../redux/manager/manager'
import { manager } from '../../../api/manager/manager'
import { setGlobalPersonalData } from '../../../redux/globalPersonalData/globalPersonalData'

export const HeaderParty = ({
	saveDataClient,
	partId,
	globalPersonalData,
	savePowerAttorneyLegal
}) => {
	const dealId = useSelector((state) => state.deals.dealId)
	const { 
		dataLoad, 
		representativeStatus, 
		headerTypeData,
		buttonState,
		fileAttorney,
		fileSig,
	} = useSelector((state) => state.manager)
	const { ecpStatus, ecpData } =
		useSelector((state) => state.ecp)
	const dispatch = useDispatch()
	const accessToken = localStorage.getItem('accessToken')
	const userRole = localStorage.getItem('userRole')

	const getClientDataFC = (typeEvent) => {
		if (typeEvent === 'participant') {
			dispatch(setGlobalPersonalData({}))
			dispatch(setRepresentativeData(false))
			dispatch(setLoadingApp(false))
			dispatch(setHeaderTypeData('participant'))
			dispatch(manager.getDataClient(partId, accessToken))
		}

		if (typeEvent === 'representative') {
			dispatch(setRepresentativeData(false))
			dispatch(setHeaderTypeData('representative'))
			dispatch(setType('REPRESENTATIVE'))
			dispatch(setGlobalPersonalData({}))
			if (representativeStatus) {
				dispatch(setLoadingApp(false))
				dispatch(manager.getRepresentative(Number(partId), accessToken))
			}
		}
	}

	const deleteSearch = () => {
		dispatch(setSearchList([]));
		dispatch(setSearchResult(false));
		dispatch(setButtonState(false))
		dispatch(setNewUser(false))
	}

	const setPhotosNull = () => {
		dispatch(setPhotoPassport1(null));
		dispatch(setPhotoPassport2(null));
		dispatch(setPhotoSnils(null));
		dispatch(setFileAttorney(null));
		dispatch(setFileSig(null));
	}
	return (
		<div className={s.headerParty}>
			<div>
				<div
					className={
						headerTypeData === 'participant'
							? s.headerParty__active
							: s.headerParty__text
					}
					onClick={
						() => {
							getClientDataFC('participant');
							deleteSearch();
							setPhotosNull();
						}
					}
				>
					<h4>Участник сделки</h4>
					{dataLoad ? <p>данные загружены</p> : <p>данные не загружены</p>}
				</div>
				<div
					style={
						(partId && globalPersonalData.inn) || headerTypeData === 'representative'
							? null
							: { opacity: 0.4 }
					}
					className={
						headerTypeData === 'representative'
							? s.headerParty__active
							: s.headerParty__text
					}
					onClick={
						globalPersonalData.inn
							? () => {
								getClientDataFC('representative');
								; deleteSearch();
								setPhotosNull();
							}
							: null
					}
				>
					<h4>Представитель</h4>
					{representativeStatus ? (
						<p>есть представитель</p>
					) : (
						<p>нет представителя</p>
					)}
				</div>
				<div
					style={partId && globalPersonalData.email ? null : { opacity: 0.4 }}
					className={
						headerTypeData === 'ecp'
							? s.headerParty__active
							: s.headerParty__text
					}
					onClick={
						partId && globalPersonalData.email
							? () => {
								dispatch(setHeaderTypeData('ecp'));
								deleteSearch();
								setPhotosNull();
							}
							: null
					}
				>
					<h4>ЭЦП</h4>
					{ecpStatus ? <p>выпущена</p> : <p>не выпущена</p>}
				</div>
			</div>

			<div>
				<Link 
					to={`/deal/${dealId}`}
					onClick={
						(event) =>{
							deleteSearch();
							setPhotosNull();
						}
					}
				>
					Отмена
				</Link>
				<button
					style={
						(globalPersonalData?.save_status && userRole !== 'AD') || buttonState === true
							? { background: '#ADAAB4' }
							: null
					}
					disabled={ (globalPersonalData?.save_status && userRole !== 'AD') || buttonState === true }
					onClick={
						() => { 
							saveDataClient();
							(fileAttorney !== null || fileSig !== null) && savePowerAttorneyLegal();
						}
					}
				>
					Сохранить
				</button>
			</div>
		</div>
	)
}
