import React from 'react'
import s from './popUpComplete.module.scss'
import { setAdminTextStatus } from '../../redux/admin/admin'
import {
	setNotificationText,
} from '../../redux/manager/manager'
import { useDispatch } from 'react-redux'

const PopUpComplete = ({ description }) => {
	const dispatch = useDispatch()
	/*useEffect(() => {
    const sound = new Howl({
      src: soundSrc,
      html15: true
    })
    if (description && soundOn) {
      sound.play()
    }
  }, [description])*/

	const close = () => {
		dispatch(setAdminTextStatus(null))
		dispatch(setNotificationText(null))
	}

	return (
		<div className={description ? s.modalActive : s.modal} onClick={close}>
			<div className={s.modalContent}>
				<p>{description?.description}</p>
				<button type='button' onClick={close}>
					Закрыть
				</button>
			</div>
		</div>
	)
}
export default PopUpComplete
