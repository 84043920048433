import React from 'react'
import { Admin } from './admin/admin'
import Manager from './manager/manager'
import { User } from './user/user'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

export const UserRoles = () => {
	const { login, userRole } = useSelector((state) => state.login)

	if (!login) {
		return <Navigate to='/login' replace />
	}

	return (
		<div>
			{userRole === 'MA' && <Manager userRole={userRole} />}
			{userRole === 'AD' && <Admin userRole={userRole} />}
			{userRole === 'CL' && <User />}
		</div>
	)
}
