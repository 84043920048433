import React, { useState } from 'react'
import Button from '../../../components/button/button'
import s from './signatureReleaseContent.module.scss'

const SignatureReleaseContent = ({
	signatureData,
	setSignatureData,
	completed,
	setCompleted,
	processing,
	setProcessing,
	issuance,
	setIssuance
}) => {
	const [receiving] = useState(['В офиcе', 'Курьером'])
	const [getSity] = useState([
		'Казань, ул Первая, 1',
		'Воронеж, ул. Новосибирская, 61д',
		'Санкт-Петербург, ул. Петровский пр. 9-11'
	])
	const [receivingMethod, setReceivingMethod] = useState('')
	const [officeCity, setOfficeCity] = useState('')
	const [openWindowCity, setOpenWindowCity] = useState(false)

	const test = (name) => {
		switch (name) {
			case 1:
				setSignatureData(true)
				setProcessing(true)
				break
			case 2:
				setSignatureData(true)
				setCompleted(false)
				setProcessing(false)
				setIssuance(false)
				break
			case 3:
				setSignatureData(true)
				setCompleted(true)
				setProcessing(false)
				setIssuance(false)
				break
			case 4:
				setSignatureData(true)
				setIssuance(true)
				break
		}
	}

	const sendData = () => {
		alert(`
    Получение: ${receivingMethod};
    Офис: ${officeCity}`)
	}

	return (
		<div className={s.signatureReleaseContent}>
			<h3>Заявление на выпуск электронной подписи</h3>
			<div className={s.signatureReleaseContent__statusItem}>
				{processing && (
					<span style={{ background: '#009EE8' }}>На рассмотрении</span>
				)}
				{signatureData && !completed && !issuance && !processing && (
					<span style={{ background: '#FF6477' }}>Отклонено</span>
				)}
				{completed && (
					<span style={{ background: '#61BA65' }}>Подпись готова</span>
				)}
				{issuance && (
					<span style={{ background: '#61BA65' }}>Подпись выпущена</span>
				)}
			</div>
			{signatureData && !completed && !issuance && !processing ? (
				<div className={s.signatureReleaseContent__falseCompleted}>
					<p>
						Электронная подпись не выпущена. Позвоните вашему менеджеру, чтобы
						устранить проблемы и выпустить подпись. Номер 8 909 100-20-20
					</p>
					<div>
						Или{' '}
						<span onClick={() => setSignatureData(false)}>
							попробуйте заново
						</span>
					</div>
				</div>
			) : (
				<>
					<div className={s.signatureReleaseContent__wrapperDataPassport}>
						{signatureData && completed && !processing ? (
							<>
								<h4>Подпись выпущена для представителя</h4>
								<p>
									Если за покупателя сделку оформляет его представитель по
									доверенности, то документы подписывает представитель и
									требуется именно его электронная подпись.
								</p>
							</>
						) : (
							<>
								<h4>Персональные данные представителя</h4>
								<p>
									Если за покупателя сделку оформляет его представитель по
									доверенности, то документы подписывает представитель и
									требуется именно его электронная подпись.
								</p>
							</>
						)}
						<div
							className={s.signatureReleaseContent__wrapperDataPassport__item}
						>
							<span>Фамилия</span>
							<span>{signatureReleaseData.surname}</span>
						</div>
						<div
							className={s.signatureReleaseContent__wrapperDataPassport__item}
						>
							<span>Имя</span>
							<span>{signatureReleaseData.name}</span>
						</div>
						<div
							className={s.signatureReleaseContent__wrapperDataPassport__item}
						>
							<span>Отчество</span>
							<span>{signatureReleaseData.patronymic}</span>
						</div>
						<div
							className={s.signatureReleaseContent__wrapperDataPassport__item}
						>
							<span>Пол</span>
							<span>{signatureReleaseData.gender}</span>
						</div>
						<div
							className={s.signatureReleaseContent__wrapperDataPassport__item}
						>
							<span>Дата рождения</span>
							<span>{signatureReleaseData.gender}</span>
						</div>
						<div
							className={s.signatureReleaseContent__wrapperDataPassport__item}
						>
							<span>Место рождения</span>
							<span>{signatureReleaseData.birthplace}</span>
						</div>
						<div
							className={s.signatureReleaseContent__wrapperDataPassport__item}
						>
							<span>Адрес регистрации</span>
							<span>{signatureReleaseData.registrationAddress}</span>
						</div>
						<div
							className={s.signatureReleaseContent__wrapperDataPassport__item}
						>
							<span>Гражданство</span>
							<span>{signatureReleaseData.citizenship}</span>
						</div>
					</div>
					{!signatureData ? (
						<div className={s.signatureReleaseContent__wrapperDataSignature}>
							<h4>Получение подписи</h4>
							<div
								className={
									s.signatureReleaseContent__wrapperDataSignature__item
								}
							>
								<span>Получение</span>
								<div
									className={
										s.signatureReleaseContent__wrapperDataSignature__item__receiving
									}
								>
									{receiving.map((item) => (
										<div key={item}>
											<span
												onClick={() => setReceivingMethod(item)}
												className={item === receivingMethod && s.active}
											>
												{item}
											</span>
										</div>
									))}
								</div>
							</div>
							<div
								className={
									s.signatureReleaseContent__wrapperDataSignature__item
								}
							>
								<span>Офис</span>
								<div
									className={
										s.signatureReleaseContent__wrapperDataSignature__item__city
									}
								>
									<span>{officeCity}</span>
									<button onClick={() => setOpenWindowCity(!openWindowCity)}>
										&#183;&#183;&#183;
									</button>
								</div>
							</div>
							{openWindowCity && (
								<div className={s.city}>
									{getSity.map((item) => {
										return (
											<span onClick={() => setOfficeCity(item)}>{item}</span>
										)
									})}
								</div>
							)}
							<p>
								Как только подпись будет выпущена, вам позвонят, или отправят
								смс. Приходите в удобное время, подпишете документы и
								сфотографируетесь. Это займёт 10 минут.
							</p>
						</div>
					) : signatureData && completed && issuance ? (
						<div className={s.wrapperInstallApplication}>
							<h4>
								Чтобы подписывать документы, установите приложение на телефон
							</h4>
							<div>
								<a href=''>App Store</a>
								<a href=''>Google Play</a>
							</div>
						</div>
					) : (
						<>
							<div className={s.signatureReleaseContent__wrapperDataSignature}>
								<h4>Получение подписи</h4>
								<div
									className={
										s.signatureReleaseContent__wrapperDataSignature__item
									}
								>
									<span>Получение</span>
									<div>
										<span>В офисе</span>
									</div>
								</div>
								<div
									className={
										s.signatureReleaseContent__wrapperDataSignature__item
									}
								>
									<span>Офис</span>
									<div>Казань, ул Первая, 1</div>
								</div>
								{signatureData && completed && !processing ? (
									<p>Приходите в офис и забирайте подпись</p>
								) : (
									<p>
										Как только подпись будет выпущена, вам позвонят, или
										отправят смс. Приходите в удобное время, подпишете документы
										и сфотографируетесь. Это займёт 10 минут.
									</p>
								)}
								{signatureData && completed && issuance && <div>гусь</div>}
								{!completed && processing && (
									<p>
										Заявка отправлена , {signatureReleaseData.dateRequest} Если
										появились вопросы, позвоните по номеру 8 800 900-10-10
									</p>
								)}
							</div>
						</>
					)}
					{!signatureData && (
						<Button small={true} FCOnclick={sendData}>
							Отправить заявление на выпуск подписи
						</Button>
					)}
				</>
			)}
			<div className={s.test}>
				<h4>Тест</h4>
				<div>
					<span onClick={() => test(1)}>На рассмотрении</span>
					<span onClick={() => test(2)}>Отклонено</span>
					<span onClick={() => test(3)}>Подпись готова</span>
					<span onClick={() => test(4)}>Подпись выпущена</span>
				</div>
			</div>
		</div>
	)
}

export default SignatureReleaseContent

const signatureReleaseData = {
	surname: 'Самоходов',
	name: 'Петр',
	patronymic: 'Гавриович',
	gender: 'Мужской',
	DOB: '01.01.1960',
	birthplace: 'г. Москва',
	registrationAddress: 'Воронеж, улица Прямая д.1',
	citizenship: 'РФ',
	dateRequest: '10 сентября 2021'
}
