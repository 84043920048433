import React, { useState } from 'react'
import ParticipantsTransactionItem from './ParticipantsTransactionItem'
import s from './index.module.scss'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { manager } from '../../api/manager/manager'
import { setListCopiedParticipant } from '../../redux/manager/manager'
import { deals } from '../../api/deals/deals'

const ParticipantsTransaction = ({ participants, seller, deal, appealId }) => {
	const [activeDealPartyEdit, setActiveDealPartyEdit] = useState(false)
	const accessToken = localStorage.getItem('accessToken')
	const { listCopiedParticipant } = useSelector((state) => state.manager)
	const dispatch = useDispatch()

	return (
		<div className={s.participantsTransaction}>
			<div className={s.participantsTransaction__header}>
				<h3>Участники сделки</h3>
				<div>
					{deal?.appeals?.length > 1 && appealId?.status && (
						<div
							onClick={() =>
								listCopiedParticipant
									? dispatch(setListCopiedParticipant(null))
									: dispatch(
											manager.getListCopiedParticipant(deal?.id, accessToken)
									  )
							}
						>
							<span>+</span>
							<span>Скопировать участника</span>
							{listCopiedParticipant?.length > 0 && (
								<div
									className={
										s.participantsTransaction__header__wrapperListParticipant
									}
								>
									{listCopiedParticipant?.map((item) => {
										return (
											<div
												style={
													listCopiedParticipant.length > 1
														? { borderBottom: '1px solid #DBDCDC' }
														: null
												}
											>
												<h4 key={item.participant_id}>{item.full_name}</h4>
												<h5>{item.role}</h5>
												<span
													onClick={async () => {
														await dispatch(
															manager.copyParticipant(
																deal?.id,
																item.participant_id,
																accessToken
															)
														)
														dispatch(deals.deal(deal.id, accessToken))
													}}
												>
													Скопировать участника
												</span>
											</div>
										)
									})}
								</div>
							)}
						</div>
					)}
					{seller !== 'UNISTROY' && (
						<Link to='/dataClient'>
							<span>+</span>
							<span>Участник</span>
						</Link>
					)}
				</div>
			</div>

			<div className={s.participantsTransaction__body}>
				{participants?.length ? (
					participants?.map((participant, key) => {
						return (
							<ParticipantsTransactionItem
								key={key}
								participant={participant}
								setActiveDealPartyEdit={setActiveDealPartyEdit}
								seller={seller}
							/>
						)
					})
				) : (
					<span className={s.participantsTransactionNone}>
						Участники не добавлены
					</span>
				)}
			</div>
		</div>
	)
}

export default ParticipantsTransaction
