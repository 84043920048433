import s from './index.module.scss'
import progressTrue from '../../../common/images/deal/statusesTrue.svg'
import notProgressEcp from '../../../common/images/deal/notProgressStatuses.svg'
import infoStatusRedIcon from '../../../common/images/infoStatusRed.svg'
import { Link, useNavigate } from 'react-router-dom'
import React from 'react'

const ParticipantsTransactionItem = ({
	participant,
	setActiveDealPartyEdit,
	seller
}) => {
	const navigate = useNavigate()
	const goToPageRepresentative = () => {
		navigate(`/dataClient/${participant?.participant_id}`)
	}

	return (
		<>
			{participant ? (
				<div className={s.participantsTransactionItem}>
					<Link
						to={`/dataClient/${participant?.participant_id}`}
						className={s.participantsTransactionItem_participant}
						onClick={() => setActiveDealPartyEdit(true)}
					>
						<span>{participant?.role}</span>
						<div className={s.participantsTransactionItem_participant__name}>
							<span>
								{participant?.full_name}
								{participant?.type === 'LEGAL_ENTITY' &&
									seller === 'UNISTROY' &&
									participant?.representative &&
									`/ ${participant?.representative?.full_name}`}
							</span>
							<span>{participant?.account}</span>
						</div>
						{(seller === 'UNISTROY' && participant?.type === 'LEGAL_ENTITY'
							? false
							: true) && (
							<div
								className={s.participantsTransactionItem_participant__status}
							>
								<div
									className={
										s.participantsTransactionItem_participant__status__item
									}
								>
									{participant?.client_data_status &&
									participant?.employee_data_status ? (
										<img src={progressTrue} alt='' />
									) : participant?.client_data_status ? (
										<img src={infoStatusRedIcon} alt='' />
									) : (
										<img src={notProgressEcp} alt='' />
									)}
									<span>Перс. данные</span>
								</div>
								{!participant?.representative && (
									<div
										className={
											s.participantsTransactionItem_participant__status__item
										}
									>
										{participant?.ecp_status ? (
											<img src={progressTrue} alt='' />
										) : (
											<img src={notProgressEcp} alt='' />
										)}
										<span>ЭЦП</span>
									</div>
								)}
							</div>
						)}
					</Link>

					{participant?.representative &&
						(seller === 'UNISTROY' && participant?.type === 'LEGAL_ENTITY'
							? false
							: true) && (
							<div
								className={s.participantsTransactionItem_participant}
								onClick={() => goToPageRepresentative()}
							>
								<span>Представитель</span>
								<div
									className={s.participantsTransactionItem_participant__name}
								>
									<span>{participant?.representative?.full_name}</span>
									<span>{participant?.account}</span>
								</div>
								<div
									className={s.participantsTransactionItem_participant__status}
								>
									<div
										className={
											s.participantsTransactionItem_participant__status__item
										}
									>
										{participant?.representative?.client_data_status &&
										participant?.representative?.employee_data_status ? (
											<img src={progressTrue} alt='' />
										) : participant?.representative?.client_data_status ? (
											<img src={infoStatusRedIcon} alt='' />
										) : (
											<img src={notProgressEcp} alt='' />
										)}
										<span>Перс. данные</span>
									</div>
									<div
										className={
											s.participantsTransactionItem_participant__status__item
										}
									>
										<div>
											{participant?.ecp_status ? (
												<img src={progressTrue} alt='' />
											) : (
												<img src={notProgressEcp} alt='' />
											)}
										</div>
										<span>ЭЦП</span>
									</div>
								</div>
							</div>
						)}
				</div>
			) : (
				<div>Участники не добавлены</div>
			)}
		</>
	)
}
export default ParticipantsTransactionItem
