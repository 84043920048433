import React, { useEffect, useState } from 'react'
import s from './personalDataPassport.module.scss'
import { PersonalData } from '../../components/deal-party-edit/personal-data/personal-data'
import { Identification } from '../../components/deal-party-edit/identification/identification'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/button/button'
import { client } from '../../api/client/client'
import { setTextStatus } from '../../redux/client/client'
import preloader from '../../common/preloader/preloader.gif'

const PersonalDataPassport = ({ saveFC, save, width }) => {
	const { globalPersonalData } = useSelector(
		(state) => state.globalPersonalData
	)
	const { textStatus, passportPhoto1, passportPhoto2 } = useSelector(
		(state) => state.client
	)
	const { isLoading } = useSelector(
		(state) => state.loading
	)
	const [genderValue, setGenderValue] = useState('')
	const accessToken = localStorage.getItem('accessToken')
	const individual_id = localStorage.getItem('individual_id')
	const reprsentative_id = localStorage.getItem('representative_id')
	const dispatch = useDispatch()
	const [disabledButton, setDisabledButton] = useState(true)
	const { snilsPhoto } = useSelector((state) => state.client)

	useEffect(() => {
		setTimeout(() => {
			dispatch(setTextStatus(null))
		}, 6000)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [textStatus])

	const sendPersonalDataPassport = () => {
		const formData = new FormData()

		if (
			passportPhoto1 &&
			!globalPersonalData?.identification_card?.photo_page_key
		) {
			formData.append('photo_page', passportPhoto1)
		}

		if (
			passportPhoto2 &&
			!globalPersonalData?.identification_card?.signature_page_key
		) {
			formData.append('signature_page', passportPhoto2)
		}
		if (snilsPhoto && 
			!globalPersonalData?.snils?.scan_key
			) {
			formData.append('snils_scan', snilsPhoto)
		}

		dispatch(
			client.editClientData(
				individual_id ? individual_id : reprsentative_id,
				{
					save_status: false,
					client_data_status: false,
					name: globalPersonalData.name,
					surname: globalPersonalData.surname,
					patronymics: globalPersonalData.patronymics,
					gender: globalPersonalData.gender,
					birthday: globalPersonalData.birthday,
					place_birth: globalPersonalData.place_birth,
					reg_address: globalPersonalData.reg_address,
					citizenship: 'РФ',
					inn: globalPersonalData.inn,
					identification_card: {
						title: 'Паспорт',
						series_number: globalPersonalData.identification_card.series_number,
						date_issue: globalPersonalData.identification_card.date_issue,
						issued_by_whom:
							globalPersonalData.identification_card.issued_by_whom,
						subsection_code:
							globalPersonalData.identification_card.subsection_code
					},
					snils: {
						number: globalPersonalData.snils.number
					},
					power_attorney_path: globalPersonalData.power_attorney_path,
				},
				passportPhoto1 || passportPhoto2 || snilsPhoto ? formData : {},
				passportPhoto1,
				passportPhoto2,
				snilsPhoto,
				accessToken,
				false
			)
		)
	}

	const savePersonalDataPassport = () => {
		dispatch(
			client.confirmClientData(
				individual_id ? individual_id : reprsentative_id,
				{},
				accessToken,
			)
		)
		dispatch(
			client.getClientIdentityCredentials(
				individual_id ? individual_id : reprsentative_id, accessToken,
			)
		)
	}

	useEffect(() => {
		if (
			(globalPersonalData?.identification_card?.photo_page_key ||
				passportPhoto1) &&
			(globalPersonalData?.identification_card?.signature_page_key ||
				passportPhoto2) &&
			(globalPersonalData?.snils?.scan_key ||
				snilsPhoto)
		) {
			setDisabledButton(false)
		} else {
			setDisabledButton(true)
		}
	}, [globalPersonalData, passportPhoto1, passportPhoto2, snilsPhoto])

	return (
		<>
			<div className={s.personalDataPassport}>
				{width > 600 && <h3>Удостоверение личности и СНИЛС</h3>}
				<PersonalData
					data1c={ false }
					setGenderValue={ setGenderValue }
					genderValue={ genderValue }
					globalPersonalData={ globalPersonalData }
					width={width}
				/>
				<Identification
					data1c={false}
					document='Паспорт РФ'
					passportPhoto={ true }
					saveFC={ saveFC }
					save={ save }
					globalPersonalData={ globalPersonalData }
					width={ width }
					sendPersonalDataPassport={ sendPersonalDataPassport }
				/>
			</div>
			<div className={s.personalDataSnils}>
				<>	
					{ isLoading === true &&
						<>
							<span>Идет загрузка...</span>
							<img src={preloader} alt='preloader' />
						</>
					}
					{ textStatus && <div className={s.textStatus}>{ textStatus }</div> }
					<div className={s.personalDataPassport__buttonWrapper}>
						<Button
							disabled={ disabledButton }
							small={ true }
							FCOnclick={ sendPersonalDataPassport }
						>
							Сохранить
						</Button>
						{ globalPersonalData?.client_data_status === true ? (
							<span>Данные подтверждены и верны</span>
						) : (
							<Button
								small={ true }
								FCOnclick={() => {
									savePersonalDataPassport();
								}}
								disabled={
									!globalPersonalData?.identification_card?.photo_page_key &&
									!globalPersonalData?.identification_card?.signature_page_key &&
									!globalPersonalData?.snils?.scan_key
								}
							>
								Подтвердить, что данные верны
							</Button>
						) }
					</div>
					{!globalPersonalData?.client_data_status && (
						<p>
							Подтвердить можно, когда все поля заполнены {width > 600 && <br />}и
							фотографии загружены.
						</p>
					)}
				</> 
			</div>
		</>
	)
}
export default PersonalDataPassport
