import { createSlice } from '@reduxjs/toolkit'
import de from 'react-datepicker'

const realty = createSlice({
	name: 'realty',
	initialState: {
		object: {},
		types: [],
		selectRadioBtn: null,
		realtyTextStatus: null,
		dicts: null,
		objectTypeState: null
	},
	reducers: {
		setObject(state, action) {
			state.object = action.payload
		},
		setObjectValue(state, action) {
			state.object[action.payload.name] = action.payload.value
		},
		setRealtyTypes(state, action) {
			state.types = action.payload
		},
		setSelectRadioBtn(state, action) {
			state.selectRadioBtn = action.payload
		},
		setRealtyTextStatus(state, action) {
			state.realtyTextStatus = action.payload
		},
		setDicts(state, action) {
			state.dicts = action.payload
		},
		setObjectTypeState(state, action) {
			state.objectTypeState = action.payload
		}
	}
})

export default realty.reducer

export const {
	setObject,
	setObjectValue,
	setRealtyTypes,
	setSelectRadioBtn,
	setRealtyTextStatus,
	setDicts,
	setObjectTypeState
} = realty.actions
