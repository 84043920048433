import React, { useEffect, useRef, useState } from 'react'
import s from './passwordRecovery.module.scss'
import Container from '../../components/container/container'
import ContainerContent from '../../components/container-content/container-content'
import Input from '../../components/input/input'
import { useMatch, useNavigate } from 'react-router-dom'
import Button from '../../components/button/button'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from '../../components/modal/modal'
import {
	setButtonDisabled,
	setLengthEight,
	setNewPassportRecovery,
	setSequenceNumbers
} from '../../redux/changePassword/changePassword'
import { passwordRecovery } from '../../api/passwordRecovery/passwordRecovery'
import { setPasswordRecoveryTextStatus } from '../../redux/passwordRecovery/passwordRecovery'

const PasswordRecovery = () => {
	const { lengthEight, sequenceNumbers, newPassportRecovery, buttonDisabled } =
		useSelector((state) => state.changePassword)

	const { 
		passwordRecoveryTextStatus,
		statusPasswordRecovery
	} = useSelector(
		(state) => state.passwordRecovery
	)

	const dispatch = useDispatch()
	const inputRef = useRef(null)
	const navigate = useNavigate()
	const [activeModal, setActiveModal] = useState(true)
	const regularSequenceNumbers =
		/(?=.*[0-9])(?=.*[a-z])[0-9a-zA-Z!@#$%^&*]{6,}/g
	const match = useMatch('/passwordRecovery/:uid64/:token')

	useEffect(() => {
		dispatch(setLengthEight(newPassportRecovery?.length > 8))
		dispatch(
			setSequenceNumbers(regularSequenceNumbers.test(newPassportRecovery))
		)

		if (lengthEight && sequenceNumbers) {
			dispatch(setButtonDisabled(false))
		} else {
			dispatch(setButtonDisabled(true))
		}
	}, [newPassportRecovery, lengthEight, sequenceNumbers])

	useEffect(() => {
		if (!activeModal) {
			navigate(`/login`)
		}

		if (statusPasswordRecovery) {
			setTimeout(() => {
				navigate(`/login`)
				dispatch(setPasswordRecoveryTextStatus(null))
			}, 1500)
		}
	}, [activeModal, statusPasswordRecovery])

	const buttonClick = () => {
		if (inputRef.current.type === 'password') {
			inputRef.current.type = 'text'
		} else {
			inputRef.current.type = 'password'
		}
	}

	const sendNewPassword = (e) => {
		if (passwordRecoveryTextStatus) {
			dispatch(setPasswordRecoveryTextStatus(null))
		}
		dispatch(
			passwordRecovery.restorePassword(
				match.params.uid64,
				match.params.token,
				newPassportRecovery
			)
		)
	}

	return (
		<Container>
			<ContainerContent>
				<Modal active={activeModal} setActive={setActiveModal} onClose>
					<div className={s.passwordRecovery}>
						<h4>Восстановление пароля</h4>
						<div>
							<span>Новый</span>
							<div>
								<Input
									onChange={(e) =>
										dispatch(setNewPassportRecovery(e.target.value))
									}
									nameRef={inputRef}
									buttonIcon
									buttonClick={buttonClick}
									type={'password'}
								/>
							</div>
						</div>
						<div className={s.textPassword}>
							{passwordRecoveryTextStatus ? (
								<div className={s.textPassword__error}>
									{passwordRecoveryTextStatus}
								</div>
							) : (
								<>
									{' '}
									<p>- Используйте только латинские буквы (a–z, A–Z)</p>
									<p>
										- Пароль должен содержать хотя бы один специальный символ:{' '}
										<br />! @ # $ % ^ & * ( ) _ + = ; : , . / ? | ` ~
									</p>
									<p>
										- Ваш пароль должен содержать от 8 до 16 символов <br />с
										использованием цифр,строчных и заглавных символов.
									</p>
								</>
							)}
						</div>
						<div>
							<Button disabled={buttonDisabled} FCOnclick={sendNewPassword}>
								Изменить пароль
							</Button>
						</div>
					</div>
				</Modal>
			</ContainerContent>
		</Container>
	)
}

export default PasswordRecovery
