import axios from 'axios'
import {
	documentRequestUserStatus,
	setDocument,
	setPowerOfAttorney,
	setDocumentStatusText,
	setDocumentsTypeList,
	setFilePowerOfAttorney,
	setSignatureManual,
} from '../../redux/documents/documents'
import {
	setNotificationText
} from '../../redux/manager/manager'
import {
	setAppealAtorneyList
} from '../../redux/appeals/appeals'
import { setRealtyTextStatus } from '../../redux/realty/realty'
import { alertStatusText } from '../../components/alertStatusText/alertStatusText'
import { client } from '../client/client'
const baseURL = process.env.REACT_APP_BASE_URL

export const documents = {
	getDocumentsTypeList(accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/document-type`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})

				dispatch(setDocumentsTypeList(response.data.document_types))
			} catch (error) {
				console.log(error)
			}
		}
	},
	getDealDocument(accessToken, appeal_id, documentId) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/appeals/${appeal_id}/documents/${documentId}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(setDocument(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
	getDocument(accessToken, documentId) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/documents/${documentId}/`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(setDocument(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
	documentRequestUser(appeal_id, documentId, participant_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/appeals/${appeal_id}/documents/${documentId}/request_from_participant/${participant_id}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(documentRequestUserStatus(true))
			} catch (error) {
				console.log(error)
			}
		}
	},

	documentConfirmed(appeal_id, documentId, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/appeals/${appeal_id}/documents/${documentId}/confirmed`,
					{},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setDocumentStatusText('Документ подтвержден'))
			} catch (error) {
				console.log(error)
			}
		}
	},

	documentUploadSign(
		appeal_id,
		documentId,
		formData,
		participant_id,
		accessToken
	) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/appeals/${appeal_id}/documents/${documentId}/participants/${participant_id}/upload_sign`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setDocumentStatusText('Запрос отменен'))
			} catch (error) {
				console.log(error)
			}
		}
	},

	uploadEgrnSignDocumentFile(
		documentId,
		signer_id,
		accessToken,
		formData,
	) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/egrn_extract/documents/${documentId}/signers/${signer_id}/upload_sign`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setDocumentStatusText('Запрос отменен'))
			} catch (error) {
				console.log(error)
			}
		}
	},

	documentRequestCancel(appeal_id, documentId, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/appeals/${appeal_id}/documents/${documentId}/request_from_participant`,
					{},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(documentRequestUserStatus(false))
				dispatch(setDocumentStatusText('Запрос отменен'))
			} catch (error) {
				console.log(error)
			}
		}
	},
	documentCreate(document_type_id, appeal_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/appeals/${appeal_id}/documents/create_document`,
					{
						document_type_id
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setDocument(response.data))
				dispatch(setDocumentStatusText('Создано'))
			} catch (error) {
				console.log(error)
			}
		}
	},
	documentDelete(appeal_id, documentId, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/appeals/${appeal_id}/documents/${documentId}/delete`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setDocumentStatusText('Документ удален'))
				setTimeout(() => {
					dispatch(setDocumentStatusText(null))
				}, 1400)
			} catch (error) {
				console.log(error)
			}
		}
	},
	documentDeleteSigner(appeal_id, document_id, signature_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/appeals/${appeal_id}/documents/${document_id}/signers/${signature_id}/delete`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setDocumentStatusText('Удалено'))
			} catch (error) {
				dispatch(
					setDocumentStatusText(
						error.response.data.detail ? error.response.data.detail : ''
					)
				)
				console.log(error)
			}
		}
	},

	documentEgrnDeleteSigner(document_id, signature_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/egrn_extract/documents/${document_id}/signers/${signature_id}/delete`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(setDocumentStatusText('Удалено'))
			} catch (error) {
				dispatch(
					setDocumentStatusText(
						error.response.data.detail ? error.response.data.detail : ''
					)
				)
				console.log(error)
			}
		}
	},

	documentDeleteFile(appeal_id, documentId, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/appeals/${appeal_id}/documents/${documentId}/file/delete`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setDocumentStatusText('Удалено'))
			} catch (error) {
				console.log(error)
			}
		}
	},
	addDocumentSigner(
		appeal_id,
		documentId,
		userAuthId,
		signature_manual,
		accessToken
	) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/appeals/${appeal_id}/documents/${documentId}/signer/${userAuthId}`,
					{
						signature_manual
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setDocumentStatusText('Подписант добавлен'))
				dispatch(setSignatureManual(false))
			} catch (error) {
				console.log(error)
				dispatch(setDocumentStatusText(error.response.data.detail))
				dispatch(setSignatureManual(false))
			}
		}
	},
	reloadDocumentSigner(
		signer_id,
		signature_manual,
		accessToken
	) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/documents/signer/${signer_id}/reload`,
					{
						signature_manual
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setDocumentStatusText('Отправлено повторно'))
				dispatch(setSignatureManual(false))
			} catch (error) {
				console.log(error)
				dispatch(setDocumentStatusText(error.response.data.detail))
				dispatch(setSignatureManual(false))
			}
		}
	},
	addEgrnDocumentSigner(
		documentId,
		signature_manual,
		accessToken
	) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/egrn_extract/documents/${documentId}/signer/`,
					{
						signature_manual
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				// dispatch(setDocumentStatusText('Подписант добавлен'))
				// dispatch(setDocumentStatusText(response.error_state + response.error_description))
				dispatch(setSignatureManual(false))
			} catch (error) {
				console.log(error)
				dispatch(setDocumentStatusText(error.response.data.detail))
				dispatch(setSignatureManual(false))
			}
		}
	},
	editDocumentFile(appeal_id, documentId, formData, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/appeals/${appeal_id}/documents/${documentId}/file`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setDocumentStatusText('Документ изменен'))
				dispatch(setDocument(response.data))
			} catch (error) {
				console.log(error)
				dispatch(setDocumentStatusText('Ошибка загрузки файла'))
			}
		}
	},
	editDocument(appeal_id, documentId, typeId, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/appeals/${appeal_id}/documents/${documentId}/edit_type`,
					{
						document_type_id: typeId
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setDocumentStatusText('Документ изменен'))
				dispatch(setDocument(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
	uploadSignDocumentFile(
		appeal_id,
		document_id,
		signer_id,
		accessToken,
		formData
	) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/appeals/${appeal_id}/documents/${document_id}/signers/${signer_id}/upload_sign`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setDocumentStatusText('Документ добавлен'))
				dispatch(setDocument(response.data))
			} catch (error) {
				console.log(error)

				dispatch(setDocumentStatusText(error.response.data.detail))
			}
		}
	},
	documentEditDetail(document_id, accessToken, numberDocument, dateDocument) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/documents/${document_id}/edit_detail`,
					{
						number: numberDocument,
						issue_date: dateDocument
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				console.log(response)
			} catch (error) {
				console.log(error)
			}
		}
	},
	documentPowerAttorney(individual_id, formData, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/document/power_attorney/${individual_id}/upload/`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				console.log(response)
				dispatch(
					setFilePowerOfAttorney({
						title: response.data.power_attorney_title,
						path: response.data.power_attorney_path
					})
				)
				dispatch(setRealtyTextStatus(alertStatusText('Сохранено', false)))
			} catch (error) {
				dispatch(setRealtyTextStatus(alertStatusText('Ошибка', false)))

				console.log(error)
			}
		}
	},

	documentPowerAttorneyLegal(legal_entity_id, formData, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/document/power_attorney/legal_entity/${legal_entity_id}/upload`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				console.log(response)
				dispatch(
					setFilePowerOfAttorney({
						data: response.data
					})
				)
				dispatch(setRealtyTextStatus(alertStatusText('Сохранено', false)))
			} catch (error) {
				dispatch(
					setNotificationText(alertStatusText("Ошибка", true))
				  )
				console.log(error)
			}
		}
	},

	documentPowerAttorneyLegalList(legal_entity_id, document_id, formData, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/document/${document_id}/legal_entity/${legal_entity_id}/power_attorney`,

					formData,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
					dispatch(
						setFilePowerOfAttorney({
							title: response.data.power_attorney_title,
							path: response.data.power_attorney_path
						})
					)
					dispatch(setRealtyTextStatus(alertStatusText('Сохранено', false)))
			} catch (error) {
				dispatch(setRealtyTextStatus(alertStatusText('Ошибка', false)))
				console.log(error)
			}
		}
	},

	documentDeletePowerAttorney(individual_id, accessToken, appealId) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/document/power_attorney/${individual_id}/delete/`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				console.log(response)
				dispatch(client.getTaskListClientData(appealId, accessToken))
				dispatch(setFilePowerOfAttorney(null))
				dispatch(setRealtyTextStatus(alertStatusText('Удалено', false)))
			} catch (error) {
				console.log(error)
				dispatch(setRealtyTextStatus(alertStatusText('Ошибка', false)))
			}
		}
	},

	
	documentDeletePowerAttorneyUser(individual_id, accessToken, appealId) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/document/power_attorney/${individual_id}/delete/`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				console.log(response)
				dispatch(setFilePowerOfAttorney(null))
				dispatch(setRealtyTextStatus(alertStatusText('Удалено', false)))
			} catch (error) {
				console.log(error)
				dispatch(setRealtyTextStatus(alertStatusText('Ошибка', false)))
			}
		}
	},

	documentDeletePowerAttorneyLegal(legal_entity_id, accessToken, appealId) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/document/power_attorney/legal_entity/${legal_entity_id}/delete`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				console.log(response)
				// dispatch(client.getTaskListClientData(appealId, accessToken))
				dispatch(setFilePowerOfAttorney(null))
				dispatch(setRealtyTextStatus(alertStatusText('Удалено', false)))
			} catch (error) {
				console.log(error)
				dispatch(setRealtyTextStatus(alertStatusText('Ошибка', false)))
			}
		}
	},

	powerOfAttorneyDate(document_id, individual_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/document/${document_id}/individual/${individual_id}/power_attorney/`,
					{},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(
					setFilePowerOfAttorney({
						title: response.data.power_attorney_title,
						path: response.data.power_attorney_path
					})
				)
				dispatch(setRealtyTextStatus(alertStatusText('Сохранено', false)))
			} catch (error) {
				dispatch(setRealtyTextStatus(alertStatusText('Ошибка', false)))
				console.log(error)
			}
		}
	},

	powerOfAttorneyAppeal(appeal_id) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/documents/appeal/${appeal_id}/power_attorney/`)
				dispatch(setAppealAtorneyList(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	powerOfAttorney(appeal_id) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/power_attorney/appeal/${appeal_id}/`)
				dispatch(setPowerOfAttorney(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	editPowerOfAttorneyAppeal(power_attorney_id, document_id, formData, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/document/${document_id}/power_attorney/${power_attorney_id}`,
					formData,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				// dispatch(setDocumentStatusText('Документ изменен'))
				dispatch(setDocument(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
}
