import React from 'react'
import s from './reviewHistory.module.scss'
import shareImg from '../../common/images/deal/share.svg'
import Dropdown from '../../components/dropdown/dropdown'

const ReviewHistory = ({ dropDown }) => {
	return (
		<div className={s.reviewHistory}>
			<h3>История рассмотрения</h3>
			{reviewHistoryData.map((item) => {
				return (
					<div className={s.reviewHistory__wrapper} key={item.id}>
						<div className={s.reviewHistory__wrapper__item}>
							<span>{item.date}</span>
							<span>{item.status}</span>
						</div>
						<div>
							<img src={shareImg} alt='shareImg' />
							{item.answer ? (
								<a href=''>Скачать Ответ</a>
							) : (
								<a href=''>Скачать отправленное</a>
							)}
						</div>
					</div>
				)
			})}
			{dropDown && (
				<div className={s.reviewHistory__dropdown}>
					<Dropdown data={dataDropDown} />
				</div>
			)}
		</div>
	)
}

export default ReviewHistory

const reviewHistoryData = [
	{
		id: 0,
		date: '18.02.2022 18:30',
		status: 'Отправлено в Росреестр',
		answer: false,
		pdf: ''
	},
	{
		id: 1,
		date: '20.02.2022 11:12',
		status: 'Создано доп обращение №1',
		answer: false,
		pdf: ''
	},
	{
		id: 2,
		date: '20.02.2022 11:12',
		status: 'Получен ответ Росрееста: Зарегистрировано',
		answer: true,
		pdf: ''
	}
]

const dataDropDown = [
	'Вспомогательные заявки',
	'Довнесение документов',
	'Приостановка',
	'Возобновление',
	'Прекращение',
	'Регистрация ипотеки в силу закона'
]
