import React, { useEffect, useState } from 'react'
import Container from '../../components/container/container'
import ContainerContent from '../../components/container-content/container-content'
import { Modal } from '../../components/modal/modal'
import Input from '../../components/input/input'
import Button from '../../components/button/button'
import s from './invitationToPersonalAccount.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { invitationAccount } from '../../api/invitationAccount'
import {
	setCheckEmail,
	setCheckPhone,
	setInvitationAccountTextStatus
} from '../../redux/invitationToPersonalAccount/invitationToPersonalAccount'
import { setGlobalPersonalDataValue } from '../../redux/globalPersonalData/globalPersonalData'

const InvitationToPersonalAccount = ({
	active,
	setActive,
	globalPersonalData
}) => {
	const dispatch = useDispatch()
	const regEmail = /\S+@\S+\.\S+/
	const regPhone = /^\+?[8] [-\(]?\d{3}\) ?-?\d{3}-?\d{2}-?\d{2}$/
	const { checkEmail, checkPhone, invitationAccountTextStatus } = useSelector(
		(state) => state.invitationToPersonalAccount
	)
	const accessToken = localStorage.getItem('accessToken')
	const [disabledButton, setDisabledButton] = useState(true)
	useEffect(() => {
		dispatch(setInvitationAccountTextStatus(null))
		dispatch(setCheckEmail(false))
		dispatch(setCheckPhone(false))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setDisabledButton(true)

		if (
			regEmail.test(globalPersonalData?.email) &&
			regPhone.test(globalPersonalData?.phone) &&
			(checkEmail || checkPhone)
		) {
			setDisabledButton(false)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		checkEmail,
		checkPhone,
		globalPersonalData?.phone,
		globalPersonalData?.email
	])

	const sendData = () => {
		dispatch(
			invitationAccount(globalPersonalData, checkPhone, checkEmail, accessToken)
		)
	}

	return (
		<Container>
			<ContainerContent>
				<Modal active={active} onClose={true} setActive={setActive}>
					<div className={s.invitationToPersonalAccount}>
						<h4>Выслать приглашение создать личный кабинет</h4>
						<div className={s.invitationToPersonalAccount__form}>
							<span>Почта</span>
							<div
								className={s.invitationToPersonalAccount__form__inputWrapper}
							>
								<Input
									value={
										globalPersonalData?.email ? globalPersonalData?.email : ''
									}
									name='email'
									validate={
										globalPersonalData?.email &&
										regEmail.test(globalPersonalData?.email)
									}
									onChange={(e) =>
										dispatch(
											setGlobalPersonalDataValue({
												name: e.target.name,
												value: e.target.value
											})
										)
									}
								/>
								<div
									className={
										s.invitationToPersonalAccount__form__inputWrapper__checkbox
									}
								>
									<Input
										type='checkbox'
										onChangeChecked={() => dispatch(setCheckEmail(!checkEmail))}
									/>
									<span>Отправить ссылку на создание ЛК</span>
								</div>
							</div>
						</div>
						<div className={s.invitationToPersonalAccount__form}>
							<span>Телефон</span>
							<div
								className={s.invitationToPersonalAccount__form__inputWrapper}
							>
								<Input
									name='phone'
									maxLength='17'
									validate={
										globalPersonalData?.phone &&
										regPhone.test(globalPersonalData?.phone)
									}
									value={
										globalPersonalData?.phone ? globalPersonalData?.phone : ''
									}
									onChange={(e) =>
										dispatch(
											setGlobalPersonalDataValue({
												name: e.target.name,
												value:
													+e.target.value.toString().length > 0 &&
													e.target.value.replace(
														/^(\d{1})(\d{3})(\d{3})(\d{2})(\d)+$/,
														'$1 ($2) $3-$4-$5'
													)
											})
										)
									}
								/>
								<div
									className={
										s.invitationToPersonalAccount__form__inputWrapper__checkbox
									}
								>
									<Input
										type='checkbox'
										onChangeChecked={() => dispatch(setCheckPhone(!checkPhone))}
									/>
									<span>Отправить ссылку на создание ЛК</span>
								</div>
							</div>
						</div>
						{invitationAccountTextStatus && (
							<div className={s.invitationToPersonalAccount__textStatus}>
								{invitationAccountTextStatus}
							</div>
						)}
						<div>
							<Button
								left={true}
								small={true}
								FCOnclick={sendData}
								disabled={disabledButton}
							>
								Отправить приглашение
							</Button>
						</div>
					</div>
				</Modal>
			</ContainerContent>
		</Container>
	)
}

export default InvitationToPersonalAccount
