import axios from 'axios'
import { setAreaValue, setFoundAddress } from '../redux/address/address'

const baseURL = process.env.REACT_APP_BASE_URL

export const address = {
	getAddress(addressName) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/address/rosreestr/api?query=${addressName}`
				)
				dispatch(setFoundAddress(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	getCadastrNumber(cadastrNumber) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/cad_numbers/rosreestr/api?query=${cadastrNumber}`
				)
				dispatch(setFoundAddress(response.data.objectData))
				dispatch(setAreaValue(response.data.parcelData.areaValue))
			} catch (error) {
				console.log(error)
			}
		}
	}
}
