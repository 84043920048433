import { createSlice } from '@reduxjs/toolkit'

const chatSlice = createSlice({
	name: 'chatSlice',
	initialState: {
		chatData: [],
		message: '',
		newMessages: null,
		lastMessages: null
	},
	reducers: {
		setChatData(state, action) {
			state.chatData = action.payload
		},
		setMessage(state, action) {
			state.message = action.payload
		},
		pushMessage(state, action) {
			state.chatData.push(action.payload)
		},
		setNewMessages(state, action) {
			state.newMessages = action.payload
		},
		setPusNewMessages(state, action) {
			state.newMessages.push(action.payload)
		},
		setLastMessages(state, action) {
			state.lastMessages = action.payload
		},
		setNewMessagesStatusSeen(state, action) {
			state.newMessages = state.newMessages.filter(
				(newMessage) => !action.payload.includes(newMessage.message_id)
			)
		},
		setPushLastMessages(state, action) {
			state.lastMessages = state.lastMessages.map((message) => {
				if (action.payload.chat_id === message.chat_id) {
					return action.payload
				}
				return message
			})
		}
	}
})

export default chatSlice.reducer

export const {
	setChatData,
	setMessage,
	pushMessage,
	setNewMessages,
	setNewMessagesStatusSeen,
	setLastMessages,
	setPushLastMessages,
	setPusNewMessages
} = chatSlice.actions
