import React, { useEffect, useState } from 'react'
import s from './task-list-sidebar.module.scss'
import Input from '../../input/input'
import arrowLeftIcon from '../../../common/images/chevron-left.svg'
import messageIcon from '../../../common/images/message.svg'
import newMessageIcon from '../../../common/images/newMessageIcon.svg'
import menuIcon from '../../../common/images/menu.svg'
import { Link } from 'react-router-dom'
import PaymentDuty from '../../paymentDuty/paymentDuty'
import docStatusFalse from '../../../common/images/clientDocStatusFalse.svg'
import statusesNull from '../../../common/images/deal/statusesFalse.svg'
import statusesSuccess from '../../../common/images/success.svg'
import { client } from '../../../api/client/client'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { MenuClient } from '../../menuClient/menu-client'
import { setTaskListClientData } from '../../../redux/client/client'
import logo from '../../../common/images/logoCompany.svg'

export const TaskListSideBar = ({
	onChat,
	activeChat,
	openBlock,
	setOpenBlock,
	taskList,
	selectedDeal,
	setSelectedDeal,
	taskListClientData,
	setOpenChatWindow,
	selectedAppeal,
	setSelectedAppeal
}) => {
	const accessToken = localStorage.getItem('accessToken')
	const dispatch = useDispatch()

	const { newMessages, lastMessages } = useSelector((state) => state.chat)
	const { userRole, userName } = useSelector((state) => state.login)
	const [width, setWidth] = useState(document?.documentElement?.clientWidth)
	const [newAppealsReverse, setNewAppealsReverse] = useState([])
	const { appealDealStatusWS } = useSelector((state) => state.appeals)
	const { wsState, wsConnect } = useSelector((state) => state.webSocket)
	const [openMenu, setOpenMenu] = useState(false)
	const [openWindowPaymentDuty, setOpenWindowPaymentDuty] = useState(false)

	const onResize = (e) => {
		setWidth(e.target.innerWidth)
	}
	useEffect(() => {
		if (!appealDealStatusWS) return
		taskListClientData?.active_appeal &&
			dispatch(
				setTaskListClientData({
					...taskListClientData,
					appeal_statuses: {
						docs_status: appealDealStatusWS?.status.general_docs_status,
						ecp_status: appealDealStatusWS?.status.general_ecp_status,
						payments_status:
							taskListClientData?.appeal_statuses.payments_status,
						personal_doc_status: appealDealStatusWS?.status.general_pd_status,
						ros_reestr_status:
							appealDealStatusWS?.status.general_rosreestr_status
					}
				})
			)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appealDealStatusWS])

	useEffect(() => {
		window.scrollTo(0, 0)
		window.addEventListener('resize', onResize)
		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [])

	useEffect(() => {
		if (selectedDeal) {
			setNewAppealsReverse([...selectedDeal?.appeals].reverse())
		}
	}, [selectedDeal])

	const handleSelectedDeal = (deal) => {
		localStorage.setItem('selectedDeal', JSON.stringify(deal))
		setSelectedDeal(deal)
	}

	const handleGetClientDataAppeal = (appeal) => {
		localStorage.setItem('appealId', appeal?.id)
		setSelectedAppeal(appeal)
		dispatch(client.getTaskListClientData(appeal?.id, accessToken))
	}

	useEffect(() => {
		const chatsIds = []
		selectedDeal?.chats?.forEach((chat) => {
			chatsIds.push(chat.id)
		})
		if (
			chatsIds?.length !== 0 &&
			wsConnect &&
			Array.isArray(selectedDeal?.chats)
		) {
			wsState &&
				wsState.send(
					JSON.stringify({
						event: 'last.messages',
						data: {
							chats_ids: chatsIds
						}
					})
				)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newMessages, selectedDeal?.chats])

	const changeDate = (date) => {
		const todayDate = new Date()
		const messageCreateAt = new Date(date)
		const yesterDay = new Date(todayDate)

		const correctWeekDay = [
			'в понедельник',
			'во вторник',
			'в среду',
			'в четверг',
			'в пятницу',
			'в субботу',
			'в воскресенье'
		]

		if (
			moment(todayDate).format('DD.MM.YYYY') ===
			moment(messageCreateAt).format('DD.MM.YYYY')
		) {
			return `в ${moment(messageCreateAt).format('HH:mm')}`
		}

		// если использовать перменную todayDate, метод setDate изменяет эту переменную
		if (
			messageCreateAt.toDateString() ===
			(yesterDay.setDate(yesterDay.getDate() - 1) && yesterDay.toDateString())
		) {
			return 'вчера'
		}

		if (
			messageCreateAt.getMonth() === todayDate.getMonth() &&
			todayDate.getDate() - messageCreateAt.getDate() < 7
		) {
			return correctWeekDay[messageCreateAt.getDay() - 1]
		}

		return messageCreateAt.toLocaleString('ru', {
			day: 'numeric',
			month: 'long'
		})
	}

	const personalDocStatus =
		taskListClientData?.appeal_statuses?.personal_doc_status === null
			? statusesNull
			: taskListClientData?.appeal_statuses?.personal_doc_status === false
			? docStatusFalse
			: taskListClientData?.appeal_statuses?.personal_doc_status === true &&
			  statusesSuccess

	const ecpStatus =
		taskListClientData?.appeal_statuses?.ecp_status === null
			? statusesNull
			: taskListClientData?.appeal_statuses?.ecp_status === false
			? docStatusFalse
			: taskListClientData?.appeal_statuses?.ecp_status === true &&
			  statusesSuccess

	const dealDocsStatus =
		taskListClientData?.appeal_statuses?.docs_status === null
			? statusesNull
			: taskListClientData?.appeal_statuses?.docs_status === false
			? docStatusFalse
			: taskListClientData?.appeal_statuses?.docs_status === true &&
			  statusesSuccess

	const paymentsStatus =
		taskListClientData?.appeal_statuses?.payments_status === null 
		|| (taskListClientData?.appeal_statuses?.payments_status === false && taskListClientData?.appeal_statuses?.ros_reestr_status !== true)
			? statusesNull
			: taskListClientData?.appeal_statuses?.payments_status === false && taskListClientData?.appeal_statuses?.ros_reestr_status === true
			? docStatusFalse
			: taskListClientData?.appeal_statuses?.payments_status === true &&
			  statusesSuccess

	const rosReestrStatus =
		taskListClientData?.appeal_statuses?.ros_reestr_status === null
			? statusesNull
			: taskListClientData?.appeal_statuses?.ros_reestr_status === false
			? docStatusFalse
			: taskListClientData?.appeal_statuses?.ros_reestr_status === true &&
			  statusesSuccess

	const appealStatuses =
		taskListClientData?.appeal_statuses?.personal_doc_status === false
		? '1/5 этап — Заполнение ПД'
		: taskListClientData?.appeal_statuses?.ecp_status === false
		? '2/5 этап — Выпуск ЭЦП'
		: taskListClientData?.appeal_statuses?.docs_status === false
		? '3/5 этап — Подписание документов по сделке'
		: taskListClientData?.appeal_statuses?.ros_reestr_status === false 
		? '4/5 этап — Регистрация в росреестре'
		: taskListClientData?.appeal_statuses?.payments_status === false
		&& '5/5 этап — Оплата Пошлины'


	const inputAtributesForSelectedDeal = {
		selectedValue: selectedDeal,
		setSelectedValue: handleSelectedDeal,
		dataList: taskList,
		select: true
	}
	const inputAtributesForSelectedAppeal = {
		selectedValue: selectedAppeal,
		setSelectedValue: handleGetClientDataAppeal,
		dataList: newAppealsReverse,
		select: true
	}

	return (
		<>
			<div className={s.taskListSideBar}>
				<div className={s.taskListSideBar__head}>
					<div className={s.taskListSideBar__head__logo}>
						<img src={logo}></img>
					</div>
					<p>
						Добро пожаловать,<br></br> {userName}
					</p>
					<Link to='/profileClient'>
						<p>Профиль</p>
					</Link>
				</div>
				<div className={s.taskListSideBar__headMobile}>
					<div className={s.taskListSideBar__headMobile__logo}>
						<div>
							<div>Уни</div>
							<div>строй</div>
						</div>
					</div>
					<div className={s.taskListSideBar__headMobile__messageAndMenuIcon}>
						{selectedDeal?.chats?.map((chat) => {
							const foundLastMessage = lastMessages?.find(
								(message) => message.chat_id === chat.id
							)
							return (
								<div
									onClick={() => {
										onChat(chat)
										setOpenChatWindow(true)
									}}
								>
									<img
										src={
											foundLastMessage?.sender_id !== chat?.client_id &&
											foundLastMessage?.status === 'MESSAGE_SENT'
												? newMessageIcon
												: messageIcon
										}
										alt=''
									/>
								</div>
							)
						})}
						<div onClick={() => setOpenMenu(true)}>
							<img src={menuIcon} alt='' />
						</div>
					</div>
				</div>

				{taskList?.length > 0 && (
					<div className={s.taskListSideBar__infoOfDeals}>
						<p>Сделка</p>
						<p>
							У вас{' '}
							{taskList?.length === 1
								? taskList?.length + ' активная сделка'
								: taskList?.length + ' активных сделки'}
						</p>
						<Input
							value={selectedDeal?.realty_address}
							{...(taskList?.length === 1
								? {}
								: { ...inputAtributesForSelectedDeal })}
							readOnly
							mobileHeaderText='Сделки'
						/>
					</div>
				)}

				<div className={s.taskListSideBar__appealToRosreestr}>
					{taskList?.length > 0 ? (
						<p>Обращения в Росреестр по этой сделке</p>
					) :(
						<p>Вам необходимо заполнить свои персональные данные и далее ознакомиться с договором</p>
					)}
					{newAppealsReverse?.map((appeal, index) => (
						<div
							key={appeal?.id}
							onClick={() => {
								handleGetClientDataAppeal(appeal, index)
								setOpenChatWindow(false)
								onChat(null)
							}}
							className={
								selectedAppeal?.id === appeal?.id && !activeChat
									? s.taskListSideBar__appealToRosreestr__appealActive
									: s.taskListSideBar__appealToRosreestr__notAppealActive
							}
						>
							{width >= 575 && <p>Обращение {index + 1}</p>}
						</div>
					))}
					{taskList?.length > 0 && (
						<div className={s.taskListSideBar__appealToRosreestr__mobileAppeals}>
							<Input
								value={`Обращение ${
									1 +
									newAppealsReverse?.findIndex(
										(index) => index?.id === selectedAppeal?.id
									)
								}`}
								{...(newAppealsReverse?.length === 1
									? {}
									: { ...inputAtributesForSelectedAppeal })}
								placeholderForSelect='Выберите обращение'
								readOnly
								mobileHeaderText='Обращения'
							/>
						</div>
					)}
				</div>
				<div className={s.taskListSideBar__correspondence}>
					<p>Переписка с менеджером</p>
					{selectedDeal?.chats?.map((chat) => {
						const foundLastMessage = lastMessages?.find(
							(message) => message.chat_id === chat.id
						)
						return (
							<div
								key={chat?.id}
								onClick={() => {
									onChat(chat)
									setOpenChatWindow(true)
								}}
								className={`${s.taskListSideBar__correspondence__chat} ${
									activeChat?.id === chat.id &&
									s.taskListSideBar__correspondence__chatOn
								}`}
							>
								<p>{chat?.employee_name}</p>
								{foundLastMessage && (
									<div>
										{foundLastMessage?.sender_id === chat.client_id &&
										(userRole === 'MA' || userRole === 'CL') ? (
											<p>
												{' '}
												Вы ответили {changeDate(foundLastMessage.created_at)}
											</p>
										) : (
											<p>
												{' '}
												Собеседник написал{' '}
												{changeDate(foundLastMessage.created_at)}
											</p>
										)}
										{userRole === 'CL' &&
										foundLastMessage.status === 'MESSAGE_SENT' ? (
											<span
												className={s.newMessages}
												style={
													activeChat?.id === chat.id
														? { backgroundColor: '#FAFAFF' }
														: chat?.client_id === foundLastMessage?.sender_id
														? { backgroundColor: ' rgba(31, 163, 74, 0.15)' }
														: { backgroundColor: '#1FA34A' }
												}
											/>
										) : (
											''
										)}
									</div>
								)}
								{!foundLastMessage && newMessages?.length === 0 && (
									<p>Нет сообщений</p>
								)}
							</div>
						)
					})}
				</div>
				{taskList?.length > 0 && (
				<div className={s.taskListSideBar__transactionRegistrData}>
					<div
						onClick={() => setOpenBlock('progress')}
						className={s.taskListSideBar__transactionRegistrData__infoField}
					>
						<div>
							<p>Прогресс</p>
							<p>{appealStatuses}</p>
						</div>
						<img
							src={arrowLeftIcon}
							style={
								openBlock !== 'progress'
									? null
									: { transform: 'rotate(-90deg)' }
							}
							alt=''
						/>
					</div>

					<div
						className={`${
							s.taskListSideBar__transactionRegistrData__progress
						} ${openBlock === 'progress' && s.active}`}
					>
						<p>Для регистрации сделки от вас потребуется</p>
						<div>
							<div>
								<img src={personalDocStatus} alt='' />
							</div>
							<Link to='/personalDataClient'>Данные личных документов</Link>
						</div>
						<div>
							<div>
								<img src={ecpStatus} alt='' />
							</div>
							<Link
								style={
									taskListClientData?.appeal_statuses?.ecp_status === null
										? { color: '#ADAAB4' }
										: { color: '#35393C' }
								}
								to='/electronicSignature'
							>
								Выпустить электронную подпись
							</Link>

							{taskListClientData?.appeal_statuses?.ecp_status === null && (
								<div className={s.statusNull}>
									<p></p>
									<p>Пока рано, доступ откроется позже</p>
								</div>
							)}
						</div>
						<div>
							<div>
								<img src={dealDocsStatus} alt='' />
							</div>
							<Link
								style={
									taskListClientData?.appeal_statuses?.docs_status === null
										? { color: '#ADAAB4' }
										: { color: '#35393C' }
								}
								to='/signingDocuments'
							>
								Подписать документы по сделке
							</Link>
							{taskListClientData?.appeal_statuses?.docs_status === null && (
								<div className={s.statusNull}>
									<p></p>
									<p>Пока рано, доступ откроется позже</p>
								</div>
							)}
						</div>
						<div>
							<div>
								<img src={rosReestrStatus} alt='' />
							</div>
							<p
								style={
									taskListClientData?.appeal_statuses?.ros_reestr_status ===
									null
										? { color: '#ADAAB4' }
										: { color: '#35393C' }
								}
							>
								Подписать заявление в Росреестр
							</p>
							{taskListClientData?.appeal_statuses?.ros_reestr_status ===
								null && (
								<div className={s.statusNull}>
									<p></p>
									<p>Пока рано, доступ откроется позже</p>
								</div>
							)}
						</div>
						<div>
							<div>
								<img src={paymentsStatus} alt='' />
							</div>
							<p
								style={
									taskListClientData?.appeal_statuses?.ros_reestr_status !== true
										? { color: '#ADAAB4' }
										: { color: '#35393C' }
								}
								onClick={() => setOpenWindowPaymentDuty(true)}
							>
								Оплатить пошлину
							</p>
							{taskListClientData?.appeal_statuses?.ros_reestr_status !==
								true && (
								<div className={s.statusNull}>
									<p></p>
									<p>Пока рано, доступ откроется позже</p>
								</div>
							)}
						</div>
					</div>
				</div>
				)}
			</div>
			<MenuClient openMenu={openMenu} setOpenMenu={setOpenMenu} />
		</>
	)
}
