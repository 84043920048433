import axios from 'axios'
import {
	setDocuments,
	setOpenedDocument,
	setTaskList,
	setTextStatus,
	setOrganizationOffices,
	setPaymentsData,
	setPay,
	setStatusLoadCheck,
	setClientData,
	setContractData,
	setClientDataRelate,
	setTaskListClientData,
} from '../../redux/client/client'
import { setIsLoading } from '../../redux/loading/loading'
import { setGlobalPersonalData } from '../../redux/globalPersonalData/globalPersonalData'

const baseURL = process.env.REACT_APP_BASE_URL

export const client = {
	getTaskList(accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/client/deals`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				dispatch(setTaskList(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	getTaskListClientData(appeal_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/client/appeal/${appeal_id}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setTaskListClientData(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	getClientData(accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/client_data`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setClientData(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	getContractData(accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/client_agreement_document`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setContractData(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	getClientDataRelate(accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/client_relate_data`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setClientDataRelate(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	getClientIdentityCredentials(individual_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/client_data/${individual_id}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setGlobalPersonalData(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	confirmContract(document_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(`${baseURL}/client_agreement_document/${document_id}/confirm`,
				{
					"confirm": true, 
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				dispatch(setContractData(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	unconfirmContract(document_id, message, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(`${baseURL}/client_agreement_document/${document_id}/confirm`,
				{
					"confirm": false,
					"message": message
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				dispatch(setContractData(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	editClientData(
		individual_id,
		globalPersonalData,
		formData,
		passportPhoto1,
		passportPhoto2,
		snilsPhoto,
		accessToken,
		saveStatus
	) {
		return async (dispatch) => {
			try {
				dispatch(setIsLoading(true));
				const response = await axios.put(
					`${baseURL}/edit_client_data/${individual_id}`,
					globalPersonalData,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				try {
					if (passportPhoto1 || passportPhoto2 !== null) {
						const formDataPhoto = await axios.post(
							`${baseURL}/clients/${individual_id}/upload_scan_identification_card?save_status=${saveStatus}`,
							formData,
							{
								headers: {
									Authorization: `Bearer ${accessToken}`
								}
							}
						)
					}
					if (snilsPhoto !== null) {
						const formDataPhotoSnils = await axios.post(
							`${baseURL}/clients/${individual_id}/upload_scan_snils?save_status=${saveStatus}`,
							formData,
							{
								headers: {
									Authorization: `Bearer ${accessToken}`
								}
							}
						)
					}
			
					const responseData = await axios.get(
						`${baseURL}/client_data/${individual_id}`,
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					
					dispatch(setGlobalPersonalData(responseData.data))

				} catch (error) {
					if ( passportPhoto1 || passportPhoto2 || snilsPhoto !== null) {
						dispatch(setTextStatus(`Фото не загружены. ${error.response.data.detail}`))
					}
				}
			} catch (error) {
				console.log(error)
				dispatch(setTextStatus(
					`Ошибка. ${error.response.data.detail.client_data_status 
					  || error.response.data.detail.power_attorney ||
					  error.response.data?.detail.surname || 
					  error.response.data?.detail.patronymics ||  
					  error.response.data?.detail.inn || 
					  error.response.data?.detail.reg_address ||
					  error.response.data?.detail.id ||
					  error.response.data?.detail.role_id ||
					  error.response.data?.detail.role ||
					  error.response.data?.detail.full_name ||
					  error.response.data?.detail.short_name ||
					  error.response.data?.detail.ogrn ||
					  error.response.data?.detail.name ||
					  error.response.data?.detail.birthday ||
					  error.response.data?.detail.place_birth ||
					  error.response.data?.detail.series_number ||
					  error.response.data?.detail.date_issue ||
					  error.response.data?.detail.issued_by_whom ||
					  error.response.data?.detail.subsection_code ||
					  error.response.data?.detail.snils_number ||
					  error.response.data?.detail.photo_page ||
					  error.response.data?.detail
					}`,
					false
				  )
				)
			} finally {
				dispatch(setIsLoading(false));
				dispatch(setTextStatus('Данные сохранены'));
			}
		}
	},

	confirmClientData(
		individual_id,
		globalPersonalData,
		accessToken,
	) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/edit_client_data/${individual_id}/confirm`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setTextStatus('Подтверждено'))
			} catch (error) {
				console.log(error)
				dispatch(setTextStatus(
					`Ошибка. ${
					  error.response.data?.detail
					}`,
					false
				  )
				)
			}
		}
	},

	deleteImage(
		individual_id,
		type_field,
		passportImg,
		accessToken,
		deletePhotoClient
	) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/client/${individual_id}/delete_scans/${type_field}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(
					client.getClientIdentityCredentials(
						individual_id,
						accessToken
					)
				)
			} catch (error) {
				console.log(error)
			}
		}
	},
	getOrganizationOffices(appeal_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/client/appeals/${appeal_id}/organization-offices`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setOrganizationOffices(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	getDocuments(apeealId, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/client/appeals/${apeealId}/documents`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(setDocuments(response.data.documents))
			} catch (error) {
				console.log(error)
			}
		}
	},

	getAppealClient(payments_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/payments/${payments_id}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				dispatch(setPaymentsData(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	getPay(payments_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(`${baseURL}/pay/${payments_id}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				dispatch(setPay(response.data.url_pay))
			} catch (error) {
				console.log(error)
			}
		}
	},

	getDocumentById(apeealId, documentId, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(
					`${baseURL}/client/appeals/${apeealId}/documents/${documentId}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)

				dispatch(setOpenedDocument(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
	uploadDocument(apeealId, documentId, file, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/client/appeals/${apeealId}/documents/${documentId}/file`,
					file,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setOpenedDocument(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},

	paymentsReceiptUpload(accessToken, paymentId, file) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/payments-request/${paymentId}`,
					file,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
							'Content-Type': 'multipart/form-data'
						}
					}
				)
				dispatch(setStatusLoadCheck('Чек загружен'))
				setTimeout(() => {
					dispatch(setStatusLoadCheck(null))
				}, 3000)
			} catch (error) {
				dispatch(setStatusLoadCheck('Что-то пошло не так'))
				console.log(error)
			}
		}
	}
}
