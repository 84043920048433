import React, { useEffect } from 'react'
import s from './personalDataContent.module.scss'
import PersonalDataPassport from '../../../blocks/personalDataPassport/personalDataPassport'
import ReadContract from '../../../blocks/readContract/readContract'
import { useDispatch, useSelector } from 'react-redux'
import { client } from '../../../api/client/client'
import { useNavigate } from 'react-router-dom'
import SigningDocumentsContent from '../../../blocks/SigningDocumentsContent/signingDocumentsContent'
import { setGlobalPersonalData } from '../../../redux/globalPersonalData/globalPersonalData'
import {
	setSnilsPhoto,
	setPassportPhoto1,
	setPassportPhoto2,
} from '../../../redux/client/client'

const PersonalDataContent = ({ save, setSave, width }) => {
	const { documentName } = useSelector((state) => state.client)
	const accessToken = localStorage.getItem('accessToken')
	const { globalPersonalData } = useSelector(
		(state) => state.globalPersonalData
	)
	const appealId = localStorage.getItem('appealId')
	const individual_id = localStorage.getItem('individual_id')
	const reprsentative_id = localStorage.getItem('representative_id')
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const saveFC = () => {
		setSave(true)
	}

	useEffect(() => {
		dispatch(client.getTaskListClientData(appealId, accessToken))
	}, [])

	useEffect(() => {
		setSave(false)
		if (documentName === 'СНИЛС') {

		} else {
			dispatch(
				client.getClientIdentityCredentials(
					individual_id ? individual_id : reprsentative_id,
					accessToken
				)
			)
		}
	}, [documentName])

	const backFC = () => {
		dispatch(setGlobalPersonalData(null))
		dispatch(setSnilsPhoto(null))
		dispatch(setPassportPhoto1(null))
		dispatch(setPassportPhoto2(null))
		navigate('/taskListClient')
	}

	return (
		<div
			className={s.personalDataContent}
			style={
				documentName === 'Доверенность' && width > 600
					? { padding: '40px 100px 0 100px', height: '100vh' }
					: documentName === 'Доверенность' && width < 600
					? { height: '100vh' }
					: null
			}
		>
			{width < 600 && (
				<div className={s.personalDataContent__menuMobile}>
					<div
						className={s.personalDataContent__menuMobile__backMenu}
						onClick={() => backFC()}
					>
						<span>&#8592;</span>
					</div>
					<div className={s.personalDataContent__menuMobile__info}>
						<h4>{documentName}</h4>
						{documentName === 'Удостоверение личности и СНИЛС' && (
							<span>
								{globalPersonalData?.client_data_status === true ? 'Данные подтверждены' : 'Ждем данные и фото'}
							</span>
						)}
					</div>
					<div className={s.personalDataContent__menuMobile__status}>
						{globalPersonalData?.client_data_status === true ? (
							<span style={{ background: '#1FA34A' }}>&#10003;</span>
						) : (
							<span style={{ background: '#FF4343' }}>&#215;</span>
						)}
					</div>
				</div>
			)}
			{documentName === 'Удостоверение личности и СНИЛС' && (
				<PersonalDataPassport
					saveFC={saveFC}
					save={save}
					setSave={setSave}
					width={width}
				/>
			)}
			{documentName === 'Договор для ознакомления' && (
				<ReadContract
					saveFC={saveFC}
					save={save}
					setSave={setSave}
					width={width}
				/>
			)}
			{documentName === 'Доверенность' && (
				<SigningDocumentsContent documentName={documentName} />
			)}
		</div>
	)
}

export default PersonalDataContent
