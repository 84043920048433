import { combineReducers, configureStore } from '@reduxjs/toolkit'
import loginSlice from '../login/login'
import passwordRecoverySlice from '../passwordRecovery/passwordRecovery'
import changePasswordSlice from '../changePassword/changePassword'
import invitationToPersonalAccount from '../invitationToPersonalAccount/invitationToPersonalAccount'
import chatSlice from '../chat/chat'
import deals from '../deals/deals'
import appeals from '../appeals/appeals'
import manager from '../manager/manager'
import realty from '../realty/realty'
import documents from '../documents/documents'
import payments from '../payments/payments'
import address from '../address/address'
import client from '../client/client'
import globalPersonalData from '../globalPersonalData/globalPersonalData'
import admin from '../admin/admin'
import user from '../user/user'
import support from '../support/support'
import webSocket from '../webSocket/webSocket'
import duty from '../duty/duty'
import ecp from '../ecp'
import loading from '../loading/loading'

const rootReducer = combineReducers({
	globalPersonalData,
	login: loginSlice,
	passwordRecovery: passwordRecoverySlice,
	changePassword: changePasswordSlice,
	invitationToPersonalAccount,
	chat: chatSlice,
	deals,
	appeals,
	manager,
	realty,
	payments,
	documents,
	address,
	client,
	admin,
	user,
	support,
	webSocket,
	duty,
	ecp,
	loading
})

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	  })
})
