import React, { useRef, useState } from 'react'
import s from './login.module.scss'
import Input from '../../components/input/input'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Button from '../../components/button/button'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
	setAssetToken,
	setEmail,
	setErrorLoginText,
	setPassword,
	setPhone,
	setUid64,
} from '../../redux/login/login'
import { useMatch } from 'react-router-dom'
import { registerClient } from '../../api/registered'
import { loginAPI } from '../../api/login/login'
import PasswordRecovery from '../passwordRecovery/passwordRecovery'
import logoCompany from '../../common/images/logoCompany.svg'
import PassportRecovery from '../../components/passportRecovery/passportRecovery'
import axios from 'axios'

const Login = () => {
	const {
		phone,
		email,
		password,
		errorPassword,
		uid64,
		login,
		accessToken,
		errorTextLogin,
		userRole
	} = useSelector((state) => state.login)
	const dispatch = useDispatch()
	const [passwordRecoveryStatus, setPasswordRecoveryStatus] = useState(false)
	const [restorePassword, setRestorePassword] = useState(false)
	const [loginDisabledButton, setLoginDisabledButton] = useState(true)
	const [isPasswordValid, setPasswordValid] = useState(true)
	const [isPhone, setIsPhone] = useState(false)
	const inputRef = useRef(null)
	const navigate = useNavigate()
	const regEmail = /\S+@\S+\.\S+/
	const regPhone = /^\+?[8] [-\(]?\d{3}\) ?-?\d{3}-?\d{2}-?\d{2}$/
	const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{8,16}/

	const match = useMatch('/login/:uid64/:token')
	const baseURL = process.env.REACT_APP_BASE_URL

	useEffect(() => {
		if (!match) return

		dispatch(setUid64(match.params.uid64))
		dispatch(setAssetToken(match.params.token))
	}, [])

	useEffect(() => {
		if (match)
		axios
		  .get(`${baseURL}/verify/${match.params.uid64}/${match.params.token}`)
		  .then((response) => {
			console.log(response.status)
		  })
		  .catch(error => {
            if(error.response?.status === 401 || 400 || 422){
				navigate('/login')
            }
			else{
				console.log(error)
			}
        });
	}, []);

	useEffect(() => {
		if (errorTextLogin === 'Ссылка недействительна') {
			dispatch(setErrorLoginText('Перенаправление'))
			navigate('/login')
			dispatch(
				loginAPI.authClient(
					!phone ? null : phone,
					email === '' ? null : email,
					password
				)
			)
			//dispatch(setErrorLoginText(null))
			return
		}

		if (errorTextLogin) {
			setTimeout(() => {
				dispatch(setErrorLoginText(null))
			}, 5000)
		}
	}, [dispatch, email, errorTextLogin, navigate, password, phone])

	useEffect(() => {
		setLoginDisabledButton(
			(regEmail.test(email) || 
			regPhone.test(phone)) && 
			regPassword.test(password)
		)
	}, [email, phone, password])

	const setDataClient = () => {
		if (match) {
			dispatch(registerClient(phone, email, password, uid64, accessToken))
		} else {
			dispatch(
				loginAPI.authClient(
					!phone ? null : phone,
					email === '' ? null : email,
					password
				)
			)
		}
	}

	const buttonClick = () => {
		if (inputRef.current.type === 'password') {
			inputRef.current.type = 'text'
		} else {
			inputRef.current.type = 'password'
		}
	}

	if (login && (userRole === 'MA' || userRole === 'AD')) {
		return <Navigate to='/userRoles' replace />
	}
	
	if (login && userRole === 'CL') {
		return <Navigate to='/taskListClient' replace />
	}

	function handleChange(evt) {
		if (regPassword.test(password)) {
			setPasswordValid(true)
		} else {
			setPasswordValid(false)
		}
	}

	return (
		<div className={s.loginClient}>
			{match ? (
				<div className={s.loginClient__info}>
					<img src={logoCompany} alt='' />
					<h3>Создание кабинета</h3>
					<p>
						Вам прислали приглашение создать личный кабинет. В нем вы найдёте
						все документы по сделке, заполните паспортные данные и выпустите
						электронную подпись.
					</p>
					<p> Всё оформление сделки дистанционно и безопасно.</p>
				</div>
			) : (
				<div className={s.loginClient__infoImg}>
					<img src={logoCompany} alt='' />
				</div>
			)}

			<div className={s.loginClient__formWrapper}>
				{restorePassword ? (
					<PassportRecovery setRestorePassword={setRestorePassword} />
				) : (
					<div>
						<h3>Добро пожаловать!</h3>
						<h4>Войдите с{' '}
							<span 
								className={isPhone === true ? s.span_contact : s.span_contact__active}
								onClick={() =>
									setIsPhone(false)
								}
							>
								почтой
							</span>
							/
							<span 
								className={isPhone === true ? s.span_contact__active : s.span_contact}
								onClick={() =>
									setIsPhone(true)
								}
							>
								телефоном
							</span>
						</h4>
						{isPhone === true &&
							<div className={s.loginClient__formWrapper__tel}>
								<span>Телефон</span>
								<Input
									id="phone"
									maxLength='17'
									value={phone ? phone : ''}
									onChange={(e) =>
										dispatch(
											setPhone(
												e.target.value === ' '
													? ''
													: +e.target.value.toString().length > 0 &&
															e.target.value.replace(
																/^(\d{1})(\d{3})(\d{3})(\d{2})(\d)+$/,
																'$1 ($2) $3-$4-$5'
															)
											)
										)
									}
								/>
							</div>
						}
						{isPhone === false &&
							<div className={s.loginClient__formWrapper__email}>
								<span>Почта</span>
								<Input
									id="post"
									value={email}
									onChange={(e) => dispatch(setEmail(e.target.value))}
								/>
							</div>
						}
						<div className={s.loginClient__formWrapper__password}>
							<span>Пароль</span>
							<Input
								nameRef={inputRef}
								buttonIcon
								buttonClick={buttonClick}
								type='password'
								value={password}
								onChange={(e) =>
									dispatch(
										setPassword(
											!e.target.value ||
												e.target.value[0] === ' ' ||
												e.target.value[0] === '\n'
												? ''
												: e.target.value
										)
									)
								}
								onKeyUp={handleChange}
								validate={true}
							/>
							{match && (
								<span 
									className={isPasswordValid === true ? s.loginClient__formWrapper__password__passwordSpanInactive : s.loginClient__formWrapper__password__passwordSpan}
								>
									Пароль не соответствует требованиям
								</span>
							)}
							{errorPassword ? (
								<div
									className={
										s.loginClient__formWrapper__password__errorPassword
									}
								>
									<span>Неправильно</span>
									<span>
										<Link to='/passwordRecovery'>Отправить ссылку</Link> на
										восстановление пароля
									</span>
								</div>
							) : (
								!match ? (
									<div className={s.loginClient__formWrapper__resetPassword}>
										<span onClick={() => setRestorePassword(true)}>
											Восстановить пароль...
										</span>

									</div>
								) : (
									<div className={s.loginClient__formWrapper__resetPassword}></div>
								)
							)}
						</div>
						{match && (
							<div className={s.textPassword}>
								<p>- Используйте только латинские буквы (a–z, A–Z)</p>
								<p>
									- Пароль должен содержать хотя бы один специальный символ:{' '}
									<br />! @ # $ % ^ & * ( ) _ + = ; : , . / ?| ` ~
								</p>
								<p>
									- Ваш пароль должен содержать от 8 до 16 символов <br />с
									использованием цифр,строчных и заглавных символов.
								</p>
							</div>
						)}

						{errorTextLogin && (
							<span className={s.textError}>{errorTextLogin}</span>
						)}
						<Button 
							className={s.loginClient__formWrapper__button} 
							FCOnclick={setDataClient} 
							// disabled={!loginDisabledButton}
						>
							{match ? 'Создать кабинет' : 'Войти в кабинет'}
						</Button>
					</div>
				)}
			</div>
			{passwordRecoveryStatus && (
				<div className={s.loginClient__password}>
					<PasswordRecovery
						passwordRecoveryStatus={passwordRecoveryStatus}
						setPasswordRecoveryStatus={setPasswordRecoveryStatus}
					/>
				</div>
			)}
		</div>
	)
}

export default Login
