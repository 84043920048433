import React, {useCallback, useEffect, useRef, useState} from 'react'
import Input from '../../components/input/input'
import s from './legalEntityData.module.scss'
import {useDispatch, useSelector} from 'react-redux'
import {address} from '../../api/address'
import {setFoundAddress} from '../../redux/address/address'
import {setLegalEntityData} from '../../redux/globalPersonalData/globalPersonalData'
import {debounce} from 'lodash'
import {manager} from '../../api/manager/manager'
import {setRolesPowerId} from '../../redux/manager/manager'
import DownloadPowerOfAttorneyLegal from '../../components/DownloadPowerOfAttorneyLegal/DownloadPowerOfAttorneyLegal'
import { documents } from '../../api/document/document'
import { setFilePowerOfAttorney } from '../../redux/documents/documents'

const LegalEntityData = ({globalPersonalData, dataCorrect, filePowerOfAttorney, handleChange }) => {
  const dispatch = useDispatch()
  const accessToken = localStorage.getItem('accessToken')
  const appeal_id = localStorage.getItem('appealId')
  const kppRegular = /\d{4}[\dA-Z][\dA-Z]\d{3}/
  const {foundAddress} = useSelector((state) => state.address)
  const {userRole} = useSelector((state) => state.login)
  const {rolesPowersList} = useSelector((state) => state.manager)

  const [rolesPowersItem, setRolesPowersItem] = useState(null)

  useEffect(() => {
    setRolesPowersItem(null)
    dispatch(manager.getRolesPowers(appeal_id, accessToken))
  }, [])

  useEffect(() => {
    if(!rolesPowersItem) return
    dispatch(setRolesPowerId(rolesPowersItem.id))
  }, [rolesPowersItem])

  useEffect(()=>{
    if(!rolesPowersList || !globalPersonalData) return
    rolesPowersList &&
    rolesPowersList.forEach(
      (item) => item.id == globalPersonalData?.powers_id && setRolesPowersItem(item)
    )
  },[globalPersonalData.powers_id,rolesPowersList])

  const addressInputRef = useRef()

  const [addressInputIsFocus, setAddressInputIsFocus] = useState()

  const handleFindAddress = useCallback(
    debounce((addressName) => {
      dispatch(address.getAddress(addressName))
    }, 350),
    []
  )

  const deleteFilePowerOfAttorney = () => {
		if (filePowerOfAttorney?.title) {
			dispatch(
				documents.documentDeletePowerAttorneyLegal(
					globalPersonalData?.id,
					accessToken
				)
			)
		} else {
			dispatch(setFilePowerOfAttorney(null))
		}
	}

  return (
    <>
      <div className={s.legalEntityData}>
        <h4>Данные юрлица</h4>
        <div>
          <span>Полное название</span>
          <Input
            value={
              globalPersonalData?.full_name ? globalPersonalData?.full_name : ''
            }
            dataCorrect={dataCorrect}
            disabled={dataCorrect}
            name='full_name'
            onChange={(e) =>
              dispatch(
                setLegalEntityData({name: e.target.name, value: e.target.value})
              )
            }
          />
        </div>
        <div>
          <span>Краткое название</span>
          <Input
            value={
              globalPersonalData?.short_name ? globalPersonalData?.short_name : ''
            }
            dataCorrect={dataCorrect}
            disabled={dataCorrect}
            name='short_name'
            onChange={(e) =>
              dispatch(
                setLegalEntityData({name: e.target.name, value: e.target.value})
              )
            }
          />
        </div>
        <div>
          <span>ОГРН</span>
          <Input
            value={globalPersonalData?.ogrn ? globalPersonalData?.ogrn : ''}
            dataCorrect={dataCorrect}
            disabled={dataCorrect}
            maxLength='13'
            validate={
              globalPersonalData?.ogrn &&
              globalPersonalData?.ogrn.toString().length === 13
            }
            name='ogrn'
            onChange={(e) =>
              dispatch(
                setLegalEntityData({
                  name: e.target.name,
                  value: e.target.value
                })
              )
            }
          />
        </div>
        <div>
          <span>ИНН</span>
          <Input
            value={globalPersonalData?.inn ? globalPersonalData?.inn : ''}
            maxLength='10'
            validate={
              globalPersonalData?.inn &&
              globalPersonalData?.inn.toString().length === 10
            }
            dataCorrect={dataCorrect}
            disabled={dataCorrect}
            name='inn'
            onChange={(e) =>
              dispatch(
                setLegalEntityData({
                  name: e.target.name,
                  value: e.target.value
                })
              )
            }
          />
        </div>
        <div>
          <span>КПП</span>
          <Input
            value={globalPersonalData?.kpp ? globalPersonalData?.kpp : ''}
            dataCorrect={dataCorrect}
            disabled={dataCorrect}
            maxLength='9'
            validate={
              globalPersonalData?.kpp && kppRegular.test(globalPersonalData?.kpp)
            }
            name='kpp'
            onChange={(e) =>
              dispatch(
                setLegalEntityData({
                  name: e.target.name,
                  value: e.target.value
                })
              )
            }
          />
        </div>
        <div>
          <span>Адрес</span>
          <Input
            value={globalPersonalData?.address ? globalPersonalData?.address : ''}
            dataCorrect={dataCorrect}
            disabled={dataCorrect}
            textarea
            name='address'
            onChange={(e) => {
              dispatch(
                setLegalEntityData({name: e.target.name, value: e.target.value})
              )
              handleFindAddress(e.target.value)
            }}
            nameRef={addressInputRef}
            onFocus={() => setAddressInputIsFocus(true)}
          />
          <>
            {addressInputIsFocus &&
            foundAddress &&
            foundAddress?.length !== 0 && (
              <div className={s.foundAddres}>
                {foundAddress.map((address) => {
                  return (
                    <div
                      onClick={() => {
                        dispatch(
                          setLegalEntityData({
                            name: 'address',
                            value: address.full_name
                          })
                        )
                        dispatch(setFoundAddress(null))
                      }}
                    >
                      <span>{address.full_name}</span>
                    </div>
                  )
                })}
              </div>
            )}
          </>
        </div>
        <div>
          <span>Почта</span>
          <Input
            value={globalPersonalData?.email ? globalPersonalData?.email : ''}
            dataCorrect={dataCorrect}
            disabled={dataCorrect}
            name='email'
            onChange={(e) =>
              dispatch(
                setLegalEntityData({name: e.target.name, value: e.target.value})
              )
            }
          />
        </div>
        <div>
          <span>Телефон</span>
          <Input
            value={globalPersonalData?.phone ? globalPersonalData?.phone : ''}
            dataCorrect={dataCorrect}
            name='phone'
            maxLength='17'
            onChange={(e) =>
              dispatch(
                setLegalEntityData({
                  name: e.target.name,
                  value:
                    +e.target.value.toString().length > 0 &&
                    e.target.value.replace(
                      /^(\d{1})(\d{3})(\d{3})(\d{2})(\d)+$/,
                      '$1 ($2) $3-$4-$5'
                    )
                })
              )
            }
          />
        </div>
      </div>
        <DownloadPowerOfAttorneyLegal
					filePowerOfAttorney={filePowerOfAttorney}
					deleteFilePowerOfAttorney={deleteFilePowerOfAttorney}
					handleChange={handleChange}
				/>
    </>


  )
}

export default LegalEntityData
