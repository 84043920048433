import { createSlice } from '@reduxjs/toolkit'

const deals = createSlice({
	name: 'deals',
	initialState: {
		dealsData: null,
		deal: null,
		pageName: 'deals',
		deleteDeals: false,
		newAppealsDocumentWS: null,
		newAppealsPaymentsWS: null,
		documentIdWS: null,
		paymentIdWs: null,
		searchDeals: '',
		resultSearchDeals: null,
		searchObject: '',
		message: '',
		dealId: null,
		foundRealty: null,
		realtyData: {
			seller: '',
			type_appeal_id: 0,
			agreement_number: '',
			agreement_date: '',
			object: {
				cadastral_number: '',
				description: '',
				square: '',
				type_id: '',
				address: ''
			}
		},
		statementGenerate: false,
		deleteDocumentsId: [],
		arrDocumentsIds: [],
		deleteAppealIds: [],
		arrAppealIds: [],
		hideRequestRosreestr: false,
	},
	reducers: {
		setDeals(state, action) {
			state.dealsData = action.payload
		},

		setSearchDeals(state, action) {
			state.searchDeals = action.payload
		},

		setDealId(state, action) {
			localStorage.setItem('dealId', `${action.payload}`)
		},

		setDeal(state, action) {
			state.deal = action.payload
		},

		setRealtyData(state, action) {
			state.object[action.payload.name] = action.payload.value
		},

		setFoundRealty(state, action) {
			state.foundRealty = action.payload
		},

		setDeleteDeal(state, action) {
			state.deal = state.deal.filter((deal) => deal?.id !== action.payload)
		},

		setAppealDeal(state, action) {
			state.deal.appeals = state.deal.appeals.filter(
				(appeal) => appeal?.id !== action.payload
			)
		},

		setAchiveDeal(state) {
			state.deal = { ...state.deal, archive: !state.deal.archive }
		},

		setEditNotes(state, action) {
			state.deal.notes = action.payload
		},

		setNewAppealsDocumentWS(state, action) {
			state.newAppealsDocumentWS = action.payload
		},

		setNewAppealsPaymentsWS(state, action) {
			state.newAppealsPaymentsWS = action.payload
		},

		setDocumentIdWS(state, action) {
			state.documentIdWS = action.payload
		},

		setPaymentIdWS(state, action) {
			state.paymentIdWs = action.payload
		},

		setDeleteDeals(state, action) {
			state.deleteDeals = action.payload
		},

		setPageName(state, action) {
			state.pageName = action.payload
		},

		setStatementGenerate(state, action) {
			state.statementGenerate = action.payload
		},

		setDeleteDocumentsId(state, action) {
			state.deleteDocumentsId.push(action.payload)
		},

		setArrDocumentsId(state, action) {
			state.arrDocumentsIds = action.payload
		},

		setArrDocumentsIdsSplice(state, action) {
			state.arrDocumentsIds.splice(action.payload, 1)
		},

		setArrDocumentsIdsPush(state, action) {
			state.arrDocumentsIds.unshift(action.payload)
		},

		setDeleteAppealIds(state, action) {
			state.deleteAppealIds.push(action.payload)
		},

		setAppealsId(state, action) {
			state.arrAppealIds = action.payload
		},

		setAppealIdsSplice(state, action) {
			state.arrAppealIds.splice(action.payload, 1)
		},

		setAppealIdsPush(state, action) {
			state.arrAppealIds.push(action.payload)
		},

		setHideRequestRosreestr(state, action) {
			state.hideRequestRosreestr = action.payload
		},
	}
})

export default deals.reducer

export const {
	setDeals,
	setDealId,
	setDeal,
	setFoundRealty,
	setAchiveDeal,
	setEditNotes,
	setDeleteDeal,
	setAppealDeal,
	setNewAppealsDocumentWS,
	setNewAppealsPaymentsWS,
	setDocumentIdWS,
	setPaymentIdWS,
	setDeleteDeals,
	setPageName,
	setStatementGenerate,
	setArrDocumentsIdsSplice,
	setArrDocumentsIdsPush,
	setDeleteDocumentsId,
	setArrDocumentsId,
	setDeleteAppealIds,
	setAppealsId,
	setAppealIdsSplice,
	setAppealIdsPush,
	setHideRequestRosreestr,
} = deals.actions
