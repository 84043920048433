import axios from 'axios'
import {
	setPasswordRecovery,
	setPasswordRecoveryTextStatus
} from '../../redux/passwordRecovery/passwordRecovery'
import { setChangePasswordTextStatus } from '../../redux/changePassword/changePassword'

const baseURL = process.env.REACT_APP_BASE_URL

export const resetPassword = {
	client(phone, email, send_phone, send_email) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}send-reset-password-client`,
					{
						phone,
						email,
						send_phone,
						send_email
					}
				)
			} catch (error) {
				console.log(error)
			}
		}
	},

	employee(email) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/send-reset-password-employee`,
					{
						email
					}
				)
				dispatch(setPasswordRecoveryTextStatus('Пароль восстановлен'))

				setTimeout(() => {
					dispatch(setPasswordRecoveryTextStatus(null))
				}, 3000)
			} catch (error) {
				dispatch(
					setPasswordRecoveryTextStatus(
						error.response.data.detail
							? error.response.data.detail
							: 'Что-то пошло не так'
					)
				)

				setTimeout(() => {
					dispatch(setPasswordRecoveryTextStatus(null))
				}, 3000)
			}
		}
	},

	changePassword(old_password, new_password, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/change-password`,
					{
						old_password,
						new_password
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				dispatch(setChangePasswordTextStatus('Пароль изменен'))
			} catch (error) {
				dispatch(
					setChangePasswordTextStatus(
						error.response.data?.detail
							? error.response.data?.detail
							: 'Что-то пошло не так'
					)
				)
				console.log(error)
			}
		}
	},

	restorePassword(password, uid64, token) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}restore-password/${uid64}/${token}`,
					{
						password
					}
				)
			} catch (error) {
				console.log(error)
			}
		}
	}
}
