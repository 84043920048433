import { createSlice } from '@reduxjs/toolkit'

const changePasswordSlice = createSlice({
	name: 'changePasswordSlice',
	initialState: {
		oldPassword: null,
		newPassword: null,
		lengthEight: false,
		sequenceNumbers: false,
		errorOldPassword: false,
		buttonDisabled: true,
		passwordChanged: false,
		changePasswordTextStatus: null,
		newPassportRecovery: null
	},
	reducers: {
		setOldPassword(state, action) {
			state.oldPassword = action.payload
		},
		setNewPassword(state, action) {
			state.newPassword = action.payload
		},
		setLengthEight(state, action) {
			state.lengthEight = action.payload
		},
		setSequenceNumbers(state, action) {
			state.sequenceNumbers = action.payload
		},
		setErrorOldPassword(state, action) {
			state.errorOldPassword = action.payload
		},
		setButtonDisabled(state, action) {
			state.buttonDisabled = action.payload
		},
		setPasswordChanged(state, action) {
			state.passwordChanged = action.payload
		},
		setChangePasswordTextStatus(state, action) {
			state.changePasswordTextStatus = action.payload
		},
		setNewPassportRecovery(state, action) {
			state.newPassportRecovery = action.payload
		}
	}
})

export default changePasswordSlice.reducer

export const {
	setOldPassword,
	setNewPassword,
	setLengthEight,
	setSequenceNumbers,
	setButtonDisabled,
	setChangePasswordTextStatus,
	setNewPassportRecovery
} = changePasswordSlice.actions
