import React, {useState, useEffect} from 'react'
import s from './button.module.scss'

const Button = ({
	children,
	disabled,
	left,
	FCOnclick,
	small,
	link,
	filters,
	buttonStyles,
	buttonClassname,
	statementGenerate
}) => {
	const [buttonStyle, setButtonStyle] = useState({});

	useEffect(() => {
		if(buttonStyles) {
			setButtonStyle({...buttonStyle, ...buttonStyles })
		}
		if(left) {
			setButtonStyle({...buttonStyle, textAlign: 'left' })
		}

		if(small) {
			setButtonStyle({...buttonStyle, padding: '10px 15px' })
		}
	}, [])

	return (
		<>
			{link ? (
				<a
					className={`${disabled ? s.buttonDisabled : s.buttonEnabled} ${buttonClassname}`}
					href={link}
					target='_blank'
					style={buttonStyle} 
					rel="noreferrer"
				>
					{children}
				</a>
			) : (
				<button
					className={`${disabled ? s.buttonDisabled : s.buttonEnabled} ${buttonClassname}`}
					disabled={disabled}
					style={buttonStyle}
					onClick={() => FCOnclick()}
				>
					{children}
					{filters}
				</button>
			)}
		</>
	)
}
export default Button
