import React, { useCallback, useEffect, useState } from 'react'
import Input from '../../input/input'
import s from './personal-data.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { address } from '../../../api/address'
import { setFoundAddress } from '../../../redux/address/address'
import { useRef } from 'react'
import { setGlobalPersonalDataValue } from '../../../redux/globalPersonalData/globalPersonalData'
import { debounce } from 'lodash'

export const PersonalData = ({ dataCorrect }) => {
	const dispatch = useDispatch()
	const { foundAddress } = useSelector((state) => state.address)
	const { globalPersonalData } = useSelector(
		(state) => state.globalPersonalData
	)
	const userRole = localStorage.getItem('userRole')
	const addressInputRef = useRef()
	const [addressInputIsFocus, setAddressInputIsFocus] = useState()

	useEffect(() => {
		return () => dispatch(setFoundAddress(null))
	}, [])

	const handleFindAddress = useCallback(
		debounce((addressName) => {
			dispatch(address.getAddress(addressName))
		}, 350),
		[]
	)

	return (
		<div className={s.personalData}>
			<div className={s.personalData__header}>
				<h4>Персональные данные</h4>
			</div>
			<div className={s.pesonalData__content}>
				<div>
					<span>Фамилия</span>
					<Input
						dataCorrect={dataCorrect}
						disabled={dataCorrect}
						name='surname'
						value={
							globalPersonalData?.surname ? globalPersonalData?.surname : ''
						}
						onChange={(e) =>
							dispatch(
								setGlobalPersonalDataValue({
									name: e.target.name,
									value: e.target.value
								})
							)
						}
					/>
				</div>
				<div>
					<span>Имя</span>
					<Input
						dataCorrect={dataCorrect}
						disabled={dataCorrect}
						name='name'
						value={globalPersonalData?.name ? globalPersonalData?.name : ''}
						onChange={(e) =>
							dispatch(
								setGlobalPersonalDataValue({
									name: e.target.name,
									value: e.target.value
								})
							)
						}
					/>
				</div>
				<div>
					<span>Отчество</span>
					<Input
						dataCorrect={dataCorrect}
						disabled={dataCorrect}
						value={
							globalPersonalData?.patronymics
								? globalPersonalData?.patronymics
								: ''
						}
						name='patronymics'
						onChange={(e) =>
							dispatch(
								setGlobalPersonalDataValue({
									name: e.target.name,
									value: e.target.value
								})
							)
						}
					/>
				</div>
				<div>
					<span>Пол</span>
					<div>
						<button
							onClick={
								dataCorrect && userRole !== 'AD'
									? null
									: () =>
											dispatch(
												setGlobalPersonalDataValue({
													name: 'gender',
													value: 'MALE'
												})
											)
							}
							className={`${s.buttonGenderM} ${
								globalPersonalData?.gender === 'MALE' && s.buttonGenderActive
							}`}
						>
							Мужской
						</button>
						<button
							onClick={
								dataCorrect && userRole !== 'AD'
									? null
									: () =>
											dispatch(
												setGlobalPersonalDataValue({
													name: 'gender',
													value: 'FEMALE'
												})
											)
							}
							className={`${s.buttonGenderF} ${
								globalPersonalData?.gender === 'FEMALE' && s.buttonGenderActive
							}`}
						>
							Женский
						</button>
					</div>
				</div>
				<div>
					<span>Дата рождения</span>
					<Input
						dataCorrect={dataCorrect}
						disabled={dataCorrect}
						name='birthday'
						value={
							globalPersonalData?.birthday ? globalPersonalData?.birthday : ''
						}
						type='date'
						onChange={(e) =>
							dispatch(
								setGlobalPersonalDataValue({
									name: e.target.name,
									value: e.target.value
								})
							)
						}
					/>
				</div>
				<div>
					<span>Место рождения</span>
					<Input
						dataCorrect={dataCorrect}
						disabled={dataCorrect}
						value={
							globalPersonalData?.place_birth
								? globalPersonalData?.place_birth
								: ''
						}
						name='place_birth'
						onChange={(e) =>
							dispatch(
								setGlobalPersonalDataValue({
									name: e.target.name,
									value: e.target.value
								})
							)
						}
					/>
				</div>
				<div className={s.pesonalData__content__address}>
					<span>Адрес регистрации</span>
					<Input
						dataCorrect={dataCorrect}
						disabled={dataCorrect}
						value={
							globalPersonalData?.reg_address
								? globalPersonalData?.reg_address
								: ''
						}
						name='reg_address'
						textarea
						onChange={(e) => {
							dispatch(
								setGlobalPersonalDataValue({
									name: e.target.name,
									value: e.target.value
								})
							)
							handleFindAddress(e.target.value)
						}}
						nameRef={addressInputRef}
						onFocus={() => setAddressInputIsFocus(true)}
					/>
					<p></p>
					{addressInputIsFocus &&
						foundAddress &&
						foundAddress?.length !== 0 && (
							<div className={s.foundAddres}>
								{foundAddress.map((address) => {
									return (
										<div
											onClick={() => {
												dispatch(
													setGlobalPersonalDataValue({
														name: 'reg_address',
														value: address.full_name
													})
												)
												dispatch(setFoundAddress(null))
											}}
										>
											<span>{address.full_name}</span>
										</div>
									)
								})}
							</div>
						)}
				</div>
				<div>
					<span>Гражданство</span>
					<Input
						dataCorrect={dataCorrect}
						disabled={true}
						value={
							globalPersonalData?.citizenship
								? globalPersonalData?.citizenship
								: 'РФ'
						}
						name='citizenship'
						readOnly
					/>
				</div>
				<div>
					<span>ИНН</span>
					<Input
						dataCorrect={dataCorrect}
						disabled={dataCorrect}
						maxLength="12"
						value={
							globalPersonalData?.inn
								? globalPersonalData?.inn
								: ''
						}
						name='inn'
						onChange={(e) =>
							dispatch(
								setGlobalPersonalDataValue({
									name: e.target.name,
									value: e.target.value
								})
							)
						}
					/>
				</div>
			</div>
		</div>
	)
}
