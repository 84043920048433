import { createSlice } from '@reduxjs/toolkit'

const supportSlice = createSlice({
	name: 'support',
	initialState: {
		chatData: [],
		message: '',
		newMessages: null,
		lastMessages: null,
		file: null
	},
	reducers: {
		setChatData(state, action) {
			state.chatData = action.payload
		},
		pushMessage(state, action) {
			state.chatData.push(action.payload)
		},
		setFile(state, action) {
			state.file = action.payload
		}
	}
})

export default supportSlice.reducer

export const { setChatData, pushMessage, setFile } = supportSlice.actions
