import axios from 'axios'
import {
  setAccStatus,
  setDataCorrect,
  setListCopiedDocuments,
  setListCopiedParticipant,
  setLoadingApp,
  setNewUser,
  setNotificationText,
  setRepresentativeStatus,
  setRole,
  setRoles,
  setSearchList,
  setSearchResult,
	setRolesPowersList,
  setButtonState
} from '../../redux/manager/manager'
import { setEcpStatus } from '../../redux/ecp'
import {setGlobalPersonalData} from '../../redux/globalPersonalData/globalPersonalData'
import {alertStatusText} from '../../components/alertStatusText/alertStatusText'

const baseURL = process.env.REACT_APP_BASE_URL

export const manager = {

  getDataClient(partId, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.get(
          `${baseURL}/employee/participants/${partId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setGlobalPersonalData(response.data))
        dispatch(setEcpStatus(response.data.ecp))
        dispatch(setRepresentativeStatus(response.data.representative_exists))
        dispatch(setLoadingApp(true))
      } catch (error) {
        dispatch(setNotificationText(error.response.data.detail))
      }
    }
  },

  getClientList(search, type, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.get(
          `${baseURL}/employee/exist-user/`,
          {
            params: { 
              type_user: type, 
              search: search
            },
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setLoadingApp(true))
        dispatch(setSearchList(response.data))
        response.data.length > 0 && dispatch(setSearchResult(true))
        response.data.length === 0 && dispatch(setNotificationText(alertStatusText("Участник не найден!", true)))
        response.data.length === 0 && dispatch(setSearchResult(false))
      } catch (error) {
        dispatch(setNotificationText(alertStatusText("Ошибка поиска!", true)))
        dispatch(setSearchResult(false))
      }
    }
  },

  getClient(user_id, type, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.get(
          `${baseURL}/employee/user/${user_id}`,
          {
            params: { 
              type_user: type, 
            },
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setGlobalPersonalData(response.data))
        dispatch(setButtonState(true))
        dispatch(setLoadingApp(true))
      } catch (error) {
        dispatch(setNotificationText(alertStatusText("Ошибка вставки данных!", true)))
      }
    }
  },

  getRoles(appeal_id, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.get(
          `${baseURL}/appeals/${appeal_id}/get_roles`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setRoles(response.data.roles))
      } catch (error) {
        console.log(error)
      }
    }
  },

  setClient(
    appeal_id,
    type,
    id,
    role_id,
    copy,
    accessToken,
  ) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/appeals/${appeal_id}/create_client_exist`,
          {
            type_user: type,
            user_id: id,
            role_id: role_id,
            copy_data: copy
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setGlobalPersonalData(response.data)) 
        dispatch(setEcpStatus(response.data.ecp))
        dispatch(setRepresentativeStatus(response.data.representative_exists))
        dispatch(setSearchResult(false))
        dispatch(setButtonState(false))
        dispatch(
          setNotificationText(alertStatusText('Пользователь добавлен!', true))
        )
        window.location.assign(`${window.location.href}/${response.data.participant_id}`)
      } catch (error) {
        console.log(error)
        setNotificationText(alertStatusText("Ошибка добавления пользователя!"))
        dispatch(setSearchResult(true))
      }
    }
  },

  setRepresentative(
    part_id,
    id,
    role_id,
    accessToken,
  ) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/appeals/participants/${part_id}/create_representative_exist`,
          {
            user_id: id,
            role_id: role_id,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setGlobalPersonalData(response.data)) 
        dispatch(setEcpStatus(response.data.ecp))
        dispatch(setRepresentativeStatus(response.data.representative_exists))
        dispatch(setSearchResult(false))
        dispatch(setButtonState(false))
        dispatch(
          setNotificationText(alertStatusText('Представитель добавлен!', true))
        )
      } catch (error) {
        console.log(error)
        setNotificationText(alertStatusText("Ошибка добавления представителя!"))
        dispatch(setSearchResult(true))
      }
    }
  },

  createLegalEntity(
    appeal_id,
    globalPersonalData,
    roleId,
    accessToken,
    dataCorrect = false,
		rolesPowerId
  ) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/appeals/${appeal_id}/create_client_legal_entity`,
          {
            type: 'LEGAL_ENTITY',
            id: appeal_id,
            role_id: roleId,
            full_name: globalPersonalData.full_name,
            short_name: globalPersonalData.short_name,
            ogrn: globalPersonalData.ogrn,
            inn: globalPersonalData.inn,
            kpp: globalPersonalData.kpp,
            address: globalPersonalData.address,
            email: globalPersonalData.email,
            phone: globalPersonalData.phone,
            denominator: globalPersonalData.denominator,
            numerator: globalPersonalData.numerator,
            surname: globalPersonalData.surname,
            name: globalPersonalData.name,
            patronymics: globalPersonalData.patronymics,
            gender: globalPersonalData.gender,
            citizenship: 'РФ',
            position: globalPersonalData.position,
            powers_id: rolesPowerId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(
          setNotificationText(alertStatusText('Пользователь сохранен!', true))
        )
        window.location.assign(`${window.location.href}/${response.data.participant_id}`)
      } catch (error) {
        console.log(error)
        setNotificationText(alertStatusText( error.response.data?.detail.surname || 
          error.response.data?.detail.patronymics ||  
          error.response.data?.detail.inn || 
          error.response.data?.detail.reg_address ||
          error.response.data?.detail.id ||
          error.response.data?.detail.role_id ||
          error.response.data?.detail.role ||
          error.response.data?.detail.full_name ||
          error.response.data?.detail.short_name ||
          error.response.data?.detail.ogrn ||
          error.response.data?.detail.name ||
          error.response.data?.detail.birthday ||
          error.response.data?.detail.place_birth ||
          error.response.data?.detail.series_number ||
          error.response.data?.detail.date_issue ||
          error.response.data?.detail.issued_by_whom ||
          error.response.data?.detail.subsection_code ||
          error.response.data?.detail.snils_number ||
          error.response.data?.detail
          , false))
      }
    }
  },

  async sendDocuments(accessToken, documents, appeal_id, individual_id) {
    try {
      const formDataPhoto = await axios.post(
        `${baseURL}/appeals/${appeal_id}/clients/${individual_id}/upload_scans`,
        documents,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
    } catch (error) {
      console.log(error)
    }
	},

  async sendNewUserDocuments(accessToken, documents, appeal_id, client_data_id, participant_id) {
    try {
      const formDataPhoto = await axios.post(
        `${baseURL}/appeals/${appeal_id}/clients/${client_data_id}/upload_scans`,
        documents,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      window.location.assign(`${window.location.href}/${participant_id}`);
    } catch (error) {
      console.log(error)
    }
	},

  createClientIndividual(
    statusPhoto,
    globalPersonalData,
    roleId,
    accessToken,
    formData,
    dataCorrect,
    partId,
    appeal_id
  ) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/appeals/${appeal_id}/create_client_individual`,
          {
            type: 'INDIVIDUAL',
            id: appeal_id,
            role_id: roleId,
            name: globalPersonalData.name,
            surname: globalPersonalData.surname,
            patronymics: globalPersonalData.patronymics,
            gender: globalPersonalData.gender,
            birthday: globalPersonalData.birthday,
            place_birth: globalPersonalData.place_birth,
            reg_address: globalPersonalData.reg_address,
            citizenship: 'РФ',
            phone: globalPersonalData.phone,
            email: globalPersonalData.email,
            denominator: globalPersonalData.denominator,
            position: globalPersonalData.position,
            numerator: globalPersonalData.numerator,
            identification_card: {
              title: 'Паспорт',
              series_number:
              globalPersonalData.identification_card.series_number,
              date_issue: globalPersonalData.identification_card.date_issue,
              issued_by_whom:
              globalPersonalData.identification_card.issued_by_whom,
              subsection_code:
              globalPersonalData.identification_card.subsection_code
            },
            snils: {
              title: 'snils',
              number: globalPersonalData.snils?.number,
            },
            inn: globalPersonalData.inn
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        
        this.sendNewUserDocuments(accessToken, formData, appeal_id, response.data.client_data_id, response.data.participant_id)
        dispatch(
          setNotificationText(alertStatusText('Пользователь сохранен!', true))
        )
        dispatch(setRole(null))
        dispatch(setNewUser(false))
      } catch (error) {
          dispatch(setNotificationText(alertStatusText( error.response.data?.detail.surname ||
            error.response.data?.detail.birthday ||
            error.response.data?.detail.patronymics ||  
            error.response.data?.detail.inn || 
            error.response.data?.detail.reg_address ||
            error.response.data?.detail.id ||
            error.response.data?.detail.role_id ||
            error.response.data?.detail.role ||
            error.response.data?.detail.full_name ||
            error.response.data?.detail.short_name ||
            error.response.data?.detail.ogrn ||
            error.response.data?.detail.name ||
            error.response.data?.detail.place_birth ||
            error.response.data?.detail.series_number ||
            error.response.data?.detail.date_issue ||
            error.response.data?.detail.issued_by_whom ||
            error.response.data?.detail.subsection_code ||
            error.response.data?.detail.snils_number ||
            error.response.data?.detail
            , false)))
      }
    }
  },

  getRepresentative(partId, accessToken) {
    return async (dispatch) => {
      dispatch(setLoadingApp(false))
      try {
        const response = await axios.get(
          `${baseURL}/employee/participants/${partId}/representative`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setLoadingApp(true))
        dispatch(setGlobalPersonalData(response.data))
      } catch (error) {
        console.log(error)
      }
    }
  },

  createRepresentative(
    statusPhoto,
    data,
    partId,
    rolesPowerId,
    accessToken,
    dataCorrect,
    appeal_id,
    formData
  ) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/appeals/${appeal_id}/participant/${partId}/create_representative`,
          {
            type: 'REPRESENTATIVE',
            name: data.name,
            surname: data.surname,
            patronymics: data.patronymics,
            gender: data.gender,
            birthday: data.birthday,
            place_birth: data.place_birth,
            reg_address: data.reg_address,
            citizenship: 'РФ',
            phone: data.phone,
            email: data.email,
            denominator: data.denominator,
            numerator: data.numerator,
            powers_id: rolesPowerId,
            position: data.position,
            identification_card: {
              title: 'Паспорт',
              series_number: data.identification_card.series_number,
              date_issue: data.identification_card.date_issue,
              issued_by_whom: data.identification_card.issued_by_whom,
              subsection_code: data.identification_card.subsection_code
            },
            snils: {
              title: 'snils',
              number: data.snils.number,
            },
            inn: data.inn
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setNewUser(false))
        this.sendDocuments(accessToken, formData, appeal_id, response.data.client_data_id);
        dispatch(
          setNotificationText(alertStatusText('Пользователь сохранен!', true))
        )
      } catch (error) {
          dispatch(setNotificationText(alertStatusText( error.response.data?.detail.surname ||
            error.response.data?.detail.birthday ||
            error.response.data?.detail.patronymics ||  
            error.response.data?.detail.inn || 
            error.response.data?.detail.reg_address ||
            error.response.data?.detail.id ||
            error.response.data?.detail.role_id ||
            error.response.data?.detail.role ||
            error.response.data?.detail.full_name ||
            error.response.data?.detail.short_name ||
            error.response.data?.detail.ogrn ||
            error.response.data?.detail.name ||
            error.response.data?.detail.place_birth ||
            error.response.data?.detail.series_number ||
            error.response.data?.detail.date_issue ||
            error.response.data?.detail.issued_by_whom ||
            error.response.data?.detail.subsection_code ||
            error.response.data?.detail.snils_number ||
            error.response.data?.detail
            , false)))
      }
    }
  },

  editRepresentative(
    statusPhoto,
    partId,
    rolesPowerId,
    data,
    accessToken,
    formData,
    appeal_id,
    dataCorrect
  ) {
    return async (dispatch) => {
      try {
        const response = await axios.put(
          `${baseURL}/employee/edit_client_representative/${partId}`,
          {
            type: 'REPRESENTATIVE',
            id: partId,
            user_auth_id: data.user_auth_id,
            citizenship: data.citizenship,
            name: data.name,
            surname: data.surname,
            patronymics: data.patronymics,
            gender: data.gender,
            birthday: data.birthday,
            place_birth: data.place_birth,
            reg_address: data.reg_address,
            phone: data.phone,
            email: data.email,
            denominator: data.denominator,
            numerator: data.numerator,
            powers_id: rolesPowerId,
            position: data.position,
            identification_card: {
              title: data.identification_card.title,
              series_number: data.identification_card.series_number,
              date_issue: data.identification_card.date_issue,
              issued_by_whom: data.identification_card.issued_by_whom,
              subsection_code: data.identification_card.subsection_code
            },
            snils: {
              title: data.snils.title,
              number: data.snils.number,
            },
            inn: data.inn
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )

        this.sendDocuments(accessToken, formData, appeal_id, response.data.client_data_id);
        
        dispatch(
          setNotificationText(alertStatusText('Пользователь сохранен!', true))
        )
      } catch (error) {
          dispatch(
            setNotificationText(alertStatusText( error.response.data?.detail.surname || 
              error.response.data?.detail.patronymics ||  
              error.response.data?.detail.inn || 
              error.response.data?.detail.reg_address ||
              error.response.data?.detail.id ||
              error.response.data?.detail.role_id ||
              error.response.data?.detail.role ||
              error.response.data?.detail.full_name ||
              error.response.data?.detail.short_name ||
              error.response.data?.detail.ogrn ||
              error.response.data?.detail.name ||
              error.response.data?.detail.birthday ||
              error.response.data?.detail.place_birth ||
              error.response.data?.detail.series_number ||
              error.response.data?.detail.date_issue ||
              error.response.data?.detail.issued_by_whom ||
              error.response.data?.detail.subsection_code ||
              error.response.data?.detail.snils_number ||
              error.response.data?.detail
              , false))
          )
      }
    }
  },

  editClientIndividual(
    // statusPhoto,
    partId,
    globalPersonalData,
    roleId,
    accessToken,
    formData,
    appeal_id,
    dataCorrect
  ) {
    return async (dispatch) => {
      try {
        const response = await axios.put(
          `${baseURL}/employee/edit_client_individual/${partId}`,
          {
            role: roleId,
            type: 'INDIVIDUAL',
            id: partId,
            role_id: roleId,
            user_auth_id: globalPersonalData.user_auth_id,
            citizenship: globalPersonalData.citizenship,
            name: globalPersonalData.name,
            surname: globalPersonalData.surname,
            patronymics: globalPersonalData.patronymics,
            gender: globalPersonalData.gender,
            birthday: globalPersonalData.birthday,
            place_birth: globalPersonalData.place_birth,
            reg_address: globalPersonalData.reg_address,
            phone: globalPersonalData.phone,
            email: globalPersonalData.email,
            denominator: globalPersonalData.denominator,
            numerator: globalPersonalData.numerator,
            identification_card: {
              title: globalPersonalData.identification_card.title,
              series_number: globalPersonalData.identification_card.series_number,
              date_issue: globalPersonalData.identification_card.date_issue,
              issued_by_whom: globalPersonalData.identification_card.issued_by_whom,
              subsection_code: globalPersonalData.identification_card.subsection_code
            },
            snils: {
              title: globalPersonalData.snils.title,
              number: globalPersonalData.snils.number,
            },
            inn: globalPersonalData.inn
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
          this.sendDocuments(accessToken, formData, appeal_id, response.data.client_data_id);

          const responseData = await axios.get(
            `${baseURL}/employee/participants/${partId}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            }
          )
          dispatch(setGlobalPersonalData(responseData.data))
          dispatch(
            setNotificationText(alertStatusText('Пользователь сохранен!', true))
          )
      } catch (error) {
          dispatch(setNotificationText(alertStatusText(
            error.response.data?.detail.surname ||
            error.response.data?.detail.birthday ||
            error.response.data?.detail.patronymics ||  
            error.response.data?.detail.inn || 
            error.response.data?.detail.reg_address ||
            error.response.data?.detail.id ||
            error.response.data?.detail.role_id ||
            error.response.data?.detail.role ||
            error.response.data?.detail.full_name ||
            error.response.data?.detail.short_name ||
            error.response.data?.detail.ogrn ||
            error.response.data?.detail.name ||
            error.response.data?.detail.place_birth ||
            error.response.data?.detail.series_number ||
            error.response.data?.detail.date_issue ||
            error.response.data?.detail.issued_by_whom ||
            error.response.data?.detail.subsection_code ||
            error.response.data?.detail.snils_number ||
            error.response.data?.detail
          , false)))
      }
    }
  },

  editLegalEntity(
    partId,
    globalPersonalData,
    roleId,
    accessToken,
    dataCorrect,
		rolesPowerId
  ) {
    return async (dispatch) => {			
      try {
        const response = await axios.put(
          `${baseURL}/employee/edit_client_legal_entity/${partId}`,
          {
            id: partId,
            role_id: roleId,
            type: 'LEGAL_ENTITY',
            role: roleId,
            full_name: globalPersonalData.full_name,
            short_name: globalPersonalData.short_name,
            ogrn: globalPersonalData.ogrn,
            inn: globalPersonalData.inn,
            kpp: globalPersonalData.kpp,
            address: globalPersonalData.address,
            email: globalPersonalData.email,
            phone: globalPersonalData.phone,
            denominator: globalPersonalData.denominator,
            numerator: globalPersonalData.numerator,
            surname: globalPersonalData.surname,
            name: globalPersonalData.name,
            patronymics: globalPersonalData.patronymics,
            gender: globalPersonalData.gender,
            citizenship: 'РФ',
            position: globalPersonalData.position,
            powers_id: rolesPowerId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(
          setNotificationText(alertStatusText('Пользователь сохранен!', true))
        )
        dispatch(setGlobalPersonalData(response.data))
      } catch (error) {
        dispatch(
          setNotificationText(alertStatusText( error.response.data?.detail.surname || 
            error.response.data?.detail.patronymics ||  
            error.response.data?.detail.inn || 
            error.response.data?.detail.reg_address ||
            error.response.data?.detail.id ||
            error.response.data?.detail.role_id ||
            error.response.data?.detail.role ||
            error.response.data?.detail.full_name ||
            error.response.data?.detail.short_name ||
            error.response.data?.detail.ogrn
            , false))
        )
        
      }
    }
  },

  deleteLegalEntity(appeal_id, part_id, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/appeals/${appeal_id}/delete_client_legal_entity/${part_id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(
          setNotificationText(alertStatusText('Пользователь удален', true))
        )
      } catch (error) {
        console.log(error)
        error.response.data.detail
          ? dispatch(
            setNotificationText(
              alertStatusText(error.response.data.detail, false)
            )
          )
          : dispatch(
            setNotificationText(alertStatusText('Что-то пошло не так', false))
          )
      }
    }
  },

  deleteRepresentative(appeal_id, part_id, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/appeals/${appeal_id}/participants/${part_id}/delete_representative`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )

        dispatch(
          setNotificationText(alertStatusText('Пользователь удален', true))
        )
      } catch (error) {
        dispatch(
          setNotificationText(
            alertStatusText(error?.response?.data?.detail, false)
          )
        )
        console.log(error)
      }
    }
  },

  deleteClientIndividual(appeal_id, part_id, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/appeals/${appeal_id}/delete_client_individual/${part_id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(
          setNotificationText(alertStatusText('Пользователь удален', true))
        )
      } catch (error) {
        dispatch(
          setNotificationText(
            alertStatusText(error?.response?.data?.detail, false)
          )
        )
        console.log(error)
      }
    }
  },

  getAccStatus(phone, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.get(
          `${baseURL}/participants/acc_status?phone=${phone}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setAccStatus(response.data.account_success))
      } catch (error) {
        console.log(error)
      }
    }
  },

  deleteImage(
    individual_id,
    type_field,
    partId,
    appeal_id,
    headerTypeData,
    accessToken
  ) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/appeals/${appeal_id}/client/${individual_id}/delete_scans/${type_field}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )

        if (headerTypeData === 'participant') {
          dispatch(manager.getDataClient(partId, accessToken))
        }
        if (headerTypeData === 'representative') {
          dispatch(manager.getRepresentative(Number(partId), accessToken))
        }
        /*  dispatch(setDeleteImgStatus(true))*/
      } catch (error) {
        console.log(error)
      }
    }
  },

  getListCopiedParticipant(deal_id, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.get(
          `${baseURL}/deals/${deal_id}/participants_previous_appeal`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setListCopiedParticipant(response.data))
      } catch (error) {
        console.log(error)
      }
    }
  },

  copyParticipant(deal_id, part_id, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/deals/${deal_id}/copy_participant/${part_id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
      } catch (error) {
        console.log(error)
      }
    }
  },

  getListCopiedDocuments(deal_id, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.get(
          `${baseURL}/deals/${deal_id}/documents_previous_appeal`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setListCopiedDocuments(response.data))
      } catch (error) {
        console.log(error)
      }
    }
  },

  copyDocument(deal_id, doc_id, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.post(
          `${baseURL}/deals/${deal_id}/copy_document/${doc_id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
      } catch (error) {
        console.log(error)
      }
    }
  },

  confirmDataParticipant(representative, partId, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.put(
          `${baseURL}/employee/participants/${partId}/confirm`,
          {
            representative
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
        dispatch(setDataCorrect(setDataCorrect))
        dispatch(setNotificationText(alertStatusText('Сохранено', true)))
      } catch (error) {
        dispatch(
          setNotificationText(
            alertStatusText(
              `Ошибка. ${error.response.data.detail.client_data_status 
                || error.response.data.detail.power_attorney ||
                error.response.data?.detail.surname || 
                error.response.data?.detail.patronymics ||  
                error.response.data?.detail.inn || 
                error.response.data?.detail.reg_address ||
                error.response.data?.detail.id ||
                error.response.data?.detail.role_id ||
                error.response.data?.detail.role ||
                error.response.data?.detail.full_name ||
                error.response.data?.detail.short_name ||
                error.response.data?.detail.ogrn ||
                error.response.data?.detail.name ||
                error.response.data?.detail.birthday ||
                error.response.data?.detail.place_birth ||
                error.response.data?.detail.series_number ||
                error.response.data?.detail.date_issue ||
                error.response.data?.detail.issued_by_whom ||
                error.response.data?.detail.subsection_code ||
                error.response.data?.detail.snils_number ||
                error.response.data?.detail.photo_page ||
                error.response.data?.detail[0].msg ||
                error.response.data?.detail
              }`,
              false
            )
          )
        )
      }
    }
  },

  changeParticipantRole(accessToken, partId, role_id) {
    return async (dispatch) => {
      try {
        const response = await axios.put(
          `${baseURL}/employee/edit/${partId}/role/${role_id}/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )
      } catch (error) {
        dispatch(
          setNotificationText(
            alertStatusText(
              `Ошибка. ${error.response.data.detail.client_data_status 
                || error.response.data.detail.power_attorney ||
                error.response.data?.detail.surname || 
                error.response.data?.detail.patronymics ||  
                error.response.data?.detail.inn || 
                error.response.data?.detail.reg_address ||
                error.response.data?.detail.id ||
                error.response.data?.detail.role_id ||
                error.response.data?.detail.role ||
                error.response.data?.detail.full_name ||
                error.response.data?.detail.short_name ||
                error.response.data?.detail.ogrn ||
                error.response.data?.detail.name ||
                error.response.data?.detail.birthday ||
                error.response.data?.detail.place_birth ||
                error.response.data?.detail.series_number ||
                error.response.data?.detail.date_issue ||
                error.response.data?.detail.issued_by_whom ||
                error.response.data?.detail.subsection_code ||
                error.response.data?.detail.snils_number ||
                error.response.data?.detail.photo_page ||
                error.response.data?.detail[0].msg ||
                error.response.data?.detail
              }`,
              false
            )
          )
        )
      }
    }
  },

  getRolesPowers(appeal_id, accessToken) {
    return async (dispatch) => {
      try {
        const response = await axios.get(
          `${baseURL}/authority-basis`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        )				
        dispatch(setRolesPowersList(response.data))
      } catch (error) {
        console.log(error)
      }
    }
  },
}
