import { createSlice } from '@reduxjs/toolkit'

const admin = createSlice({
	name: 'admin',
	initialState: {
		employees: null,
		organizations: null,
		openedOrganization: null,
		adminTextStatus: null,
		listEgrn: null,
		choiceEgrn: null,
		packageDownloadLink: null,
		formAPackage: false,
		showDownloadData: false,
		file: null
	},
	reducers: {
		setEmployees(state, action) {
			state.employees = action.payload
		},
		setAddEmployee(state, action) {
			state.employees.push(action.payload)
		},
		setDeletedEmployee(state, action) {
			state.employees = state.employees.filter(
				(employee) => employee?.id !== action.payload
			)
		},
		setOrganizations(state, action) {
			state.organizations = action.payload
		},
		setOpenedOrganization(state, action) {
			state.openedOrganization = action.payload
		},
		setAdminTextStatus(state, action) {
			state.adminTextStatus = action.payload
		},
		setListEgrn(state, action) {
			state.listEgrn = action.payload
		},
		setWSListEgrn(state, action) {
			const newEgrnItemEl = state.listEgrn?.find(item => item.id === action.payload.egrn_extract_id);
			const newEgrnItemIndex = state.listEgrn?.indexOf(newEgrnItemEl)
			if(newEgrnItemIndex) {
				state.listEgrn[newEgrnItemIndex] = {
					...state.listEgrn[newEgrnItemIndex],
					cadastral_number: action.payload.cadastral_number,
					status_label: action.payload.status_label,
					status: action.payload.status
				}
			}
		},
		setExtract(state, action) {
			state.extract = action.payload
		},
		setFormAPackage(state, action) {
			state.formAPackage = action.payload
		},
		setShowDownloadData(state, action) {
			state.showDownloadData = action.payload
		},
		setPackageDownloadLink(state, action) {
			state.packageDownloadLink = action.payload
		},
		setFile(state, action) {
			state.file = action.payload
		}
	}
})

export default admin.reducer

export const {
	setEmployees,
	setDeletedEmployee,
	setOrganizations,
	setOpenedOrganization,
	setAddEmployee,
	setAdminTextStatus,
	setListEgrn,
	setWSListEgrn,
	setExtract,
	setFormAPackage,
	setShowDownloadData,
	setPackageDownloadLink,
	setFile
} = admin.actions
