import React from 'react'
import s from './dropdown.module.scss'

const Dropdown = ({ data, disabled }) => {
	return (
		<>
			{data && (
				<select name='select' disabled={disabled} className={s.dropdown}>
					{data?.map((item) => (
						<option key={item} value='item'>
							{item}
						</option>
					))}
				</select>
			)}
		</>
	)
}

export default Dropdown
