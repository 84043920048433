import { createSlice } from '@reduxjs/toolkit'

const appeals = createSlice({
	name: 'appeals',
	initialState: {
		appealDeal: null,
		appealAtorneyList: null,
		appealDealStatusWS: null,
		typeAppeals: null,
		filteresDate: null
	},
	reducers: {
		settypeAppeals(state, action) {
			state.typeAppeals = action.payload
		},
		setAppealDeal(state, action) {
			state.appealDeal = action.payload
		},
		setAppealAtorneyList(state, action) {
			state.appealAtorneyList = action.payload
		},
		setAddPayment(state, action) {
			state.appealDeal.payments.push(action.payload)
		},
		setDeletedPayment(state, action) {
			state.appealDeal.payments = state.appealDeal.payments.filter(
				(payment) => payment.id !== action.payload
			)
		},
		setEditedPaymentStatus(state, action) {
			state.appealDeal.payments = state.appealDeal.payments.map((payment) => {
				if (payment.id === action.payload) {
					return { ...payment, payments_status: 'PAID' }
				}
				return payment
			})
		},
		setAppealDealStatusWS(state, action) {
			state.appealDealStatusWS = action.payload
		},
		setFilteresDate(state, action) {
			state.filteresDate = action.payload
		},

		setAppealDealRequestRosreestrWS(state, action) {
			if (state.appealDeal.id === action.payload.appeal_id) {
				state.appealDeal = {
					...state.appealDeal,
					request_rosreestr: action.payload
				}
			}
		}
	}
})

export default appeals.reducer

export const {
	settypeAppeals,
	setAppealDeal,
	setDeletedPayment,
	setEditedPaymentStatus,
	setAppealDealStatusWS,
	setAppealDealRequestRosreestrWS,
	setFilteresDate,
	setAppealAtorneyList
} = appeals.actions
