import React, { useEffect, useRef, useState } from 'react'
import Input from '../../components/input/input'
import { Link } from 'react-router-dom'
import s from './changePassword.module.scss'
import Button from '../../components/button/button'
import Container from '../../components/container/container'
import ContainerContent from '../../components/container-content/container-content'
import { resetPassword } from '../../api/resetPassword/resetPassword'
import { useDispatch, useSelector } from 'react-redux'
import {
	setButtonDisabled,
	setChangePasswordTextStatus,
	setLengthEight,
	setNewPassword,
	setOldPassword,
	setSequenceNumbers
} from '../../redux/changePassword/changePassword'
import { Modal } from '../../components/modal/modal'

const ChangePassword = ({
	onSendPasswordRecoveryLink,
	onCloseModal,
	setChangePasswordWindow,
	changePasswordWindow
}) => {
	const inputRef = useRef(null)
	const dispatch = useDispatch()
	const accessToken = localStorage.getItem('accessToken')
	const [passwordChanged, setPasswordChanged] = useState(false)

	const {
		oldPassword,
		newPassword,
		lengthEight,
		sequenceNumbers,
		errorOldPassword,
		buttonDisabled,
		changePasswordTextStatus
	} = useSelector((state) => state.changePassword)
	//const regularSequenceNumbers = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/g
	const regularSequenceNumbers =
		/(?=.*[0-9])(?=.*[a-z])[0-9a-zA-Z!@#$%^&*]{6,}/g

	useEffect(() => {
		setPasswordChanged(false)
	}, [])

	useEffect(() => {
		dispatch(setLengthEight(newPassword?.length > 8))
		dispatch(setSequenceNumbers(regularSequenceNumbers.test(newPassword)))
		changePasswordWindow
			? dispatch(
					setButtonDisabled(
						lengthEight && sequenceNumbers && oldPassword?.length > 3
					)
			  )
			: dispatch(setButtonDisabled(lengthEight && sequenceNumbers))
	}, [newPassword, lengthEight, sequenceNumbers, oldPassword])

	useEffect(() => {
		if (changePasswordTextStatus === 'Пароль изменен') {
			setPasswordChanged(true)
		}
	}, [changePasswordTextStatus])

	const buttonClick = () => {
		if (inputRef.current.type === 'password') {
			inputRef.current.type = 'text'
		} else {
			inputRef.current.type = 'password'
		}
	}

	const sendChangePassword = () => {
		dispatch(setChangePasswordTextStatus(null))
		dispatch(
			resetPassword.changePassword(oldPassword, newPassword, accessToken)
		)
	}

	return (
		<Container>
			<ContainerContent>
				<div className={s.changePassword}>
					<Modal
						active={changePasswordWindow}
						onClose
						setActive={setChangePasswordWindow}
					>
						<h3>Изменить пароль</h3>
						<div className={s.changePassword__oldPassword}>
							<span>Старый пароль</span>
							<Input
								onChange={(e) => dispatch(setOldPassword(e.target.value))}
								type={'password'}
								nameRef={inputRef}
							/>
							{errorOldPassword && (
								<div className={s.changePassword__oldPassword__error}>
									<span>Неправильно</span>
									<span>
										<Link onClick={onSendPasswordRecoveryLink} to=''>
											Отправить ссылку
										</Link>{' '}
										на восстановление пароля
									</span>
								</div>
							)}
						</div>

						<div className={s.changePassword__newPassword}>
							<span>Новый</span>
							<div>
								<Input
									onChange={(e) => dispatch(setNewPassword(e.target.value))}
									nameRef={inputRef}
									buttonIcon
									buttonClick={buttonClick}
									type={'password'}
								/>
								<span
									style={lengthEight ? { color: '#61BA65' } : { color: '' }}
								>
									&#10003; Больше 8 символов
								</span>
								<span
									style={sequenceNumbers ? { color: '#61BA65' } : { color: '' }}
								>
									&#10003; Должен содержать хотя бы один специальный символ ! @
									# $ % ^ & * ( ) _ + = ; : , . / ? | ` ~
								</span>
							</div>
						</div>
						{changePasswordTextStatus && (
							<div className={s.textStatus}>{changePasswordTextStatus}</div>
						)}
						<Button
							FCOnclick={sendChangePassword}
							small={true}
							disabled={!buttonDisabled}
						>
							Изменить пароль
						</Button>
					</Modal>
				</div>

				<div className={s.passwordChanged}>
					<Modal
						active={passwordChanged}
						onClose
						setActive={setPasswordChanged}
					>
						<h3>Пароль изменён</h3>
						<Link onClick={onCloseModal} to='/taskListClient'>
							В личный кабинет
						</Link>
					</Modal>
				</div>
			</ContainerContent>
		</Container>
	)
}

export default ChangePassword
