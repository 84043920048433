import { createSlice } from '@reduxjs/toolkit'

const address = createSlice({
	name: 'address',
	initialState: {
		foundAddress: null,
		areaValue: null
	},
	reducers: {
		setFoundAddress(state, action) {
			state.foundAddress = action.payload
		},
		setAreaValue(state, action) {
			state.areaValue = action.payload
		}
	}
})

export default address.reducer

export const { setFoundAddress, setAreaValue } = address.actions
