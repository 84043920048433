import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

const baseURL = process.env.REACT_APP_BASE_URL

function getAccessToken() {
	return localStorage.getItem('accessToken')
}

function getRefreshToken() {
	return localStorage.getItem('refreshToken')
}

const createAxiosResponseInterceptor = () =>
	createAuthRefreshInterceptor(axios, refreshAuthLogic, { statusCodes: [403] })

const refreshAuthLogic = (failedRequest) =>
	axios
		.get(`${baseURL}/refresh-token`, {
			headers: {
				Authorization: `Bearer ${getRefreshToken()}`
			}
		})
		.then((tokenRefreshResponse) => {
			localStorage.setItem(
				'accessToken',
				tokenRefreshResponse.data.access_token
			)
			failedRequest.response.config.headers['Authorization'] =
				'Bearer ' + tokenRefreshResponse.data.access_token
			return Promise.resolve()
		})
		.catch((error) => console.log('EEEEEEEEEEEEEEROR', error))

export const refreshTokenFC = () => {
	return axios.get(`${baseURL}/refresh-token`, {
		headers: {
			Authorization: `Bearer ${getRefreshToken()}`
		}
	})
}

const goAuth = () => {
	localStorage.clear();
	if (window.location.href !== window.location.origin+"/login") {
		window.location.replace(window.location.origin+"/login")
	}
}

axios.interceptors.request.use((request) => {
	if (request.method === 'get' && request.url.includes('refresh-token')) {
		return request
	}
	request.headers['Authorization'] = `Bearer ${getAccessToken()}`
	return request
})

axios.interceptors.response.use(
	(response) => {
		if (response.method === 'get' && response.url.includes('refresh-token')) {
			return response
		}
		response.headers['Authorization'] = `Bearer ${getAccessToken()}`
		return response
	},
	async (error) => {
	  const originalConfig = error.config;
	  if (error.response) {
		if (error.response.status === 401 && !originalConfig._retry) {
		  originalConfig._retry = true;
  
		  return refreshTokenFC
		}
  
		if (error.response.status === 403) {
			return goAuth();
		}

		return Promise.reject(error);
	  }
	}
  );

export default createAxiosResponseInterceptor
