import React, {useCallback, useEffect, useRef, useState} from 'react'
import Input from '../../input/input'
import s from './object-deal.module.scss'
import {useDispatch, useSelector} from 'react-redux'
import {
  setObjectValue,
  setRealtyTextStatus,
} from '../../../redux/realty/realty'
import {realty} from '../../../api/realty/realty'
import {address} from '../../../api/address'
import {setFoundAddress} from '../../../redux/address/address'
import {debounce} from 'lodash'
import {Modal} from '../../modal/modal'
import {setListEgrn} from '../../../redux/admin/admin'
import {
  setDescriptionPrice,
  setPriceName, setRightTypeState, setTypeObjState, setTypeOriginObjState
} from '../../../redux/payments/payments'
import {payments} from '../../../api/payments'
import Button from '../../button/button'
import {useParams} from 'react-router'
import PopUpComplete from '../../popUpComplete/popUpComplete'
import {setRightType} from '../../../redux/payments/payments'

export const ObjectDeal = ({infoViewObject}) => {
  const accessToken = localStorage.getItem('accessToken')
  const {object, types, realtyTextStatus} = useSelector(
    (state) => state.realty
  )
  const {priceName, descriptionPrice, egrnType} = useSelector(
    (state) => state.payments
  )
  const {foundAddress} = useSelector((state) => state.address)
  const {objectTypeState, dicts} = useSelector((state) => state.realty)
  const {appealDeal} = useSelector((state) => state.appeals)
  const [windowExtract, setWindowExtract] = useState(false)
  const {realtyId} = useParams()
  const [selectValue, setSelectedValue] = useState()
  const [rightTypeTitle, setRightTypeTitle] = useState()
  //const [typeObj, setTypeObj] = useState()
  const dispatch = useDispatch()
  const addressInputRef = useRef()
  const [addressInputIsFocus, setAddressInputIsFocus] = useState()
  const [extractData, setExtractData] = useState(null)

  const [rightType, setRightType] = useState(null)
  const [typeOriginObj, setTypeOriginObj] = useState(null)
  const [typeObj, setTypeObj] = useState(null)

/*

  useEffect(()=>{

    dispatch(setRightType(objectType))
  },[objectType])
*/

  useEffect(()=>{
    dispatch(setRightTypeState(rightType))
  },[rightType])

  useEffect(()=>{
    dispatch(setTypeOriginObjState(typeOriginObj))
  },[typeOriginObj])

  useEffect(()=>{
    dispatch(setTypeObjState(typeObj))
  },[typeObj])


  useEffect(() => {
    if (!realtyTextStatus) return
    setTimeout(() => {
      dispatch(setRealtyTextStatus(null))
      setWindowExtract(false)
    }, 3000)
  }, [realtyTextStatus])

/*  useEffect(() => {
    dispatch(setRightType(rightTypeTitle))
  }, [rightTypeTitle])*/

  const handleFindAddress = useCallback(
    debounce(async (addressName) => {
      dispatch(address.getAddress(addressName))
    }, 350),
    []
  )

  useEffect(() => {
    if (object?.cadastral_number && foundAddress?.length === 1) {
      dispatch(
        setObjectValue({
          name: 'address',
          value: foundAddress[0].value
        })
      )
    }
    if (
      object?.cadastral_number &&
      addressInputRef?.current !== document.activeElement &&
      (foundAddress?.length > 1 || foundAddress?.length === 0)
    ) {
      dispatch(
        setObjectValue({
          name: 'address',
          value: ''
        })
      )
    }

    if (addressInputRef?.current !== document.activeElement) {
      setAddressInputIsFocus(false)
    }
  }, [foundAddress])

  useEffect(() => {
    dispatch(realty.getRealtyTypes(accessToken))
    dispatch(realty.getDicts(accessToken))
  }, [])

  useEffect(() => {
    dispatch(
      setObjectValue({
        name: 'type_obj_id',
        value: selectValue?.id
      })
    )
    dispatch(
      setObjectValue({
        name: 'type_obj',
        value: selectValue?.title
      })
    )
  }, [selectValue])

  useEffect(() => {
    if(!object) return

    if (dicts) {
      dicts.forEach(
        (item) =>
          item.id == object?.right_type_id && setRightType(item)
      )
    }
    
    if (types) {
      types.forEach(
        (item) =>
          item.id == object?.type_origin_obj_id && setTypeOriginObj(item)
      )

      types.forEach(
        (item) =>
          item.id == object?.type_obj_id && setTypeObj(item)
      )
    }


  /*  setRightTypeTitle(object?.right_type_title)
    setTypeObj(object?.type_obj)*/
  }, [object])

  useEffect(() => {
    if (windowExtract) {
      dispatch(payments.egrType(accessToken))
    } else {
      dispatch(setListEgrn(null))
    }
  }, [windowExtract])

  const setDescriptionPriceFC = () => {
    dispatch(realty.egrnRealty(extractData, realtyId, accessToken))
  }

  return (
    <>
      <div className={s.container}>
        <p>Объект</p>
        {infoViewObject === 'third-person' && (
          <>
            <div className={s.item}>
              <span>Вид право объекта</span>
              <Input
                value={rightType?.title ? rightType.title : rightType}
                setSelectedValue={setRightType}
                selectedValue={rightType}
                select
                dataList={dicts}
                placeholder='Выберите тип'
                placeholderForSelect='Выберите тип'
              />
            </div>
            <div className={s.item}>
              <span>Основной тип</span>
              <Input
                value={typeOriginObj?.title ? typeOriginObj.title : typeOriginObj}
                setSelectedValue={setTypeOriginObj}
                selectedValue={typeOriginObj}
                select
                dataList={types}
                placeholder='Выберите тип'
                placeholderForSelect='Выберите тип'
              />
            </div>
            <div className={s.item}>
              <span>Кадастровый номер<br/> земельного участка</span>
              <Input
                value={
                  object?.cadastral_number_area
                }
                name={'cadastral_number_area'}
                onChange={(e) => {
                  dispatch(
                    setObjectValue({
                      name: e.target.name,
                      value: e.target.value
                    })
                  )
                  handleFindAddress(e.target.value)
                }}
              />
            </div>
            <div className={s.item}>
              <span>Площадь застройки</span>
              <Input
                value={object?.building_area}
                name={'building_area'}
                onChange={(e) =>
                  dispatch(
                    setObjectValue({
                      name: e.target.name,
                      value: e.target.value
                    })
                  )
                }
              />
              <div className={s.item}>
                <span>Адрес</span>
                <Input
                  value={object?.address}
                  name='address'
                  textarea
                  onChange={(e) => {
                    dispatch(
                      setObjectValue({
                        name: e.target.name,
                        value: e.target.value
                      })
                    )
                    handleFindAddress(e.target.value)
                  }}
                  nameRef={addressInputRef}
                  onFocus={() => setAddressInputIsFocus(true)}
                />
                <div className={s.item} style={{marginTop: 0}}>
                  <p></p>
                  {addressInputIsFocus &&
                  object?.address &&
                  foundAddress &&
                  foundAddress?.length !== 0 && (
                    <div className={s.foundAddres}>
                      {foundAddress.map((address) => {
                        return (
                          <div
                            onClick={() => {
                              dispatch(
                                setObjectValue({
                                  name: 'address',
                                  value: address.full_name
                                })
                              )
                              dispatch(setFoundAddress(null))
                            }}
                          >
                            <span>{address.full_name}</span>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>

              <div className={s.item}>
                <span>Тип</span>
                <Input
                  value={typeObj?.title ? typeObj.title : typeObj}
                  setSelectedValue={setTypeObj}
                  selectedValue={typeObj}
                  select
                  dataList={types}
                  placeholder='Выберите тип'
                  placeholderForSelect='Выберите тип'
                />
              </div>
              <div className={s.item}>
                <span>Кадастровый номер</span>
                <Input
                  value={
                    object?.cadastral_number
                  }
                  name={'cadastral_number'}
                  onChange={(e) => {
                    dispatch(
                      setObjectValue({
                        name: e.target.name,
                        value: e.target.value
                      })
                    )
                    handleFindAddress(e.target.value)
                  }}
                />
              </div>
            </div>
            <div className={s.item}>
              <span>Площадь</span>
              <Input
                value={object?.square}
                name={'square'}
                onChange={(e) =>
                  dispatch(
                    setObjectValue({
                      name: e.target.name,
                      value: e.target.value
                    })
                  )
                }
              />
            </div>
            <div className={s.item}>
              <span>Номер дома</span>
              <Input
                value={object?.suspended_number_building}
                name={'suspended_number_building'}
                onChange={(e) =>
                  dispatch(
                    setObjectValue({
                      name: e.target.name,
                      value: e.target.value
                    })
                  )
                }
              />
            </div>
            <div className={s.item}>
              <span>Номер квартиры</span>
              <Input
                value={object?.suspended_number_flat}
                name={'suspended_number_flat'}
                onChange={(e) =>
                  dispatch(
                    setObjectValue({
                      name: e.target.name,
                      value: e.target.value
                    })
                  )
                }
              />
            </div>


            <div className={s.item}>
              <span>Описание</span>
              <Input
                value={object?.description}
                name='description'
                textarea
                onChange={(e) =>
                  dispatch(
                    setObjectValue({
                      name: e.target.name,
                      value: e.target.value
                    })
                  )
                }
              />
            </div>

            <div className={s.item}>
              <span>Жилой комплекс</span>
              <Input
                value={object?.apartment_complex}
                name={'apartment_complex'}
                onChange={(e) =>
                  dispatch(
                    setObjectValue({
                      name: e.target.name,
                      value: e.target.value
                    })
                  )
                }
              />
            </div>

            {descriptionPrice && (
              <>
                <div className={s.item}>
                  <span>Тип выписки:</span>
                  <Input value={descriptionPrice?.title} textarea/>
                </div>
                <div className={s.item}>
                  <span>Сумма:</span>
                  <Input
                    value={
                      priceName === 'legalEntity'
                        ? descriptionPrice?.price_legal
                        : descriptionPrice?.price_individual
                    }
                  />
                </div>
              </>
            )}
          </>
        )}

        {infoViewObject === 'unistroy' && (
          <div className={s.container__unistroyWrapper}>
            <div className={s.item}>
              <span>Тип</span>
              <Input
                value={selectValue?.title ? selectValue.title : selectValue}
                setSelectedValue={setSelectedValue}
                selectedValue={selectValue}
                select
                dataList={types}
                placeholder='Выберите тип'
                placeholderForSelect='Выберите тип'
              />
            </div>
            <div className={s.item}>
              <span>Площадь</span>
              <Input
                value={object?.square}
                name='square'
                onChange={(e) =>
                  dispatch(
                    setObjectValue({
                      name: e.target.name,
                      value: e.target.value
                    })
                  )
                }
              />
            </div>
            <div className={s.item}>
              <span>Кадастровый номер</span>
              <Input
                value={object?.cadastral_number}
                name='cadastral_number'
                onChange={(e) =>
                  dispatch(
                    setObjectValue({
                      name: e.target.name,
                      value: e.target.value
                    })
                  )
                }
              />
            </div>
            <div className={s.item}>
              <span>Адрес КЛАДР</span>
              <Input
                value={object?.address}
                name='address'
                onChange={(e) =>
                  dispatch(
                    setObjectValue({
                      name: e.target.name,
                      value: e.target.value
                    })
                  )
                }
              />
            </div>
          </div>
        )}
        <div className={s.extractEgrn} onClick={() => setWindowExtract(true)}>
          {descriptionPrice ? 'Изменить тип выписки' : 'Заказать выписку ЕГРН'}
        </div>
      </div>

      <Modal
        onClose
        active={windowExtract}
        setActive={setWindowExtract}
        fullScreen
      >
        <PopUpComplete description={realtyTextStatus}/>
        {egrnType ? (
          <div className={s.dutyWrapper}>
            <div className={s.dutyWrapper__statementType}>
              <h4>Тип выписки</h4>
              <div>
                <span>Для юрлица</span>
                <input
                  type='checkbox'
                  checked={priceName === 'legalEntity'}
                  onChange={() => dispatch(setPriceName('legalEntity'))}
                />
              </div>
              <div>
                <span>Для физлица</span>
                <input
                  type='checkbox'
                  checked={priceName === 'person'}
                  onChange={() => dispatch(setPriceName('person'))}
                />
              </div>
            </div>
            <div className={s.dutyWrapper__descriptionPrice}>
              {egrnType?.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className={s.dutyWrapper__descriptionPrice__item}
                  >
                    <input
                      type='checkbox'
                      checked={extractData?.id === item.id}
                      onChange={() => setExtractData(item)}
                    />
                    <p>
                      {index + 1}. {item.title}
                    </p>
                  </div>
                )
              })}
              <Button FCOnclick={setDescriptionPriceFC}>Сохранить</Button>
            </div>
          </div>
        ) : (
          <span>Подождите</span>
        )}
      </Modal>
    </>
  )
}
