import axios from 'axios'
import { 
	setPasswordRecoveryTextStatus
} from '../../redux/passwordRecovery/passwordRecovery'

const baseURL = process.env.REACT_APP_BASE_URL

export const passwordRecovery = {
	client(phone, email) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/send-reset-password-client`,
					{
						phone,
						email
					}
				)
				dispatch(setPasswordRecoveryTextStatus('Пароль сброшен'))

				setTimeout(() => {
					dispatch(setPasswordRecoveryTextStatus(null))
				}, 3000)
			} catch (error) {
				console.log(error)
				dispatch(
					setPasswordRecoveryTextStatus(
						error.response.data.detail
							? error.response.data.detail
							: 'Что-то пошло не так'
					)
				)

				setTimeout(() => {
					dispatch(setPasswordRecoveryTextStatus(null))
				}, 3000)
			}
		}
	},

	restorePassword(uidb64, token, newPassword) {
		return async (dispatch) => {
			try {
				const response = await axios.post(
					`${baseURL}/restore-password/${uidb64}/${token}`,
					{
						password: newPassword
					}
				)
				dispatch(
					setPasswordRecoveryTextStatus({
						text: 'Пароль создан',
						status: true
					})
				)
				window.location.replace(`${window.location.origin}/login`);
			} catch (error) {
				error?.response?.data?.detail &&
					dispatch(
						setPasswordRecoveryTextStatus({
							text: error.response.data.detail,
							status: false
						})
					)
				console.log(error)
			}
		}
	}
}
