import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { support } from '../../../api/support'
import s from './message-support.module.scss'

export const MessageSupport = ({
	text,
	date,
	colorBgDark,
	contentType,
	fileName,
	messageId,
	progressUploadFile
}) => {
	const { chatData } = useSelector((state) => state.support)
	const accessToken = localStorage.getItem('accessToken')
	const dispatch = useDispatch()

	const getFile = (messageId) => {
		dispatch(support.getFile(accessToken, messageId))
	}

	return (
		<div
			className={s.message}
			style={
				colorBgDark
					? { background: 'rgb(232 232 232)' }
					: { background: 'rgb(255 255 255)' }
			}
		>
			{contentType !== 'message' ? (
				<div className={s.message__file}>
					<div className={s.message__file__preview}></div>
					<div className={s.message__file__info}>
						<div>
							<p style={colorBgDark ? { color: '#black' } : { color: 'black' }}>
								{fileName?.length > 15
									? `${fileName.substring(0, 10)}...${fileName.substring(
											10,
											20
									  )}.${fileName.split('.')[1]}`
									: fileName}
							</p>
							{chatData[chatData?.length - 1]?.id === messageId && (
								<p
									style={
										progressUploadFile === false
											? { color: 'red' }
											: progressUploadFile === true
											? { color: '#1FA34A' }
											: {}
									}
								>
									{progressUploadFile === false
										? 'Файл загружается...'
										: progressUploadFile === true && 'Файл загружен'}
								</p>
							)}
						</div>
						<div onClick={() => getFile(messageId)}>
							{(progressUploadFile === true || progressUploadFile !== false) &&
								'Скачать'}
						</div>
					</div>
				</div>
			) : (
				<div className={s.message__text}>
					<p>{text}</p>
				</div>
			)}

			<div className={s.message__date}>
				<span>{date}</span>
				<span />
			</div>
		</div>
	)
}
