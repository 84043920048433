import React from 'react'
import s from './dealsManager.module.scss'
import Button from '../../../components/button/button'
import InvitationToPersonalAccount from '../../../blocks/invitationToPersonalAccount/invitationToPersonalAccount'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { setExitLogin } from '../../../redux/login/login'
import { Navigate } from 'react-router-dom'
import { Deals } from '../../../components/deals/deals'

const DealsManager = () => {
	const secretUrl = useSelector(
		(state) => state.invitationToPersonalAccount.secretUrl
	)
	const login = useSelector((state) => state.login.login)
	const dispatch = useDispatch()
	const [active, setActive] = useState(false)
	const [pageName, setPageName] = useState('deals')

	const exitLogin = () => {
		dispatch(setExitLogin(null))
	}

	if (!login) {
		return <Navigate to='/login' replace />
	}

	return (
		<div className={s.manager}>
			<Deals pageName={pageName} setPageName={setPageName} />
			<div className={s.deals}>
				<div>
					<a href={secretUrl} target='_blank'>
						{secretUrl}
					</a>
				</div>
				<Button small={true} FCOnclick={() => setActive(true)}>
					Создать приглашение в личный кабинет
				</Button>
				<Button small={true} FCOnclick={exitLogin}>
					Выход
				</Button>
				<InvitationToPersonalAccount active={active} setActive={setActive} />
			</div>
		</div>
	)
}

export default DealsManager
