import { createSlice } from '@reduxjs/toolkit'

const documents = createSlice({
	name: 'webSocket',
	initialState: {
		wsState: null,
		wsConnect: null
	},
	reducers: {
		setWsState(state, action) {
			state.wsState = action.payload
		},
		setWsConnect(state, action) {
			state.wsConnect = action.payload
		}
	}
})

export default documents.reducer

export const { setWsState, setWsConnect } = documents.actions
