import { createSlice } from '@reduxjs/toolkit'

const documents = createSlice({
	name: 'documents',
	initialState: {
		documentsTypeList: null,
		documentData: null,
		saveIdUserDocumentRequest: null,
		documentRequestUserStatus: false,
		documentStatusText: null,
		documentRequest: null,
		numberDocument: null,
		dateDocument: null,
		filePowerOfAttorney: null,
		signatureManual: false,
		documentAppeal: null
	},
	reducers: {
		setDocumentsTypeList(state, action) {
			state.documentsTypeList = action.payload
		},
		setDocument(state, action) {
			state.documentData = action.payload
		},
		setPowerOfAttorney(state, action) {
			state.powerOfAttorney = action.payload
		},
		setDocumentWS(state, action) {
			if (action.payload?.id === state.documentData?.id) {
				state.documentData.signers_data = action.payload.participants_documents
			}
		},
		documentRequestUserStatus(state, action) {
			state.documentRequestUserStatus = action.payload
		},
		setSaveIdUserDocumentRequest(state, action) {
			state.saveIdUserDocumentRequest = action.payload
		},
		setDocumentStatusText(state, action) {
			state.documentStatusText = action.payload
		},
		setDocumentRequest(state, action) {
			state.documentRequest = action.payload
		},
		setNumberDocument(state, action) {
			state.documentData.number = action.payload
		},
		setDateDocument(state, action) {
			state.documentData.issue_date = action.payload
		},
		setFilePowerOfAttorney(state, action) {
			state.filePowerOfAttorney = action.payload
		},
		setSignatureManual(state, action) {
			state.signatureManual = action.payload
		}
	}
})

export default documents.reducer

export const {
	setDocumentsTypeList,
	setDocument,
	setDocumentWS,
	documentRequestUserStatus,
	setSaveIdUserDocumentRequest,
	setDocumentStatusText,
	setNumberDocument,
	setDateDocument,
	setFilePowerOfAttorney,
	setSignatureManual,
	setPowerOfAttorney
} = documents.actions
