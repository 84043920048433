import React, { useEffect, useRef, useState } from 'react'
import useDebounce from '../../hooks/useDebounce'
import s from './egrn-new-statement.module.scss'
import Input from '../input/input'
import Button from '../button/button'
import { useDispatch, useSelector } from 'react-redux'
import { address } from '../../api/address'
import { setFoundAddress } from '../../redux/address/address'
import { payments } from '../../api/payments'
import {
	setDescriptionPrice,
	setPriceName
} from '../../redux/payments/payments'
import { admin } from '../../api/admin'
import { setExtract } from '../../redux/admin/admin'
import PopUpComplete from '../../components/popUpComplete/popUpComplete'
import { useNavigate } from 'react-router-dom'

export const EgrnNewStatement = ({ newEgrn, aktiveModal }) => {
	const navigate = useNavigate()
	const accessToken = localStorage.getItem('accessToken')
	const [realEstateData, setEstateData] = useState('')
	const [validationError, setValidationError] = useState()
	const find = realEstateData && !validationError
	const [adress, setAdress] = useState(null)
	const { foundAddress, areaValue } = useSelector((state) => state.address)
	const { extract, adminTextStatus } = useSelector((state) => state.admin)
	const { egrnType, descriptionPrice, priceName } = useSelector(
		(state) => state.payments
	)
	const [addressInputIsFocus, setAddressInputIsFocus] = useState()
	const dispatch = useDispatch()
	const addressInputRef = useRef()
	const regCadastralnumber = /\d{1,2}:\d{1,2}:\d{6,7}:\d{1,4}/

	useEffect(() => {
		setEstateData('')
	}, [aktiveModal])

	useEffect(() => {
		if (extract) {
			navigate(`extract/${extract.id}`)
		}
	}, [extract])

	useEffect(() => {
		if (newEgrn) {
			dispatch(payments.egrType(accessToken))
		} else {
			dispatch(setDescriptionPrice(null))
			dispatch(setExtract(null))
			setAdress(null)
		}
	}, [newEgrn])

	const handleFindAddress = async (search) => {
		if (regCadastralnumber.test(search)) {
			dispatch(address.getCadastrNumber(search))
		} else {
			dispatch(address.getAddress(search))
		}
	}

	const debouncedValue = useDebounce(realEstateData, 2000);

	useEffect(() => {
  
		handleFindAddress(realEstateData)
  
	}, [debouncedValue]);

	return (
		<>
			<div className={s.container}>
				<PopUpComplete description={adminTextStatus} addCloseBtn={true} />
				<div className={s.content}>
					<div className={s.cadastralNumAddress}>
						<p>Новая выписка из ЕГРН</p>
						<div>
							<div>
								<Input
									id={s.cadNumAndAddress}
									validate={!validationError}
									value={realEstateData}
									findedRealEstate={adress}
									placeholder={'Адрес или кадастровый номер'}
									onChange={(event) => {
										setEstateData(event.target.value)
									}}
									nameRef={addressInputRef}
									onFocus={() => setAddressInputIsFocus(true)}
								/>
								{realEstateData &&
									(foundAddress?.addressNote || foundAddress?.full_name) && (
										<div>
											Найден объект
											<p>
												{foundAddress.addressNote
													? foundAddress?.addressNote
													: foundAddress?.full_name}
											</p>
											<p>
												{foundAddress.id
													? foundAddress?.id
													: foundAddress?.cadnum}
											</p>
										</div>
									)}
								<div className={s.foundAddres} style={{ marginTop: 0 }}>
									<p></p>
									{realEstateData &&
										addressInputIsFocus &&
										foundAddress &&
										!foundAddress.full_name &&
										foundAddress.length >= 1 && (
											<div>
												{foundAddress?.map((address, i) => {
													return (
														<div
															key={i + "a"}
															onClick={() => {
																setEstateData(address?.full_name)
																dispatch(setFoundAddress(address))
																setAdress(address)
															}}
														>
															<span>{address?.full_name}</span>
														</div>
													)
												})}
											</div>
										)}
								</div>
							</div>
						</div>
					</div>
					<div className={s.content__statementType}>
						<h4>Тип выписки</h4>
						<div>
							<input
								type='checkbox'
								checked={priceName === 'legalEntity'}
								onChange={() => dispatch(setPriceName('legalEntity'))}
							/>
							<span>Для юрлица</span>
						</div>
						<div>
							<input
								type='checkbox'
								checked={priceName === 'person'}
								onChange={() => dispatch(setPriceName('person'))}
							/>
							<span>Для физлица</span>
						</div>
					</div>
					<div className={s.statement}>
						{egrnType?.map((item, index) => {
							return (
								<div key={item.id} className={s.statement__item}>
									<input
										type='checkbox'
										checked={descriptionPrice?.id === item.id}
										onChange={() => dispatch(setDescriptionPrice(item))}
									/>
									<p>
										{index + 1}. {item.title}
									</p>
								</div>
							)
						})}
					</div>
					<Button
						FCOnclick={() =>
							dispatch(
								admin.createEgrnExtract(
									accessToken,
									foundAddress.id ? foundAddress?.id : foundAddress?.cadnum,
									descriptionPrice?.id,
									priceName === 'legalEntity'
										? descriptionPrice?.price_legal
										: descriptionPrice?.price_individual,
									foundAddress?.objectType,
									foundAddress?.addressNote
										? foundAddress?.addressNote
										: foundAddress?.full_name,
									areaValue
								)
							)
						}
						small={true}
					>
						Продолжить создание выписки
					</Button>
				</div>
			</div>
		</>
	)
}
