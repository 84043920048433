import React from 'react'
import s from './view-organization.module.scss'
export const ViewOrganization = ({ openedOrganization }) => {
	return (
		<div className={s.container}>
			<div>
				<p>Данные юрлица организации</p>
				<ul>
					<li>Полное название</li>
					<li>{openedOrganization?.full_name}</li>
				</ul>
				<ul>
					<li>ОГРН</li>
					<li>{openedOrganization?.ogrn}</li>
				</ul>
				<ul>
					<li>ИНН</li>
					<li>{openedOrganization?.inn}</li>
				</ul>
				<ul>
					<li>КПП</li>
					<li>{openedOrganization?.kpp}</li>
				</ul>
				<ul>
					<li>Адрес</li>
					<li>{openedOrganization?.address}</li>
				</ul>
				<ul>
					<li>Почта</li>
					<li>{openedOrganization?.email}</li>
				</ul>
				<ul>
					<li>Телефон</li>
					<li>{openedOrganization?.phone}</li>
				</ul>
			</div>
		</div>
	)
}
