import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '../../../components/container/container'
import ContainerContent from '../../../components/container-content/container-content'
import { Navigate } from 'react-router-dom'
import Button from '../../../components/button/button'
import { setExitLogin } from '../../../redux/login/login'

const TaskList = () => {
	const login = useSelector((state) => state.login.login)
	const dispatch = useDispatch()

	if (!login) {
		return <Navigate to='/login' replace />
	}

	const test = () => {
		dispatch(setExitLogin())
	}

	return (
		<Container>
			<ContainerContent>
				<h3>Ты зашел в ЛК клиента</h3>
				<Button FCOnclick={test}>Выход</Button>
			</ContainerContent>
		</Container>
	)
}

export default TaskList
