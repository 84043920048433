import React, {useEffect} from 'react'
import {InfoFromDB} from './info-from-db/info-from-db'
import {ObjectDeal} from './object-deal/object-deal'
import {PaymentMethod} from './payment-method/payment-method'
import s from './view-object-deal.module.scss'
import {useParams} from 'react-router'
import {realty} from '../../api/realty/realty'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {setRealtyTextStatus} from '../../redux/realty/realty'
import PopUpComplete from '../popUpComplete/popUpComplete'
import { useNavigate } from 'react-router-dom'

export const ViewObjectPayment = () => {
  const infoViewObject = 'third-person'
  const {realtyId} = useParams()
  const dispatch = useDispatch()
  const accessToken = localStorage.getItem('accessToken')
  const dealId = useSelector((state) => state.deals.dealId)
  const {object, realtyTextStatus} = useSelector((state) => state.realty)
  const {descriptionPrice, rightType,typeOriginObj,typeObj} = useSelector((state) => state.payments)
  const appeal_id = localStorage.getItem('appeal_id')
  const navigate = useNavigate();

  useEffect(() => {
    if (!realtyTextStatus) return
    setTimeout(() => {
      dispatch(setRealtyTextStatus(null))
    }, 3000)
  }, [realtyTextStatus])

  useEffect(() => {
    if (realtyId === 'undefined') {
      const realtyIdNew = localStorage.getItem('realtyId')
      dispatch(realty.getRealty(appeal_id, realtyIdNew, accessToken))
    } else {
      localStorage.setItem('realtyId', realtyId)
      dispatch(realty.getRealty(appeal_id, realtyId, accessToken))
    }
  }, [])

  const saveButton = () => {
    dispatch(
      realty.editRealtyThirdParty(
        appeal_id,
        realtyId,
        object,
        rightType?.id,
        typeOriginObj.id,
        typeObj.id,
        descriptionPrice?.title,
        accessToken
      )
    )
    // navigate(`/deal/${dealId}`)
  }

  return (
    <div className={s.customModal}>
      <div className={s.container}>
        <div className={s.header}>
          <p>Объект и оплата</p>
          <div>
            <Link to={`/deal/${dealId}`}>Отмена</Link>
            <button onClick={() => saveButton()}>Сохранить</button>
          </div>
        </div>
        <div className={s.content}>
          <PopUpComplete description={realtyTextStatus}/>
          <div className={s.objectDeal}>
            <div>
              <ObjectDeal infoViewObject={infoViewObject}/>
              {infoViewObject === 'unistroy' && <InfoFromDB/>}
            </div>
          </div>
          <div className={s.paymentMethod}>
            <PaymentMethod/>
          </div>
        </div>
      </div>
    </div>
  )
}
