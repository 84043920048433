import React from 'react'
import s from './input-radio.module.scss'
export const InputRadio = ({ label, ...props }) => {
	return (
		<div className={s.container}>
			<input className={s.checkbox} type='radio' {...props} />
			<span></span>
			<label>{label}</label>
		</div>
	)
}
