import axios from 'axios'
import {
	setAppealDeal,
	setFilteres,
	setFilteresDate,
	settypeAppeals
} from '../redux/appeals/appeals'
import { setAdminTextStatus } from '../redux/admin/admin'
import { alertStatusText } from '../components/alertStatusText/alertStatusText'
import { setNotificationText } from '../redux/manager/manager'

const baseURL = process.env.REACT_APP_BASE_URL

export const appeals = {
	typeAppealsList(accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/appeal-type`, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				dispatch(settypeAppeals(response.data.types))
			} catch (error) {
				console.log(error)
			}
		}
	},
	appealDeal(appealId) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/appeals/${appealId}`)
				dispatch(setAppealDeal(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	},
	editAppealType(appeal_type_id, appeal_id, accessToken) {
		return async (dispatch) => {
			try {
				const response = await axios.put(
					`${baseURL}/appeals/${appeal_id}/edit_appeal_type`,
					{
						appeal_type_id
					},
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
			} catch (error) {
				dispatch(
					setNotificationText(
						alertStatusText(error?.response?.data?.detail, false)
					)
				)
				console.log(error)
			}
		}
	},
	getDicts() {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/dicts`)

				dispatch(setFilteresDate(response.data))
			} catch (error) {
				console.log(error)
			}
		}
	}
}
