import React, { useEffect, useState } from 'react'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json'
import s from './duty-page.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Link, useNavigate } from 'react-router-dom'
import DocumentSignatureWindow from '../../blocks/documentSignatureWindow/documentSignatureWindow'
import RequestDocument from '../../blocks/requestDocument/requestDocument'
import { Modal } from '../modal/modal'

const DutyPage = ({ selected, setSelected, documentsTypeList }) => {
	const baseURL = process.env.REACT_APP_BASE_URL
	const defaultLayoutPluginInstance = defaultLayoutPlugin()
	const { dutyData } = useSelector((state) => state.duty)
	const dealIdLocal = localStorage.getItem('dealId')
	const [requestDocument, setRequestDocument] = useState(null)
	const [requestDocumentWindow, setRequestDocumentWindow] = useState(false)
	const [requestSignatureWindow, setRequestSignatureWindow] = useState(false)
	const [addSigned, setAddSigned] = useState(false)
	const [activeUser, setActiveUser] = useState(null)
	const [documentTypeChange, setDocumentTypeChange] = useState(false)
	const dispatch = useDispatch()
	const accessToken = localStorage.getItem('accessToken')
	const [urlImg, setUrlImg] = useState(null)
	const [dutyFile, setDutyFile] = useState(null)
	const [
		windowConfirmationDeleteDocument,
		setWindowConfirmationDeleteDocument
	] = useState(false)

	const appeal_id = localStorage.getItem('appeal_id')
	const seller = localStorage.getItem('sellerStatus')
	const { documentId } = useParams()

	const navigate = useNavigate()

	const closeRequestDocumentWindow = () => {
		setRequestDocumentWindow(!requestDocumentWindow)
	}
	const closeRequestSignatureWindow = () => {
		setRequestSignatureWindow(!requestSignatureWindow)
	}

	const test2 = true

	return (
		<div className={s.documentCreationData}>
			<div className={s.documentCreationData__leftSidebar}>
				{dutyFile ? (
					<>
						{dutyData.media_type === 'application/pdf' && (
							<Worker workerUrl='/pdf.worker.bundle.worker.js'>
								<Viewer
									localization={ru_RU}
									fileUrl={dutyFile}
									plugins={[defaultLayoutPluginInstance]}
								/>
							</Worker>
						)}
					</>
				) : (
					<span className={s.documentCreationData__leftSidebar__bg}>
						Документ пока не загружен
					</span>
				)}
			</div>

			<div className={s.documentCreationData__rightSidebar}>
				{documentTypeChange ? (
					<div className={s.documentTypeChange}>
						<h4>Тип документа</h4>
					</div>
				) : (
					<>
						<h4>{selected?.title}</h4>
						<div className={s.documentCreationData__rightSidebar__fileWrapper}>
							<div
								className={
									s.documentCreationData__rightSidebar__fileWrapper__closeButton
								}
							>
								<span>Файл</span>
								<Link to={`/deal/${dealIdLocal}`} />
							</div>
							<div
								className={
									s.documentCreationData__rightSidebar__fileWrapper__downloadFalse
								}
							>
								{dutyData?.title ? (
									<>
										<div
											className={
												s.documentCreationData__rightSidebar__fileWrapper__downloadFalse__filePresent
											}
										>
											<span>
												{dutyData?.title} <br />
												<a
													href={`${baseURL}/file-cloud/${dutyData?.path}`}
													target='_blank'
													download
												>
													Скачать
												</a>
											</span>
											{dutyData?.signers_data.length ? null : (
												<button
												/* onClick={() =>
                               dispatch(documents.documentDeleteFile(appeal_id, documentId, accessToken))
                             }*/
												/>
											)}
										</div>
									</>
								) : (
									<div
										className={
											s.documentCreationData__rightSidebar__fileWrapper__downloadFalse__file
										}
									>
										<input
											type='file'
											name='file'
											id='input__file'
											onChange={(e) => setUrlImg(e.target.files[0])}
										/>
										<label htmlFor='input__file'>Загрузить документ...</label>
									</div>
								)}
								{dutyData?.can_upload_by_participant
									? !dutyData?.status_confirmed && (
											<div
												className={
													s.documentCreationData__rightSidebar__fileWrapper__downloadFalse__requestDocument
												}
											>
												<span>
													Документ будет запрошен у &#8195;
													{dutyData.can_upload_by_participant?.full_name
														? dutyData.can_upload_by_participant.full_name
														: requestDocument?.full_name}
												</span>
												<div>
													<button
													/*onClick={() =>
                          dispatch(
                            documents.documentRequestCancel(appeal_id, documentId, accessToken),
                          )
                        }*/
													>
														Отменить запрос
													</button>
												</div>
											</div>
									  )
									: dutyData?.file && (
											<button onClick={() => setRequestDocumentWindow(true)}>
												Выбрать физ. лица{' '}
											</button>
									  )}
							</div>
						</div>
						{test2 && (
							<div className={s.documentCreationData__rightSidebar__signature}>
								<div>
									<h4>Физ лица</h4>
								</div>
							</div>
						)}
						<div className={s.documentDelete}>
							<button onClick={() => setWindowConfirmationDeleteDocument(true)}>
								Удаление документа
							</button>
						</div>
					</>
				)}
			</div>

			{requestSignatureWindow && (
				<DocumentSignatureWindow
					requestSignatureWindow={requestSignatureWindow}
					closeRequestSignatureWindow={closeRequestSignatureWindow}
					test={activeUser}
				/>
			)}

			{requestDocumentWindow && (
				<RequestDocument
					requestDocumentWindow={requestDocumentWindow}
					closeRequestDocumentWindow={closeRequestDocumentWindow}
					requestDocument={requestDocument}
					setRequestDocument={setRequestDocument}
					addSigned={addSigned}
					setAddSigned={setAddSigned}
				/>
			)}

			{/*{windowConfirmationDeleteDocument && (
        <Modal active={windowConfirmationDeleteDocument}>
          <div className={s.windowConfirmationDeleteDocument}>
            <h4>Вы точно хотите удалить документ?</h4>
            {documentStatusText ? (
              <span>{documentStatusText}</span>
            ) : (
              <div className={s.windowConfirmationDeleteDocument__wrapperButtonDeleteDocument}>
                <button
                  onClick={() =>
                    dispatch(documents.documentDelete(appeal_id, documentId, accessToken))
                  }>
                  Да
                </button>
                <button onClick={() => setWindowConfirmationDeleteDocument(false)}>Нет</button>
              </div>
            )}
          </div>
        </Modal>
      )}*/}
		</div>
	)
}

export default DutyPage
