import React, { useEffect, useState } from 'react'
import s from '../../pages/login/login.module.scss'
import Input from '../input/input'
import Button from '../button/button'
import { useDispatch, useSelector } from 'react-redux'
import {
	setEmail,
	setPhone
} from '../../redux/passwordRecovery/passwordRecovery'
import { passwordRecovery } from '../../api/passwordRecovery/passwordRecovery'
import { resetPassword } from '../../api/resetPassword/resetPassword'

const PassportRecovery = ({ setRestorePassword, notClient }) => {
	const { phone, email, passwordRecoveryTextStatus } = useSelector(
		(state) => state.passwordRecovery
	)
	const regEmail = /\S+@\S+\.\S+/
	const regPhone = /^\+?[8] [-\(]?\d{3}\) ?-?\d{3}-?\d{2}-?\d{2}$/
	const [disabledButtonSend, setDisabledButtonSend] = useState(true)
	const [isVisible, setIsVisible] = useState(false)

	useEffect(() => {
		if (notClient) {
			setDisabledButtonSend(regEmail.test(email?.value))
		} else {
			setDisabledButtonSend(
				regEmail.test(email?.value) || regPhone.test(phone?.value)
			)
		}
	}, [phone, email])

	const sendPasswordRecovery = () => {
		if (notClient) {
			dispatch(resetPassword.employee(email.value))
		} else {
			dispatch(passwordRecovery.client(phone?.value, email?.value))
		}
		setIsVisible(true)
	}

	const dispatch = useDispatch()
	return (
		<div className={s.loginClient__formWrapper__restorePassword}>
			<h3>Восставновление пароля</h3>
			{!notClient && (
				<>
					<p>
						Введите почту или телефон, для которых нужно восстановить пароль
					</p>
					<div style={notClient ? { display: 'flex' } : { display: 'block' }}>
						<span>Телефон</span>
						<Input
							value={phone?.value}
							maxLength='17'
							validate={phone?.value && regPhone.test(phone?.value)}
							onChange={(e) =>
								dispatch(
									setPhone({
										value:
											+e.target.value.toString().length > 0 &&
											e.target.value.replace(
												/^(\d{1})(\d{3})(\d{3})(\d{2})(\d)+$/,
												'$1 ($2) $3-$4-$5'
											)
									})
								)
							}
						/>
					</div>
				</>
			)}
			<div style={notClient ? { display: 'flex' } : { display: 'block' }}>
				{notClient ? <span>Логин</span> : <span>Почта</span>}
				<Input
					value={email?.value}
					validate={email?.value && regEmail.test(email?.value)}
					onChange={(e) =>
						dispatch(
							setEmail({
								value: e.target.value
							})
						)
					}
				/>
			</div>
			{passwordRecoveryTextStatus && (
				<span className={s.passwordRecoveryTextStatus}>
					{passwordRecoveryTextStatus}
				</span>
			)}
			<div className={s.wrapperButtonLogin}>
				<Button FCOnclick={sendPasswordRecovery} disabled={!disabledButtonSend}>
					Восстановить пароль
				</Button>
				{notClient && (
					<span onClick={() => setRestorePassword(false)}>Вход</span>
				)}
			</div>
			<p 
				className={isVisible === true ? s.loginClient__formWrapper__restorePassword__textVisible : s.loginClient__formWrapper__restorePassword__textInvisible}
			>
				Ссылка на форму восстановления пароля отправленна Вам на электронную почту.
			</p>

			{!notClient && (
				<span
					className={s.loginButton}
					onClick={() => setRestorePassword(false)}
				>
					Вход / регистрация
				</span>
			)}
		</div>
	)
}

export default PassportRecovery
