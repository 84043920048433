import React, { useState } from 'react'
import menuIcon from '../../../common/images/menu.svg'
import s from './profile-content.module.scss'
import { SupportIcon } from '../../support-icon/support-icon'
import { Support } from '../../support/support'

export const ProfileContent = ({ onChangePassword, onOpenMenu, exitLogin }) => {
	const [openSupport, setOpenSupport] = useState(false)
	const userEmail = localStorage.getItem('userEmail')
	const userPhone = localStorage.getItem('userPhone')

	const handleCloseSupport = () => {
		setOpenSupport(false)
	}

	return (
		<div className={s.content}>
			<div className={s.content__headerMobile}>
				<div className={s.content__headerMobile__logo}>
					<div>
						<div>Уни</div>
						<div>строй</div>
					</div>
				</div>
				<div onClick={() => onOpenMenu(true)}>
					<img src={menuIcon} alt='' />
				</div>
			</div>
			<div className={s.content__data}>
				<p>Профиль</p>
				<div className={s.content__data__loginDetails}>
					<p>Данные для входа</p>
					<p>{userEmail}</p>
					<p>{userPhone}</p>
				</div>
				<div className={s.content__data__changePasswordAndExitProfile}>
					<div onClick={onChangePassword}>Сменить пароль...</div>
					<p onClick={() => exitLogin()}>Выйти из профиля</p>
				</div>
			</div>
			<div className={s.content__support}>
				<SupportIcon left='20px' setOpenSupport={setOpenSupport} />
			</div>
			{openSupport && <Support onClose={handleCloseSupport} />}
		</div>
	)
}
