import React, { useState } from 'react'
import s from './signingDocumentsSidebar.module.scss'
import { Link, useParams } from 'react-router-dom'
import Button from '../../components/button/button'
import { useSelector } from 'react-redux'

const SigningDocumentsSidebar = ({ width, documents }) => {
	const { documentId } = useParams()

	const { openedDocument } = useSelector((state) => state.client)

	const foundDocumentById = documents?.find(
		(document) => Number(documentId) === document?.id
	)

	const documentStatus = (document) => {
		return !document?.file_status && document?.can_upload
			? { text: 'загрузите документ', status: 1 }
			: document?.file_status &&
			  document?.can_upload &&
			  !document?.status_confirmed
			? { text: 'на проверке', status: 2 }
			: document?.status_confirmed && !document?.sign_status
			? { text: 'подпишите в приложении', status: 1 }
			: { text: document?.sign_status && 'подписано', status: 4 }
	}

	return (
		<>
			{width < 800 ? (
				<div className={s.signingDocumentsSidebarMobile}>
					<Link to='/taskListClient' className={s.backMobile}>
						<span>&#8592;</span>
					</Link>
					<div>
						<h4>{openedDocument?.document_type}</h4>
						<span>{documentStatus(foundDocumentById).text}</span>
					</div>
				</div>
			) : (
				<div className={s.signingDocumentsSidebar}>
					<Link to='/taskListClient'>&#8592; Кабинет</Link>
					<div className={s.signingDocumentsSidebar__itemWrapper}>
						<h3>Документы</h3>
						{documents?.map((document) => {
							documentStatus(document)
							return (
								<Link to={`/signingDocuments/${document?.id}`}>
									<div
										className={
											document?.id === Number(documentId)
												? s.signingDocumentsSidebar__itemWrapper__item__active
												: s.signingDocumentsSidebar__itemWrapper__item
										}
									>
										<h4>{document?.document_type}</h4>
										<div>
											<span>{documentStatus(document).text}</span>
											{documentStatus(document).status === 1 && <div></div>}
										</div>
									</div>
								</Link>
							)
						})}
					</div>
					{!openedDocument?.sign_status && (
						<Button>Открыть приложение и подписать...</Button>
					)}
				</div>
			)}
		</>
	)
}

export default SigningDocumentsSidebar
