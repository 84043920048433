import React, { useEffect } from 'react'
import { useState } from 'react'
import { InputRadio } from '../../input-radio/input-radio'
import Input from '../../input/input'
import s from './payment-method.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setObjectValue, setSelectRadioBtn } from '../../../redux/realty/realty'

export const PaymentMethod = () => {
	const [btnSelect, setBtnSelect] = useState(true)
	const { object, selectRadioBtn } = useSelector((state) => state.realty)
	const dispatch = useDispatch()

	useEffect(() => {
		if (object?.mutual_settlements) {
			dispatch(setSelectRadioBtn(object?.mutual_settlements))
		}

		if (object?.mortgage) {
			setBtnSelect(true)
		} else {
			setBtnSelect(false)
		}
	}, [object])

	useEffect(() => {
		dispatch(
			setObjectValue({
				name: 'mortgage',
				value: btnSelect
			})
		)
	}, [btnSelect])

	useEffect(() => {
		dispatch(
			setObjectValue({
				name: 'mutual_settlements',
				value: selectRadioBtn
			})
		)
	}, [selectRadioBtn])

	const isRadioSelected = (value) => selectRadioBtn === value
	return (
		<div className={s.container}>
			<p>Способ оплаты</p>
			<ul>
				<li>Ипотека</li>
				<li className={s.btn}>
					<button
						onClick={() => setBtnSelect(true)}
						className={`${s.buttonSelectYes} ${
							btnSelect && s.buttonSelectActive
						}`}
					>
						Да
					</button>
					<button
						onClick={() => setBtnSelect(false)}
						className={`${s.buttonSelectNo} ${
							!btnSelect && s.buttonSelectActive
						}`}
					>
						Нет
					</button>
				</li>
			</ul>
			<ul className={s.settLementForm}>
				<li>Форма взаиморасчётов</li>
				<li>
					<InputRadio
						onChange={() => dispatch(setSelectRadioBtn('LETTER_CREDIT'))}
						checked={isRadioSelected('LETTER_CREDIT')}
						label='Аккредитив'
						value={selectRadioBtn}
					/>
					<InputRadio
						onChange={() => dispatch(setSelectRadioBtn('ESCROW'))}
						checked={isRadioSelected('ESCROW')}
						label='Эскроу'
						value={selectRadioBtn}
					/>
					<InputRadio
						onChange={() => dispatch(setSelectRadioBtn('ANY'))}
						checked={isRadioSelected('ANY')}
						label='Другое'
						value={selectRadioBtn}
					/>
				</li>
			</ul>
			<ul className={s.accountingOrganization}>
				<li>Организация, проводящая взаиморасчёты</li>
				<li>
					<Input
						textarea
						value={object?.organization_settlements}
						name='organization_settlements'
						onChange={(e) =>
							dispatch(
								setObjectValue({
									name: e.target.name,
									value: e.target.value
								})
							)
						}
					/>
				</li>
			</ul>
		</div>
	)
}
