import React, { useCallback, useEffect, useRef } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { address } from '../../../api/address'
import { deals } from '../../../api/deals/deals'
import { realty } from '../../../api/realty/realty'
import { setFoundAddress } from '../../../redux/address/address'
import Input from '../../input/input'
import Textarea from '../../textarea/textarea'
import s from './real-estate.module.scss'
import { debounce } from 'lodash'
import { setObjectTypeState } from '../../../redux/realty/realty'
import { Collapse } from 'react-collapse'

export const RealEstate = ({
	foundRealty,
	seller,
	realtyData,
	handleRealtyData,
	handleTypeOriginObjId,
	typeRealtyValueSelected,
	setRealtyData,
	selectedValuetypeAppeals,
	selectTypeOriginObjId,
	handleTypeId,
	selectedValuetypeAppealsId,
	objectType,
	setObjectType,
}) => {
	const dispatch = useDispatch()
	const accessToken = localStorage.getItem('accessToken')
	const addressInputRef = useRef()
	const realtyTypes = useSelector((state) => state.realty.types)
	const { dicts, objectTypeState } = useSelector((state) => state.realty)
	const { foundAddress } = useSelector((state) => state.address)
	const [addressInputIsFocus, setAddressInputIsFocus] = useState()

	const [openWindowAddress, setOpenWindowAddress] = useState(false)

	useEffect(() => {
		setOpenWindowAddress(false)
	}, [selectedValuetypeAppeals, seller])

	useEffect(() => {
		if (realtyData.agreement_number && realtyData.agreement_date) {
			dispatch(
				deals.realtySearch(
					realtyData.agreement_number,
					realtyData.agreement_date,
					accessToken
				)
			)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [realtyData.agreement_number, realtyData.agreement_date])

	useEffect(() => {
		dispatch(realty.getRealtyTypes(accessToken))
		dispatch(realty.getDicts(accessToken))
	}, [])

	const handleFindAddress = useCallback(
		debounce(async (addressName) => {
			dispatch(address.getAddress(addressName))
		}, 350),
		[]
	)

	useEffect(() => {
		if (realtyData?.object?.cadastral_number && foundAddress?.length === 1) {
			setRealtyData({
				...realtyData,
				object: { ...realtyData.object, address: foundAddress[0].value }
			})
		}
		if (
			realtyData?.object?.cadastral_number &&
			addressInputRef?.current !== document.activeElement &&
			(foundAddress?.length > 1 || foundAddress?.length === 0)
		) {
			setRealtyData({
				...realtyData,
				object: { ...realtyData.object, address: '' }
			})
		}

		if (addressInputRef?.current !== document.activeElement) {
			setAddressInputIsFocus(false)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [foundAddress])

	useEffect(() => {
		dispatch(setObjectTypeState(objectType))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectType])

	useEffect(() => {
		dispatch(setObjectTypeState(null))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seller])

	return (
		<div className={s.realEstate}>
			<p className={s.realEstate__text}>Объект недвижимости</p>
			{seller === 'UNISTROY' && (
				<>
					<div className={s.realEstate__details}>
						<p>Вид право объекта</p>
						<p>
							{!foundRealty && <>&mdash;</>}{' '}
							{foundRealty && foundRealty?.right_type}
						</p>
					</div>

					<div className={s.realEstate__details}>
						<p>Номер договора</p>
						{seller !== 'THIRD_PARTY' && (
							<Input
								name='agreement_number'
								value={realtyData?.agreement_number}
								onChange={handleRealtyData}
							/>
						)}
						{foundRealty && <p className={s.objectFound}>Объект найден</p>}

						{!foundRealty && foundRealty !== null && (
							<p className={s.objectNotFound}>Объект не найден</p>
						)}
					</div>
					<div className={s.realEstate__details}>
						<p>Дата договора</p>
						{seller !== 'THIRD_PARTY' && (
							<Input
								type='date'
								name='agreement_date'
								value={realtyData?.agreement_date}
								onChange={handleRealtyData}
							/>
						)}
						{foundRealty && <p className={s.objectFound}>Объект найден</p>}

						{!foundRealty && foundRealty !== null && (
							<p className={s.objectNotFound}>Объект не найден</p>
						)}
					</div>
					<div className={s.realEstate__details}>
						<p>Описание</p>
						<p>
							{!foundRealty && <>&mdash;</>}{' '}
							{foundRealty && foundRealty?.description}
						</p>
					</div>
					<div className={s.realEstate__details}>
						<p>Основной тип</p>
						<Input
							name='type'
							value={selectTypeOriginObjId?.title}
							id={s.realEstate__details__input}
							select
							setSelectedValue={handleTypeOriginObjId}
							selectedValue={selectTypeOriginObjId}
							dataList={realtyTypes}
							placeholderForSelect='Выберите тип объекта'
							placeholder='Выберите тип объекта'
							readOnly
						/>
					</div>

					<div className={s.realEstate__details}>
						<p>КН земельного участка</p>
						<p>
							{foundRealty?.cadastral_number_area ? (
								foundRealty?.cadastral_number_area
							) : (
								<>&mdash;</>
							)}
						</p>
					</div>

					<div className={s.realEstate__details}>
						<p>S застройки</p>
						<p>
							{foundRealty?.building_area ? (
								foundRealty?.building_area
							) : (
								<>&mdash;</>
							)}
						</p>
					</div>

					<div className={s.realEstate__details}>
						<p>ЖК</p>
						<p>
							{!foundRealty && <>&mdash;</>}{' '}
							{foundRealty && foundRealty?.apartment_complex}
						</p>
					</div>
					<div className={s.realEstate__details}>
						<p>Тип</p>
						<p>
							{!foundRealty && <>&mdash;</>} {foundRealty && foundRealty?.type}
						</p>
					</div>
					<div className={s.realEstate__details}>
						<p>Кадастровый номер</p>
						<p>
							{!foundRealty && <>&mdash;</>}{' '}
							{foundRealty && foundRealty?.cadastral_number}
						</p>
					</div>
					<div className={s.realEstate__details}>
						<p>Площадь</p>
						<p>
							{!foundRealty && <>&mdash;</>}{' '}
							{foundRealty && foundRealty?.square}
						</p>
					</div>
					<div className={s.realEstate__details}>
						<p>Адрес</p>
						<p>
							{!foundRealty && <>&mdash;</>}{' '}
							{foundRealty && foundRealty?.address}
						</p>
					</div>

					<>
						<div className={s.realEstate__details}>
							<p>Условный № дома</p>
							<p>
								{foundRealty?.suspended_number_building ? (
									foundRealty?.suspended_number_building
								) : (
									<>&mdash;</>
								)}
							</p>
						</div>

						<div className={s.realEstate__details}>
							<p>Условный № квартиры</p>
							<p>
								{foundRealty?.suspended_number_flat ? (
									foundRealty?.suspended_number_flat
								) : (
									<>&mdash;</>
								)}
							</p>
						</div>
					</>
					<div className={s.realEstate__details}>
						<p>Продавец</p>
						<p>
							{!foundRealty && <>&mdash;</>}{' '}
							{foundRealty && foundRealty?.seller}
						</p>
					</div>
				</>
			)}

			{seller === 'THIRD_PARTY' && (
				<>
					<div className={s.realEstate__details}>
						<p>Основной тип</p>
						<Input
							name='type'
							value={selectTypeOriginObjId?.title}
							id={s.realEstate__details__input}
							select
							setSelectedValue={handleTypeOriginObjId}
							selectedValue={selectTypeOriginObjId}
							dataList={realtyTypes}
							placeholderForSelect='Выберите тип объекта'
							placeholder='Выберите тип объекта'
							readOnly
						/>
					</div>

					<div className={s.realEstate__details}>
						<p>Вид право объекта</p>
						<Input
							value={
								objectTypeState?.title ? objectTypeState.title : objectTypeState
							}
							setSelectedValue={setObjectType}
							selectedValue={objectType}
							select
							dataList={dicts}
							placeholder='Выберите вид право объекта'
							placeholderForSelect='Выберите вид право объекта'
						/>
					</div>

					<div className={s.realEstate__details}>
						<p>КН земельного участка</p>
						<Input
							name='cadastral_number_area'
							id={s.realEstate__details__input}
							value={realtyData?.object?.cadastral_number_area}
							onChange={handleRealtyData}
						/>
					</div>

					<div className={s.realEstate__details}>
						<p>Кадастровый номер</p>
						<Input
							id={s.realEstate__details__input}
							value={realtyData?.object?.cadastral_number}
							onChange={handleRealtyData}
							name='cadastral_number'
						/>
					</div>
					<div className={s.realEstate__details}>
						<p>Адрес</p>
						<Input
							name='address'
							id={s.realEstate__details__input}
							value={realtyData?.object?.address}
							onChange={(ev) => {
								handleRealtyData(ev)
								handleFindAddress(ev.target.value)
							}}
							autoComplete='off'
							nameRef={addressInputRef}
							onFocus={() => setAddressInputIsFocus(true)}
						/>
						<div className={s.realEstate__details} style={{ marginTop: 0 }}>
							<p></p>
							{addressInputIsFocus &&
								foundAddress &&
								realtyData?.object?.address &&
								foundAddress?.length !== 0 && (
									<div className={s.foundAddres}>
										{foundAddress.map((address, i) => {
											return (
												<div
													key={i}
													onClick={() => {
														setRealtyData({
															...realtyData,
															object: {
																...realtyData.object,
																address: address.full_name
															}
														})
														dispatch(setFoundAddress(null))
													}}
												>
													<span>{address.full_name}</span>
												</div>
											)
										})}
									</div>
								)}
						</div>
					</div>
					<div className={s.realEstate__details}>
						<p>S застройки</p>
						<Input
							name='building_area'
							id={s.realEstate__details__input}
							value={realtyData?.object?.building_area?.replace(/,/, '.')}
							onChange={handleRealtyData}
						/>
					</div>
					<div className={s.realEstate__details__textarea}>
						<p>Описание</p>
						<div>
							<Textarea
								name='description'
								value={realtyData?.object?.description}
								placeholder=''
								onChange={handleRealtyData}
							/>
							<p>Данные продавца можно внести после создания сделки</p>
						</div>
					</div>

					<div className={s.realEstate__details__additionalInputWrapper}>
						<span onClick={() => setOpenWindowAddress(!openWindowAddress)}>
							{openWindowAddress
								? 'Скрыть дополнительные поля'
								: 'Уточнить адрес'}
						</span>

						<Collapse isOpened={openWindowAddress}>
							<div
								className={s.realEstate__details__additionalInputWrapper__block}
							>
								<div className={s.realEstate__details}>
									<p className={s.dealTypeField}>Тип2</p>
									<Input
										value={selectedValuetypeAppealsId?.title}
										setSelectedValue={handleTypeId}
										selectedValue={selectedValuetypeAppealsId}
										select
										dataList={realtyTypes}
										placeholderForSelect='Выберите тип помещения'
										placeholder='Выберите тип помещения'
										readOnly
									/>
								</div>
								<div className={s.realEstate__details}>
									<p>Площадь помещения</p>
									<Input
										name='square'
										id={s.realEstate__details__input}
										value={realtyData?.square}
										onChange={(e) =>
											setRealtyData({
												...realtyData,
												[e.target.name]: e.target.value
											})
										}
									/>
								</div>
								<div className={s.realEstate__details}>
									<p>№ дома</p>
									<Input
										name='suspended_number_building'
										id={s.realEstate__details__input}
										value={realtyData?.suspended_number_building}
										type='number'
										onChange={(e) =>
											setRealtyData({
												...realtyData,
												[e.target.name]: e.target.value
											})
										}
									/>
								</div>
								<div className={s.realEstate__details}>
									<p>№ квартиры</p>
									<Input
										name='suspended_number_flat'
										type='number'
										id={s.realEstate__details__input}
										value={realtyData?.suspended_number_flat}
										onChange={(e) =>
											setRealtyData({
												...realtyData,
												[e.target.name]: e.target.value
											})
										}
									/>
								</div>
							</div>
						</Collapse>
					</div>
				</>
			)}
		</div>
	)
}
