import React from 'react'
import {Modal} from '../modal/modal'
import {useSelector} from 'react-redux'
import s from './index.module.scss'
import downloadIcon from '../../common/images/download.svg'

const RegistrationRRModal = ({registrationRR, setRegistrationRR}) => {
  const {organizationOffices, taskListClientData} = useSelector(
    (state) => state.client
  )
  const baseURL = process.env.REACT_APP_BASE_URL

  return (
    <Modal
      onClose={true}
      active={registrationRR}
      setActive={setRegistrationRR}
      mobileHeaderText={'Обращение в Росреестр'}
    >
      <div className={s.registrationRRModalWrapper}>
        <h3>Обращение в Росреестр</h3>
        <h4>Регистрационные данные обращения</h4>

        <div className={s.registrationRRModalWrapper__dataWrapper}>
          <div className={s.registrationRRModalWrapper__dataWrapper__item}>
            <h4>Номер (№ КУВД)</h4>
            <span>{taskListClientData.request_rosreestr.kuvd ? taskListClientData.request_rosreestr.kuvd : '-'}</span>
          </div>

          <div className={s.registrationRRModalWrapper__dataWrapper__item}>
            <h4>Тип (тип обращения)</h4>
            <span>{taskListClientData.type_appeal_title ? taskListClientData.type_appeal_title : '-'}</span>
          </div>
        </div>


        <h4 style={{marginTop: '30px'}}>История рассмотрения</h4>

        <div className={s.registrationRRModalWrapper__dataWrapper}>
          <div className={s.registrationRRModalWrapper__dataWrapper__item}>
            <h4>Отправлено в Росреестр</h4>
            <span>

               <div>
              <span>{taskListClientData.request_rosreestr.date_send ? taskListClientData.request_rosreestr.date_send : '-'}</span>
              <a style={{textDecoration: 'underline'}} target='_blank'
                 href={`${baseURL}/file-cloud/${taskListClientData.request_rosreestr.package_path}`}> <img
                src={downloadIcon} alt="downloadIcon"/></a>
            </div>
            </span>

          </div>

          <div className={s.registrationRRModalWrapper__dataWrapper__item}>
            <h4>Получен ответ от Росреестра</h4>
            <span>
              <div>
                <span>{taskListClientData.request_rosreestr.status_label}</span>

                {taskListClientData.request_rosreestr.request_file
                && <a style={{textDecoration: 'underline'}} target='_blank'
                      href={taskListClientData.request_rosreestr.request_file}> <img
                  src={downloadIcon} alt="downloadIcon"/></a>}

              </div>
              </span>
          </div>
        </div>

      </div>

    </Modal>
  )
}

export default RegistrationRRModal
