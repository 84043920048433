import { createSlice } from '@reduxjs/toolkit'

const passwordRecoverySlice = createSlice({
	name: 'passwordRecoverySlice',
	initialState: {
		phone: null,
		email: null,
		validateEmail: true,
		validatePhone: true,
		passwordRecovery: false,
		disabledButton: true,
		passwordRecoveryTextStatus: null,
		statusPasswordRecovery: false
	},
	reducers: {
		setPhone(state, action) {
			state.phone = action.payload
		},
		setEmail(state, action) {
			state.email = action.payload
		},
		setValidateEmail(state, action) {
			state.validateEmail = action.payload
		},
		setValidatePhone(state, action) {
			state.validatePhone = action.payload
		},
		setPasswordRecovery(state, action) {
			state.passwordRecovery = action.payload
		},
		setDisabledButton(state, action) {
			state.disabledButton = action.payload
		},
		setPasswordRecoveryTextStatus(state, action) {
			state.passwordRecoveryTextStatus = action.payload?.text
			state.statusPasswordRecovery = action.payload?.status
		}
	}
})

export default passwordRecoverySlice.reducer

export const {
	setPhone,
	setEmail,
	setPasswordRecovery,
	setPasswordRecoveryTextStatus,
} = passwordRecoverySlice.actions
