import React, { useEffect } from 'react'
import s from './requestDocument.module.scss'
import { Modal } from '../../components/modal/modal'
import { useDispatch, useSelector } from 'react-redux'
import { appeals } from '../../api/appeals'
import { useParams, useLocation } from 'react-router'
import { documents } from '../../api/document/document'
import { setSaveIdUserDocumentRequest } from '../../redux/documents/documents'
import { user } from '../../api/user'

const RequestDocument = ({
	requestDocumentWindow,
	closeRequestDocumentWindow,
	requestDocument,
	setRequestDocument,
	addSigned,
	setAddSigned
}) => {
	const { appealDeal } = useSelector((state) => state.appeals)
	const { signatureManual } = useSelector((state) => state.documents)
	const { userName } = useSelector((state) => state.login)
	const dispatch = useDispatch()
	const accessToken = localStorage.getItem('accessToken')
	const { documentId } = useParams()
	const appeal_id = localStorage.getItem('appeal_id')
	const { state } = useLocation()

	useEffect(() => {
		if (!appealDeal) {
			if (appeal_id) {
				dispatch(appeals.appealDeal(appeal_id))
			}
		}
	}, [])

	useEffect(() => {
		if (!requestDocument) return

		if (addSigned) {
			setAddSigned(false)
			if (state?.source === 'egrn') {
				dispatch(
					documents.addEgrnDocumentSigner(
						documentId,
						signatureManual,
						accessToken
					)
				)
			} else {
				dispatch(
					documents.addDocumentSigner(
						appeal_id,
						documentId,
						requestDocument.participant_id,
						signatureManual,
						accessToken
					)
				)
			}
			setRequestDocument(null)
		} else {
			dispatch(setSaveIdUserDocumentRequest(requestDocument.participant_id))
			dispatch(
				documents.documentRequestUser(
					appeal_id,
					documentId,
					requestDocument.participant_id,
					accessToken
				)
			)
		}
	}, [requestDocument])

	useEffect(() => {
		if (!userName) {
			dispatch(user.getUserName(accessToken))
		}
	}, [accessToken, dispatch, userName])

	// if (!appealDeal) {
	// 	return <h4>Загрузка...</h4>
	// }

	return (
		<div className={s.requestDocument}>
			<Modal
				active={requestDocumentWindow}
				setActive={closeRequestDocumentWindow}
				onClose={true}
			>
				{addSigned ? (
					<h3>Добавить подписантов</h3>
				) : (
					<h3>Запросить документ</h3>
				)}

				{state?.source === 'egrn' ? (
					<h4 onClick={() => setRequestDocument(documentId)} style={{ color: '#35393C' }}>{userName}</h4>
				) : (
					appealDeal?.participant.map((item) => {
						return (
							<div
								className={s.requestDocument__item}
								key={item.id}
								onClick={() => setRequestDocument(item)}
							>
								<div className={s.requestDocument__item__name}>
									<h4 style={{ color: '#35393C' }}>{item?.full_name}</h4>
									<span style={{ color: '#35393C' }}>{item.role?.title}</span>
								</div>
							</div>
						)
					})
				)}
			</Modal>
		</div>
	)
}
export default RequestDocument
