import React from 'react'
import s from './deals-table.module.scss'
import messageIcon from '../../../common/images/messageDeal.svg'
import newMessageIcon from '../../../common/images/newMessageIcon.svg'
import infoStatusRedIcon from '../../../common/images/infoStatusRed.svg'
import successIcon from '../../../common/images/success.svg'
import { Link } from 'react-router-dom'
import falseStatusDocument from '../../../common/images/ProgressStatusesFalse.svg'
import billDocument from '../../../common/images/Bill.svg'
import { useSelector } from 'react-redux'

export const DealsTable = ({ pageName, userRole, item }) => {
	const { newMessages } = useSelector((state) => state.chat)
	const foundNewMessages =
		newMessages && newMessages.find((message) => message.deal_id === item.id)
	return (
		<>
			<Link to={`/deal/${item.id}`}>
				<div className={s.container}>
					<div className={s.infoDeal}>
						<div className={s.infoDeal__object}>
							<div className={s.infoDeal__object__item}>
								<div className={s.infoDeal__object__item__object}>
									<p>{item.object.cadastral_number}</p>
									<p>{item.object.description}</p>
									<div>
										<p>
											{item.seller === 'UNISTROY'
												? `Унистрой - ${item.employee?.full_name}`
												: `Третьи лица`}
										</p>
										<span>
											Номер КУВД:
											<i>{item.appeal_kuvd ? item.appeal_kuvd : ' - '}</i>
										</span>
									</div>
								</div>
								<div className={s.infoDeal__object__item__buyer}>
									{item.participant?.map((item, key) => (
										<div key={key}>
											<p>{item.full_name}</p>
											<p>{item.representative?.full_name}</p>
										</div>
									))}
									<p
										style={
											!item.general_pd_status && item.participant.length
												? { color: '#ED6A5A' }
												: { color: 'inherit' }
										}
									>
										{!item.general_pd_status && item.participant.length
											? 'Требуется проверка Пдн'
											: null}
									</p>
								</div>
								<div className={s.infoDeal__object__item__progressDoc}>
									<div>
										<img
											src={
												item.general_pd_status
													? successIcon
													: !item.general_pd_status && item.participant.length
													? infoStatusRedIcon
													: falseStatusDocument
											}
											alt=''
										/>
										<p>ПД</p>
									</div>
									<div>
										<img
											src={
												item.general_ecp_status
													? successIcon
													: falseStatusDocument
											}
											alt=''
										/>
										<p>ЭЦП</p>
									</div>
									<div>
										<img
											src={
												item.general_docs_status
													? successIcon
													: falseStatusDocument
											}
											alt=''
										/>
										<p>Доки</p>
									</div>
									<div>
										<img
											src={
												item.general_rosreestr_status
													? successIcon
													: falseStatusDocument
											}
											alt=''
										/>
										<p>РР</p>
									</div>
									<div>
										<img
											src={
												item.payments_count >= 1 ?
													billDocument
													: falseStatusDocument
											}
											alt=''
										/>
										<p>Пошлина</p>
									</div>
								</div>
								{pageName !== 'archive' && userRole !== 'AD' && (
									<div
										style={{ width: '300px' }}
										className={s.infoDeal__object__item__message}
									>
										{foundNewMessages?.deal_id === item?.id ? (
											<img src={newMessageIcon} alt='' />
										) : (
											<img src={messageIcon} alt='' />
										)}
									</div>
								)}
								{userRole === 'AD' && pageName !== 'archive' && (
									<div className={s.infoDeal__object__item__manager}>
										<p>
											{item.employee?.full_name
												.split(' ')
												.slice(0, -1)
												.join(' ')}
										</p>
										<p>
											{item.employee?.role === 'AD'
												? 'Администратор'
												: 'Менеджер'}
										</p>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</Link>
		</>
	)
}
