import { createSlice } from '@reduxjs/toolkit'

const manager = createSlice({
	name: 'manager',
	initialState: {
		loadingApp: false,
		representativeData: false,
		photoPagePassport: null,
		headerTypeData: 'participant',
		signaturePagePassport: null,
		snilsPagePassport: null,
		roles: null,
		role: null,
		roleId: null,
		notificationText: null,
		edisUserStatus: '',
		representativeStatus: false,
		deleteUserText: null,
		dataCorrect: false,
		type: 'INDIVIDUAL',
		accStatus: null,
		deleteImgStatus: false,
		newUser: true,
		listCopiedParticipant: null,
		listCopiedDocuments: null,
		dataLoad: false,
		photoPassportPdf1: null,
		photoPassportPdf2: null,
		photoSnilsPdf: null,
		photoPassportPdf1Representative: null,
		photoPassportPdf2Representative: null,
		photoSnilsPdfRepresentative: null,
		rolesPowersList: null,
		rolesPowerId:null,
		powerAttorney:null,
		searchList: [],
		searchResult: false,
		buttonState: false,
		photoPassport1: null,
		photoPassport2: null,
		photoSnils: null,
		fileAttorney: null,
		fileSig: null,
		fileDate: null
	},
	reducers: {
		setRoles(state, action) {
			state.roles = action.payload
		},
		setNotificationText(state, action) {
			state.notificationText = action.payload
		},
		setRole(state, action) {
			state.role = action?.payload ? action?.payload : null
		},
		setRoleId(state, action) {
			state.roleId = action?.payload ? action?.payload.id : null
		},
		setSnilsPagePassport(state, action) {
			state.snilsPagePassport = action.payload
		},
		setPhotoPagePassport(state, action) {
			state.photoPagePassport = action.payload
		},
		setSignaturePagePassport(state, action) {
			state.signaturePagePassport = action.payload
		},
		setDeleteUserText(state, action) {
			state.deleteUserText = action.payload
		},
		setDataCorrect(state, action) {
			state.dataCorrect = action.payload
		},
		setType(state, action) {
			state.type = action.payload
		},
		setAccStatus(state, action) {
			state.accStatus = action.payload
		},
		setDeleteImgStatus(state, action) {
			state.deleteImgStatus = action.payload
		},
		setNewUser(state, action) {
			state.newUser = action.payload
		},
		setListCopiedParticipant(state, action) {
			state.listCopiedParticipant = action.payload
		},
		setListCopiedDocuments(state, action) {
			state.listCopiedDocuments = action.payload
		},
		setDataLoad(state, action) {
			state.dataLoad = action.payload
		},
		setRepresentativeStatus(state, action) {
			state.representativeStatus = action.payload
		},
		setLoadingApp(state, action) {
			state.loadingApp = action.payload
		},
		setHeaderTypeData(state, action) {
			state.headerTypeData = action.payload
		},
		setRepresentativeData(state, action) {
			state.representativeData = action.payload
		},
		setPhotoPassportPdf1(state, action) {
			state.photoPassportPdf1 = action.payload
		},
		setPhotoPassportPdf1Representative(state, action) {
			state.photoPassportPdf1Representative = action.payload
		},
		setPhotoPassportPdf2(state, action) {
			state.photoPassportPdf2 = action.payload
		},
		setPhotoPassportPdf2Representative(state, action) {
			state.photoPassportPdf2Representative = action.payload
		},
		setPhotoSnilsPdf(state, action) {
			state.photoSnilsPdf = action.payload
		},
		setPhotoSnilsPdfRepresentative(state, action) {
			state.photoSnilsPdfRepresentative = action.payload
		},
		setRolesPowersList(state, action) {
			state.rolesPowersList = action.payload
		},
		setRolesPowerId(state, action) {
			state.rolesPowerId = action.payload
		},
		setPowerAttorney(state, action) {
			state.powerAttorney = action.payload
		},
		setSearchList(state, action) {
			state.searchList = action.payload
		},
		setSearchResult(state, action) {
			state.searchResult = action.payload
		},
		setButtonState(state, action) {
			state.buttonState = action.payload
		},
		setPhotoPassport1(state, action) {
			state.photoPassport1 = action.payload
		},
		setPhotoPassport2(state, action) {
			state.photoPassport2 = action.payload
		},
		setPhotoSnils(state, action) {
			state.photoSnils = action.payload
		},
		setFileAttorney(state, action) {
			state.fileAttorney = action.payload
		},
		setFileSig(state, action) {
			state.fileSig = action.payload
		},
		setFileDate(state, action) {
			state.fileDate = action.payload
		},
	}
})

export default manager.reducer

export const {
	setRoles,
	setNotificationText,
	setRole,
	setDeleteUserText,
	setDataCorrect,
	setType,
	setSnilsPagePassport,
	setPhotoPagePassport,
	setSignaturePagePassport,
	setAccStatus,
	setDeleteImgStatus,
	setNewUser,
	setListCopiedParticipant,
	setListCopiedDocuments,
	setDataLoad,
	setLoadingApp,
	setRepresentativeStatus,
	setHeaderTypeData,
	setRepresentativeData,
	setOrganizations,
	setPhotoPassportPdf1,
	setPhotoPassportPdf2,
	setPhotoSnilsPdf,
	setPhotoPassportPdf1Representative,
	setPhotoPassportPdf2Representative,
	setPhotoSnilsPdfRepresentative,
	setRolesPowersList,
	setRolesPowerId,
	setPowerAttorney,
	setSearchList,
	setSearchResult,
	setButtonState,
	setPhotoPassport1,
	setPhotoPassport2,
	setPhotoSnils,
	setFileAttorney,
	setFileSig,
	setFileDate
} = manager.actions
