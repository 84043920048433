import React, { useEffect, useState } from 'react'
import s from './ecp.module.scss'
import IssueEcp from '../issueEcp/issueEcp'
import { useDispatch, useSelector } from 'react-redux'
import { ecp } from '../../api/ecp/ecp'
import loadStatusImg from '../../common/images/deal/progressStatuses.svg'
import preloader from '../../common/preloader/preloader.gif'
import Input from '../../components/input/input'
import moment from 'moment'
import Button from '../../components/button/button'
import PopUpComplete from '../../components/popUpComplete/popUpComplete'
import EcpComment from '../../components/ecp-comment'
import { setEcpStatusText, setAlias, setLoadStatus, setOrganization, setEcpTariff } from '../../redux/ecp'

const Ecp = ({
	type = ``,
	setNotificationText,
	withParticipant = false,
	isUser = false,
	employeeId
}) => {
	const [editionStatus, setEditionStatus] = useState('Выпустить')
	const accessToken = localStorage.getItem('accessToken')
	const participant_id = localStorage.getItem('participant_id')
	const dispatch = useDispatch()

	const [file1, setFile1] = useState(null)
	const [file2, setFile2] = useState(null)
	const [tariffEcpData, setTariffEcpData] = useState(null)
	const [idOrganizations, setIdOrganizations] = useState(null)
	const [date, setDate] = useState(null)
	const [disabledButton, setDisabledButton] = useState(true)
	const [photoPassport1, setPhotoPassport1] = useState(null)
	const [photoPassport2, setPhotoPassport2] = useState(null)
	const [powerOfAttorney, setPowerOfAttorney] = useState(null)
	const [isRequestSending, setIsRequestSending] = useState(false)
	const [powerOfAttorneyLoading, setPowerOfAttorneyLoading] = useState(false)
	const [pdfLoading, setPdfLoading] = useState(false)

	const {
		alias,
		ecpStatusText,
		loadEcpStatus,
		ecpData,
		organizations,
		ecpTariffs,
		personalDataEcp,
	} = useSelector((state) => state.ecp)

	const { globalPersonalData } = useSelector(
		(state) => state.globalPersonalData
	)
	const { notificationText, representativeStatus } = useSelector(
		(state) => state.manager
	)

	useEffect(() => {
		dispatch(ecp.getTariffs(accessToken))
		dispatch(ecp.getOrganizations(accessToken))
	}, [])

	useEffect(() => {
		if (isUser) return;
		dispatch(ecp.getEcp(participant_id, accessToken))
	}, [isUser])

	useEffect(() => {
		if (notificationText) {
			setTimeout(() => {
				dispatch(setNotificationText(null))
			}, 3000)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notificationText])

	useEffect(() => {
		tariffEcpData && idOrganizations
			? setDisabledButton(false)
			: setDisabledButton(true)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tariffEcpData, idOrganizations])

	const handleChange = (event) => {
		setFile1(event.target.files[0])
	}
	const handleChange2 = (event) => {
		setFile2(event.target.files[0])
	}

	const sendImgDocument = async () => {
		const formData = new FormData()

		if (ecpData.comment) {
			file1 && formData.append('files_statement', file1)
			file2 && formData.append('photo_owner_statement', file2)
			photoPassport1 && formData.append('photo_owner_passport', photoPassport1)
			photoPassport2 &&
				formData.append('photo_owner_passport_signature', photoPassport2)
			powerOfAttorney && formData.append('power_attorney', powerOfAttorney)
			dispatch(ecp.sendPhoto(accessToken, ecpData?.uuid, formData))
		} else {
			file1 && formData.append('files_statement', file1)
			file2 && formData.append('photo_owner_statement', file2)
			dispatch(ecp.sendPhoto(accessToken, ecpData?.uuid, formData))
		}
	}

	useEffect(() => {
		if (!ecpData) return
		if (ecpData?.application_status === '3') {
			dispatch(setEcpStatusText(null))
		}

		if (ecpData?.application_status === '10') {
			let dateEnd = moment(new Date(ecpData?.expire_date))
			let toDayDate = moment(new Date())
			setDate(dateEnd.diff(toDayDate, 'days'))
		}

		if (ecpData?.ecp_status) {
			setEditionStatus('Уже есть')
		} else {
			setEditionStatus('Выпустить')
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ecpData])

	const issueSignature = () => {
		if (isUser) {
			dispatch(
				ecp.sendUserEcp(
					accessToken,
					employeeId,
					alias.value,
					tariffEcpData.TariffId,
					tariffEcpData.ExpireDay,
					false,
					idOrganizations.id,
					tariffEcpData.Cost,
					tariffEcpData.Name
				)
			)
		} else {
			dispatch(
				ecp.sendEcp(
					accessToken,
					globalPersonalData.participant_id,
					alias.value,
					tariffEcpData.TariffId,
					tariffEcpData.ExpireDay,
					false,
					idOrganizations.id,
					tariffEcpData.Cost,
					tariffEcpData.Name
				)
			)
		}

		dispatch(setLoadStatus(true))
		dispatch(setEcpStatusText(null))
	}

	const reissueEcp = () => {
		dispatch(setLoadStatus(true))
		dispatch(
			ecp.sendEcp(
				accessToken,
				globalPersonalData?.participant_id,
				ecpData?.alias,
				ecpData?.tariff_id,
				ecpData?.expire_day,
				true,
				idOrganizations?.id,
				tariffEcpData?.Cost,
				tariffEcpData?.Name
			)
		)
	}

	const sendNewAlias = () => {
		dispatch(ecp.sendNewAlias(ecpData.uuid, alias.value, accessToken))
	}

	const uploadPhoto = () => {
		dispatch(setLoadStatus(true))
		if(isUser) {
			dispatch(ecp.uploadUserEcpDocuments(accessToken, ecpData.uuid, employeeId))
		} else {
			dispatch(ecp.sendRequests(accessToken, ecpData.uuid, participant_id))
		}
	}

	const sendRequests = () => {
		if(alias.value === undefined) {
			setIsRequestSending(true)
			dispatch(ecp.sendForVerification(accessToken, ecpData.uuid))
		} else {
			dispatch(ecp.sendNewAlias(ecpData.uuid, alias.value, accessToken))
			if (ecpStatusText == 'Новый код авторизации успешно установлен') {
				setIsRequestSending(true)
				dispatch(ecp.sendForVerification(accessToken, ecpData.uuid))
			}
		}
	}

	const downloadPDF = async () => {
		setPdfLoading(true)
		const pdfResponse = await ecp.getDocumentEcp(accessToken, ecpData?.uuid)

		if (pdfResponse.status === 200) {
			setPdfLoading(false)
			setPowerOfAttorneyLoading(false)
			const pdf = pdfResponse?.data

			if (pdf) {
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(pdf)
				let fileName = 'Заявление'
				link.download = fileName
				link.click()
			}
		}
	}

	const downloadPowerAttorney = async () => {
		setPowerOfAttorneyLoading(true)
		const fileResponse = await ecp.getPowerAttorney(accessToken, ecpData?.uuid)

		if (fileResponse.status === 200) {
			setPowerOfAttorneyLoading(false)
			const file = fileResponse?.data

			if (file) {
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(file)
				let fileName = 'Доверенность'
				link.download = fileName
				link.click()
			}
		}
	}

	return (
		<div className={s.ecp}>
			<PopUpComplete description={notificationText} />
			<>
				{withParticipant && (
					<>
						<h4>Подпись представителя</h4>
						<p>
							Если есть представитель, подписывать документы будет он и
							требуется именно его ЭЦП.
						</p>
					</>
				)}

				<div className={s.ecp__editionWrapper}>
					<span>Подпись уже есть?</span>
					<div>
						<span
							className={
								editionStatus === 'Уже есть' ? s.editionWrapperActive : ''
							}
						>
							Уже есть
						</span>
						<span
							className={
								editionStatus === 'Выпустить' ? s.editionWrapperActive : ''
							}
						>
							Выпустить
						</span>
					</div>
				</div>

				{ecpData?.statuses_init?.error === null ? (
					<div className={s.ecp__statusEcp}>
						{ecpData?.application_status !== '3' &&
							ecpData?.application_status !== '4' &&
							ecpData?.application_status !== '10' && (
								<div>
									<h4>Выпуск ЭЦП</h4>
									{ecpData?.statuses_init?.fa &&
										ecpData?.statuses_init?.init_operation &&
										ecpData?.statuses_init?.passport_uploaded && (
											<span>{ecpData?.status_label}</span>
										)}
								</div>
							)}
							{ecpData?.application_status === '1' && (
								<div className={s.issueEcp__wrapperData__item}>
									<span>Код из MyDss</span>
									<span>
										<Input
											onChange={(e) =>
												dispatch(
													setAlias({
														value: e.target.value
													})
												)
											}
										/>
									</span>
									<p>Если для выпуска ЭЦП участник использует другое приложение, то пропустите этот шаг и сразу перейдите к загрузке фото документов</p>
								</div>
							)}
						{ecpData?.application_status === '1' && ecpData?.reissue && (
							<div className={s.addedProfile__numberOne}>
								<span>{ecpData?.status_label}</span>
							</div>
						)}

						{ecpData?.application_status === '1' &&
							!ecpData?.passport_uploaded && (
								<>
									<Button disabled={loadEcpStatus} FCOnclick={uploadPhoto}>
										Загрузить фото документов
									</Button>
									{loadEcpStatus && (
										<div className={s.loadStatusImg}>
											<span>Подождите...</span>
											<img src={preloader} alt='preloader' />
										</div>
									)}
								</>
							)}

						{ecpData?.application_status === '1' &&
							ecpData?.passport_uploaded && (
								<>
									{isRequestSending && 
										<div className={s.loadStatusImg}>
											<span>Подождите...</span>
											<img src={preloader} alt='preloader' />
										</div>
									}
									<Button disabled={isRequestSending} FCOnclick={sendRequests}>
										{isRequestSending ? 'Отправлено' : 'Отправить'}
									</Button>
									{ecpStatusText ? <span>{ecpStatusText}</span> : <></>}
								</>
							)}

						{/*      {ecpData?.application_status === '1' && ecpData?.comment
            && <>
              <Button FCOnclick={sendRequests}>Загрузить фото документов</Button>
              <span>{ecpData?.comment}</span>
            </>
            }*/}

						{ecpData?.application_status === '3' &&
							!ecpData?.reissue &&
							!ecpData?.documents_uploaded && (
								<div>
									<h4>Идентификация личности</h4>
									<p>
										Распечатайте заявление, подпишите его, 
										сфотографируйте, загрузите.
									</p>
									<div className={s.ecp__statusEcp__documentEcp}>
										<button
											disabled={pdfLoading}
											className={s.documentEcp__button}
											type='button'
											onClick={downloadPDF}
										>
											{pdfLoading ? 'Подождите...' : 'Скачать заявление'}
										</button>
										<div>
											<span>Фото подписанного документа</span>
											<div>
												<input type='file' onChange={handleChange} />
												<button>Выберите файл</button>
												<p>
													{file1?.name
														? `${file1?.name?.substring(0, 30)}${
																file1?.name?.length > 30 ? '...' : ''
														  }`
														: 'Файл не выбран'}
												</p>
											</div>
										</div>

										{ecpData.comment.length > 0 ? (
											<EcpComment
												photoPassport1={photoPassport1}
												setPhotoPassport1={setPhotoPassport1}
												photoPassport2={photoPassport2}
												setPhotoPassport2={setPhotoPassport2}
												ecpData={ecpData}
												powerOfAttorney={powerOfAttorney}
												setPowerOfAttorney={setPowerOfAttorney}
											/>
										) : null}

										{ecpStatusText ? (
											<span>{ecpStatusText}</span>
										) : (
											<button
												onClick={sendImgDocument}
												/*disabled={!(file1 && file2)}*/
											>
												Отправить
											</button>
										)}
									</div>
								</div>
							)}
						{ecpData?.application_status === '3' && ecpData?.reissue && (
							<span>{ecpData?.status_label}</span>
						)}
						{ecpData?.application_status === '3' &&
							ecpData?.documents_uploaded && (
								<span>{ecpData?.status_label}</span>
							)}

						{ecpData?.application_status === '4' && (
							<div className={s.addedProfile}>
								<h4>Выпуск ЭЦП</h4>
								<span>Ожидание запроса на выпуск сертификата</span>
								<span>Подтвердите создание профиля в приложении</span>
							</div>
						)}

						{ecpData?.application_status === '10' && (
							<div className={s.ecp__statusEcp__signatureReady}>
								<h4>Выпуск ЭЦП</h4>
								<span>&#10003; Подпись выпущена</span>
								<div>
									<span>
										Заявление на выпуск ЭЦП отправлено{' '}
										{new Date(ecpData?.date_created).toLocaleDateString()}
									</span>
									<span>
										Подпись выпущена{' '}
										{new Date(ecpData?.data_release).toLocaleDateString()}
									</span>
									<span>
										Действительна до{' '}
										{new Date(ecpData?.expire_date).toLocaleDateString()}
									</span>
								</div>
								<div>
									{date <= 3 && (
										<>
											<Button
												small
												disabled={loadEcpStatus}
												FCOnclick={reissueEcp}
											>
												Перевыпустить ЭЦП
											</Button>
											{loadEcpStatus && (
												<div className={s.loadStatusImg}>
													<span>Подождите...</span>
													<img src={preloader} alt='preloader' />
												</div>
											)}
										</>
									)}
								</div>
							</div>
						)}

						{/* <div>
              {(ecpData?.application_status === '0' || ecpData?.application_status === '1' || ecpData?.application_status === '3' || ecpData?.application_status === '4' || ecpData?.application_status === '6')
                && <Button small FCOnclick={sendForVerification}>Отправить на проверку</Button>}
            </div>*/}
					</div>
				) : (
					<div className={s.ecp__statusEcp__issueEcp}>
						{ecpData?.statuses_init?.error?.['Alias'] ? (
							<div className={s.newAlias}>
								<span>Код авторизации неверный,напишите верный код:</span>
								<Input
									onChange={(e) =>
										dispatch(setAlias({ value: e.target.value }))
									}
								/>
								<div className={s.newAlias__ecpStatusText}>{ecpStatusText}</div>
								<button onClick={() => sendNewAlias()}>
									Установить новый код авторизации
								</button>
							</div>
						) : (
							<>
								<IssueEcp
									tariffEcpData={tariffEcpData}
									setTariffEcpData={setTariffEcpData}
									idOrganizations={idOrganizations}
									setIdOrganizations={setIdOrganizations}
									setAlias={setAlias}
									organizations={organizations}
									ecpTariffs={ecpTariffs}
									personalDataEcp={personalDataEcp}
								/>
								{loadEcpStatus && (
									<div className={s.loadStatusImg}>
										<span>Подождите...</span>
										<img src={preloader} alt='preloader' />
									</div>
								)}
								{ecpStatusText && (
									<div className={s.loadStatus} style={{ color: '#1FA34A' }}>
										{ecpStatusText}
									</div>
								)}
								<div>
									{ecpData?.statuses_init?.error?.['Owner.Inn']?.map((item) => (
										<div key={item}>{item}</div>
									))}
									{ecpData?.statuses_init?.error?.['Owner.Snils']?.map(
										(item) => (
											<div key={item}>{item}</div>
										)
									)}
									<span className={s.ecp__statusEcp__issueEcp__textError}>
										{ecpData?.statuses_init?.error?.innerContent?.message}
									</span>
									<Button
										disabled={disabledButton || loadEcpStatus}
										FCOnclick={issueSignature}
									>
										Выпустить ЭЦП
									</Button>
								</div>
							</>
						)}
					</div>
				)}
			</>
		</div>
	)
}

export default Ecp
