import React, { useEffect, useState } from 'react'
import PersonalDataSidebar from '../../../../components/personalData/personalDataSidebar/personalDataSidebar'
import PersonalDataContent from '../../../../components/personalData/personalDataContent/personalDataContent'
import s from './personalData.module.scss'
import { Modal } from '../../../../components/modal/modal'
import { Link, useNavigate } from 'react-router-dom'
import { client } from '../../../../api/client/client'
import { useDispatch, useSelector } from 'react-redux'

const PersonalData = ({ menuSelection, setMenuSelection }) => {
	const [save, setSave] = useState(false)
	const [openWindowBack, setOpenWindowBack] = useState(false)
	const [width, setWidth] = useState(document.documentElement.clientWidth)
	const { globalPersonalData } = useSelector(
		(state) => state.globalPersonalData
	)
	const { documentName } = useSelector((state) => state.client)
	const accessToken = localStorage.getItem('accessToken')
	const appeal_id = localStorage.getItem('appealsId')
	const individual_id = localStorage.getItem('individual_id')
	const reprsentative_id = localStorage.getItem('representative_id')
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const onResize = (e) => {
		setWidth(e.target.innerWidth)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		window.addEventListener('resize', onResize)
		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [])

	const backFC = () => {
		dispatch(
			client.editClientData(
				individual_id ? individual_id : reprsentative_id,
				appeal_id,
				{
					save_status: false,
					name: globalPersonalData.name,
					surname: globalPersonalData.surname,
					patronymics: globalPersonalData.patronymics,
					gender: globalPersonalData.gender,
					birthday: globalPersonalData.birthday,
					place_birth: globalPersonalData.place_birth,
					reg_address: globalPersonalData.reg_address,
					citizenship: 'РФ',
                       inn: globalPersonalData.inn,
					identification_card: {
						title: 'Паспорт',
						series_number:
							globalPersonalData.identification_card.series_number,
						date_issue: globalPersonalData.identification_card.date_issue,
						issued_by_whom:
							globalPersonalData.identification_card.issued_by_whom,
						subsection_code:
							globalPersonalData.identification_card.subsection_code
					},
                       snils: {
                           number: globalPersonalData.snils.number
                       }
				},
				accessToken
			)
		)
		navigate('/taskListClient')
		setOpenWindowBack(false)
	}

	return (
		<div className={s.personalData}>
			{width < 600 && documentName ? (
				<></>
			) : (
				<PersonalDataSidebar
					save={save}
					setOpenWindowBack={setOpenWindowBack}
					width={width}
					setMenuSelection={setMenuSelection}
					menuSelection={menuSelection}
				/>
			)}

			{width < 600 && !documentName ? (
				<></>
			) : (
				<PersonalDataContent
					save={save}
					setSave={setSave}
					menuSelection={menuSelection}
					setOpenWindowBack={setOpenWindowBack}
					setMenuSelection={setMenuSelection}
					width={width}
				/>
			)}

			<Modal
				active={openWindowBack}
				onClose={width > 575 && true}
				setActive={setOpenWindowBack}
			>
				<div className={s.windowBack}>
					<h4>Уйти без сохранения?</h4>
					<span>
						Вы отредактировали некоторые поля, не хотите их сохранять?
					</span>
					<div>
						<span onClick={() => backFC()}>Сохранить</span>
						<Link to='/taskListClient' onClick={() => setOpenWindowBack(false)}>
							Не сохранять, уйти
						</Link>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default PersonalData
