import React, {useEffect, useState} from 'react'
import Checkbox from '../../components/checkbox/checkbox'
import s from './sendingRequestsRR.module.scss'
import Button from '../../components/button/button'
import Input from '../../components/input/input'
import {useDispatch, useSelector} from 'react-redux'
import {deals} from '../../api/deals/deals'
import {
  setAppealIdsPush,
  setAppealIdsSplice,
  setAppealsId,
  setArrDocumentsId,
  setArrDocumentsIdsPush,
  setArrDocumentsIdsSplice,
  setDeleteAppealIds,
  setDeleteDocumentsId
} from '../../redux/deals/deals'
import PopUpComplete from '../../components/popUpComplete/popUpComplete'
import {setRealtyTextStatus} from '../../redux/realty/realty'

const SendingRequestsRr = ({
                             additionalSendingRequestsRR,
                             appealId,
                             appealDeal,
                             windowAppealPP,
                             setWindowAppealPP
                           }) => {
  const dispatch = useDispatch()
  const accessToken = localStorage.getItem('accessToken')
  const appeal_id = localStorage.getItem('appeal_id')
  const {arrDocumentsIds, arrAppealIds} = useSelector((state) => state.deals)
  const {realtyTextStatus} = useSelector((state) => state.realty)
  const { globalPersonalData } = useSelector(
    (state) => state.globalPersonalData
  )
  const [newRequestValue, setNewRequestValue] = useState(null)
  const [statementDetailsData, setStatementDetailsData] = useState({
    statement_reg_date: '',
    previous_statement_kuvd: '',
    reg_number: '',
    reg_date: '',
    err_record: '',
    corr_record: '',
    note: ''
  })

  useEffect(() => {
    if (windowAppealPP) {
      setStatementDetailsData({
        ...statementDetailsData,
        statement_reg_date: '',
        previous_statement_kuvd: '',
        reg_number: '',
        reg_date: '',
        err_record: '',
        corr_record: '',
        note: ''
      })
    }
  }, [windowAppealPP])

  useEffect(() => {
    if (!realtyTextStatus) return
    setTimeout(() => {
      dispatch(setRealtyTextStatus(null))
      if (realtyTextStatus.redirect) {
        setWindowAppealPP(false)
      }
    }, 3000)
  }, [realtyTextStatus])

  useEffect(() => {
    if (windowAppealPP) {
      dispatch(
        setArrDocumentsId(
          appealDeal?.documents?.map((item) => {
            return item.id
          })
        )
      )

      const arr = []
      appealDeal?.participant.forEach((id) => {
        arr.push(id.participant_id)
      })
      dispatch(setAppealsId(arr))
    } else {
      dispatch(setAppealsId([]))
      dispatch(setArrDocumentsId([]))
    }
  }, [windowAppealPP])

  const handleChangeDocuments = (id) => {
    dispatch(setDeleteDocumentsId(id))

    if (arrDocumentsIds.includes(id)) {
      arrDocumentsIds.forEach((item, i) => {
        if (item === id) {
          dispatch(setArrDocumentsIdsSplice(i))
        }
      })
    } else {
      dispatch(setArrDocumentsIdsPush(id))
    }
  }

  const handleChangeSignatories = (id) => {
    dispatch(setDeleteAppealIds(id))

    if (arrAppealIds.includes(id)) {
      arrAppealIds.forEach((item, i) => {
        if (item === id) {
          dispatch(setAppealIdsSplice(i))
        }
      })
    } else {
      dispatch(setAppealIdsPush(id))
    }
  }

  const sendRequest = () => {
    dispatch(
      deals.statementGenerate(
        appeal_id,
        accessToken,
        statementDetailsData,
        arrDocumentsIds,
        arrAppealIds
      )
    )
  }

  return (
    <>
      <PopUpComplete description={realtyTextStatus}/>
      <div className={s.sendingRequestsRr__wrapper}>
        {additionalSendingRequestsRR ? (
          <div className={s.sendingRequestsRr__select}>
            <h4>Оформить вспомогательную заявку</h4>
            <Input
              value={newRequestValue?.text}
              select
              dataList={dataSelect}
              setSelectedValue={setNewRequestValue}
            />
          </div>
        ) : (
          <div className={s.sendingRequestsRr__appealType}>
            <h4>Тип обращения</h4>
            <span>{appealId?.title}</span>
          </div>
        )}
        <div className={s.sendingRequestsRr__documents}>
          <h4>Приложенные документы</h4>
          <div>
            {appealDeal?.documents?.filter(
					    document => document.is_statement !== true).map((item) => (
              <label key={item.id}>
                <Checkbox
                  onChange={() => handleChangeDocuments(item.id)}
                  defaultChecked
                  item={item}
                  //disabled={deleteDocumentsId?.find((id) => id == item?.id)}
                />
                {item.document_type}
              </label> 
            ))}
          </div>
        </div>
        <div className={s.sendingRequestsRr__signatories}>
          <h4>Подписанты</h4>
          <div>
            {appealDeal?.participant?.map((name) => {
              return (
                <>
                <label >
                  <Checkbox
                    onChange={() =>
                      handleChangeSignatories(name.participant_id)
                    }
                    defaultChecked
                    item={name}
                    key={name.id}
                    /*disabled={deleteAppealIds?.find((id) => id == name.participant_id)}*/
                  />
                  {name?.full_name
                    ? name?.full_name
                    : name.representative?.full_name}
                </label>
                </>
              )
            })}
          </div>
        </div>

        {appealDeal?.type_appeal?.type_statement_action_code === '659411111113'
          ? <div className={s.sendingRequestsRr__textArreaBlock}>
            <div>
              <span>Укажите дату внесения данных в запись реестра:</span>
              <Input
                value={statementDetailsData.statement_reg_date}
                type='date'
                onChange={(e) =>
                  setStatementDetailsData({
                    ...statementDetailsData,
                    statement_reg_date: e.target.value
                  })
                }
              />
            </div>
            <div>
              <span>Укажите номер КУВД исходного обращения:</span>
              <textarea
                value={setStatementDetailsData.previous_statement_kuvd}
                onChange={(e) =>
                  setStatementDetailsData({
                    ...statementDetailsData,
                    previous_statement_kuvd: e.target.value
                  })
                }
              />
            </div>
            <div>
              <span>Укажите ошибочную запись:</span>
              <textarea
                value={statementDetailsData.err_record}
                onChange={(e) =>
                  setStatementDetailsData({
                    ...statementDetailsData,
                    err_record: e.target.value
                  })
                }
              />
            </div>
            <div>
              <span>Укажите корректную запись:</span>
              <textarea
                value={statementDetailsData.corr_record}
                onChange={(e) =>
                  setStatementDetailsData({
                    ...statementDetailsData,
                    corr_record: e.target.value
                  })
                }
              />
            </div>
          </div>
          : null}


        {appealDeal?.type_appeal?.type_statement_action_code === '659411111120' || appealDeal?.type_appeal?.type_statement_action_code === '659411111117' ? (
          <div className={s.sendingRequestsRr__textArreaBlock}>
            <div>
              <span>Укажите дату предоставления документов:</span>
              <Input
                value={statementDetailsData.statement_reg_date}
                type='date'
                onChange={(e) =>
                  setStatementDetailsData({
                    ...statementDetailsData,
                    statement_reg_date: e.target.value
                  })
                }
              />
            </div>
            <div>
              <span>Укажите номер КУВД исходного обращения:</span>
              <textarea
                value={setStatementDetailsData.previous_statement_kuvd}
                onChange={(e) =>
                  setStatementDetailsData({
                    ...statementDetailsData,
                    previous_statement_kuvd: e.target.value
                  })
                }
              />
            </div>
            <div>
              <span>Дополнительная информация:</span>
              <textarea
                value={setStatementDetailsData.previous_statement_kuvd}
                onChange={(e) =>
                  setStatementDetailsData({
                    ...statementDetailsData,
                    note: e.target.value
                  })
                }
              />
            </div>

            {appealDeal?.type_appeal?.type_statement_action_code === '659411111117'
              ? <div className={s.sendingRequestsRr__wrapper__stopRegistrationWrapper__item}>
                <span>Срок приостановки</span>
                <Input
                  value={statementDetailsData.time}
                  type='date'
                  onChange={(e) =>
                    setStatementDetailsData({
                      ...statementDetailsData,
                      time: e.target.value
                    })
                  }
                />
              </div>
              : null}
          </div>
        ) : null}


        {appealDeal?.type_appeal?.type_statement_action_code === '659411111118' ||appealDeal?.type_appeal?.type_statement_action_code === '659411111119'  ? (
          <div className={s.sendingRequestsRr__textArreaBlock}>
            <div>
              <span>Укажите дату предоставления документов:</span>
              <Input
                value={statementDetailsData.statement_reg_date}
                type='date'
                onChange={(e) =>
                  setStatementDetailsData({
                    ...statementDetailsData,
                    statement_reg_date: e.target.value
                  })
                }
              />
            </div>
            <div>
              <span>Укажите номер КУВД исходного обращения:</span>
              <textarea
                value={setStatementDetailsData.previous_statement_kuvd}
                onChange={(e) =>
                  setStatementDetailsData({
                    ...statementDetailsData,
                    previous_statement_kuvd: e.target.value
                  })
                }
              />
            </div>
          </div>
        ) : null}


        {/*{appealDeal?.type_appeal?.statement_details ===
        'registryErrorAction' && (
          <div className={s.sendingRequestsRr__textArreaBlock}>
            <div>
              <span>Укажите номер записи реестра:</span>
              <textarea
                value={statementDetailsData.reg_number}
                onChange={(e) =>
                  setStatementDetailsData({
                    ...statementDetailsData,
                    reg_number: e.target.value
                  })
                }
              />
            </div>
            <div>
              <span>Укажите дату внесения данных в запись реестра:</span>
              <Input
                value={statementDetailsData.reg_date}
                type='date'
                onChange={(e) =>
                  setStatementDetailsData({
                    ...statementDetailsData,
                    reg_date: e.target.value
                  })
                }
              />
            </div>
            <div>
              <span>Укажите ошибочную запись:</span>
              <textarea
                value={statementDetailsData.err_record}
                onChange={(e) =>
                  setStatementDetailsData({
                    ...statementDetailsData,
                    err_record: e.target.value
                  })
                }
              />
            </div>
            <div>
              <span>Укажите корректную запись:</span>
              <textarea
                value={statementDetailsData.corr_record}
                onChange={(e) =>
                  setStatementDetailsData({
                    ...statementDetailsData,
                    corr_record: e.target.value
                  })
                }
              />
            </div>
          </div>
        )}*/}


        <div className={s.sendingRequestsRr__description}>
          <Button small={true} FCOnclick={sendRequest}>
            Запросить подписи у участников сделки
          </Button>
          <span>
						Обращение будет отправлено, как только все участники сделки подпишут
						обращение.
					</span>
        </div>
      </div>
    </>
  )
}

export default SendingRequestsRr

const dataSelect = [
  {
    id: 0,
    text: 'Договор купли продажи'
  },
  {
    id: 1,
    text: 'Договор участия в долевом строительстве'
  },
  {
    id: 2,
    text: 'Соглашение об уступке права требования по ДДУ'
  },
  {
    id: 3,
    text: 'Соглашение об изменении условий ДДУ'
  },
  {
    id: 4,
    text: 'Соглашение об изменении ДУПТ'
  },
  {
    id: 5,
    text: 'Соглашение о расторжении ДДУ'
  },
  {
    id: 6,
    text: 'Договор аренды'
  },
  {
    id: 7,
    text: 'Соглашение об изменении условий договора аренды'
  },
  {
    id: 8,
    text: 'Прекращение права'
  },
  {
    id: 9,
    text: 'Переход права собственности по ДКП'
  },
  {
    id: 10,
    text: 'Новое право собственности'
  },
  {
    id: 11,
    text: 'Заявление о прекращении государственной регистрации'
  },
  {
    id: 12,
    text: 'Заявление о возобновлении государственной регистрации'
  }
]
