import React, { useEffect, useState } from 'react'
import DealSidebar from '../../../../components/deal/dealSidebar/dealSidebar'
import DealContent from '../../../../components/deal/dealContent/dealContent'
import s from './deal.module.scss'
import ChatWindow from '../../../../blocks/chatWindow/chatWindow'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { deals } from '../../../../api/deals/deals'
import { setDealId } from '../../../../redux/deals/deals'
import { manager } from '../../../../api/manager/manager'
import { useNavigate } from 'react-router-dom'
import { appeals } from '../../../../api/appeals'
import { setDocument } from '../../../../redux/documents/documents'
import { clearEcpState } from '../../../../redux/ecp'
import {
	setDataLoad,
	setHeaderTypeData,
	setLoadingApp,
	setPhotoPagePassport,
	setPhotoPassportPdf1,
	setPhotoPassportPdf2,
	setPhotoSnilsPdf,
	setPhotoPassportPdf1Representative,
	setPhotoPassportPdf2Representative,
	setPhotoSnilsPdfRepresentative,
	setRepresentativeData,
	setRepresentativeStatus,
	setRole,
	setSignaturePagePassport,
	setSnilsPagePassport,
	setType
} from '../../../../redux/manager/manager'
import { setGlobalPersonalData } from '../../../../redux/globalPersonalData/globalPersonalData'
import { setDescriptionPrice } from '../../../../redux/payments/payments'

const Deal = () => {
	const accessToken = localStorage.getItem('accessToken')
	const [activeChat, setActiveChat] = useState(null)
	const [openChatWindow, setOpenChatWindow] = useState(false)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { dealId } = useParams()
	const { deal } = useSelector((state) => state.deals)
	const { appealDeal } = useSelector((state) => state.appeals)
	const [appealId, setAppelId] = useState()

	useEffect(() => {
		if (deal?.seller) {
			localStorage.setItem('sellerStatus', deal?.seller)
		}

		if (appealDeal?.id !== 'null') {
			localStorage.setItem('appeal_id', appealDeal?.id)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appealDeal?.id])


	useEffect(() => {
		if (dealId === 'null') {
			const dealIdLocal = localStorage.getItem('dealId')
			navigate(`/deal/${dealIdLocal}`)
			dispatch(setDealId(dealIdLocal))
			dispatch(deals.deal(dealIdLocal, accessToken))	
			dispatch(setGlobalPersonalData({}))
		} else {
			dispatch(setDealId(dealId))
		}

		if(appealId?.id) {
			dispatch(manager.getRoles(appealId?.id, accessToken))
		}
		dispatch(setDescriptionPrice(null))
		dispatch(setRole(null))
		dispatch(setDataLoad(false))
		dispatch(deals.deal(dealId, accessToken))
		dispatch(setHeaderTypeData('participant'))
		dispatch(setRepresentativeStatus(false))
		dispatch(setDocument(null))
		dispatch(setGlobalPersonalData({}))
		dispatch(setRepresentativeData(false))
		dispatch(setLoadingApp(false))
		dispatch(clearEcpState())
		localStorage.removeItem('ecpStatus')
		dispatch(setType('INDIVIDUAL'))
		dispatch(setPhotoPagePassport(null))
		dispatch(setSignaturePagePassport(null))
		dispatch(setSnilsPagePassport(null))
		dispatch(setPhotoPassportPdf1(null))
		dispatch(setPhotoPassportPdf2(null))
		dispatch(setPhotoSnilsPdf(null))
		dispatch(setPhotoPassportPdf1Representative(null))
		dispatch(setPhotoPassportPdf2Representative(null))
		dispatch(setPhotoSnilsPdfRepresentative(null))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const foundActiveAppeal = deal?.appeals?.find(
			(appeal) => appeal?.status === true
		)
		setAppelId(foundActiveAppeal)
		if (foundActiveAppeal?.id) {
			dispatch(appeals.appealDeal(foundActiveAppeal?.id))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deal])

	useEffect(() => {
		if (!activeChat) return
		setOpenChatWindow(true)
	}, [activeChat])

	const handleAddArchive = () => {
		dispatch(deals.dealsArchive(dealId, accessToken))
	}

	return (
		<div className={s.deal}>
			<DealSidebar
				appealId={appealId}
				onChangeAppealId={setAppelId}
				deal={deal}
				handleAddArchive={handleAddArchive}
				setActiveChat={setActiveChat}
				activeChat={activeChat}
				setOpenChatWindow={setOpenChatWindow}
			/>
			{openChatWindow ? (
				<ChatWindow activeChat={activeChat} />
			) : (
				<DealContent appealId={appealId} appealDeal={appealDeal} deal={deal} />
			)}
		</div>
	)
}

export default Deal
