import React from 'react'
import s from './textarea.module.scss'

const Textarea = ({ disabled, placeholder, ...props }) => {
	return (
		<>
			<textarea
				{...props}
				className={s.textarea}
				disabled={disabled}
				placeholder={placeholder}
			/>
		</>
	)
}

export default Textarea
