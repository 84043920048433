import React from 'react'
import { Modal } from '../../components/modal/modal'
import s from './documentSignatureWindow.module.scss'

const DocumentSignatureWindow = ({
	requestSignatureWindow,
	closeRequestSignatureWindow,
	test
}) => {
	return (
		<div className={s.documentSignatureWindow}>
			<Modal
				active={requestSignatureWindow}
				setActive={closeRequestSignatureWindow}
				onClose={true}
			>
				<h3>Подпись документа</h3>
				<div className={s.documentSignatureWindow__info}>
					<span>{test.name}</span>
					{test.signature ? (
						<span>
							Подписано {test.dateSignature}
							<button>{test.namePdf}</button>
						</span>
					) : (
						<div className={s.documentSignatureWindow__info__signatureFalse}>
							<span>Не подписано</span>
							<p>
								Если обычно подписываете через мобильное приложение, откройте
								его, и подпишите документы там.
							</p>
							<span>Подписать используя мобильное приложение...</span>
						</div>
					)}
				</div>
				{test.signature && (
					<div className={s.documentSignatureWindow__deleteSignature}>
						<span>Удалить подпись</span>
					</div>
				)}
			</Modal>
		</div>
	)
}

export default DocumentSignatureWindow
