import axios from 'axios'
import { setUserId, setUserName } from '../redux/login/login'

const baseURL = process.env.REACT_APP_BASE_URL

export const user = {
	getUserName(token) {
		return async (dispatch) => {
			try {
				const response = await axios.get(`${baseURL}/user_data`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				dispatch(setUserName(response.data.full_name))
				dispatch(setUserId(response.data.user_id))
			} catch (error) {
				console.log(error)
			}
		}
	}
}
