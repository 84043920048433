import React, { useEffect } from 'react'
import { useState } from 'react'
import { ViewObjectPayment } from '../../components/view-object-payment/view-object-payment'
import s from './objectPayment.module.scss'
import { Link } from 'react-router-dom'

const ObjectPayment = ({ object }) => {
	return (
		<div className={s.objectPayment}>
			<h3>Объект</h3>

			<Link
				to={`/viewObjectPayment/${object?.id}`}
				className={s.objectPayment__wrapper}
			>
				<div className={s.objectPayment__wrapper__item}>
					<h4>{object?.cadastral_number}</h4>
				</div>
				<div className={s.objectPayment__wrapper__item}>
					<p>{object?.address}</p>
				</div>
			</Link>
		</div>
	)
}

export default ObjectPayment
