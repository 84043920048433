import React from 'react'
import s from './duties.module.scss'
import { DutyInvoice } from '../../components/duty-invoice/duty-invoice'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { payments } from '../../api/payments'
import { useEffect } from 'react'
import { setAppealDeal } from '../../redux/deals/deals'
import { useNavigate } from 'react-router-dom'

const Duties = ({ appealDeal }) => {
	const accessToken = localStorage.getItem('accessToken')
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [openModal, setOpenModal] = useState(false)
	const [openDownloadReceipt, setOpenDownloadReceipt] = useState(false)
	const { newAppealsPaymentsWS } = useSelector((state) => state.deals)

	useEffect(() => {
		if (!newAppealsPaymentsWS) return

		if (newAppealsPaymentsWS?.appeal_id === appealDeal?.id) {
			
			dispatch(
				setAppealDeal(
					appealDeal.payments.map((item) => {
						return item.id === newAppealsPaymentsWS?.payment.id
							? newAppealsPaymentsWS?.payment
							: item
					})
				)
			)
		}
	}, [newAppealsPaymentsWS])

	const handleGetPayment = (paymentId) => {
		dispatch(payments.payment(paymentId, accessToken,navigate))
	}

	useEffect(() => {
		if (!openModal) {
			setOpenDownloadReceipt(false)
		}
	}, [openModal])

	return (
		<div className={s.duties}>
			<div>
				<h3>Пошлины</h3>
				<div>
					<span>+</span>
					<span>Пошлина</span>
				</div>
			</div>
			{appealDeal?.payments?.length !== 0 ? (
				appealDeal?.payments?.map((payment) => (
					<div
						key={payment.id}
						className={`${s.duties__wrapper} ${
							appealDeal?.payments[appealDeal?.payments.length - 1]?.id ===
							payment?.id
								? s.duties__wrapperLastItem
								: undefined
						}`}
						onClick={() => {
							setOpenDownloadReceipt(true)
							setOpenModal(true)
							handleGetPayment(payment?.id)
							navigate('/dutyLoading')
						}}
					>
						<span>{payment?.amount}₽</span>
						<span style={{maxWidth:'500px'}}>{payment?.payment_type}</span>
						<span
							style={{maxWidth:'100px'}}
						>
							{payment?.full_name}
						</span>
					</div>
				))
			) : (
				<span className={s.noDuties}>Пошлины не выставлены</span>
			)}
		</div>
	)
}

export default Duties
