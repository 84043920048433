import React, {useEffect} from 'react'
import {useState} from 'react'
import s from './paymentDuty.module.scss'
import {Modal} from '../modal/modal'
import QRCode from 'qrcode.react'
import {useDispatch, useSelector} from 'react-redux'
import {payments} from "../../api/payments";
import SigningDocumentsContent from "../../blocks/SigningDocumentsContent/signingDocumentsContent";
import {Link} from "react-router-dom";
import Button from "../button/button";
import {Viewer, Worker} from "@react-pdf-viewer/core";
import cloudUploadImg from "../../common/images/cloud-upload.svg";
import cloudUploadWhiteImg from "../../common/images/cloud-upload-white.svg";
import ru_RU from '@react-pdf-viewer/locales/lib/ru_RU.json'
import FileViewer from 'react-file-viewer'
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";
import {useParams} from "react-router";
import {client} from "../../api/client/client";
import PowerAttorneyComponents from "../powerAttorneyComponents/powerAttorneyComponents";


const pdfjs = import('pdfjs-dist/build/pdf').then(() => {
  const pdfjsWorker = import('pdfjs-dist/build/pdf.worker.entry').then(() => {
    if (pdfjs.GlobalWorkerOptions) {
      pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
    }
  })
})

const PaymentDuty = ({openWindowPaymentDuty, setOpenWindowPaymentDuty, width}) => {
  const {documentId} = useParams()
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const accessToken = localStorage.getItem('accessToken')
  const idPayment = localStorage.getItem('idPayment')

  const {openedPayment} = useSelector((state) => state.payments)
  const dispatch = useDispatch()
  const [height, setHeight] = useState(document.documentElement.clientHeight)
  const appealId = localStorage.getItem('appealId')
  const [file, setFile] = useState(false)
  const [fileDataURL, setFileDataURL] = useState(false)
  const [fileDataType, setFileDataType] = useState(false)
  const [pdfFile, setPdfFile] = useState(null)
  const [pdfFileError, setPdfFileError] = useState('')

  const fileType = ['application/pdf']

  useEffect(() => {
    dispatch(payments.payment(idPayment, accessToken))
  }, [])


  useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(document.documentElement.clientHeight)
    })

    return () => {
      window.removeEventListener('resize', {})
    }
  }, [])


  useEffect(() => {
    setPdfFile(null)
    setFileDataType(null)
    setFileDataURL(null)


  }, [documentId])

  useEffect(() => {
    if (openedPayment?.receipt) {
      setPdfFile(
        `data:${openedPayment?.media_type};base64,${openedPayment?.receipt}`
      )
    }
  }, [openedPayment])

  useEffect(() => {
    setPdfFile(null)
    setFileDataType(null)
    setFileDataURL(null)
  }, [])

  const uploadDocument = (selectedFile) => {
    const formData = new FormData()
    selectedFile && formData.append('file', selectedFile)

    dispatch(client.uploadDocument(appealId, documentId, formData, accessToken))
  }

  const handleChange = (event) => {
    let fileType = ext(event.target.files[0].name)

    setFileDataType(false)
    setFile(false)

    function ext(name) {
      var m = name.match(/\.([^.]+)$/)
      return m && m[1]
    }

    if (fileType === 'doc') {
      setFileDataType('docx')
    } else {
      setFileDataType(ext(event.target.files[0].name))
    }

    setFile(event.target.files[0])
    uploadDocument(event.target.files[0])
  }

  useEffect(() => {
    let fileReader,
      isCancel = false
    if (openedPayment?.receipt) {
      const fileType =
        openedPayment?.media_type.split('.')[
        openedPayment?.media_type.split('.').length - 1
          ]
      setFileDataURL(
        `data:${
          openedPayment?.media_type.split('/')[1] === 'msword'
            ? 'application/docx'
            : openedPayment?.media_type
        };base64,${openedPayment?.receipt}`
      )
      setFileDataType(
        fileType === 'document'
          ? 'docx'
          : fileType === 'sheet'
            ? 'xlsx'
            : openedPayment?.media_type.split('/')[1] === 'msword'
              ? 'docx'
              : openedPayment?.media_type.split('/')[1]
      )
    }
    return () => {
      isCancel = true
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [file, openedPayment])


  return (
    <Modal
      active={openWindowPaymentDuty}
      setActive={setOpenWindowPaymentDuty}
      fullScreen={true}
      onClose={true}
      mobileHeaderText='Пошлина'
    >
      <div className={s.paymentDutyWrapper} style={width < 800 ? {margin:'0px',maxWidth:'unset',padding:'30px 0 0 0'}:null }>
        {width > 800
        && <h4 style={{marginBottom: '50px'}}>Пошлина</h4>
        }

        {width < 800 ? (
          <div className={s.signingDocumentsContentMobile}>
            {pdfFile &&
            <div
              style={width < 576 ? {height: `${height - 200}px`} : {}}
              className={s.signingDocumentsContentMobile__wrapper}
            >
              {fileDataURL && (
                <div
                  style={
                    openedPayment?.media_type !== 'application/pdf'
                      ? {padding: '10px 20px 10px 20px'}
                      : {}
                  }
                  className={s.signingDocumentsContentMobile__wrapper__file}
                >
                  {openedPayment?.media_type === 'application/pdf' ? (
                    <Worker workerUrl='/pdf.worker.bundle.worker.js'>
                      <Viewer
                        localization={ru_RU}
                        fileUrl={fileDataURL}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </Worker>
                  ) : openedPayment.media_type === 'image/jpeg' ? (
                    <img
                      title='file'
                      src={fileDataURL}
                      style={{opacity: '1'}}
                      alt=''
                    />
                  ) : (
                    <FileViewer
                      fileType={fileDataType}
                      filePath={fileDataURL}
                    />
                  )}
                </div>
              )}
            </div>
            }
          </div>
        ) : (
          <div
            className={s.signingDocumentsContent}
            style={!fileDataURL ? {display: 'flex'} : {}}
          >
            {fileDataURL &&
            <div className={s.signingDocumentsContent__file}>
              {openedPayment?.media_type === 'application/pdf' ? (
                <Worker workerUrl='/pdf.worker.bundle.worker.js'>
                  <Viewer
                    localization={ru_RU}
                    fileUrl={fileDataURL}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </Worker>
              ) : openedPayment.media_type === 'image/jpeg' ? (
                <img
                  title='file'
                  src={fileDataURL}
                  style={{opacity: '1', maxHeight: '700px'}}
                  alt=''
                />
              ) : (
                <FileViewer fileType={fileDataType} filePath={fileDataURL}/>
              )}
            </div>
            }
          </div>
        )}
      </div>
    </Modal>
  )
}
export default PaymentDuty
