import React, { useState } from 'react'
import SigningDocumentsSidebar from '../../blocks/SigningDocumentsSidebar/signingDocumentsSidebar'
import SigningDocumentsContent from '../../blocks/SigningDocumentsContent/signingDocumentsContent'
import s from './signingDocuments.module.scss'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { client } from '../../api/client/client'

const SigningDocuments = () => {
	const accessToken = localStorage.getItem('accessToken')

	const dispatch = useDispatch()
	const appealId = localStorage.getItem('appealId')

	const { documents } = useSelector((state) => state.client)

	const [selectDocument, setSelectDocument] = useState(null)
	const [width, setWidth] = useState(document?.documentElement?.clientWidth)

	const onResize = (e) => {
		setWidth(e.target.innerWidth)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		window.addEventListener('resize', onResize)

		dispatch(client.getDocuments(appealId, accessToken))

		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [])

	return (
		<div className={s.signingDocuments}>
			<SigningDocumentsSidebar
				documents={documents}
				setSelectDocument={setSelectDocument}
				width={width}
			/>
			<SigningDocumentsContent
				selectDocument={selectDocument}
				width={width}
				setSelectDocument={setSelectDocument}
			/>
		</div>
	)
}

export default SigningDocuments
