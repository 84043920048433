import React from 'react'
import s from './sales-man.module.scss'

export const SaleMan = ({ seller, FCSelectSeller }) => {
	return (
		<div className={s.salesMan}>
			<div className={s.salesMan__field}>
				<p>Продавец</p>
				<div className={s.salesMan__field__salesManBtn}>
					<button
						onClick={() => FCSelectSeller('UNISTROY')}
						id={seller === 'UNISTROY' ? s.sellerButton : ''}
					>
						Унистрой
					</button>
					<button
						onClick={() => FCSelectSeller('THIRD_PARTY')}
						id={seller === 'THIRD_PARTY' ? s.sellerButton : ''}
					>
						Третье лицо
					</button>
				</div>
			</div>

			<div className={s.salesMan__textInfo}>
				<p>Указывайте унистрой, если сделка с недвижимостью из нашей базы.</p>
				<p>Третье лицо — если просто регистрируете сделку третьих лиц.</p>
			</div>
		</div>
	)
}
